import React from 'react'
import {
	HeaderAvatar,
	classes,
	HeaderContainer,
	HeaderContent,
	HeaderTitle,
	HeaderLiveStatus,
	HeaderActions,
	AvatarAndTitleWrapper
} from './style'
import Theme from '@styles/theme'
import { mapDispatchToProps } from '@helpers/wrappers/withReduxStore'
import { State } from '@stores/index'
import { connect } from 'react-redux'
import Placeholder from '@components/Micro/Placeholder'
import { classGenerator } from '@styles/sharedLogics'
import LiveActions, { AvailableAction } from '../LiveActions'

interface Props {
	uiIsLight?: boolean
	storeDatas?: any
	className?: any
	separator?: boolean
	actions?: Array<AvailableAction>
	noAvatar?: boolean
	noTitle?: boolean
}

const mapStateToProps = (state: State, props: Props) => {
	return { ...state, ...props }
}

const Header: React.FC<Props> = ({ uiIsLight, storeDatas, className, actions, separator, noAvatar, noTitle }) => {
	//
	let $avatar = null
	let $title = null

	if (storeDatas) {
		$avatar = (
			<Placeholder
				sizes={[[100, 100]]}
				media={storeDatas.event.event.settings.branding.avatar}
				color={Theme.colors.cta_main}
				fit="cover"
			/>
		)
		$title = storeDatas.event.event.settings.branding.brandName
	}

	let status = 'LIVE'

	if (storeDatas.event.event.status === 'replay') {
		status = 'REPLAY'
	}

	if (storeDatas.event.event.status === 'finishing') {
		status = 'FINISHING'
	}
	return (
		<>
			<HeaderContainer
				className={classGenerator(classes.root, className, {
					[classes.hidden]: uiIsLight,
					[classes.separator]: separator
				})}
			>
				<AvatarAndTitleWrapper>
					{!noAvatar && <HeaderAvatar>{$avatar}</HeaderAvatar>}
					{!noTitle && (
						<HeaderContent>
							<HeaderTitle>{$title}</HeaderTitle>
							<HeaderLiveStatus>{status}</HeaderLiveStatus>
						</HeaderContent>
					)}
				</AvatarAndTitleWrapper>
				<HeaderActions className={classes.actionsContainer}>
					{actions?.length > 0 && <LiveActions actions={actions} />}
				</HeaderActions>
			</HeaderContainer>
		</>
	)
}

export const headerClasses = classes

export default connect(mapStateToProps, mapDispatchToProps)(Header)
