import styled from '@emotion/styled'
import Theme from '@styles/theme'

const PREFIX = 'poll'

export const pollClasses = {
	root: PREFIX,
	question: `${PREFIX}-question`,
	answer: `${PREFIX}-answer`
}
export const PollQuestion = styled.div`
	font-family: ${() => Theme.fontFamilies.Main};
	font-weight: ${() => Theme.fontWeights.bold};
	color: ${() => Theme.colors.text_main};
	font-size: 20px;
	line-height: 26px;
	margin: ${Theme.spacing(6)} 0;
	width: 100%;
`

export const PollAnswersWrapper = styled.div`
	font-family: ${() => Theme.fontFamilies.Second};
	font-weight: ${() => Theme.fontWeights.regular};
	width: 100%;
	margin-bottom: ${Theme.spacing(5)};
`

export const PollAnswer = styled.div`
	font-size: 14px;
	line-height: 18px;
	padding: 10px 15px;
	border-radius: 8px;
	border: 1px solid;
	cursor: pointer;
	white-space: nowrap;
	transition: width ease 0.8s;
	margin-bottom: ${Theme.spacing(3)};
	color: ${() => Theme.colors.text_main};
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;

	${Theme.fadeAndScaleOnClick(false)}
`
