import { css } from '@emotion/react'
import Theme from '@styles/theme'
import styled from '@emotion/styled'

export default {
	isHidden: css`
		display: none;
	`,

	input: css`
		width: 100%;
		background: none;
		border: 1px solid ${Theme.colors.black(0.2)};
		font-size: 13px;
		/* padding: 10px 12px; */
		padding: 16px 20px 15px;
		outline: none;
		border-radius: 8px;
		appearance: none;
		font-family: ${Theme.fontFamilies.Second};
		font-weight: ${Theme.fontWeights.bold};
		transition: border 600ms ${Theme.eases.outExpo} 0ms;
		&:focus {
			border: 1px solid ${Theme.colors.black(0.5)};
		}
	`,

	textArea: css`
		min-width: 100%;
		max-width: 100%;
		height: 100%;
		min-height: 100%;
		max-height: 100%;
		resize: none;
		font-family: ${Theme.fontFamilies.Second};
		font-weight: ${Theme.fontWeights.bold};
	`,

	isDisabled: css`
		pointer-events: none;
		opacity: 0.5;
	`,

	error: css`
		border: 1px solid ${Theme.colors.red()};
	`
}

export const Label = styled.div<{ hasError: boolean }>`
	font-family: ${Theme.fontFamilies.Second};
	font-weight: ${Theme.fontWeights.bold};
	font-size: 10em;
	margin-bottom: ${Theme.spacing(2)};
	color: ${({ hasError }) => (hasError ? Theme.colors.red() : Theme.colors.text_main)};
`

export const InputField = styled.div`
	position: relative;
`

export const ErrorMessage = styled.div`
	margin-top: ${Theme.spacing(2)};
`

export const RequiredLabel = styled.div<{ color: string }>`
	font-family: ${Theme.fontFamilies.sansSerif};
	font-weight: ${Theme.fontWeights.regular};
	font-size: 10em;
	text-transform: uppercase;
	text-align: right;
	margin-top: ${Theme.spacing(2)};
	color: ${({ color }) => color};
`

export const CharacterCount = styled.div<{ isMobile: boolean }>`
	position: absolute;
	right: 15px;
	bottom: ${({ isMobile }) => (isMobile ? '15px' : '18px')};
`
