import { PanelsType } from '@components/Notifiers/Panels/constants'
import { useNotificationsContext } from '@helpers/contexts/notifications'
import React from 'react'
import Theme from '@styles/theme'
import IconCta, { IconProps } from '@components/Micro/IconCta'
import Bus from '@helpers/bus'
import Constants from '@constants'

interface CatalogButtonProps {
	playerData?: any
	products?: any
	show?: boolean
}

export const CatalogButton: React.FC<CatalogButtonProps> = ({ playerData, products, show = true }) => {
	const { openPanel, catalogOpened } = useNotificationsContext()

	const iconProps: IconProps = {
		...(playerData?.brand_live_product_icon
			? { url: playerData?.brand_live_product_icon }
			: { name: 'catalog_v2', fill: Theme.colors.white() })
	}

	const handleClick = (e) => {
		e.stopPropagation()
		if (catalogOpened) return // Don't try to open catalog again if already opened

		Bus.send(Constants.bus.player.catalog_clicked)
		openPanel(PanelsType.CATALOG, {
			title: playerData?.ui.panel_catalog_title,
			back: playerData?.ui.panel_back,
			data: products
		})
	}

	return <>{show && <IconCta iconProps={iconProps} onClick={handleClick} />}</>
}
