import DeactivatedChat from '@components/Micro/DeactivatedChat'
import ChatBox, { PinningPosition } from '@components/StatusManager/LiveAndReplay/components/ChatBox'
import ChatField from '@components/StatusManager/LiveAndReplay/components/ChatField/Modular'
import { usePlayerContext } from '@helpers/contexts/player'
import { classGenerator as cx } from '@styles/sharedLogics'
import React, { useState } from 'react'
import ReplayChatBox from '../ChatBox/ReplayChat'
import LiveActions, { AvailableAction } from '../LiveActions'
import { ChatContainer, ChatWrapper, classes } from './style'

interface ChatProps {
	onWrite?: (value: boolean) => void
	chatRef?: any
	products?: any
	actionsBefore?: Array<AvailableAction>
	actions?: Array<AvailableAction>
	floating?: boolean
	pinningPosition?: PinningPosition
	fieldBorderActive?: boolean
	hasScrollShadow?: boolean // add a shadow when chat is not scrolled
	noChatInput?: boolean
}

const Chat: React.FC<ChatProps> = ({
	onWrite,
	chatRef,
	products,
	actions,
	actionsBefore,
	floating,
	pinningPosition,
	fieldBorderActive,
	hasScrollShadow,
	noChatInput
}) => {
	const [isWriting, setIsWriting] = useState<boolean>(false)
	const { isLive, isReplay, fakeStatus, replayChatDeactivated } = usePlayerContext()

	const handleWrite = (value: boolean) => {
		onWrite(value)
		setIsWriting(value)
	}

	return (
		<ChatWrapper className={cx({ [classes.floating]: floating })}>
			{replayChatDeactivated ? (
				<DeactivatedChat />
			) : (
				<>
					{isLive && (
						<ChatBox
							hasScrollShadow={hasScrollShadow}
							floating={floating}
							innerRef={chatRef}
							pinningPosition={pinningPosition}
						/>
					)}
					{isReplay && (
						<ReplayChatBox
							hasScrollShadow={hasScrollShadow}
							floating={floating}
							innerRef={chatRef}
							pinningPosition={pinningPosition}
						/>
					)}
				</>
			)}

			<ChatContainer className={cx(classes.root, { [classes.fieldFocused]: isWriting })}>
				{actionsBefore?.length > 0 && <LiveActions products={products} actions={actions} />}
				{(isLive || fakeStatus === 'live') && !noChatInput && (
					<ChatField
						floating={floating}
						onWriting={() => handleWrite(true)}
						onUnWriting={() => handleWrite(false)}
						borderActive={fieldBorderActive}
					/>
				)}
				{actions?.length > 0 && <LiveActions products={products} actions={actions} />}
			</ChatContainer>
		</ChatWrapper>
	)
}

export const chatClasses = classes

export default Chat
