import styled from '@emotion/styled'
import Theme from '@styles/theme'
import { iconClasses } from '../Icon'

const PREFIX = 'card-item'

export const classes = {
	root: PREFIX
}

export const CardItemContainer = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	width: 100%;
	padding: ${Theme.spacing(1)};
	cursor: pointer;
`

export const LabelContainer = styled.div`
	font-family: ${Theme.fontFamilies.Main};
	margin-left: ${Theme.spacing(3)};
`

export const CardLabel = styled.div`
	font-size: 14px;
	line-height: 18px;
	font-weight: 700;
	color: ${() => Theme.colors.text_main};
`

export const CardDescription = styled.div`
	font-size: 13px;
	font-weight: 400;
	line-height: 22px;
	letter-spacing: 0px;
	color: ${Theme.colors.grey1};
	font-family: ${Theme.fontFamilies.Second};
`

export const ChevronIconContainer = styled.div`
	& .${iconClasses.root} {
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		margin: auto;
	}
`
