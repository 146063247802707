import styled from '@emotion/styled'
import IconCta from '@components/Micro/IconCta'
import Theme from '@styles/theme'

const PREFIX = 'othersActionsButton'

export const classes = {
	root: PREFIX,
	active: `${PREFIX}-active`
}

export const OtherActionsCta = styled(IconCta)`
	&.${classes.active} {
		background-color: ${Theme.colors.white()} !important;
		& svg {
			fill: ${Theme.colors.black()};
		}
	}
`
