import { css, keyframes } from '@emotion/react'
import Theme from '@styles/theme'
import styled from '@emotion/styled'

const bounce = keyframes`
	0% { transform: scale(1); }
	20% {  transform: scale(1); }
	24% { transform: scale(1.1); }
	28% { transform: scale(1); }
	0% { transform: scale(1); }
`

const widthTransition = `width 400ms ${Theme.eases.inOutCubic}`
const transformTransition = `transform 400ms ${Theme.eases.inOutCubic}`
const opacityTransition = `opacity 400ms ${Theme.eases.inOutCubic}`

const PREFIX = 'bottomBar'

export const classes = {
	root: `${PREFIX}-root`,
	ctaContainer: `${PREFIX}-cta-container`,
	hidden: `hidden`,
	removed: `removed`
}

const verticalStyle = (isVertical) => {
	return isVertical
		? css`
				flex-direction: column-reverse;
			`
		: null
}

export const CtaContainer = styled.div<{ isVertical?: boolean }>`
	display: flex;
	width: 'fit-content';
	transition:
		${opacityTransition} 100ms,
		${transformTransition} 100ms,
		${widthTransition} 100ms;
	will-change: opacity, transform, width;
	grid-gap: 10px;
	${({ isVertical }) => verticalStyle(isVertical)}
	&.${classes.hidden} {
		opacity: 0;
		transform: translateX(20px) scale(0);
		width: 0;
		transition: ${opacityTransition}, ${transformTransition}, ${widthTransition};
		pointer-events: none;
	}
	&.${classes.removed} {
		display: none;
	}
`

export default {
	el: css`
		position: absolute;
		left: 15px;
		bottom: 95px;
		pointer-events: all;
		width: calc(100% - 30px);
		z-index: ${Theme.zIndices.bottomBar};
		transition: ${transformTransition};
		@media ${Theme.media(0)} {
			bottom: 103px;
		}
	`,
	desktopLandscapeStyle: css`
		width: 33%;
		left: unset;
		right: 15px;
		align-items: flex-end !important;
	`,
	mobileLandscapeHorizontalStyle: css`
		flex-direction: column-reverse;
		grid-gap: 10px;
	`,

	isTranslated: css`
		transform: translate3d(0, 20px, 0);
	`,

	elReplay: css`
		bottom: 145px;
		@media ${Theme.media(0)} {
			bottom: 150px;
		}
	`,

	hidden: css`
		display: none !important;
	`,

	isHidden: css`
		opacity: 0;
		pointer-events: none;
	`,

	isOpened: css`
		opacity: 1;
		pointer-events: all;
	`,

	inputLabel: css`
		font-family: ${Theme.fontFamilies.Second};
		font-weight: ${Theme.fontWeights.bold};
		text-transform: uppercase;
	`,

	field: css`
		border-radius: 10px;
		position: relative;
		transition:
			${opacityTransition} 100ms,
			${transformTransition} 100ms,
			${widthTransition} 100ms;
		will-change: opacity, transform, width;
		opacity: 1;
		width: 100%;
		background-color: ${Theme.colors.black(0.5)};
		backdrop-filter: blur(2px);
	`,

	cart: css`
		position: relative;
		pointer-events: all;
	`,
	iconCatalog: css`
		> img {
			width: 100%;
		}
	`,

	input: css`
		box-sizing: border-box;
		opacity: 1;
		width: 100%;
		background: none;
		border: none;
		font-size: 13em;
		outline: none;
		appearance: none;
		opacity: 1;
		color: ${Theme.colors.white()};
		font-family: ${Theme.fontFamilies.Second};
		font-weight: ${Theme.fontWeights.regular};
		line-height: 130%;
		resize: none;
		overflow: hidden;
		&::placeholder {
			color: ${Theme.colors.white()};
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	`,

	sendWrap: css`
		position: absolute;
		top: 0;
		right: 0;
		border-top-right-radius: 10px;
		border-bottom-right-radius: 10px;
	`,

	send: css`
		opacity: 0.5;
		pointer-events: none;
		transition: ${opacityTransition};
	`,

	isShown: css`
		opacity: 1;
		pointer-events: all;
	`,

	error: css`
		border: 1px solid red;
	`,

	box: css`
		width: 'fit-content';
		transition:
			${opacityTransition} 100ms,
			${transformTransition} 100ms,
			${widthTransition} 100ms;
		will-change: opacity, transform, width;
	`,

	isHide: css`
		opacity: 0;
		transform: translateX(20px) scale(0);
		width: 0;
		transition: ${opacityTransition}, ${transformTransition}, ${widthTransition};
		pointer-events: none;
	`,

	btn: css`
		background-color: ${Theme.colors.black(0.25)};
		backdrop-filter: blur(2px);
		border-radius: 10px;
		width: 35px;
		height: 35px;
		cursor: pointer;
		&:active {
			opacity: 0.5;
		}
	`,

	like: css`
		background-color: ${Theme.colors.red()};
		animation: ${bounce} 10000ms ${Theme.eases.bounceIn} infinite;
		cursor: pointer;
		transition: opacity 600ms ${Theme.eases.outExpo} 0ms;
		@media ${Theme.media(0)} {
			&:hover {
				opacity: 0.5;
			}
		}
	`,

	muteChat: css`
		background: ${Theme.colors.black(0.25)};
		mix-blend-mode: normal;
		backdrop-filter: blur(2px);
		border-radius: 10px;
	`
}
