import styled from '@emotion/styled'
import { css } from '@emotion/react'
import Theme from '@styles/theme'
import { BaseFlex } from '@styles/common'
import Placeholder from '@components/Micro/Placeholder'

export const VideoWrapper = styled(BaseFlex)`
	justify-content: center;
	position: relative;
	overflow: hidden;
	width: 100vw;
	margin: 60px auto 0 auto;
	@media ${Theme.media(0)} {
		position: relative;
		border-radius: 30px;
		overflow: hidden;
		width: 800px;
		height: 450px;
	}
	${() => css([Theme.squaredEdges])};
`

export const PosterPlay = styled.div`
	background-color: ${() => Theme.colors.white()};
	padding: ${Theme.spacing(7)};
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translateX(-50%) translateY(-50%);
	border-radius: 10px;
	z-index: 6;
	transition: opacity 300ms ${Theme.eases.outExpo} 0ms;
	&:active {
		opacity: 0.5;
	}
	${() => css([Theme.squaredEdges])};
`

export const SoundActivate = styled.div`
	background-color: ${() => Theme.colors.black(0.2)};
	padding: ${Theme.multiSpacing(3, 5)};
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	border-radius: 10px;
	z-index: 6;
	transition: opacity 300ms ${Theme.eases.outExpo} 0ms;
	display: flex;
	gap: 10px;
	align-items: center;
	cursor: pointer;
	backdrop-filter: blur(2px);
	${() => css([Theme.squaredEdges])};
	i {
		flex-grow: 1;
	}
`

const largeStyle = (aspectRatio) => {
	return css`
		position: fixed;
		height: ${aspectRatio > 1 ? 'unset' : '90vh'};
		width: ${aspectRatio > 1 ? '80vw' : 'unset'};
		aspect-ratio: ${aspectRatio || '16/9'};
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
		z-index: ${Theme.zIndices.popin};
	`
}

export const VideoPlayer = styled.video<{ hidden; large; aspectRatio }>`
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	z-index: 5;
	object-fit: contain;
	opacity: ${({ hidden }) => (hidden ? 0 : 1)};
	cursor: pointer;
	${({ large, aspectRatio }) => large && largeStyle(aspectRatio)}
`

export const Poster = styled(Placeholder)`
	z-index: 1;
	transition: opacity 600ms ${Theme.eases.outExpo} 0ms;
`

export const BackdropFilter = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: ${Theme.colors.black()};
`

export const BackgroundShadow = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: ${Theme.zIndices.popin - 1};
	width: 100vw;
	height: 100vh;
	background-color: ${Theme.colors.black(0.5)};
`
