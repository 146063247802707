import { css } from '@emotion/react'

const PREFIX = 'icon'

export const classes = {
	root: PREFIX
}

export default {
	icon: css`
		display: block;
		pointer-events: none;
	`,

	iconSvg: css`
		display: block;
		width: 100%;
		height: 100%;
	`
}
