import Theme from '@styles/theme'
import styled from '@emotion/styled'
import { iconClasses } from '@components/Micro/Icon'

export const MenuItemContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	padding: 7px 14px;
	border-radius: 5px;
	cursor: pointer;
	transition: all 600ms ${Theme.eases.outExpo};

	& .${iconClasses.root} {
		min-width: 16px;
		margin-right: ${Theme.spacing(2)};
	}

	&:hover {
		background-color: ${() => Theme.colors.withOpacity(Theme.colors.cta_main, 30)};
	}
`
