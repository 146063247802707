import React, { useEffect, useRef } from 'react'
import { Flex } from 'rebass'
import Datas from '@components/Utils/Datas'
import Tracker from '@components/Utils/Tracker'
import { usePlayerContext } from '@helpers/contexts/player'

interface Props {
	children: any
}

const DefaultLayout: React.FC<Props> = ({ children }) => {
	const userRef = useRef()
	const trackerRef = useRef()

	const filteredChildren = React.Children.map(children, (child) => {
		return React.cloneElement(child, { userRef, trackerRef })
	})

	const { setTracker, setFakeStatus } = usePlayerContext()

	useEffect(() => {
		const qStatus = new URL(window.location.href).searchParams.get('status')
		if (qStatus) {
			setFakeStatus(qStatus)
		}
	}, [])

	useEffect(() => {
		if (trackerRef) {
			setTracker(trackerRef)
		}
	}, [trackerRef])

	return (
		<>
			<Datas />
			<Tracker innerRef={trackerRef} />
			<Flex as="section" flexWrap="wrap" justifyContent="center">
				{filteredChildren}
			</Flex>
		</>
	)
}

export default DefaultLayout
