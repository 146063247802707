import { css } from '@emotion/react'
import Theme from '@styles/theme'
import styled from '@emotion/styled'
import { Flex, Box } from 'rebass'
import { toastClasses } from '@components/Micro/Toast'
import Text from '@components/Micro/Text'

const PREFIX = 'winning-instant-button'

const handleButtonProps = (buttonProps) => {
	if (buttonProps) {
		return css`
			top: ${buttonProps.top};
			left: ${buttonProps.left};
			bottom: ${buttonProps.bottom};
			right: ${buttonProps.right};
		`
	}
	return null
}

interface ButtonWrapperProps {
	top: any
	cursor: any
	buttonProps: any
}

export const classes = {
	root: PREFIX,
	blue: `${PREFIX}-blue`,
	white: `${PREFIX}-white`,
	borderless: `${PREFIX}-borderless`
}

export const ButtonWrapper = styled(Box)<ButtonWrapperProps>`
	box-sizing: border-box;
	position: absolute;
	right: 15px;
	top: ${({ top }) => `${top}px`};
	z-index: ${Theme.zIndices.modal};
	pointer-events: all;
	cursor: ${({ cursor }) => cursor};
	will-change: top;
	transition: top 400ms ${Theme.eases.inOutSmooth};
	${({ buttonProps }) => handleButtonProps(buttonProps)};
`

export const TopBar = styled(Flex)(() => ({
	position: 'absolute',
	left: 0,
	top: 0,
	backgroundColor: Theme.colors.white(),
	zIndex: Theme.zIndices.modal,
	pointerEvents: 'all',
	cursor: 'pointer',
	alignItems: 'center',
	width: '100%',
	padding: '13px 15px'
}))

export const ToastWrapper = styled.div`
	width: 100%;
	position: relative;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	z-index: 10;
	transition: all 400ms cubic-bezier(0.52, 0.16, 0.24, 1);
	& .${toastClasses.root} {
		margin-top: ${Theme.spacing(3)};
	}
`

export const GiftIcon = styled(Flex)((props) => ({
	borderRadius: '50%',
	border: `1px solid ${props.color || Theme.colors.grey2}`,
	alignItems: 'center',
	justifyContent: 'center',
	padding: props.padding || 7,
	backgroundColor: props.color || 'transparent'
}))

export const ArrowWrapper = styled.div<{ backgroundColor }>`
	background-color: ${({ backgroundColor }) => backgroundColor};
	border-radius: 5px;
	padding: 10px;
	${() => css([Theme.squaredEdges])};
`

export const AnimationWrapper = styled('div')`
	background-color: ${Theme.colors.white()};
	padding: 15px;
	border-radius: 15px;
	width: auto;
	height: auto;
	${() => css([Theme.squaredEdges])};
`

export const NotifContent = styled.div`
	margin-left: ${Theme.spacing(2)};
`

export const NotifTitle = styled(Text)`
	font-family: ${Theme.fontFamilies.Main};
	font-weight: ${Theme.fontWeights.bold};
	font-size: 14px;
	line-height: 18px;
	margin-bottom: 3px;
`

export const NotifDescription = styled(Text)`
	font-family: ${Theme.fontFamilies.Second};
	font-weight: ${Theme.fontWeights.regular};
	font-size: 13px;
`

export default {
	isReduced: css`
		pointer-events: none;
		opacity: 0;
	`,
	buttonTransition: css`
		.animated_content {
			display: grid;
			grid-template-columns: auto minmax(160px, auto) auto;
			grid-gap: 10px;
			align-items: center;
		}

		.animated {
			width: 240px;
			max-width: 90%;
			transition: width 800ms ${Theme.eases.outExpo};
		}

		.compact {
			width: 30px;
			transition: width 800ms ${Theme.eases.outExpo};
		}

		.hide {
			width: 0;
			height: 0;
			opacity: 0;
			transition: width 800ms ${Theme.eases.outExpo};
		}
		.show {
			width: auto;
			height: auto;
			opacity: 1;
			transition: width 800ms ${Theme.eases.outExpo};
		}

		.delayed_transition {
			transition:
				width 800ms ${Theme.eases.outExpo},
				opacity 450ms ease 150ms;
		}

		.end_transition {
			transition:
				width 800ms ${Theme.eases.outExpo},
				opacity 450ms ease 250ms;
		}
	`
}
