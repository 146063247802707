import styled from '@emotion/styled'
import { CSSTransition } from 'react-transition-group'
import Theme from 'styles/theme'

export const TranslateXAndFadeTransition = styled(CSSTransition)`
	&.${({ classNames }) => classNames}-enter {
		opacity: 0;
		transform: translateX(${({ translate }) => translate || '25%'});
		transition: none;
	}
	&.${({ classNames }) => classNames}-enter-active {
		opacity: 1;
		transform: translateX(0);
		transition: all ${({ timeout }) => timeout}ms ${({ ease }) => Theme.eases[ease || 'inOutSmooth']};
	}
	&.${({ classNames }) => classNames}-exit {
		opacity: 1;
		transform: translateX(0);
	}
	&.${({ classNames }) => classNames}-exit-active {
		opacity: 0;
		transition: all ${({ timeout }) => timeout}ms ${({ ease }) => Theme.eases[ease || 'inOutSmooth']};
	}
`
