import Grid from '@components/Utils/Grid'
import FirstNameContent from '@components/Notifiers/SharedNotifiersContent/FirstNameContent'
import { firstNameDrawerClasses } from '@components/Notifiers/SharedNotifiersContent/FirstNameContent/style'
import { useNotificationsContext } from '@helpers/contexts/notifications'

const FirstName: React.FC = () => {
	const { closeDrawer } = useNotificationsContext()
	return (
		<Grid className={firstNameDrawerClasses.root}>
			<FirstNameContent onClose={closeDrawer} />
		</Grid>
	)
}

export default FirstName
