import React from 'react'
import { Flex, Box } from 'rebass'
import Style from './style'
import Placeholder from '@components/Micro/Placeholder'
import Icon from '@components/Micro/Icon'
import Theme from '@styles/theme'
import Grid from '@components/Utils/Grid'
import { mapDispatchToProps } from '@helpers/wrappers/withReduxStore'
import { State } from '@stores/index'
import { connect } from 'react-redux'
import Bus from '@helpers/bus'
import Constants from '@constants'

interface Props {
	dispatch?: (action) => void
	storeDatas?: any
	storeEvent?: any
}

const mapStateToProps = (state: State, props: Props) => {
	return { ...state, ...props }
}

const Shopper: React.FC<Props> = ({ storeDatas }) => {
	let $title = null
	let $text = null
	let $image = null
	let $links = null

	const setBackgroundColor = () => {
		return storeDatas && storeDatas.event.event.settings.options.hasPreliveProducts ? Theme.colors.background : null
	}

	if (storeDatas) {
		$title = storeDatas.event.event.content.shopper.name.replace(/(\\r\\n|\\n|\\r)/g, '\n')
		$text = storeDatas.event.event.content.shopper.description.replace(/(\\r\\n|\\n|\\r)/g, '\n')
		$image = (
			<Placeholder
				sizes={[[500, 500]]}
				media={
					storeDatas.event.event.content.shopper.picture || { url: 'medias/default/shopper-1000x1000.jpg' }
				}
				color={Theme.colors.black(0.1)}
			/>
		)
		$links = storeDatas.event.event.content.shopper.socialNetworks.map((link, i) => {
			// console.log(link)
			return (
				<Box
					key={`network-${i}`}
					width={[40]}
					height={[40]}
					p={'12px'}
					css={Style.network}
					onClick={() => {
						Bus.send(Constants.bus.player.link_browsed, { url: link.url, target: '_blank' })
						Bus.send(Constants.bus.player.shopper_network_clicked, { url: link.url, network: link.label })
					}}
				>
					<Icon
						name={link.label}
						width="100%"
						height="100%"
						fill={Theme.colors.text_main}
						className="theme-icon"
					/>
				</Box>
			)
		})
	}

	return (
		<Flex
			width={1}
			css={Style.el}
			pt={Theme.preLive.separationValues}
			pb={Theme.preLive.separationValues}
			justifyContent={'center'}
			backgroundColor={setBackgroundColor()}
		>
			<Grid className="shopper-grid">
				<Flex
					width={1}
					flexWrap="wrap"
					alignItems="flex-start"
					justifyContent="center"
					className="shopper-container"
				>
					<Box css={[Style.image, Theme.squaredEdges]} width={[500]} className="shopper-image">
						{$image}
					</Box>
					<Flex
						width={1}
						mt={40}
						textAlign="center"
						flexWrap="wrap"
						justifyContent="center"
						className="shopper-wrapper"
					>
						<Box
							width={1}
							mt={[30, 0]}
							fontSize={['24em', '28em']}
							css={Style.title}
							color={Theme.colors.text_main}
							className="shopper-title"
						>
							{$title}
						</Box>
						<Box
							width={[1, 500]}
							mt={[20, 40]}
							fontSize={'13em'}
							css={Style.bio}
							color={Theme.colors.text_second}
							className="wysiwyg shopper-text"
						>
							{$text}
						</Box>
						<Flex width={1} mt={30} justifyContent="center" flexWrap="wrap" className="shopper-links">
							{$links}
						</Flex>
					</Flex>
				</Flex>
			</Grid>
		</Flex>
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(Shopper)
