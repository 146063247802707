import styled from '@emotion/styled'
import { css } from '@emotion/react'
import Theme from '@styles/theme'
import { Flex, Box } from 'rebass'
import Spinner from '@assets/img/spinner-black.gif'
import { customScrollbar } from '@styles/shared/customScrollbar'

interface DisplayModeNotifierProps {
	isMobile?: boolean
	isTablet?: boolean
	isHorizontal?: boolean
	storeIsOffline?: boolean
	isLandscape?: boolean
}

const handleWarningDisplay = ({ isMobile, isHorizontal, storeIsOffline, isLandscape }: DisplayModeNotifierProps) => {
	if ((isMobile && isHorizontal && !isLandscape) || (!isMobile && !isHorizontal) || storeIsOffline) {
		return css`
			display: flex;
			justify-content: center;
			align-items: center;
			text-align: center;
		`
	}
}

export const RightAnswer = styled(Flex)`
	border-radius: 8px;
	width: 100%;
	padding: 10px;
	font-size: 14px;
	line-height: 18px;
	justify-content: center;
	align-items: center;
	color: ${Theme.colors.white()};
	background-color: ${Theme.colors.green()};
	font-family: ${Theme.fontFamilies.Main};
	font-weight: ${Theme.fontWeights.bold};
	text-align: center;
`

export const RewardsListTitle = styled(Box)`
	font-size: 14px;
	line-height: 18px;
	text-align: center;
	font-family: ${Theme.fontFamilies.Main};
	font-weight: ${Theme.fontWeights.bold};
	margin-bottom: 15px;
	@media ${Theme.media(0)} {
		font-size: 16px;
	}
`

export const RewardsListDescription = styled(Box)`
	font-size: 13px;
	line-height: 22px;
	font-family: ${Theme.fontFamilies.Second};
	font-weight: ${Theme.fontWeights.regular};
	color: ${() => Theme.colors.text_main};
`
export const RewardsListContainer = styled(Box)`
	padding: 15px 20px;
	border-radius: 15px;
`

export const PositionIcon = styled(Flex)`
	width: 100px;
	height: 100px;
	justify-content: center;
	align-items: center;
	background-color: ${Theme.colors.yellow};
	color: ${Theme.colors.white()};
	border-radius: 70px;
	border: dashed 2px ${Theme.colors.brown};
	box-shadow: 0px 0px 0px 10px ${Theme.colors.yellow};
	font-weight: ${Theme.fontWeights.bold};
	font-size: 20px;
	margin-bottom: 25px;
	font-family: ${Theme.fontFamilies.Main};
	font-size: 60px;
`
export const DisplayModeNotifier = styled.div<DisplayModeNotifierProps>`
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: ${Theme.zIndices.max};
	background-color: ${Theme.colors.black(0.9)};
	backdrop-filter: blur(10px);
	color: ${Theme.colors.white()};
	font-family: ${Theme.fontFamilies.Main};
	font-weight: ${Theme.fontWeights.bold};
	line-height: 140%;
	font-size: 18em;
	display: none;
	justify-content: center;
	align-items: center;
	${(props) => handleWarningDisplay(props)}
`

export default {
	wrap: css`
		width: 100%;
		height: 100%;
		position: absolute;
		@media ${Theme.media(0)} {
			height: 100vh;
			position: relative;
		}
	`,

	unsetCinema: css`
		background-color: ${Theme.colors.black(0.2)};
		backdrop-filter: blur(2px);
		border-radius: 10px;
		width: 40px;
		height: 40px;
		padding: 12px;
		cursor: pointer;
		z-index: 2;
		&:active {
			opacity: 0.5;
		}
		* {
			pointer-events: none;
		}
		position: absolute;
		right: 15px;
		bottom: 15px;
		transition: transform 400ms cubic-bezier(0.52, 0.16, 0.24, 1);
		&:before {
			position: absolute;
			content: '';
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			pointer-events: none;
		}
		> div {
			position: relative;
		}
	`,

	conversationalReplaySpinner: css`
		background: ${Theme.colors.white()} url(${Spinner}) no-repeat center;
	`,

	unsetCinemaIsTranslated: css`
		transform: translateY(-80px);
	`,

	isNotAvailable: css`
		position: fixed;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: ${Theme.zIndices.max};
		background-color: ${Theme.colors.black(0.9)};
		backdrop-filter: blur(10px);
		color: ${Theme.colors.white()};
		text-align: center;
		font-family: ${Theme.fontFamilies.Main};
		font-weight: ${Theme.fontWeights.bold};
		line-height: 140%;
		font-size: 18em;
		display: none !important;
	`,

	forceShow: css`
		display: flex !important;
	`,

	desktopBlock: css`
		z-index: ${Theme.zIndices.panels - 1};
		@media ${Theme.media(0)} {
			position: relative;
			overflow: hidden;
			box-shadow: 0px 0 2px 0 ${Theme.colors.black(0.3)};
		}
	`,

	desktopLive: css`
		@media ${Theme.media(0)} {
			z-index: 1;
			background: ${Theme.colors.black(1)};
		}
	`,

	desktopChat: css`
		display: none;
		@media ${Theme.media(0)} {
			display: flex;
			opacity: 0;
			pointer-events: none;
			background-color: ${Theme.colors.white(1)};
			transform: translateX(100%);
			z-index: 0;
		}
	`,

	desktopChatIsShown: css`
		@media ${Theme.media(0)} {
			opacity: 1;
			pointer-events: all;
			transform: translateX(0);
			transition:
				transform 800ms ${Theme.eases.outExpo},
				opacity 800ms ${Theme.eases.outExpo};
		}
	`,

	desktopPanels: css`
		@media ${Theme.media(0)} {
			opacity: 0;
			pointer-events: none;
			transform: translateX(-100%);
			z-index: 0;
		}
	`,

	desktopCatalog: css`
		display: none;
		background-color: ${Theme.colors.white()};
		@media ${Theme.media(0)} {
			display: flex;
		}
		overflow: auto;
		${customScrollbar};
	`,

	columnTitle: css`
		position: absolute;
		left: 0;
		top: 0;
		border-bottom: 1px solid ${Theme.colors.black(0.1)};
		background-color: ${Theme.colors.white()};
		padding-left: 25px;
		font-family: ${Theme.fontFamilies.Main};
		font-weight: ${Theme.fontWeights.bold};
		display: none;
		z-index: ${Theme.zIndices.header};
		@media ${Theme.media(0)} {
			display: flex;
		}
	`,

	ui: css`
		position: absolute;
		left: 0;
		bottom: 0;
		top: 0;
		opacity: 0;
		pointer-events: none;
		height: 100%;
		transition: opacity 200ms cubic-bezier(0.52, 0.16, 0.24, 1);
		&:before,
		&:after {
			position: absolute;
			content: '';
			width: 100%;
			left: 0;
			z-index: 1;
			pointer-events: none;
		}
		&:before {
			height: 60px;
			top: 0;
			background: linear-gradient(180deg, ${Theme.colors.black(0.5)} 0%, ${Theme.colors.black(0)} 100%);
		}
		&:after {
			height: 400px;
			bottom: 0;
			background: linear-gradient(180deg, ${Theme.colors.black(0)} 0%, ${Theme.colors.black(0.5)} 100%);
		}
	`,

	isReplay: css`
		&:before {
			height: 120px;
			top: 0;
			background: linear-gradient(180deg, ${Theme.colors.black(0.8)} 0%, ${Theme.colors.black(0)} 100%);
		}
	`,

	isVisible: css`
		opacity: 1;
		pointer-events: all;
	`,

	isReduced: css`
		.stories,
		.liveproducts,
		.header,
		.bottom-bar,
		.nav,
		.chatbox,
		.pinned-message,
		.likes-box,
		.timeline {
			opacity: 0;
		}
		.ui:before,
		.ui:after {
			display: none;
		}
	`,

	isCinema: css`
		.nav,
		.chatbox,
		.pinned-message,
		.likes-box {
			opacity: 0;
			pointer-events: none !important;
			* {
				pointer-events: none !important;
			}
		}
		&:before {
			display: none;
		}
		&:after {
			height: 150px;
			background: linear-gradient(180deg, ${Theme.colors.black(0)} 0%, ${Theme.colors.black(0.3)} 100%);
		}
	`,

	translatorLive: css`
		transition: transform 400ms cubic-bezier(0.52, 0.16, 0.24, 1);
		z-index: 2;
		transform: translate3d(0, 84px, 0);
		pointer-events: none;
		@media ${Theme.media(0)} {
			transform: translate3d(0, 88px, 0);
		}
	`,

	translatorReplay: css`
		transition: transform 400ms cubic-bezier(0.52, 0.16, 0.24, 1);
		z-index: 2;
		transform: translate3d(0, 114px, 0);
		pointer-events: none;
		@media ${Theme.media(0)} {
			transform: translate3d(0, 118px, 0);
		}
	`,

	isTranslated: css`
		transition: transform 400ms cubic-bezier(0.52, 0.16, 0.24, 1);
		transform: translate3d(0, 0, 0);
		@media ${Theme.media(0)} {
			transform: translate3d(0, 0, 0);
		}
	`,

	isNotTranslatedByKeyboard: css`
		transition: transform 0ms ${Theme.eases.outSine};
	`,

	desktopPanelsIsShown: css`
		@media ${Theme.media(0)} {
			opacity: 1;
			pointer-events: all;
			transform: translateX(0);
			transition:
				transform 800ms ${Theme.eases.outExpo},
				opacity 800ms ${Theme.eases.outExpo};
		}
	`,
	pauseButtonBox: css`
		z-index: ${Theme.zIndices.pause};
		pointer-events: all;
	`,
	mobileLandscapeVerticalChat: css`
		position: relative;
		background-color: ${Theme.colors.white()};
		width: 100vw;
	`
}
