import { BaseFlex } from '@styles/common'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import Theme from '@styles/theme'

export const VariantWrapper = styled(BaseFlex)`
	width: 100%;
	align-items: flex-start;
	flex-wrap: wrap;
`

export const VariantLabel = styled.div<{ error: boolean }>`
	margin-top: 5px;
	margin-right: 25px;
	font-size: 10em;
	margin-bottom: 10px;
	font-family: ${Theme.fontFamilies.Second};
	font-weight: ${Theme.fontWeights.bold};
	color: ${() => Theme.colors.text_main};
	line-height: 140%;
	text-transform: uppercase;
	width: 100%;
	color: ${({ error }) => !!error && Theme.colors.red()};
`

export const SelectWrap = styled.div<{ error: boolean }>`
	position: relative;
	padding: 15px 20px;
	border-radius: 10px;
	font-family: ${Theme.fontFamilies.Second};
	font-weight: ${Theme.fontWeights.regular};
	font-size: 13em;
	width: 100%;
	color: ${() => Theme.colors.cta_second};
	border: 1px solid;
	text-transform: capitalize;
	&:before,
	&:after {
		position: absolute;
		content: '';
		right: 15px;
		top: 21px;
		width: 6px;
		height: 2px;
		background-color: ${Theme.colors.black()};
	}
	&:before {
		transform: translateX(-1px) rotate(45deg);
	}
	&:after {
		transform: translateX(2px) rotate(-45deg);
	}
	${({ error }) =>
		error &&
		css`
			border: 1px solid ${Theme.colors.red()};
			color: ${Theme.colors.red()};
			&:before,
			&:after {
				background-color: ${Theme.colors.red()};
			}
		`}
	${() => css([Theme.squaredEdges])};
`

export const SelectBox = styled.select`
	display: block;
	position: absolute;
	width: calc(100% + 10px);
	opacity: 0;
	top: -5px;
	right: -5px;
	bottom: -5px;
	left: -5px;
	cursor: pointer;
	text-transform: capitalize;
`

export const SelectOption = styled.option``
