import { css } from '@emotion/react'
import Theme from '../theme'

const customScrollbar = css`
	scrollbar-color: ${Theme.colors.grey1} transparent;
	scrollbar-width: thin;

	::-webkit-scrollbar {
		background-color: transparent;
		width: 8px;
	}
	::-webkit-scrollbar-thumb {
		background-color: ${Theme.colors.grey1};
		border-radius: 10px;
	}
	::-webkit-scrollbar-corner {
		background: transparent;
	}
`

export { customScrollbar }
