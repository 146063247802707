import Utils from '@utils/index'
import { EventProductCta } from 'types/Event'
import { DisplayedProductInfo, ProgramStep } from 'types/ReplayProgram'

const handleCtaName = (datas, productsCount): string => {
	const productCtaSettings: EventProductCta = datas?.event?.event?.settings?.productCta
	const seeProductCtaName = productsCount === 1 ? datas.ui.live_step_product_cta : datas.ui.live_step_products_cta
	switch (productCtaSettings) {
		case 'see_product':
			return seeProductCtaName
		case 'appointment':
			return datas.ui.live_step_products_cta_appointment
		case 'book':
			return datas.ui.live_step_products_cta_book
		default:
			return seeProductCtaName
	}
}

export { handleCtaName }

export const stepToDisplayedProductInfo = (
	step: ProgramStep,
	locale: string,
	currency: string,
	stepProductTranslation: string
): DisplayedProductInfo => {
	if (step.products.length === 1) {
		const product = step.products[0]
		const title = product.title
		const item = product.variants?.combinations.find((combination) => combination.price > 0) || product
		const price = item.price || 0
		const crossOutPrice = item.crossOutPrice || 0

		const subtitle = price > 0 && Utils.setReadablePrice(price, locale, currency)

		return {
			title,
			price,
			subtitle,
			products: step.products,
			chipPriceText: price > 0 && Utils.setReadablePrice(price, locale, currency),
			crossOutPrice,
			crossOutPriceText: crossOutPrice > 0 && Utils.setReadablePrice(crossOutPrice, locale, currency)
		}
	} else {
		return {
			title: step.name,
			subtitle: (step.products.length + ' ' + stepProductTranslation).toUpperCase(),
			products: step.products,
			chipPriceText: step.products.length + ' ' + stepProductTranslation
		}
	}
}
