import { BaseFlex } from './../../../../../styles/common'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import Theme from '@styles/theme'

interface BaseProps {
	isLandscape?: boolean
	isHorizontal?: boolean
	isMobile?: boolean
	isWriting?: boolean
}

const PREFIX = 'chat-field'

export const classes = {
	root: PREFIX,
	floating: `${PREFIX}-floating`,
	input: `${PREFIX}-input`,
	textarea: `${PREFIX}-textarea`,
	fieldWrapper: `${PREFIX}-fieldWrapper`,
	mobileLandscape: `${PREFIX}-mobileLandscape`
}

const mobileLandscapeHorizontalWrapperStyle = () => {
	return css`
		height: 50px;
	`
}
const mobileLandscapeVerticalWrapperStyle = (isWriting) => {
	return isWriting
		? css`
				height: 60px;
			`
		: css`
				height: 60px;
				border-top: none;
			`
}

export const ChatFieldWrapper = styled(BaseFlex)<BaseProps>`
	position: absolute;
	bottom: 0;
	border-top: 1px solid ${Theme.colors.black(0.1)};
	cursor: text;
	height: 70px;
	width: 100%;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	@media ${Theme.media(0)} {
		background: ${Theme.colors.white()};
	}
	${({ isLandscape, isHorizontal, isMobile }) =>
		isLandscape && isHorizontal && isMobile ? mobileLandscapeHorizontalWrapperStyle() : null}
	${({ isLandscape, isHorizontal, isMobile, isWriting }) =>
		isLandscape && !isHorizontal && isMobile ? mobileLandscapeVerticalWrapperStyle(isWriting) : null}
	&.${classes.floating} {
		border-radius: 10px;
		position: relative;
		transition:
			opacity 400ms ${Theme.eases.inOutCubic}100ms,
			transform 400ms ${Theme.eases.inOutCubic} 100ms,
			width 400ms ${Theme.eases.inOutCubic} 100ms;
		will-change: opacity, transform, width;
		opacity: 1;
		width: 100%;
		height: 35px;
		background-color: ${Theme.colors.black(0.5)};
		backdrop-filter: blur(2px);
		& svg {
			fill: ${Theme.colors.white()};
		}
		& .${classes.fieldWrapper} {
			margin: 0;
			padding-left: ${Theme.spacing(3)};
			padding-right: ${Theme.spacing(9)};
		}
	}
`

export const FieldWrapper = styled.div<BaseProps>`
	width: 100%;
	position: relative;
	transition: width 400ms cubic-bezier(0.52, 0.16, 0.24, 1);
	margin-left: 25px;
	margin-right: 15px;
	&.${classes.mobileLandscape} {
		margin-left: 15px;
		margin-right: 5px;
	}
`

export const InputWrapper = styled.input<BaseProps>`
	left: 0;
	top: 0;
	opacity: 1;
	width: -webkit-fill-available;
	height: 100%;
	background: none;
	border: none;
	outline: none;
	appearance: none;
	padding-right: 43px;
	opacity: 1;
	color: ${Theme.colors.black()};
	font-family: ${Theme.fontFamilies.Second};
	font-weight: ${Theme.fontWeights.regular};
	letter-spacing: normal;
	font-size: ${({ isLandscape, isMobile }) => (isLandscape && isMobile ? '11em' : '14em')};
	&::placeholder {
		color: ${Theme.colors.black(0.5)};
	}
	&.${classes.floating} {
		margin: 0;
		font-size: 13em;
		color: ${Theme.colors.white()};
		font-family: ${Theme.fontFamilies.Second};
		font-weight: ${Theme.fontWeights.regular};
		letter-spacing: normal;
		line-height: 130%;
		&::placeholder {
			color: ${Theme.colors.white()};
		}
		resize: none;
		overflow: hidden;
	}
	&.${classes.textarea} {
	}
`

export const SendWrapper = styled(BaseFlex)`
	width: 44px;
	height: 100%;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 0;
	right: 0;
	border-top-right-radius: 35px;
	border-bottom-right-radius: 35px;
`

export const SendIcon = styled.div<{ isWriting }>`
	opacity: ${({ isWriting }) => (isWriting ? 0.8 : 0.2)};
	pointer-events: ${({ isWriting }) => (isWriting ? 'all' : 'none')};
	transition: opacity 400ms cubic-bezier(0.52, 0.16, 0.24, 1);
`
