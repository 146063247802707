import { css } from '@emotion/react'
import Theme from '@styles/theme'

export default {
	isFullscreen: css`
		position: absolute;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: ${Theme.zIndices.max};
		top: 0;
		.slide {
			height: 100%;
			> div {
				height: 100%;
			}
		}
	`,

	arrow: css`
		border-radius: 5px;
		border: 1px solid ${Theme.colors.black(0.1)};
		cursor: pointer;
		position: absolute;
		top: 50%;
		z-index: 1;
	`,

	arrowPrev: css`
		left: 20px;
	`,

	arrowNext: css`
		right: 20px;
	`,

	arrowHidden: css`
		opacity: 0;
		pointer-events: none;
	`,

	parallax: css`
		/* position: absolute;
		bottom: 0;
		left: 0; */
	`,

	back: css`
		position: absolute;
		left: 7.5%;
		top: 25px;
		/* z-index: 1; */
	`,

	fullscreen: css`
		position: absolute;
		right: 7.5%;
		top: 25px;
		@media ${Theme.media(0)} {
			display: none !important;
		}
	`,

	fullscreenDisabled: css`
		top: 25px;
	`,

	fullscreenEnabled: css`
		top: calc(100% - 65px);
	`,

	viewport: css`
		position: relative;
	`,

	overview: css`
		will-change: transform;
	`,

	item: css`
		overflow: hidden;
		position: relative;
		/* &:before {
			content: '';
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			position: absolute;
			z-index: 1;
			background: linear-gradient(
				90deg,
				${Theme.colors.black(0)} 0%,
				${Theme.colors.black(0)} 10%,
				${Theme.colors.black(0)} 80%,
				${Theme.colors.black(0.04)} 100%
			);
		} */
	`,

	statusWrap: css`
		position: absolute;
		bottom: 20px;
		left: 50%;
		transform: translateX(-50%);
	`,

	statusItem: css`
		background-color: ${Theme.colors.white()};
		border-radius: 7px;
		margin: 4px;
		border: 1px solid ${Theme.colors.black(0.7)};
	`,

	currentStatusItem: css``
}
