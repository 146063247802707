import React, { useState, useEffect } from 'react'
import {
	classes,
	FloatingPrice,
	LiveProductButton,
	LiveProductButtonContainer,
	LiveProductContainer,
	LiveProductDescription,
	LiveProductText,
	LiveProductWrapper
} from './style'
import Theme from '@styles/theme'
import { State } from '@stores/index'
import { connect } from 'react-redux'
import Bus from '@helpers/bus'
import Constants from '@constants'
import { useNotificationsContext } from '@helpers/contexts/notifications'
import { PanelsType } from '@components/Notifiers/Panels/constants'
import { usePlayerContext } from '@helpers/contexts/player'
import { classGenerator as cx } from '@styles/sharedLogics'
import Text from '@components/Micro/Text'
import ProductsImage from './ProductsImage'
import { handleCtaName, stepToDisplayedProductInfo } from './logics'
import { CSSTransition } from 'react-transition-group'
import { Product } from 'types/Product'
import { sortCatalogProducts } from '@helpers/ProductsSorter'
import PriceComponent from '@components/Micro/Price/Price'
import { DisplayedProductInfo, ProgramStep } from 'types/ReplayProgram'
import { useTimeLineContext } from '@helpers/contexts/timeline'

interface LiveProductsProps {
	togglable?: boolean
	storeCurrentStep?: ProgramStep
	block?: boolean
}

const mapStateToProps = (state: State, props: LiveProductsProps) => {
	return { ...state, ...props }
}

const LiveProductsElement: React.FC<LiveProductsProps> = ({ storeCurrentStep, togglable, block }) => {
	const { openPanel, productPageOpened, productsCatalogPageOpened } = useNotificationsContext()
	const { playerData, videoMode, isReplay } = usePlayerContext()
	const { currentTime } = useTimeLineContext()
	const [opened, setOpened] = useState<boolean>(false)
	// To trigger the animations of the products, we stock the current **step**
	// and trigger the activation of the step **stepActive**
	const [stepActive, setStepActive] = useState<boolean>(false)
	const [cta, setCta] = useState<string>(null)
	const [displayedProductInfo, setDiplayedProductInfo] = useState<DisplayedProductInfo>(null)

	const productClickHandler = (e) => {
		e.stopPropagation()
		if (displayedProductInfo?.products.length > 1) {
			// Prevent from opening product catalog panel if already opened
			if (productsCatalogPageOpened(displayedProductInfo.products)) return

			openPanel(PanelsType.CATALOG, {
				title: displayedProductInfo?.title,
				back: playerData?.ui.panel_back,
				data: sortCatalogProducts(displayedProductInfo?.products, storeCurrentStep?.products || [], isReplay)
			})
		} else {
			const product: Product = displayedProductInfo?.products[0]
			// Prevent from opening product panel if already opened
			// Return here to not trigger data recording as well
			if (productPageOpened(product)) return

			Bus.send(Constants.bus.player.product_clicked, product)

			if (playerData.event.event.settings.options.hasCart) {
				openPanel(PanelsType.PRODUCT, {
					data: product,
					back: playerData?.ui.panel_back
				})
			} else {
				Bus.send(Constants.bus.player.link_browsed, {
					url: product.link.url,
					target: '_self',
					isLandscape: videoMode === 'landscape',
					timecode: currentTime
				})
			}
		}
	}

	const showThenHideCard = () => {
		setOpened(true)
		if (togglable) {
			return setTimeout(() => setOpened(false), 5_000)
		}
	}

	useEffect(() => {
		let timeout = null
		if (!!displayedProductInfo) {
			timeout = showThenHideCard()
			setCta(handleCtaName(playerData, displayedProductInfo.products.length))

			if (timeout) return () => clearTimeout(timeout)
		}
	}, [displayedProductInfo])

	useEffect(() => {
		if (opened === false) {
			const interval = setInterval(() => {
				showThenHideCard()
			}, 60_000)

			return () => clearInterval(interval)
		}
	}, [opened])

	// We use the following to trigger the animations of the component
	// The style of the animation is triggered by the Mobile/Desktop tmplates
	useEffect(() => {
		const getDisplatesProductInfo = (step: ProgramStep) =>
			stepToDisplayedProductInfo(
				step,
				playerData.channel.locale,
				playerData.channel.currency,
				playerData.ui.live_step_products
			)

		// Had previous step
		if (storeCurrentStep && displayedProductInfo) {
			// Hide the previous step
			setStepActive(false)
			setTimeout(() => setDiplayedProductInfo(getDisplatesProductInfo(storeCurrentStep)), 800)
			// Show the new step
			setTimeout(() => {
				setTimeout(() => showThenHideCard(), 200)
				setStepActive(true)
			}, 800)
		} else if (!!storeCurrentStep && displayedProductInfo === null) {
			//there is a step but nothing displayed currently
			setDiplayedProductInfo(getDisplatesProductInfo(storeCurrentStep))
			setTimeout(() => setStepActive(true), 800)
		} else if (storeCurrentStep == null && displayedProductInfo !== null) {
			// Hide current step then remove the current step
			setStepActive(false)
			setTimeout(() => setDiplayedProductInfo(null), 800)
		}
	}, [storeCurrentStep])

	return (
		<CSSTransition timeout={800} in={stepActive} unmountOnExit={!storeCurrentStep} classNames={classes.transition}>
			<LiveProductWrapper
				onClick={productClickHandler}
				className={cx('liveproducts', classes.card, {
					[classes.opened]: !togglable || (togglable && opened),
					[classes.closed]: togglable && !opened,
					[classes.block]: block
				})}
			>
				{displayedProductInfo && displayedProductInfo.products.length > 0 && (
					<ProductsImage className={classes.image} products={displayedProductInfo.products} />
				)}
				{displayedProductInfo?.chipPriceText && (
					<FloatingPrice>{displayedProductInfo.chipPriceText}</FloatingPrice>
				)}
				<LiveProductDescription className={classes.description}>
					<LiveProductText>
						<Text className={classes.title} size="13em" color={Theme.colors.text_main}>
							{displayedProductInfo?.title}
						</Text>
						{displayedProductInfo?.price ? (
							<PriceComponent
								price={displayedProductInfo?.price}
								crossOutPrice={displayedProductInfo?.crossOutPrice}
								channel={playerData.channel}
							/>
						) : (
							displayedProductInfo?.subtitle && (
								<Text className={classes.price} size="12em" color={Theme.colors.text_second}>
									{displayedProductInfo?.subtitle}
								</Text>
							)
						)}
					</LiveProductText>
					<LiveProductButtonContainer>
						<LiveProductButton className={classes.button}>{cta}</LiveProductButton>
					</LiveProductButtonContainer>
				</LiveProductDescription>
			</LiveProductWrapper>
		</CSSTransition>
	)
}

const LiveProducts: React.FC<LiveProductsProps> = () => {
	const { eventStatus } = usePlayerContext()
	return (
		<LiveProductContainer
			className={cx(classes.root, classes.transitionGroup, {
				[classes.replay]: eventStatus === 'replay' || eventStatus === 'finishing'
			})}
		>
			<LiveProductsCard />
		</LiveProductContainer>
	)
}

export const liveProductsClasses = classes

export const LiveProductsCard = connect(mapStateToProps, null)(LiveProductsElement)

export default connect(mapStateToProps, null)(LiveProducts)
