import { classGenerator } from '@styles/sharedLogics'
import React, { useRef } from 'react'
import { classes, LikeButtonWrapper } from './style'
import Theme from '@styles/theme'
import IconCta from '@components/Micro/IconCta'
import Bus from '@helpers/bus'
import Constants from '@constants'
import LikesBox from './LikesBox'

interface LikeButtonProps {
	uiIsLight?: boolean
	show?: boolean
}

const LikeButton: React.FC<LikeButtonProps> = ({ uiIsLight = false, show }) => {
	const likesBoxRef: React.MutableRefObject<JSX.Element> = useRef()

	const onClick = (e) => {
		e.stopPropagation()
		Bus.send(Constants.bus.player.like_clicked)
		//@ts-ignore
		likesBoxRef.current.like()
	}
	return (
		<>
			{show && (
				<LikeButtonWrapper className={classGenerator(classes.root, { [classes.hidden]: uiIsLight })}>
					<IconCta
						iconProps={{
							name: 'heart_plain',
							fill: Theme.colors.white()
						}}
						onClick={onClick}
					/>
					<LikesBox className={classes.heartBox} innerRef={likesBoxRef} />
				</LikeButtonWrapper>
			)}
		</>
	)
}

export default LikeButton
