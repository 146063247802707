import { css } from '@emotion/react'
import Theme from '@styles/theme'
import styled from '@emotion/styled'
import { iconCtaClasses } from '@components/Micro/IconCta'
import { BaseFlex } from '@styles/common'

const PREFIX = 'header'

export const classes = {
	root: PREFIX,
	hidden: `hidden`,
	separator: 'separator',
	actionsContainer: `${PREFIX}-action-container`
}

export const HeaderAvatar = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 35px;
	min-width: 35px;
	border-radius: 100%;
	overflow: hidden;
	margin-right: ${Theme.spacing(2)};
	* {
		border-radius: 100%;
		overflow: hidden;
	}
	${() => css([Theme.squaredEdges])}
`

export const HeaderContent = styled.div`
	display: flex;
	width: 100%;
	flex-wrap: nowrap;
	align-items: center;
	margin-right: ${Theme.spacing(2)};
`

export const HeaderTitle = styled.div`
	max-width: 65vw;
	font-family: ${Theme.fontFamilies.Main};
	font-weight: ${Theme.fontWeights.bold};
	font-size: 15em;
	color: ${Theme.colors.white()};
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	br {
		white-space: nowrap;
	}
`

export const HeaderLiveStatus = styled.div`
	font-family: ${Theme.fontFamilies.Main};
	font-weight: ${Theme.fontWeights.bold};
	font-size: 10em;
	background-color: ${Theme.colors.red()};
	color: ${Theme.colors.white()};
	text-transform: uppercase;
	border-radius: 18px;
	padding: 6px 8px;
	margin-left: ${Theme.spacing(1.5)};
`

export const HeaderActions = styled.div`
	display: flex;
	justify-content: end;
	align-items: center;
	position: relative;

	& .${iconCtaClasses.root} {
		box-sizing: border-box;
		height: 30px;
		width: 30px;
		border: 1px solid ${Theme.colors.white()};
		border-radius: 8px;
		cursor: pointer;
		transition: opacity 600ms ${Theme.eases.outExpo} 0ms;
		padding: 9px;
		background-color: transparent;
		${() => css([Theme.squaredEdges])}
	}
`

export const AvatarAndTitleWrapper = styled(BaseFlex)`
	max-width: calc(100% - 145px);
`

export const HeaderContainer = styled.header`
	box-sizing: border-box;
	position: absolute;
	display: flex;
	width: 100%;
	flex-wrap: nowrap;
	padding: ${Theme.spacing(3)};
	left: 0;
	top: 0;
	justify-content: space-between;

	& ${HeaderAvatar}, & ${HeaderContent}, & .${iconCtaClasses.root} {
		z-index: ${Theme.zIndices.header};
	}

	&.${classes.separator} {
		border-bottom: 1px solid ${Theme.colors.white(0.25)};
	}

	&.${classes.hidden} {
		opacity: 0;
		pointer-events: none;
	}
`
