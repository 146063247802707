import React from 'react'
import { Flex } from 'rebass'
import Theme from '@styles/theme'

const Grid: React.FC<{
	children?: any
	justifyContent?: any
	justifyItems?: any
	alignItems?: any
	alignContent?: any
	flexWrap?: any
	flexFlow?: any
	style?: any
	as?: string
	flexDirection?: any
	p?: any
	className?: string
}> = ({
	children,
	justifyContent = 'space-between',
	flexDirection,
	justifyItems,
	flexWrap = `wrap`,
	flexFlow,
	alignContent,
	alignItems,
	style,
	as,
	p,
	className
}) => {
	return (
		<Flex
			// backgroundColor="rgba(255, 0, 0, 0.5)"
			as={as}
			maxWidth={Theme.grid.maxWidth}
			width={Theme.grid.width}
			flexWrap={flexWrap}
			flexFlow={flexFlow}
			justifyContent={justifyContent}
			justifyItems={justifyItems}
			alignContent={alignContent}
			alignItems={alignItems}
			flexDirection={flexDirection}
			css={style}
			p={p}
			className={className}
		>
			{children}
		</Flex>
	)
}

export default Grid
