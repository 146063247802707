import OtherActionsContent from '../SharedNotifiersContent/OtherActionsContent'
import { DrawerBuild, DrawersType } from './constants'
import FirstName from './FirstName'
import Poll from './Poll'
import WinningInstant from './WinningInstant'

export const getDrawerBuild = (drawerType): DrawerBuild => {
	switch (drawerType) {
		case DrawersType.FIRST_NAME_DRAWER:
			return {
				drawerType,
				Component: <FirstName />
			}
		case DrawersType.POLL_DRAWER:
			return {
				drawerType,
				Component: <Poll />
			}
		case DrawersType.WINNING_INSTANT:
			return {
				drawerType,
				Component: <WinningInstant />
			}
		case DrawersType.SHARE_AND_OPTOUT_DRAWER:
			return {
				drawerType,
				Component: <OtherActionsContent />
			}
	}
}
