import React from 'react'
import Theme from '@styles/theme'
import Icon from '@components/Micro/Icon'
import {
	ConversationalContent,
	ConversationalDescription,
	StartConversationButton,
	Question,
	QuitReplayButton,
	ConversationIcon,
	quitReplayClasses,
	QuitReplayContentWrapper
} from './style'
import { useNotificationsContext } from '@helpers/contexts/notifications'
import { usePlayerContext } from '@helpers/contexts/player'
import { classGenerator as cx } from '@styles/sharedLogics'

interface ConversationalQuitReplayProps {
	onClosePlayer: () => void
	onStartConversation: () => void
	playerData: any
}

const ConversationalQuitReplayPopin: React.FC<ConversationalQuitReplayProps> = ({
	onClosePlayer,
	onStartConversation,
	playerData
}) => {
	const { closeModal } = useNotificationsContext()
	const { isLandscape, isDesktop, isMobile } = usePlayerContext()

	/**
	 * @Methods
	 */
	const getUiText = (field: string): string => {
		if (playerData) {
			return playerData.ui.conversational_replay.popin[field]
		}
		return ''
	}

	return (
		<QuitReplayContentWrapper
			className={cx(quitReplayClasses.root, { [quitReplayClasses.desktopLandscape]: isLandscape && isDesktop })}
		>
			<ConversationalContent className={quitReplayClasses.boxWrapper}>
				<ConversationIcon className={quitReplayClasses.boxIcon}>
					<Icon name="bubble_idz" width="18px" height="18px" fill={Theme.colors.cta_main} />
				</ConversationIcon>
				<div className={quitReplayClasses.boxText}>
					<Question className={quitReplayClasses.question}>{getUiText('title')}</Question>
					<ConversationalDescription className={quitReplayClasses.description}>
						{getUiText('text')}
					</ConversationalDescription>
				</div>
				<StartConversationButton
					onClick={() => {
						onStartConversation()
						closeModal()
					}}
					className={quitReplayClasses.boxButton}
				>
					{isDesktop && isLandscape ? (
						<Icon name="path" height="10px" width="10px" fill={Theme.colors.white()} />
					) : (
						getUiText('start')
					)}
				</StartConversationButton>
			</ConversationalContent>
			<QuitReplayButton
				type="border"
				label={getUiText('quit')}
				onClick={() => {
					closeModal()
					onClosePlayer()
				}}
				className={quitReplayClasses.quitButton}
				fullWidth={isMobile}
			/>
		</QuitReplayContentWrapper>
	)
}

export default ConversationalQuitReplayPopin
