import { drawerClasses } from '@components/Notifiers/Drawers/style'
import { pollVoteClasses } from '@components/Notifiers/SharedNotifiersContent/PollContent/PollVote/style'
import { pollClasses } from '@components/Notifiers/SharedNotifiersContent/PollContent/style'
import { popinClasses } from '@components/Utils/Popin'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import Theme from '@styles/theme'
import { liveActionsClasses } from '../../components/LiveActions'
import { LivePlayerShadows, LiveContentWrapper, LivePlayerContainer as BaseLivePlayerContainer } from '../style'
import BaseHeader, { headerClasses } from '../../components/Header'
import { liveProductsClasses } from '../../components/LiveProducts'
import { chatFieldClasses } from '../../components/ChatField/Modular/style'
import { videoPlayerClasses } from '../../components/Livestream/style'
import { panelsClasses } from '@components/Notifiers/Panels/style'
import { XTranlationAndFadeOverride, YTranlationAndFadeOverride } from '@styles/transitions/TransitionOverrides'
import { convReplayButtonClasses } from '../../components/ConversationalNotification'
import { mobileLandscapeClasses } from './classes'

export const Header = styled(BaseHeader)<{ className?: string }>`
	&.${mobileLandscapeClasses.headerHide} {
		display: none;
	}
`

export const LandscapeTogglerWrapper = styled.div`
	position: absolute;
	top: ${Theme.spacing(3)};
	right: ${Theme.spacing(3)};
	z-index: ${Theme.zIndices.bottomBar};
`

export const ShadowContainer = styled(LivePlayerShadows)`
	& .${mobileLandscapeClasses.chatPanelButton} {
		position: absolute;
		top: ${Theme.spacing(3)};
		right: ${Theme.spacing(3)};
		z-index: ${Theme.zIndices.bottomBar};
	}

	& .${liveActionsClasses.root} {
		position: absolute;
		z-index: ${Theme.zIndices.liveChat};
	}

	&.${mobileLandscapeClasses.horizontal} {
		& .${liveActionsClasses.root} {
			right: ${Theme.spacing(3)};
			bottom: ${({ isReplay }) => Theme.spacing(isReplay ? 7 : 3)};
		}

		.${liveProductsClasses.description} {
			width: 300px;
		}
	}

	&.${mobileLandscapeClasses.vertical} {
		& .${liveActionsClasses.root} {
			bottom: ${Theme.spacing(3)};
			right: ${Theme.spacing(3)};
		}
	}
`

export const LiveProductsContainer = styled.div<{ isReplay? }>`
	position: absolute;
	left: ${Theme.spacing(3)};
	bottom: ${({ isReplay }) => Theme.spacing(isReplay ? 7 : 3)};
	& .${liveProductsClasses.button} {
		white-space: nowrap;
	}
`

const chatClosedDrawerStyle = () => {
	return css`
		width: 100%;

		& .${drawerClasses.content} {
			position: absolute;
			width: 33%;
			left: unset;
			right: 0;
			border-top-right-radius: 0;
		}
	`
}

export const ContentWrapper = styled(LiveContentWrapper)<{ chatOpened }>`
	&.${mobileLandscapeClasses.horizontal} {
		& .${drawerClasses.root} {
			width: 33%;
			left: unset;
			right: 0;
		}

		& .${pollVoteClasses.content}, & .${pollClasses.answer} {
			padding: ${Theme.multiSpacing(1, 2)};
		}

		& .${pollClasses.question} {
			margin-top: ${Theme.spacing(4)};
		}

		& .${pollClasses.question} {
			margin-bottom: ${Theme.spacing(4)};
		}

		${({ chatOpened }) => (!chatOpened ? chatClosedDrawerStyle() : null)}

		.${popinClasses.content} {
			padding: ${Theme.spacing(4)} !important;
		}

		& .${chatFieldClasses.xs} {
			min-height: 50px;
		}

		& .${panelsClasses.wrapper} {
			max-width: 300px;
			transition: width 400ms ${Theme.eases.inOutSmooth};
		}
	}
	&.${mobileLandscapeClasses.vertical} {
		flex-direction: column;

		.${convReplayButtonClasses.root} {
			top: unset;
			bottom: ${Theme.spacing(7)};
		}
	}

	.${chatFieldClasses.iconActive} {
		padding: 10px;
	}

	.${chatFieldClasses.fieldWrapper} {
		margin-right: 10px;
	}

	&.${mobileLandscapeClasses.horizontal} {
		${XTranlationAndFadeOverride(liveProductsClasses.transition, '-100%')}

		.${convReplayButtonClasses.root} {
			top: ${Theme.spacing(3)};
			left: ${Theme.spacing(3)};
			right: unset;
		}
	}
`

export const ChatContainer = styled.div<{ isWriting }>`
	position: relative;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	flex-grow: 1;
	height: calc(100vh - 56.25vw); // 100vh - (100vw * 9 / 16) -> 16/9
	max-height: calc(100vh - 56.25vw);
	width: 100vw;
	z-index: ${Theme.zIndices.panels - 2};
	background-color: ${Theme.colors.white()};

	& .${liveProductsClasses.card} {
		padding: 0 ${Theme.spacing(2)};
	}

	${YTranlationAndFadeOverride(liveProductsClasses.transition, '-100%')}

	.${liveProductsClasses.button} {
		white-space: unset;
		max-width: 120px;
	}

	& .${liveActionsClasses.root} {
		margin-right: ${({ isWriting }) => (isWriting ? Theme.spacing(0) : Theme.spacing(4))};
	}
`

export const LivePlayerContainer = styled(BaseLivePlayerContainer)`
	z-index: ${Theme.zIndices.panels - 1};
	&.${mobileLandscapeClasses.vertical} {
		aspect-ratio: 16/9;
		flex-grow: 0;
		height: unset;
		flex-shrink: 0;
		display: block;
		height: 56.25vw;
		/* Hack to remove a black bar some screen sizes: https://github.com/aploze/v1-player/issues/378#issuecomment-1218264118*/
		& .${videoPlayerClasses.root} {
			bottom: -1px;
		}

		&.${mobileLandscapeClasses.pip} {
			z-index: 300;
			width: 0;
			height: 0;
		}
	}
	/* For some devices we have to set the header in absolute with a define width
	 * otherwise the header might not take the full width of his parent	 
	*/
	& .${headerClasses.root} {
		min-width: 66vw;
		&.${mobileLandscapeClasses.headerFullWidth} {
			min-width: 100vw;
		}
		& .${headerClasses.actionsContainer} {
			position: absolute;
			right: 15px;
			margin-top: 2px;
		}
	}
`
