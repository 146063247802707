import React, { useState, useRef, useEffect, useCallback } from 'react'
import { ChatBoxContainer, ChatBoxReplayFooter, ChatBoxWrapper, classes } from './style'
import ChatMessage from '../ChatMessage'
import { useMessagesContext } from '@helpers/contexts/messages'
import PinnedMessage from '../PinnedMessage'
import ScrollToButton from '../ScrollToButton'
import { useBrandContext } from '@helpers/contexts/brands'
import { usePlayerContext } from '@helpers/contexts/player'
import { Virtuoso } from 'react-virtuoso'
import { Message } from 'types/Message'
import { classGenerator as cx } from '@styles/sharedLogics'
import useHasScrolled from '@helpers/hooks/useHasScrolled'

export type PinningPosition = 'top' | 'bottom'

interface Props {
	innerRef?: any
	dispatch?: any
	uiIsLight?: boolean
	isTranslated?: boolean
	chatMuted?: boolean
	isLandscape?: boolean
	floating?: boolean
	pinningPosition?: PinningPosition
	hasScrollShadow?: boolean
}

const ChatBox: React.FC<Props> = ({
	uiIsLight,
	isTranslated,
	chatMuted,
	isLandscape,
	floating,
	pinningPosition = 'bottom',
	hasScrollShadow
}) => {
	/**
	 * @States
	 */

	const [pinnedMessage, setPinnedMessage] = useState<Message>(null)
	const [isScrolling, setIsScrolling] = useState<boolean>(true)
	const [firstItemIndex, setFirstItemIndex] = useState<number>(9999999)
	const {
		messages,
		pinnedMessages,
		hasMoreMessages,
		getMessages,
		newMessagesCount,
		messagesLoading,
		setIsPaused,
		isPaused,
		MESSAGES_FETCH_LIMIT
	} = useMessagesContext()
	const { brand, setBrandWithData } = useBrandContext()
	const { isLive, isFinishing, isMobile, isReplayOrFinishing, displayMode, playerData, isReduced, fakeStatus } =
		usePlayerContext()
	const virtuosoRef = useRef(null)
	const chatBoxRef = useRef(null)

	/**
	 * @Methods
	 */

	const scrollToBottom = useCallback(() => {
		if (!isScrolling) {
			setTimeout(() => {
				const cbContainer = document.querySelector(`.${classes.virtualList}`)
				if (cbContainer) cbContainer.scrollTop = cbContainer?.scrollHeight
			}, 50)
		}
	}, [isScrolling])

	const hasNewMessages = () => newMessagesCount > 0

	const loadMore = () => {
		if (!hasMoreMessages || messagesLoading) return

		getMessages(messages[0].created_at, true)
	}

	const pinningCondition = (position: PinningPosition) => {
		return pinningPosition === position && pinnedMessage && (isLive || isFinishing || fakeStatus === 'live')
	}

	/**
	 * @Effects
	 */

	useHasScrolled(`.${classes.virtualList}`, (hasScrolled) => setIsPaused(hasScrolled))

	useEffect(() => {
		// Handle Pinned Messages
		setPinnedMessage(pinnedMessages?.length ? pinnedMessages[pinnedMessages.length - 1] : null)
	}, [pinnedMessages])

	useEffect(() => {
		if (playerData) setBrandWithData(playerData)
	}, [playerData])

	useEffect(() => {
		if (messages.length !== messages.length + newMessagesCount) {
			if (isPaused || !messages.length) {
				setFirstItemIndex((current) => current - MESSAGES_FETCH_LIMIT)
			}
		}
	}, [messages])

	useEffect(() => {
		if (!isPaused && virtuosoRef) {
			scrollToBottom()
		}
	}, [messages, virtuosoRef])

	return (
		<ChatBoxContainer className={classes.container}>
			{pinningCondition('top') && <PinnedMessage pinnedMessage={pinnedMessage} isBlock={!floating} />}
			<ChatBoxWrapper
				className={cx(classes.root, {
					[classes.scrollShadow]: hasScrollShadow,
					[classes.isScrolling]: isPaused
				})}
				hidden={uiIsLight || chatMuted}
				isReplay={isReplayOrFinishing}
				translated={isTranslated && isReplayOrFinishing}
				ref={chatBoxRef}
				displayMode={displayMode}
				noSmoothScroll={newMessagesCount > 15}
			>
				<Virtuoso
					ref={virtuosoRef}
					className={classes.virtualList}
					firstItemIndex={firstItemIndex}
					initialTopMostItemIndex={MESSAGES_FETCH_LIMIT}
					data={messages}
					startReached={loadMore}
					overscan={200}
					atBottomThreshold={20}
					alignToBottom={true}
					components={{ Footer: () => <ChatBoxReplayFooter id="chatBox-footer" /> }}
					isScrolling={setIsScrolling}
					itemContent={(index, message) => (
						<ChatMessage
							key={`message-${index}`}
							message={message}
							brand={brand}
							isBlock={!floating}
							isLandscapeMobile={isLandscape && isMobile}
						/>
					)}
				/>
				{messages.length && isPaused && !isReduced && (
					<ScrollToButton
						countInQueue={newMessagesCount}
						hasNewMessages={hasNewMessages()}
						scrollToBottom={scrollToBottom}
						isBlock={!floating}
						refWidth={chatBoxRef?.current?.getBoundingClientRect()?.width}
						isLandscapeAndDesktop={floating && isLandscape}
					/>
				)}
			</ChatBoxWrapper>
			{pinningCondition('bottom') && <PinnedMessage pinnedMessage={pinnedMessage} isBlock={!floating} />}
		</ChatBoxContainer>
	)
}

export const modularChatBoxClasses = classes

export default ChatBox
