import React, { useRef, useEffect, useCallback } from 'react'
import { ChatBoxContainer, ChatBoxReplayFooter, ChatBoxWrapper, classes } from '../style'
import { useBrandContext } from '@helpers/contexts/brands'
import { usePlayerContext } from '@helpers/contexts/player'
import { Virtuoso } from 'react-virtuoso'
import { Message } from 'types/Message'
import { classGenerator as cx } from '@styles/sharedLogics'
import { useRouter } from 'next/router'
import { useTimeLineContext } from '@helpers/contexts/timeline'
import { replayChatclasses } from './style'
import useReplayMessages from './UseReplayMessages/useReplayMessages'
import { PinningPosition } from '..'
import ChatMessage from '../../ChatMessage'

interface Props {
	innerRef?: any
	dispatch?: any
	uiIsLight?: boolean
	isTranslated?: boolean
	chatMuted?: boolean
	isLandscape?: boolean
	floating?: boolean
	pinningPosition?: PinningPosition
	hasScrollShadow?: boolean
}

const ReplayChatBox: React.FC<Props> = ({
	uiIsLight,
	isTranslated,
	chatMuted,
	isLandscape,
	floating,
	hasScrollShadow
}) => {
	/**
	 * @States
	 */
	const MESSAGES_FETCH_LIMIT = 50
	const eventId = useRouter().query.id as string
	const { currentDateTime } = useTimeLineContext()
	const { brand, setBrandWithData } = useBrandContext()
	const { isMobile, isReplayOrFinishing, displayMode, playerData } = usePlayerContext()
	const messages = useReplayMessages(eventId, playerData?.record)

	const virtuosoRef = useRef(null)
	const chatBoxRef = useRef(null)

	/**
	 * @Methods
	 */

	const scrollToBottom = () => {
		setTimeout(() => {
			const cbContainer = document.querySelector(`.${classes.virtualList}`)
			if (cbContainer) cbContainer.scrollTop = cbContainer?.scrollHeight
		}, 50)
	}

	/**
	 * @Effects
	 */

	useEffect(() => {
		if (playerData) setBrandWithData(playerData)
	}, [playerData])

	useEffect(() => {
		if (virtuosoRef) {
			scrollToBottom()
		}
	}, [messages, virtuosoRef])

	const isMessageVisible = useCallback(
		(message: Message) => {
			const result = new Date(message.created_at).toString() <= currentDateTime
			return result
		},
		[currentDateTime]
	)

	return (
		<ChatBoxContainer className={classes.container}>
			<ChatBoxWrapper
				className={cx(classes.root, {
					[classes.scrollShadow]: hasScrollShadow
				})}
				hidden={uiIsLight || chatMuted}
				isReplay={isReplayOrFinishing}
				translated={isTranslated && isReplayOrFinishing}
				ref={chatBoxRef}
				displayMode={displayMode}
			>
				<Virtuoso
					ref={virtuosoRef}
					className={classes.virtualList}
					firstItemIndex={9999999}
					initialTopMostItemIndex={MESSAGES_FETCH_LIMIT}
					data={messages}
					overscan={200}
					atBottomThreshold={20}
					alignToBottom={true}
					components={{ Footer: () => <ChatBoxReplayFooter id="chatBox-footer" /> }}
					itemContent={(index, message) => (
						<ChatMessage
							key={`message-${index}`}
							className={cx({ [replayChatclasses.messageVisible]: isMessageVisible(message) })}
							message={message}
							brand={brand}
							isBlock={!floating}
							isLandscapeMobile={isLandscape && isMobile}
						/>
					)}
				/>
			</ChatBoxWrapper>
		</ChatBoxContainer>
	)
}

export const modularChatBoxClasses = classes

export default ReplayChatBox
