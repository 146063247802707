import React from 'react'
import { Message } from 'types/Message'
import { Brand } from 'types/Branding'
import AnsweredMessage from './AnsweredMessage'
import BaseMessage from './BaseMessage'
import { classes, MessagesListWrapper } from './style'
import { classGenerator as cx } from '@styles/sharedLogics'

interface Props {
	className?: string
	message?: Message
	brand: Brand
	isBlock?: boolean
	isLandscapeMobile?: boolean
}

const ChatMessage: React.FC<Props> = ({ className, message, brand, isBlock, isLandscapeMobile }) => {
	const isBrandMessage = message.is_brand

	return (
		<MessagesListWrapper className={cx(classes.wrapper, className)} isBrand={isBrandMessage}>
			{isBrandMessage ? (
				<AnsweredMessage
					className={classes.root}
					message={message}
					name={brand.name}
					brand={brand}
					isBlock={isBlock}
					isLandscapeMobile={isLandscapeMobile}
				/>
			) : (
				<BaseMessage
					className={classes.root}
					message={message}
					name={message.user_displayName}
					brand={brand}
					isBlock={isBlock}
					isLandscapeMobile={isLandscapeMobile}
				/>
			)}
		</MessagesListWrapper>
	)
}

export const chatMessageClasses = classes

export default ChatMessage
