import Icon from '@components/Micro/Icon'
import Constants from '@constants'
import Bus from '@helpers/bus'
import { mapDispatchToProps } from '@helpers/wrappers/withReduxStore'
import { State } from '@stores/index'
import Theme from '@styles/theme'
import React from 'react'
import { connect } from 'react-redux'
import { Network, NetworkContainer } from './style'

interface Props {
	dispatch?: (action) => void
	storeDatas?: any
}

const mapStateToProps = (state: State, props: Props) => {
	return { ...state, ...props }
}

const Networks: React.FC<Props> = ({ storeDatas }) => {
	let $links = null

	if (storeDatas) {
		$links = storeDatas.event.event.settings.branding.networks.map((link, i) => {
			return (
				<Network
					key={`network-${i}`}
					onClick={() => {
						Bus.send(Constants.bus.player.link_browsed, { url: link.url, target: '_blank' })
						Bus.send(Constants.bus.player.brand_network_clicked, { url: link.url, network: link.label })
					}}
				>
					<Icon
						name={link.label}
						width="100%"
						height="100%"
						fill={Theme.colors.text_main}
						className="theme-icon"
					/>
				</Network>
			)
		})
	}

	return (
		<>
			<NetworkContainer number={$links.length}>{$links}</NetworkContainer>
		</>
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(Networks)
