import React, { useEffect } from 'react'
import Header from '@components/StatusManager/LiveAndReplay/components/Header'
import { LiveProductsCard } from '@components/StatusManager/LiveAndReplay/components/LiveProducts'
import Timeline from '@components/StatusManager/LiveAndReplay/components/Timeline'
import { mapDispatchToProps } from '@helpers/wrappers/withReduxStore'
import { State } from '@stores/index'
import dynamic from 'next/dynamic'
import { connect } from 'react-redux'
import { GenericPlayerTemplateProps } from '../constant'
import { classes as globalClasses, livePlayerclasses, LivePlayerShadows } from '../style'
import PausePlayButton from '../../components/PausePlayButton'
import Panels from '@components/Notifiers/Panels'
import { useNotificationsContext } from '@helpers/contexts/notifications'
import WinningInstantButton from '../../components/WinningInstantButton'
import LiveActions from '../../components/LiveActions'
import { classGenerator as cx } from '@styles/sharedLogics'
import Chat from '../../components/Chat'
import { ChatAndActionsContainer, ContentWrapper, LiveProductsContainer, LivePlayerContainer } from './style'
import Drawer from '@components/Notifiers/Drawers'
import { usePlayerContext } from '@helpers/contexts/player'
import Unmute from '../../components/Unmute'
import ConversationalNotification from '../../components/ConversationalNotification'
import { DisplayModeNotifier } from '../../style'
import Shutters from '@components/Notifiers/Shutters'
import { desktopLandscapeClasses } from './classes'

const Livestream = dynamic(() => import('@components/StatusManager/LiveAndReplay/components/Livestream'), {
	ssr: false
})

const mapStateToProps = (state: State, props: GenericPlayerTemplateProps) => {
	return { ...state, ...props }
}

const DesktopLandscapeTemplate: React.FC<GenericPlayerTemplateProps> = ({
	/** @Refs */
	trackerRef,
	livestreamRef,
	timelineRef,
	/** @Booleans */
	isCinema,
	isStarted,
	isMuted,
	// isStreaming,
	isReduced,
	isReplay,
	isReplayOrFinishing,
	isDragged,
	hasConversationalReplay,
	/** @Values */
	sizes,
	products,
	playerData,
	/** @Setters */
	onDrag,
	onWrite,
	onPlay,
	onPlayOnReady,
	onPause,
	onLiveStreamPlay,
	onSeekTo
}) => {
	const {
		panelsQueue,
		landscapeHorizontalChatOpened,
		closeChat,
		drawerOpen,
		currentDrawer,
		shutterOpen,
		currentShutter
	} = useNotificationsContext()
	const { isPlayed, isReady, playOnReady, hasPlayedOnReady, isVertical, replayChatDeactivated } = usePlayerContext()

	useEffect(() => {
		if (landscapeHorizontalChatOpened) {
			closeChat()
		}
	}, [])

	useEffect(() => {
		if (isReady && playOnReady && !hasPlayedOnReady && !isPlayed) {
			onPlayOnReady()
		}
	}, [isPlayed, isReady, playOnReady])

	return (
		<ContentWrapper
			height={sizes.height}
			isReduced={isReduced}
			isLandscape={true}
			className={cx(livePlayerclasses.root, desktopLandscapeClasses.root, {
				[desktopLandscapeClasses.panelsOpened]: panelsQueue.length > 0
			})}
		>
			<Shutters isOpen={shutterOpen}>
				{currentShutter?.Component && React.cloneElement(currentShutter.Component)}
			</Shutters>
			<LivePlayerContainer isReduced={isReduced} panelsOpened={panelsQueue.length > 0}>
				{!isReduced && <WinningInstantButton trackerRef={trackerRef} buttonTop={65} />}
				{isMuted && !isReduced && (
					<Unmute onPlayClick={onPlay} playerIsUnmuted={!isMuted} playerIsReady={isReady} />
				)}

				<Livestream innerRef={livestreamRef} onPlaying={onLiveStreamPlay} isLandscape={true} />
				{!isReduced && (
					<LivePlayerShadows
						height={'100%'}
						className={cx({
							[globalClasses.replay]: isReplayOrFinishing,
							[globalClasses.visible]: !isMuted || isStarted,
							[globalClasses.cinema]: isCinema
						})}
					>
						<Header actions={['OTHERS', 'CLOSE_PLAYER', 'OPEN_CHAT']} />
						{!isReduced && <PausePlayButton isDragged={isDragged} onPause={onPause} onPlay={onPlay} />}
						<ChatAndActionsContainer
							isReplay={isReplay}
							className={cx(desktopLandscapeClasses.chat, {
								[desktopLandscapeClasses.cinema]: isCinema,
								[desktopLandscapeClasses.panelsOpened]: landscapeHorizontalChatOpened
							})}
						>
							{replayChatDeactivated || landscapeHorizontalChatOpened || (
								<Chat hasScrollShadow floating onWrite={onWrite} pinningPosition="top" />
							)}
							<LiveActions column products={products} actions={['CART', 'CATALOG', 'LIKE', 'CINEMA']} />
						</ChatAndActionsContainer>
						{isReplay && <Timeline innerRef={timelineRef} seekTo={onSeekTo} setIsDragged={onDrag} />}
						<LiveProductsContainer>
							<LiveProductsCard togglable />
						</LiveProductsContainer>
					</LivePlayerShadows>
				)}
				{!isReduced && (
					<Drawer isOpen={drawerOpen}>
						{currentDrawer?.Component && React.cloneElement(currentDrawer.Component)}
					</Drawer>
				)}
				{isReplay && hasConversationalReplay && <ConversationalNotification isHidden={isReduced} />}
			</LivePlayerContainer>
			{!isReduced && <Panels isLandscape={true} />}
			{isVertical && !isReduced && (
				<DisplayModeNotifier isMobile={false} isHorizontal={false} isLandscape={true}>
					<div>{playerData.ui.turn_tablet_landscape}</div>
				</DisplayModeNotifier>
			)}
		</ContentWrapper>
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(DesktopLandscapeTemplate)
