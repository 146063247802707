import styled from '@emotion/styled'
import { css } from '@emotion/react'
import Theme from '@styles/theme'
import { BaseFlex } from '@styles/common'

const PREFIX = 'poll-vote'

export const pollVoteClasses = {
	root: PREFIX,
	content: `${PREFIX}-content`,
	results: `${PREFIX}-results`
}

export const VoteWrapper = styled(BaseFlex)`
	margin-bottom: ${Theme.spacing(3)};
	justify-content: space-between;
	align-items: center;
	width: 100%;
`

const userAnswerStyle = (isUserAnswer) => {
	if (isUserAnswer) {
		return css`
			border: 1px solid;
			font-weight: ${Theme.fontWeights.bold};
		`
	}
}

export const VoteContent = styled.div<{ width; isUserAnswer; hasNoVote }>`
	font-size: 14px;
	line-height: 18px;
	padding: 10px 15px;
	border-radius: 8px;
	border: 1px solid;
	cursor: pointer;
	white-space: nowrap;
	transition: width ease 0.8s;
	width: ${({ width }) => width};
	max-width: 60%;
	color: ${() => Theme.colors.text_main};
	background-color: ${({ hasNoVote }) => (hasNoVote ? 'transparent' : `${Theme.colors.text_main}10`)};
	border: none;
	${({ isUserAnswer }) => userAnswerStyle(isUserAnswer)}
`

export const VoteResults = styled.div`
	font-size: 12px;
	max-width: 30%;
	color: ${() => Theme.colors.text_second};
	text-align: end;
`
