import { DrawerBuild } from '@components/Notifiers/Drawers/constants'
import { ModalBuild, ModalsType } from '@components/Notifiers/Modals/constants'
import { PanelBuild } from '@components/Notifiers/Panels/constants'
import { ShutterBuild } from '@components/Notifiers/Shutters/constants'

/**
 * @type {Modals}
 */

export interface ModalsContextState {
	modalOpen: boolean
	modalsQueue: Array<ModalBuild>
	currentModal?: ModalBuild
	modalHistory: Array<ModalsType>
}

export interface ModalsContextAction {
	modalType?: ModalsActionType
	metadata?: any
	closeCurrent?: boolean
}

export const modalsInitialContextState: ModalsContextState = {
	modalOpen: false,
	modalsQueue: [],
	modalHistory: []
}

export enum ModalsActionType {
	OPEN_MODAL = 'open_modal',
	CLOSE_MODAL = 'clode_modal',
	OPEN_NEXT_MODAL = 'open_next_modal'
}

/**
 * @type {Panels}
 */

export interface PanelsContextState {
	panelsQueue: Array<PanelBuild>
	isPip: number
}
export const panelsInitialContextState: PanelsContextState = {
	panelsQueue: [],
	isPip: 0
}

export enum PanelsActionType {
	OPEN_PANEL = 'open_panel',
	CLOSE_LAST_PANEL = 'close_last_panel',
	CLOSE_ALL_PANELS = 'close_all_panels',
	CLOSE_PANEL = 'close_panel',
	SET_PIP = 'set_pip'
}

/**
 * @type {Drawers}
 */

export interface DrawerContextState {
	drawerOpen: boolean
	currentDrawer?: DrawerBuild
}

export enum DrawersActionType {
	OPEN_DRAWER = 'open_drawer',
	CLOSE_DRAWER = 'clode_drawer'
}

export const drawersInitialContextState: DrawerContextState = {
	drawerOpen: false,
	currentDrawer: null
}

/**
 * @type {Shutters}
 */

export interface ShutterContextState {
	shutterOpen: boolean
	currentShutter?: ShutterBuild
}

export enum ShuttersActionType {
	OPEN_SHUTTER = 'open_shutter',
	CLOSE_SHUTTER = 'clode_shutter'
}

export const shuttersInitialContextState: ShutterContextState = {
	shutterOpen: false,
	currentShutter: null
}
