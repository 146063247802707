import React from 'react'
import { CircleIconContainer, classes, CircleSize } from './style'
import Icon, { IconUnion } from '../Icon'

enum IconSize {
	md = 13
}

interface CircleIconProps {
	icon: IconUnion
	color: string
	backgroundColor?: string
	size?: CircleSize
}

const CircleIcon: React.FC<CircleIconProps> = ({ icon, color, backgroundColor, size = 'md' }) => {
	const getIconSize = () => {
		return `${IconSize[size]}px`
	}
	return (
		<CircleIconContainer className={classes.root} size={size} backgroundColor={backgroundColor}>
			<Icon name={icon} width={getIconSize()} height={getIconSize()} fill={color} />
		</CircleIconContainer>
	)
}

export default CircleIcon
