import React, { useRef, useState, useEffect } from 'react'
import { mapDispatchToProps } from '@helpers/wrappers/withReduxStore'
import { State } from '@stores/index'
import { connect } from 'react-redux'
import { Flex, Box } from 'rebass'
import Style, { AddToCartFloatingButton, classes } from './style'
import Description from './Description'
import Theme from '@styles/theme'
import Icon from '@components/Micro/Icon'
import Utils from '@utils/index'
import { NotificationManager } from 'react-notifications'
import Bus from '@helpers/bus'
import Constants from '@constants'
import { useNotificationsContext } from '@helpers/contexts/notifications'
import { PanelsType } from '../constants'
import { ProductProps } from '@components/StatusManager/LiveAndReplay/components/Items/Product'
import { classGenerator as cx } from '@styles/sharedLogics'
import { usePlayerContext } from '@helpers/contexts/player'
import { useTimeLineContext } from '@helpers/contexts/timeline'

interface Props {
	dispatch?: (action) => void
	storeDatas?: any
	storeProducts?: any
	product?: any
}

const mapStateToProps = (state: State, props: Props) => {
	return { ...state, ...props }
}

interface ProductPanelButtonProps {
	children?: any
	playerData?: any
	product?: any
	isLandscape?: boolean
	isMobile?: boolean
}

export const ProductPanelButton: React.FC<ProductPanelButtonProps> = ({
	children,
	playerData,
	product,
	isLandscape = false,
	isMobile
}) => {
	const { openPanel, closeAllPanels } = useNotificationsContext()
	const { currentTime } = useTimeLineContext()

	const handleClick = () => {
		Bus.send(Constants.bus.player.product_clicked, product)
		if (playerData?.event.event.settings.options.hasCart) {
			openPanel(PanelsType.PRODUCT, {
				data: product,
				back: playerData?.ui.panel_back
			})
		} else {
			Bus.send(Constants.bus.player.link_browsed, {
				url: product.link.url,
				target: '_self',
				isLandscape,
				timecode: currentTime
			})
			if (isMobile && !isLandscape) {
				closeAllPanels()
			}
		}
	}
	return React.Children.map(children, (child) => {
		if (React.isValidElement(child)) {
			return React.cloneElement(child as React.ReactElement<ProductProps>, { onClick: handleClick })
		}
		return child
	})
}

const ProductPanel: React.FC<Props> = ({ product }) => {
	const elRef = useRef(null)
	const descriptionRef = useRef(null)
	const [price, setPrice] = useState(null)
	const [isBuying, setIsBuying] = useState(false)
	const { displayMode, playerData, isVertical } = usePlayerContext()

	let $addToCartButton = null

	const fixedHeight = 70

	useEffect(() => {
		elRef.current.scrollTop = 0
	}, [product])

	const addToCartSuccess = () => {
		NotificationManager.success(playerData.ui.panel_product_add_success, ' ', 1000)
		Bus.send(Constants.bus.player.product_added_to_cart_success, { ...product, price: price || product.price })
	}

	const addToCartError = (error = null) => {
		if (error === 'out-of-stock') {
			NotificationManager.error(playerData.ui.panel_product_add_error_out, ' ', 2000)
		} else {
			NotificationManager.error(playerData.ui.panel_product_add_error, ' ', 2000)
		}
	}

	const cartCallbackHandler = (datas) => {
		NotificationManager.listNotify.forEach((n) => NotificationManager.remove({ id: n.id }))
		if (typeof datas.success === 'object' && datas.success !== null) {
			if (datas.success.success === true) {
				addToCartSuccess()
			} else {
				addToCartError(datas.success.error)
			}
		} else {
			if (datas.success === true) {
				addToCartSuccess()
			} else if (datas.success === false) {
				addToCartError(null)
			}
		}
		setIsBuying(false)
	}

	useEffect(() => {
		Bus.on(Constants.bus.library.cart_callback, cartCallbackHandler)
		setIsBuying(false)
		return () => {
			Bus.off(Constants.bus.library.cart_callback, cartCallbackHandler)
			setIsBuying(false)
		}
	}, [price])

	//@ts-ignore
	const variantChoiceHandler = (infos) => {
		setPrice(infos.price)
	}

	const buyClickHandler = () => {
		const isBuying = descriptionRef.current.buy()
		setIsBuying(isBuying)
	}

	const productHasVariants = !!product.variants

	const getAddToCartLabel = () => {
		if (!product.available) {
			return playerData.ui.panel_product_out_of_stock
		} else if (isBuying) {
			return playerData.ui.panel_product_add_progress
		}
		const readablePrice = Utils.setReadablePrice(
			price || product.price,
			playerData.channel.locale,
			playerData.channel.currency
		)
		return (
			playerData.ui.panel_product_add_cta +
			(price || (!productHasVariants && product.price) ? ` (${readablePrice})` : '')
		)
	}

	if (playerData.event.event.status !== 'prelive') {
		$addToCartButton = (
			<AddToCartFloatingButton
				className={cx({ [classes.processing]: isBuying, [classes.outOfStock]: !product.available })}
				onClick={buyClickHandler}
			>
				{!isBuying && displayMode !== 'MOBILE_LANDSCAPE' && !isVertical && (
					<Box width={15} height={15} mr={10} css={[Style.icon]}>
						<Icon name={'cart'} width="100%" height="100%" fill={Theme.colors.white()} />
					</Box>
				)}
				<Box>{getAddToCartLabel()}</Box>
			</AddToCartFloatingButton>
		)
	}

	return (
		<>
			<Flex
				css={Style.content}
				width={1}
				height={$addToCartButton ? `calc(100% - ${fixedHeight}px)` : '100%'}
				justifyContent="center"
				alignContent="flex-start"
				flexWrap="wrap"
				ref={elRef}
			>
				<Flex
					width={1}
					flexWrap={'wrap'}
					justifyContent="center"
					pb={[40, playerData.event.event.status === 'prelive' ? 150 : 40]}
				>
					<Description innerRef={descriptionRef} data={product} onVariantChoice={variantChoiceHandler} />
				</Flex>
			</Flex>
			{$addToCartButton}
		</>
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductPanel)
