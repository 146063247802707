import React from 'react'
import { Flex, Box } from 'rebass'
import Style from './style'
import Theme from '@styles/theme'
import { mapDispatchToProps } from '@helpers/wrappers/withReduxStore'
import { State } from '@stores/index'
import { connect } from 'react-redux'

interface Props {
	dispatch?: (action) => void
	storeDatas?: any
	storeEvent?: any
}

const mapStateToProps = (state: State, props: Props) => {
	return { ...state, ...props }
}

const Introduction: React.FC<Props> = ({ storeDatas }) => {
	let $title = null
	let $text = null

	if (storeDatas) {
		if (storeDatas.event.event.content.concept.title) {
			$title = storeDatas.event.event.content.concept.title.replace(/(\\r\\n|\\n|\\r)/g, '\n')
			$text = storeDatas.event.event.content.concept.description.replace(/(\\r\\n|\\n|\\r)/g, '\n')
			return (
				<Box
					width={1}
					mt={[0, -225]}
					pt={[0, 225]}
					pb={Theme.preLive.separationValues}
					justifyContent="center"
					flexWrap="wrap"
					backgroundColor={Theme.colors.background}
				>
					<Flex
						width={[0.8, 1]}
						justifyContent="center"
						fontSize={['24em', '28em']}
						pt={[75, 100]}
						mx={'auto'}
						css={Style.title}
						color={Theme.colors.text_main}
						textAlign="center"
						className="introduction-title"
					>
						{$title}
					</Flex>
					<Flex
						width={0.8}
						maxWidth={500}
						mt={50}
						mx="auto"
						justifyContent="center"
						fontSize={['13em', '14em']}
						textAlign="center"
						css={Style.text}
						color={Theme.colors.text_second}
						className="wysiwyg introduction-text"
						flexWrap="wrap"
					>
						{$text}
					</Flex>
				</Box>
			)
		} else {
			return <></>
		}
	} else {
		return <></>
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Introduction)
