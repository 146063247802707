import { css } from '@emotion/react'
import Theme from '@styles/theme'

export default {
	el: css`
		/* opacity: 0.6; */
		img {
			width: 100%;
		}
		transition: opacity 600ms ${Theme.eases.outExpo} 0ms;
		@media ${Theme.media(0)} {
			&:hover {
				opacity: 0.5;
			}
		}
	`
}
