import styled from '@emotion/styled'
import { RegularSecondText, Text } from '@styles/SharedTextComponents'
import Theme from '@styles/theme'

const PREFIX = 'chat-message'

export const classes = {
	root: PREFIX,
	wrapper: `${PREFIX}-wrapper`
}

export const MessagesListWrapper = styled.div<{ isBrand? }>`
	padding: ${({ isBrand }) => (isBrand ? '3px 0' : 0)};
`

export const BaseAuthorName = styled(Text)<{ isBlock?; isLandscapeMobile? }>`
	font-weight: ${({ isBlock, isLandscapeMobile }) =>
		isBlock && !isLandscapeMobile ? Theme.fontWeights.bold : Theme.fontWeights.semibold};
	font-size: ${({ isBlock, isLandscapeMobile }) => (isBlock && !isLandscapeMobile ? '13em' : '11em')};
`

export const BaseMessageText = styled(RegularSecondText)<{ isBlock?; isLandscapeMobile }>`
	font-size: ${({ isBlock, isLandscapeMobile }) => (isBlock && !isLandscapeMobile ? '13em' : '12em')};
`
