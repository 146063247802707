import styled from '@emotion/styled'
import { css } from '@emotion/react'
import Theme from '@styles/theme'
import { handleSquaredEdges } from '@styles/sharedLogics'
import { BoldSansSerifText } from '@styles/SharedTextComponents'

interface ScrollToWrapperProps {
	shown: boolean
	isBlock?: boolean
	hasNewMessages?: boolean
	refWidth?: number
	isLandscapeAndDesktop?: boolean
}

const PREFIX = 'scroll-to-button'

export const classes = {
	root: PREFIX
}

const shownStyle = (shown) =>
	shown
		? css`
				opacity: 1;
				cursor: pointer;
				pointer-events: all;
				z-index: 10;
				transition: opacity 600ms ${Theme.eases.outExpo} 0ms;
				&:hover {
					opacity: 0.8;
				}
			`
		: null

const desktopStyle = (isBlock) =>
	isBlock
		? css`
				max-height: 35px;
				bottom: 80px;
				left: 50%;
				transform: translateX(-50%);
			`
		: null

export const ScrollToWrapper = styled.div<ScrollToWrapperProps>`
	display: flex;
	flex-wrap: wrap;
	bottom: 145px;
	position: absolute;
	left: ${({ refWidth, isLandscapeAndDesktop }) =>
		isLandscapeAndDesktop ? null : `${((refWidth || 100) * 50) / 100 + 15}px`};
	right: ${({ refWidth, isLandscapeAndDesktop }) =>
		isLandscapeAndDesktop ? `${((refWidth || 100) * 50) / 100 + 15}px` : null};
	transform: translateX(-50%);
	background-color: ${Theme.colors.black()};
	border-radius: 36px;
	opacity: 0;
	pointer-events: none;
	white-space: nowrap;
	padding: ${({ hasNewMessages }) => (hasNewMessages ? '12px 22px 10px 14px' : '12px')};
	&:active {
		opacity: 0.5;
	}
	${({ shown }) => shownStyle(shown)}
	${({ isBlock }) => desktopStyle(isBlock)}
	${handleSquaredEdges}
`

export const ScrollToText = styled(BoldSansSerifText)`
	text-transform: uppercase;
	font-size: 11em;
	margin-left: 4px;
	margin-right: 10px;
`

export const ScrollToArrow = styled.div`
	position: absolute;
	top: 13px;
	right: 10px;
	width: 10px;
	height: 10px;
`
