import React from 'react'
import Icons from './icons'
import { css } from '@emotion/react'
import Style, { classes } from './style'
import Utils from '@utils/index'
import Theme from '@styles/theme'
import { Box } from 'rebass'

const Icon: React.FC<{
	name?: string
	url?: string
	width: any
	height: any
	r?: number
	fill?: any
	stroke?: any
	strokeWidth?: any
	className?: string
}> = ({
	name = null,
	url = null,
	width = null,
	height = null,
	r,
	fill = null,
	stroke = null,
	className = null,
	strokeWidth = null
}) => {
	if (name) {
		name = name.toLowerCase()
		let customFill = null
		let customStroke = null

		// custom fill
		if (Utils.is.isArray(fill)) {
			let medias = ''
			for (const i in fill) {
				const index = parseInt(i)
				if (index !== fill.length - 1) {
					medias += `
				@media ${Theme.media(index)} {
					fill: ${fill[index]};
				}
			`
				}
			}
			customFill = css`
				fill: ${fill[fill.length - 1]};
				${medias}
			`
		} else {
			customFill = css`
				fill: ${fill};
			`
		}

		// custom stroke
		if (Utils.is.isArray(stroke)) {
			let medias = ''
			for (const i in stroke) {
				const index = parseInt(i)
				if (index !== stroke.length - 1) {
					medias += `
				@media ${Theme.media(index)} {
					stroke: ${stroke[index]};
				}
			`
				}
			}
			customStroke = css`
				stroke: ${stroke[stroke.length - 1]};
				stroke-width: ${strokeWidth};
				${medias}
			`
		} else {
			customStroke = css`
				stroke: ${stroke};
				stroke-width: ${strokeWidth};
			`
		}

		// `
		const rotation = r
			? css`
					transform: rotate(${r}deg);
				`
			: null

		if (Icons[name]) {
			return (
				<>
					<Box className={classes.root} as="i" css={[Style.icon, rotation]} height={height} width={width}>
						<Box
							as="svg"
							className={className}
							css={[Style.iconSvg, customFill, customStroke]}
							viewBox={`${Icons[name].viewBox}`}
						>
							<use xlinkHref={`#${name}`} />
						</Box>
					</Box>
				</>
			)
		} else {
			return <></>
		}
	} else if (url) {
		return <Box as="img" src={url} height="100%" />
	} else {
		return <></>
	}
}

export const iconClasses = classes

export type IconUnion = keyof typeof Icons

export default Icon
