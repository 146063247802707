import React, { createContext, useContext, useMemo, useReducer } from 'react'
import { Brand } from 'types/Branding'
import { ProviderBaseProps } from 'types/Common'
import { brandContextInitialState, brandReducer, BrandContextActions } from './logics'

interface BrandContext {
	brand?: Brand
	setBrandWithData?: (data) => void
}

const Context = createContext<BrandContext | null>({})

export const useBrandContext = () => useContext(Context)

export const BrandProvider: React.FC<ProviderBaseProps> = ({ children }) => {
	/**
	 * @States
	 */

	const [{ brand }, manageBrand] = useReducer(brandReducer, brandContextInitialState)

	const setBrandWithData = (data) => manageBrand({ type: BrandContextActions.SET_BRAND, data })

	const values = useMemo(
		(): BrandContext => ({
			brand,
			setBrandWithData
		}),
		[brand]
	)

	return <Context.Provider value={values}>{children}</Context.Provider>
}
