import Utils from '@utils/index'
import { CrossOutPrice, Price, Prices } from './style'

export type PriceProps = {
	price?: number
	crossOutPrice?: number
	channel?: any
}

const PriceComponent: React.FC<PriceProps> = ({ price, crossOutPrice, channel }) => {
	return (
		price && (
			<Prices>
				<Price isCrossedOut={!!crossOutPrice}>
					{Utils.setReadablePrice(price, channel?.locale, channel?.currency)}
				</Price>
				{crossOutPrice && (
					<CrossOutPrice>
						{Utils.setReadablePrice(crossOutPrice, channel?.locale, channel?.currency)}
					</CrossOutPrice>
				)}
			</Prices>
		)
	)
}

export default PriceComponent
