import { css } from '@emotion/react'
import Theme from '@styles/theme'

export default {
	inputLabel: css`
		font-family: ${Theme.fontFamilies.Second};
		font-weight: ${Theme.fontWeights.bold};
		/* text-transform: uppercase; */
	`,

	inputWrap: css`
		width: 100%;
		border: 1px solid ${Theme.colors.black(0.2)};
		border-radius: 8px;
		position: relative;
		transition: border 600ms ${Theme.eases.outExpo} 0ms;
	`,

	isFocus: css`
		border: 1px solid ${Theme.colors.black(0.5)};
	`,

	prefix: css`
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 70px;
		border-top-left-radius: 8px;
		border-bottom-left-radius: 8px;
		border-right: 1px solid ${Theme.colors.black(0.2)};
	`,

	prefixValue: css`
		font-family: ${Theme.fontFamilies.Second};
		font-weight: ${Theme.fontWeights.bold};
		line-height: 10px;
	`,

	input: css`
		width: 100%;
		appearance: none !important;
		background: none;
		border: none;
		outline: none;
		margin: 0;
		font-size: 13px;
		padding: 16px 20px 15px 90px;
		border-radius: 8px;
		font-family: ${Theme.fontFamilies.Second};
		font-weight: ${Theme.fontWeights.bold};
	`,

	selector: css`
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		opacity: 0;
		padding: 0;
		top: 0;
		outline: none;
		margin: 0;
		left: 0;
		cursor: pointer;
	`,

	chevron: css`
		pointer-events: none;
	`,

	error: css`
		border: 1px solid ${Theme.colors.red()};
	`,

	errorText: css`
		color: ${Theme.colors.red()};
	`,

	isDisabled: css`
		pointer-events: none;
		opacity: 0.5;
	`,

	isHidden: css`
		display: none;
	`,

	required: css`
		font-family: ${Theme.fontFamilies.sansSerif};
		font-weight: ${Theme.fontWeights.regular};
	`
}
