import Icon from '@components/Micro/Icon'
import { useViewer } from '@helpers/contexts/viewerContext'
import Theme from '@styles/theme'
import getConfig from 'next/config'
import { useRouter } from 'next/router'
import React, { useRef, useState } from 'react'
import { Box, Flex } from 'rebass'
import Style, { classes, CtaContainer } from './style'
import { usePlayerContext } from '@helpers/contexts/player'
import LikeButton from '../LiveActions/LikeButton'
import { CatalogButton } from '../LiveActions/CatalogButton'
import CartButton from '../LiveActions/CartButton'
import { classGenerator } from '@styles/sharedLogics'
import { useNotificationsContext } from '@helpers/contexts/notifications'
import { DrawersType } from '@components/Notifiers/Drawers/constants'
import { handleApiResponseErrors } from '@helpers/contexts/fetchHelpers'
import { MAX_MESSAGES_LENGTH } from '../ChatField/Modular'
import { Product } from 'types/Product'
const { publicRuntimeConfig } = getConfig()
const NEXT_PUBLIC_API_ENDPOINT = publicRuntimeConfig.NEXT_PUBLIC_API_ENDPOINT

interface Props {
	products: Product[]
	innerRef?: any
	likesBoxRef?: any
	onWriting?: () => void
	onUnWriting?: () => void
	isMobile?: boolean
	uiIsLight?: boolean
	isTranslated?: boolean
	onCinemaClick: () => void
	isCinema: boolean
	isLandscape?: boolean
	chatboxRef?: any
	landscapeChatShiftY?: number
	isHorizontal?: boolean
}
const BottomBar: React.FC<Props> = ({
	products,
	onWriting,
	onUnWriting,
	isMobile,
	uiIsLight,
	isTranslated,
	onCinemaClick,
	isCinema,
	isLandscape,
	isHorizontal
}) => {
	const { currentViewer, viewerIsBanned } = useViewer()
	const { playerData, fakeStatus } = usePlayerContext()
	const input = useRef()
	const eventId = useRouter().query.id
	const [message, setMessage] = useState('')
	const [isWriting, setIsWriting] = useState(false)
	const { isLive, isFinishing, isReplay } = usePlayerContext()
	const { openDrawer } = useNotificationsContext()

	const inputFocusHandler = () => {
		if (currentViewer?.firstName) {
			setIsWriting(true)
			onWriting()
		} else {
			openDrawer(DrawersType.FIRST_NAME_DRAWER)
			//@ts-ignore
			input.current.blur()
		}
	}

	const inputBlurHandler = () => {
		setIsWriting(false)
		onUnWriting()
	}

	const submit = async () => {
		const value = message.trim()
		if (value.length > 1 && value.length < 136) {
			if (!viewerIsBanned()) {
				setMessage('')
				fetch(`${NEXT_PUBLIC_API_ENDPOINT}/api/public/events/${eventId}/messages`, {
					body: JSON.stringify({
						user_displayName: currentViewer.firstName,
						viewerId: currentViewer.uid,
						value,
						event: eventId,
						locale: playerData?.channel?.locale
					}),
					headers: {
						'Content-Type': 'application/json'
					},
					method: 'POST'
				})
					.then((res) => res.json())
					.then((res) => handleApiResponseErrors(res, currentViewer))
					.catch((error) => console.error(error))
			} else {
				setMessage('')
			}
		}
	}

	const onInput = () => {
		//@ts-ignore
		input.current.style.height = '5px'
		//@ts-ignore
		input.current.style.height = input.current.scrollHeight + 'px'
	}

	const onKeyUp = (e) => {
		if (e.keyCode === 13) {
			submit()
			//@ts-ignore
			input.current.blur()
		}
	}

	const onChange = (e) => {
		setMessage(e.currentTarget.value)
	}

	return (
		<Flex
			css={[
				Style.el,
				isReplay || isFinishing ? Style.elReplay : null,
				isTranslated && (isReplay || isFinishing) ? Style.isTranslated : null,
				isLandscape ? Style.desktopLandscapeStyle : null, // TODO: Styled component
				isMobile && isLandscape && isHorizontal ? Style.mobileLandscapeHorizontalStyle : null // TODO: Styled component
			]}
			alignItems={'center'}
			justifyContent={((isReplay || isFinishing) && isMobile) || !isMobile ? 'flex-end' : 'space-between'}
			className={'bottom-bar'}
		>
			{isMobile && (isLive || fakeStatus === 'live') && (
				<Flex css={[Style.box, isWriting ? Style.isHide : null]}>
					<Flex
						css={[Style.btn, Theme.squaredEdges, Style.muteChat, uiIsLight ? Style.hidden : null]}
						justifyContent="center"
						alignItems="center"
						flexWrap="wrap"
						onClick={onCinemaClick}
					>
						<Box width={19} height={17}>
							<Icon
								name={isCinema ? 'unmute_chat' : 'mute_chat'} // TODO: Change icon in Mobile Landscape horizontal
								width="100%"
								height="100%"
								fill={Theme.colors.white()}
							/>
						</Box>
					</Flex>
				</Flex>
			)}
			<Box
				css={[
					Style.field,
					(isMobile && isLandscape) ||
					(!isLandscape && !isMobile) ||
					uiIsLight ||
					isReplay ||
					isFinishing ||
					isCinema
						? Style.hidden
						: null,
					Theme.squaredEdges
				]}
				width={
					isWriting
						? 1
						: playerData.event.event.settings.options.hasCart
							? isLandscape && !isMobile
								? 1
								: 'calc(100% - 180px)'
							: 'calc(100% - 135px)'
				}
				mx={2}
			>
				<Box
					ref={input}
					as="textarea"
					value={message}
					minHeight={35}
					maxHeight={isWriting && message.length ? 9999 : 35}
					onChange={onChange}
					maxLength={MAX_MESSAGES_LENGTH}
					autoComplete="off"
					onKeyUp={onKeyUp}
					onInput={onInput}
					onFocus={inputFocusHandler}
					onBlur={inputBlurHandler}
					css={[Style.input]}
					placeholder={isWriting ? null : playerData.ui.message_field_placeholder}
					pr={message?.length ? 43 : 15}
					py={'8px'}
					pl={15}
				/>

				<Flex
					width={44}
					height={35}
					alignItems={'center'}
					justifyContent="center"
					onTouchStart={submit}
					css={Style.sendWrap}
				>
					{(isWriting || message.length) && (
						<Box width={14} height={14} p={'1px'} css={[Style.send, message.length ? Style.isShown : null]}>
							<Icon name="plane" width="100%" height="100%" fill={Theme.colors.white()} />
						</Box>
					)}
				</Flex>
			</Box>
			<CtaContainer
				className={classGenerator(classes.root, {
					[classes.hidden]: isWriting && !isLandscape,
					[classes.removed]: isCinema
				})}
				isVertical={isLandscape && isHorizontal}
			>
				<CartButton show={playerData.event.event.settings.options.hasCart} />
				<CatalogButton products={products} playerData={playerData} show={isMobile || isLandscape} />
				<LikeButton uiIsLight={uiIsLight} show={playerData.event.event.status === 'live'} />
			</CtaContainer>
			{isReplay && isMobile && (
				<Flex css={[Style.box, isWriting && !isLandscape ? Style.isHide : null]} ml={10}>
					<Flex
						css={[Style.btn, Theme.squaredEdges, Style.muteChat, uiIsLight ? Style.hidden : null]}
						justifyContent="center"
						alignItems="center"
						flexWrap="wrap"
						onClick={onCinemaClick}
					>
						<Box width={19} height={17}>
							<Icon
								name={isCinema ? 'unmute_chat' : 'mute_chat'}
								width="100%"
								height="100%"
								fill={Theme.colors.white()}
							/>
						</Box>
					</Flex>
				</Flex>
			)}
		</Flex>
	)
}

export default BottomBar
