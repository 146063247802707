import React, { useEffect, useRef, useState } from 'react'
import { classGenerator as cx } from '@styles/sharedLogics'
import {
	ChatFieldWrapper,
	chatFieldClasses,
	ExtensibleInputWrapper,
	FieldWrapper,
	InputWrapper,
	SendIcon
} from './style'
import { useMessagesContext } from '@helpers/contexts/messages'
import { useViewer } from '@helpers/contexts/viewerContext'
import Theme from '@styles/theme'
import Icon from '@components/Micro/Icon'
import { usePlayerContext } from '@helpers/contexts/player'
import { DrawersType } from '@components/Notifiers/Drawers/constants'
import { useNotificationsContext } from '@helpers/contexts/notifications'
import { ModalsType } from '@components/Notifiers/Modals/constants'

interface ChatFieldProps {
	onWriting?: any
	onUnWriting?: any
	borderActive?: boolean
	floating?: boolean
}

export const MAX_MESSAGES_LENGTH = 200

const ChatField: React.FC<ChatFieldProps> = ({ onWriting, onUnWriting, borderActive, floating }) => {
	const { currentViewer, viewerIsBanned } = useViewer()
	const { sendMessage } = useMessagesContext()
	const { playerData, isLandscape, isVertical } = usePlayerContext()
	const { openDrawer, closeModal, openModal } = useNotificationsContext()

	const input = useRef(null)
	const [message, setMessage] = useState('')
	const [isWriting, setIsWriting] = useState(false)

	const inputFocusHandler = () => {
		if (currentViewer?.firstName) {
			setIsWriting(true)
			onWriting()
		} else {
			if (isLandscape && !isVertical) {
				openModal(ModalsType.FIRST_NAME, { onClose: closeModal, isModal: true })
			} else {
				openDrawer(DrawersType.FIRST_NAME_DRAWER)
			}
			//@ts-ignore
			input.current.blur()
		}
	}

	const inputBlurHandler = () => {
		setIsWriting(false)
		onUnWriting()
	}

	const submit = async () => {
		const value = message.trim()
		if (value.length > 1 && value.length <= MAX_MESSAGES_LENGTH) {
			if (!viewerIsBanned()) {
				await sendMessage(value, currentViewer, playerData)
			}
			setMessage('')
		}
		setChatFieldHeight(true)
	}

	const onKeyDown = (e) => {
		if (e.keyCode === 13 && !e.shiftKey) {
			e.preventDefault()
			submit()
		}
	}

	const handleFieldClick = (e) => {
		e.stopPropagation()
		input.current.focus()
	}

	const onChange = (e) => {
		setMessage(e.currentTarget.value)
	}

	const onInput = () => {
		setChatFieldHeight()
	}

	useEffect(() => {
		setChatFieldHeight(true)
	}, [])

	const setChatFieldHeight = (reset?: Boolean) => {
		if (input?.current) {
			input.current.style.height = '5px'
			if (reset) return
			input.current.style.height = input.current.scrollHeight + 'px'
		}
	}

	return (
		<>
			<ChatFieldWrapper
				className={cx(chatFieldClasses.root, {
					[chatFieldClasses.xs]: isLandscape && !isVertical,
					[chatFieldClasses.sm]: isLandscape && isVertical,
					[chatFieldClasses.withBorder]: borderActive,
					[chatFieldClasses.floating]: floating
				})}
				isWriting={isWriting}
				onClick={handleFieldClick}
			>
				<FieldWrapper
					className={cx(chatFieldClasses.fieldWrapper, {
						[chatFieldClasses.floating]: floating
					})}
				>
					{!floating && (
						<InputWrapper
							ref={input}
							value={message}
							onInput={onInput}
							onChange={onChange}
							autoComplete="off"
							onKeyDown={onKeyDown}
							onFocus={inputFocusHandler}
							onBlur={inputBlurHandler}
							placeholder={isWriting ? null : playerData.ui.message_field_placeholder}
							className={cx({
								[chatFieldClasses.floating]: floating
							})}
							maxLength={MAX_MESSAGES_LENGTH}
						/>
					)}
					{floating && (
						<ExtensibleInputWrapper
							ref={input}
							onChange={onChange}
							onInput={onInput}
							onKeyDown={onKeyDown}
							maxLength={MAX_MESSAGES_LENGTH}
							autoComplete="off"
							onFocus={inputFocusHandler}
							onBlur={inputBlurHandler}
							value={message}
							isWriting={isWriting}
							placeholder={playerData.ui.message_field_placeholder}
							hasText={message.length > 1}
							className={cx({
								[chatFieldClasses.floating]: floating
							})}
						/>
					)}

					<SendIcon
						className={cx(chatFieldClasses.icon, { [chatFieldClasses.iconActive]: isWriting })}
						onClick={submit}
						onTouchStart={submit}
						hasText={message.length > 1}
					>
						<Icon name="plane" width={14} height={14} fill={Theme.colors.black()} />
					</SendIcon>
				</FieldWrapper>
			</ChatFieldWrapper>
		</>
	)
}

export default ChatField
