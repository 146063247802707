import styled from '@emotion/styled'
import { BaseFlex } from '@styles/common'
import Theme from '@styles/theme'

export const ShutterWrapper = styled(BaseFlex)`
	align-items: center;
	justify-content: center;
	flex-direction: column;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	background-color: ${Theme.colors.white()};
	position: absolute;
	z-index: ${Theme.zIndices.max};
	font-family: ${() => Theme.fontFamilies.Main};
	overflow-x: scroll;
`

export const IgnoreEl = styled.div<{ isMobile }>`
	position: absolute;
	top: ${({ isMobile }) => Theme.spacing(isMobile ? 6 : 8)};
	right: ${({ isMobile }) => Theme.spacing(isMobile ? 6 : 10)};
	font-size: 14px;
	line-height: 18px;
	font-weight: 700;
	color: ${Theme.colors.grey1};
	cursor: pointer;
`
