import { css } from '@emotion/react'
import styled from '@emotion/styled'
import Theme from '@styles/theme'

const transitionTime = '800ms'
const transitionEase = 'cubic-bezier(0.19, 1, 0.22, 1)'

export const Content = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	overflow: hidden;
	position: absolute;
	&:before {
		content: '';
		display: block;
		position: absolute;
		right: 0px;
		top: 15px;
		width: 15px;
		height: 30px;
		background: linear-gradient(-90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
	}
`

export const NotificationWrapper = styled.div<{ isExpanded?: boolean; isHidden?: boolean }>`
	width: 60px;
	height: 60px;
	right: 15px;
	top: 81px;
	background-color: ${Theme.colors.white()};
	position: absolute;
	border-radius: 15px;
	pointer-events: all;
	cursor: pointer;
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.12);
	transition: width ${transitionTime} ${transitionEase} 0ms;
	z-index: ${Theme.zIndices.liveProducts};
	&:active {
		opacity: 0.5;
	}
	${({ isHidden }) =>
		isHidden &&
		css`
			display: none;
		`}
	${({ isExpanded }) =>
		isExpanded &&
		css`
			width: 324px;
		`}
`

export const NewMessages = styled.div<{ isShown?: boolean }>`
	position: absolute;
	z-index: 1;
	width: 26px;
	height: 26px;
	background-color: rgba(213, 0, 50, 0.6);
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.12);
	right: -8px;
	top: -8px;
	border-radius: 100%;
	span {
		display: block;
		width: 100%;
		font-size: 12px;
		font-family: ${Theme.fontFamilies.Main};
		text-align: center;
		color: #fff;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		line-height: 20px;
	}
	&:before {
		content: '';
		display: block;
		border-radius: 100%;
		position: absolute;
		left: 3px;
		top: 3px;
		width: calc(100% - 6px);
		height: calc(100% - 6px);
		background-color: #d50032;
	}
	opacity: 0;
	transition: all ${transitionTime} ${transitionEase} 0ms;
	${({ isShown }) =>
		isShown &&
		css`
			opacity: 1;
			transition: all ${transitionTime} ${transitionEase} 1000ms;
		`}
`

export const Ico = styled.div<{ isExpanded?: boolean }>`
	padding: 18px;
	width: 24px;
	height: 24px;
	border-radius: 11px;
	background-color: rgba(0, 0, 0, 0);
	transition: all ${transitionTime} ${transitionEase} 0ms;
	${({ isExpanded }) =>
		isExpanded &&
		css`
			border-radius: 50%;
			box-shadow: inset 0 0 0 1px ${Theme.colors.black(0.1)};
			transform: scale(0.6);
		`}
`
export const Description = styled.div<{ isExpanded?: boolean }>`
	width: 250px;
	height: 100%;
	left: 60px;
	display: flex;
	justify-content: space-between;
	position: absolute;
	align-items: center;
	opacity: 0;
	transition: opacity ${transitionTime} ${transitionEase} 0ms;
	${({ isExpanded }) =>
		isExpanded &&
		css`
			opacity: 1;
		`}
`

export const TextWrap = styled.div`
	display: box;
`

export const CtaWrap = styled.div`
	display: box;
`

export const Title = styled.div`
	font-size: 14px;
	font-family: ${Theme.fontFamilies.Main};
	font-weight: ${Theme.fontWeights.bold};
	color: #000;
	margin-bottom: 4px;
`

export const Subtitle = styled.div`
	font-size: 13px;
	font-family: ${Theme.fontFamilies.Second};
	font-weight: ${Theme.fontWeights.regular};
	color: #9e9e9e;
`

export const Cta = styled.div`
	border-radius: 8px;
	background: #000;
	padding: 7px 10px;
	color: #fff;
	font-size: 13px;
	font-family: ${Theme.fontFamilies.Main};
	font-weight: ${Theme.fontWeights.bold};
`
