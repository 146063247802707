import React from 'react'

import Title from './Title'
import Timer from './Timer'

interface Props {}

const Subscribe: React.FC<Props> = () => {
	return (
		<>
			<Title />
			<Timer />
		</>
	)
}

export default Subscribe
