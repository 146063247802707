import arrow_0_deg from '@assets/icons/arrow_0_deg.svg'
import arrow_45_deg from '@assets/icons/arrow_45_deg.svg'
import arrow_90_deg from '@assets/icons/arrow_90_deg.svg'
import arrow_135_deg from '@assets/icons/arrow_135_deg.svg'
import arrow_180_deg from '@assets/icons/arrow_180_deg.svg'
import arrow_225_deg from '@assets/icons/arrow_225_deg.svg'
import arrow_270_deg from '@assets/icons/arrow_270_deg.svg'
import arrow_315_deg from '@assets/icons/arrow_315_deg.svg'
import arrow_circular from '@assets/icons/arrow_circular.svg'
import ban from '@assets/icons/ban.svg'
import bubble from '@assets/icons/bubble.svg'
import bubble_idz from '@assets/icons/bubble_idz.svg'
import calendar from '@assets/icons/calendar.svg'
import catalog from '@assets/icons/catalog.svg'
import catalog_v2 from '@assets/icons/catalog_v2.svg'
import cart from '@assets/icons/cart.svg'
import cart_add from '@assets/icons/cart_add.svg'
import chat_border from '@assets/icons/chat_border.svg'
import chat_plain from '@assets/icons/chat_plain.svg'
import check from '@assets/icons/check.svg'
import check_round from '@assets/icons/check_round.svg'
import chevron from '@assets/icons/chevron.svg'
import cinema_set from '@assets/icons/cinema_set.svg'
import cinema_unset from '@assets/icons/cinema_unset.svg'
import mute_chat from '@assets/icons/mute_chat.svg'
import unmute_chat from '@assets/icons/unmute_chat.svg'
import clock from '@assets/icons/clock.svg'
import cookie from '@assets/icons/cookie.svg'
import close from '@assets/icons/close.svg'
import drag from '@assets/icons/drag.svg'
import ellipsis from '@assets/icons/ellipsis.svg'
import email from '@assets/icons/email.svg'
import facebook from '@assets/icons/facebook.svg'
import fullscreen from '@assets/icons/fullscreen.svg'
import google_calendar from '@assets/icons/google_calendar.svg'
import unfullscreen from '@assets/icons/unfullscreen.svg'
import heart_plain from '@assets/icons/heart_plain.svg'
import heart_border_thin from '@assets/icons/heart_border_thin.svg'
import heart_border_bold from '@assets/icons/heart_border_bold.svg'
import instagram from '@assets/icons/instagram.svg'
import light from '@assets/icons/light.svg'
import link from '@assets/icons/link.svg'
import linkedin from '@assets/icons/linkedin.svg'
import list from '@assets/icons/list.svg'
import logo from '@assets/icons/logo.svg'
import mobile from '@assets/icons/mobile.svg'
import notification from '@assets/icons/notification.svg'
import outlook from '@assets/icons/outlook.svg'
import pinned from '@assets/icons/pinned.svg'
import pinterest from '@assets/icons/pinterest.svg'
import play from '@assets/icons/play.svg'
import plane from '@assets/icons/plane.svg'
import question from '@assets/icons/question.svg'
import screen from '@assets/icons/screen.svg'
import settings from '@assets/icons/settings.svg'
import share from '@assets/icons/share.svg'
import shirt from '@assets/icons/shirt.svg'
import spinner from '@assets/icons/spinner.svg'
import stats from '@assets/icons/stats.svg'
import success from '@assets/icons/success.svg'
import tiktok from '@assets/icons/tiktok.svg'
import trash from '@assets/icons/trash.svg'
import twitter from '@assets/icons/twitter.svg'
import users from '@assets/icons/users.svg'
import vibrator from '@assets/icons/vibrator.svg'
import waves from '@assets/icons/waves.svg'
import yahoo from '@assets/icons/yahoo.svg'
import youtube from '@assets/icons/youtube.svg'
import chevron_round from '@assets/icons/chevron_round.svg'
import pause from '@assets/icons/pause.svg'
import gift from '@assets/icons/gift.svg'
import thumb from '@assets/icons/thumb.svg'
import sad_face from '@assets/icons/sad_face.svg'
import tos from '@assets/icons/tos.svg'
import reduce_arrow from '@assets/icons/reduce_arrow.svg'
import unreduce_arrow from '@assets/icons/unreduce_arrow.svg'
import round_cross from '@assets/icons/round_cross.svg'
import phone from '@assets/icons/phone.svg'
import cookies from '@assets/icons/cookies.svg'
import path from '@assets/icons/path.svg'
import star_fill from '@assets/icons/star_fill.svg'
import star_empty from '@assets/icons/star_empty.svg'
import success_color from '@assets/icons/success_color.svg'
import microphone from '@assets/icons/microphone.svg'
import full_screen from '@assets/icons/full_screen.svg'
import no_chat from '@assets/icons/no_chat.svg'

export default {
	youtube,
	arrow_0_deg,
	arrow_45_deg,
	arrow_90_deg,
	arrow_135_deg,
	arrow_180_deg,
	arrow_225_deg,
	arrow_270_deg,
	arrow_315_deg,
	arrow_circular,
	ban,
	chevron_round,
	bubble,
	bubble_idz,
	calendar,
	catalog,
	catalog_v2,
	cart,
	cart_add,
	chat_border,
	chat_plain,
	check,
	check_round,
	chevron,
	cinema_set,
	cinema_unset,
	cookie,
	cookies,
	clock,
	close,
	drag,
	ellipsis,
	email,
	facebook,
	fullscreen,
	google_calendar,
	unfullscreen,
	heart_plain,
	heart_border_thin,
	heart_border_bold,
	instagram,
	light,
	link,
	linkedin,
	list,
	logo,
	mobile,
	mute_chat,
	unmute_chat,
	notification,
	outlook,
	phone,
	pinned,
	pinterest,
	plane,
	play,
	question,
	screen,
	settings,
	share,
	shirt,
	spinner,
	stats,
	success,
	tiktok,
	trash,
	twitter,
	users,
	vibrator,
	waves,
	yahoo,
	pause,
	gift,
	thumb,
	sad_face,
	tos,
	reduce_arrow,
	unreduce_arrow,
	round_cross,
	path,
	star_fill,
	star_empty,
	success_color,
	microphone,
	full_screen,
	no_chat
}
