const defineClassChangesByElement = (products, classes) => {
	return products.map((_, index) => {
		const productClass = `${classes.root}-${index + 1}`
		const elToChange = document.getElementsByClassName(productClass)[0]
		if (elToChange) {
			return {
				id: elToChange.id,
				currentClass: productClass,
				nextClass:
					productClass === classes.firstImage
						? `${classes.root}-${products.length}`
						: `${classes.root}-${index}`
			}
		}
		return []
	})
}

const applyClassChanges = (elementsBuffer) => {
	elementsBuffer.map((el) => {
		const elToChange = document.getElementById(el.id)
		if (elToChange) {
			elToChange.classList.replace(el.currentClass, el.nextClass)
		}
	})
}

export const handleClassesRotation = (products, classes) => {
	if (products.length > 1) {
		const elementsBuffer = defineClassChangesByElement(products, classes)
		applyClassChanges(elementsBuffer)
	}
}
