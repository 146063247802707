import React, { useEffect, useRef, useState } from 'react'
import { mapDispatchToProps } from '@helpers/wrappers/withReduxStore'
import { State } from '@stores/index'
import { connect } from 'react-redux'
import {
	Answer,
	Button,
	classes,
	FillDescription,
	Container,
	FormWrapper,
	IconWrapper,
	Optin,
	OptinBox,
	OptinText,
	Question,
	Rewards,
	RewardsList,
	RewardsListItem,
	RewardsTitle,
	RewardsToggle,
	RewardsTop,
	RewardsWrapper,
	RulesOptinLink,
	SplitBar,
	TextWrapper,
	TimerWrapper
} from './style'
import Timer from '@components/Micro/Timer'
import Theme from '@styles/theme'
import Field from '@components/Micro/Field'
import TextWithUrlAndPhone from '@components/Micro/TextWithUrlAndPhone'
import Icon from '@components/Micro/Icon'
import Utils from '@utils/index'
import getConfig from 'next/config'
import { GiftIcon } from '@components/StatusManager/LiveAndReplay/components/WinningInstantButton/style'
import { WinningInstant } from 'types/WinningInstant'
import { useViewer } from '@helpers/contexts/viewerContext'
const { publicRuntimeConfig } = getConfig()
const NEXT_PUBLIC_API_ENDPOINT = publicRuntimeConfig.NEXT_PUBLIC_API_ENDPOINT
import { TimerService } from '@helpers/TimerService'
import { useNotificationsContext } from '@helpers/contexts/notifications'
import { ModalsType } from '@components/Notifiers/Modals/constants'
import { usePlayerContext } from '@helpers/contexts/player'
import { classGenerator } from '@styles/sharedLogics'
import { FadeTransition } from '@styles/transitions/FadeTransition'
import { TranslateYAndFadeTransition } from '@styles/transitions/TranslateYAndFadeTransition'
import TermsOfServicesComponent from '@components/Templates/TermsOfServices'
import Bus from '@helpers/bus'
import Constants from '@constants'
import { StoreConfig } from '@models/stores'
import { handleApiResponseErrors } from '@helpers/contexts/fetchHelpers'
import { useWinningInstantContext } from '@helpers/contexts/winningInstant'

interface Props {
	winningInstant?: WinningInstant
	playerData?: any
	wiCounter?: number
	storeConfig?: StoreConfig
	dispatch?: (action) => void
}

const mapStateToProps = (state: State, props: Props) => {
	return { ...state, ...props }
}

const WinningInstantForm: React.FC<Props> = ({ storeConfig, dispatch }) => {
	const { currentViewer, updateCurrentViewer } = useViewer()
	const { winningInstant, wiCounter } = useWinningInstantContext()
	const { closeLastPanel, openModal } = useNotificationsContext()
	const { playerData, isMobile, videoMode, displayMode } = usePlayerContext()
	const [currentAnswerId, setCurrentAnswerId] = useState<string>(null)
	const [email, setEmail] = useState<string>('')
	const [name, setName] = useState<string>('')
	const [emailIsNotValid, setEmailIsNotValid] = useState<boolean>(false)
	const [hasSubmitted, setHasSubmitted] = useState<boolean>(false)
	const [nameIsNotValid, setNameIsNotValid] = useState<boolean>(false)
	const [rulesOptinIsNotValid, setRulesOptinIsNotValid] = useState<boolean>(false)
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
	const [hasRulesOptin, setHasRulesOptin] = useState<boolean>(false)
	const [hasCommercialOptin, setHasCommercialOptin] = useState<boolean>(false)
	const [rewardsToggled, setRewardsToggled] = useState<boolean>(false)
	const tosRef = useRef(null)

	const formInfosRef = useRef(null)

	const answerClickHandler = (answerId) => {
		setCurrentAnswerId(answerId)
	}

	useEffect(() => {
		setName(currentViewer?.firstName || '')
		setEmail(currentViewer?.email || '')
		setHasCommercialOptin(!!currentViewer?.emailCommercialOptIn)
	}, [currentViewer])

	const submit = () => {
		if (playerData.event.event.status === 'live') {
			let hasError = false
			if (!Utils.is.isEmail(email)) {
				setEmailIsNotValid(true)
				hasError = true
			} else {
				setEmailIsNotValid(false)
			}

			if (name.length < 1) {
				setNameIsNotValid(true)
				hasError = true
			} else {
				setNameIsNotValid(false)
			}

			if (!hasRulesOptin && winningInstant.hasRulesOptin) {
				setRulesOptinIsNotValid(true)
				hasError = true
			} else {
				setRulesOptinIsNotValid(false)
			}
			const acceptedTos = tosRef?.current?.validate()
			if (!storeConfig?.consent?.tos && !acceptedTos) {
				tosRef?.current?.setError()
				hasError = true
			}

			if (!hasError) {
				updateCurrentViewer({ firstName: name, email }).then(() => {
					if (acceptedTos) {
						const consent = {
							...storeConfig.consent,
							tos: true
						}
						Bus.send(Constants.bus.player.legal_consented, consent)
						dispatch({ type: 'SET_CONSENT', ...consent })
					}
				})
				subscribe()
			}
		}
	}

	const subscribe = async () => {
		if (isSubmitting || hasSubmitted) return

		setIsSubmitting(true)
		setFormOptinMethod()
			.then((res) => {
				handleApiResponseErrors(res, currentViewer)
				setIsSubmitting(false)
				setHasSubmitted(true)
				updateCurrentViewer({
					wiParticipations: currentViewer.wiParticipations.concat(res)
				})
				closeLastPanel()
				openModal(ModalsType.WINNING_INSTANT_SUBSCRIPTION, { playerData })
			})
			.catch((error) => {
				console.log(error)
				setIsSubmitting(false)
			})
	}

	const setFormOptinMethod = () => {
		const participation = {
			viewerId: currentViewer?.uid,
			email: email,
			name: name,
			hasCommercialOptin: hasCommercialOptin || false,
			domainId: storeConfig?.domain,
			interactionId: winningInstant?.uuid,
			answerId: currentAnswerId,
			eventId: playerData?.event?.event?.uuid
		}

		return fetch(
			`${NEXT_PUBLIC_API_ENDPOINT}/api/channels/${playerData.channel.uuid}/events/${playerData.event.event.uuid}/winning_instants/${winningInstant.uuid}/participate`,
			{
				body: JSON.stringify(participation),
				headers: {
					'Content-Type': 'application/json'
				},
				method: 'POST'
			}
		).then((res) => res.json())
	}

	useEffect(() => {
		let timeout = null
		if (formInfosRef?.current && currentAnswerId) {
			// The basic `scrollIntoView` method doesn't work properly
			// when library is loaded in an iframe so we use a basic `scrollBy` function
			const form = document.getElementById('winning_instant_form')
			timeout = setTimeout(
				() => form?.scrollBy({ top: formInfosRef.current?.offsetTop + 160, behavior: 'smooth' }),
				400
			)
		}
		return () => {
			if (timeout) clearTimeout(timeout)
		}
	}, [currentAnswerId])

	const formatedTimer = () => TimerService.formatedTimer(wiCounter)

	return (
		winningInstant?.action !== 'end' && (
			<FormWrapper
				className={classGenerator({
					[classes.rewardTitleMobileLandspace]: isMobile && videoMode === 'landscape'
				})}
				id="winning_instant_form"
			>
				<RewardsWrapper displayMode={displayMode}>
					<RewardsTop>
						<IconWrapper>
							<GiftIcon color={Theme.colors.white()}>
								<Icon name="gift" height={'auto'} width={15} />
							</GiftIcon>
						</IconWrapper>
						<TextWrapper>
							<Rewards>
								<RewardsTitle>{playerData?.ui?.winning_instant?.title}</RewardsTitle>
							</Rewards>
							<RewardsToggle
								color={Theme.colors.text_main}
								onClick={() => setRewardsToggled(!rewardsToggled)}
							>
								{rewardsToggled
									? playerData?.ui?.winning_instant?.hide_results
									: playerData?.ui?.winning_instant?.show_results}
							</RewardsToggle>
						</TextWrapper>
					</RewardsTop>
					<FadeTransition timeout={200} classNames="opacity" in={rewardsToggled} unmountOnExit>
						<RewardsList color={Theme.colors.black()}>
							{winningInstant?.rewardsList.split(/\n/).map((reward, i) => (
								<RewardsListItem key={`rewards-list-item-${i}`}>
									<TextWithUrlAndPhone text={reward} fieldKey={`winning-instant-reward-${i}`} />
								</RewardsListItem>
							))}
						</RewardsList>
					</FadeTransition>
				</RewardsWrapper>
				<Container displayMode={displayMode}>
					{wiCounter && (
						<TimerWrapper>
							<Timer timer={formatedTimer()} customCss={{ marginBottom: '10px' }} />
						</TimerWrapper>
					)}
					<Question color={Theme.colors.text_main} marginBottom={15}>
						{winningInstant?.question}
					</Question>
					{winningInstant?.answers.map((answer, i, array) => (
						<Answer
							marginBottom={i !== array.length - 1 ? '15px' : '0'}
							color={Theme.colors.text_main}
							onClick={() => answerClickHandler(answer.uuid)}
							disabled={currentAnswerId && currentAnswerId !== answer.uuid}
							currentChoice={currentAnswerId && currentAnswerId === answer.uuid}
							key={`winning-instant-answer-${i}`}
							noSelection={!currentAnswerId}
							css={Theme.squaredEdges}
						>
							{answer.content}
						</Answer>
					))}
					<TranslateYAndFadeTransition
						timeout={400}
						classNames="form"
						in={!!currentAnswerId}
						translate="200px"
						unmountOnExit
					>
						<div ref={formInfosRef}>
							<SplitBar backgroundColor={Theme.colors.text_second} />
							<FillDescription color={Theme.colors.text_second}>
								{playerData?.ui?.winning_instant?.fill_form}
							</FillDescription>
							<Field
								label={`${playerData.ui.alert_sms_form_firstname_label || 'FIRST NAME'}*`}
								name="name"
								type="text"
								value={name}
								isRequired={true}
								hasError={nameIsNotValid}
								onChange={(e) => {
									setName(e.currentTarget.value)
								}}
							/>
							<Field
								label={`${playerData.ui.commercial_optin_email || 'E-MAIL'}*`}
								name="email"
								type="email"
								value={email}
								isRequired={true}
								requiredLabel={playerData.ui.alert_sms_form_required}
								hasError={emailIsNotValid}
								onChange={(e) => {
									setEmail(e.currentTarget.value)
								}}
							/>

							{winningInstant?.hasRulesOptin && winningInstant.rulesOptin && (
								<Optin
									color={rulesOptinIsNotValid ? Theme.colors.red() : Theme.colors.text_main}
									marginBottom={
										!currentViewer?.emailCommercialOptIn || !storeConfig?.consent?.tos ? 10 : 30
									}
									onClick={() => setHasRulesOptin(!hasRulesOptin)}
								>
									<OptinBox>
										{hasRulesOptin && (
											<Icon
												name="check"
												width="100%"
												height="100%"
												fill={Theme.colors.text_main}
											/>
										)}
									</OptinBox>

									<OptinText>
										<span>{winningInstant.rulesOptin}</span>{' '}
										<RulesOptinLink
											href={winningInstant.rulesOptinLink.url}
											target="_blank"
											onClick={(e) => e.stopPropagation()}
										>
											{winningInstant.rulesOptinLink.label}
										</RulesOptinLink>
									</OptinText>
								</Optin>
							)}
							{winningInstant?.hasCommercialOptin && !currentViewer?.emailCommercialOptIn && (
								<Optin
									color={Theme.colors.text_main}
									marginBottom={
										!currentViewer?.emailCommercialOptIn || !storeConfig?.consent?.tos ? 10 : 30
									}
									onClick={() => setHasCommercialOptin(!hasCommercialOptin)}
								>
									<OptinBox>
										{hasCommercialOptin && (
											<Icon
												name="check"
												width="100%"
												height="100%"
												fill={Theme.colors.text_main}
											/>
										)}
									</OptinBox>

									<OptinText>
										<TextWithUrlAndPhone
											text={winningInstant.commercialOptinText}
											fieldKey="optin-text"
										/>
									</OptinText>
								</Optin>
							)}
							{!storeConfig?.consent?.tos && <TermsOfServicesComponent innerRef={tosRef} />}
							<Button
								onClick={submit}
								backgroundColor={isSubmitting ? `${Theme.colors.cta_main}50` : Theme.colors.cta_main}
								css={Theme.squaredEdges}
							>
								{playerData.ui.message_modal_submit}
							</Button>
						</div>
					</TranslateYAndFadeTransition>
				</Container>
			</FormWrapper>
		)
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(WinningInstantForm)
