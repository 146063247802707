import { Message, MessagesResponse } from 'types/Message'
import { useRouter } from 'next/router'
import React, { createContext, useContext, useEffect, useMemo, useReducer, useState } from 'react'
import { messagesContextInitialState, messagesReducer, MessagesContextActions, lastMessageTimeStamp } from './logics'
import { useDb } from '../dbContext'
import getConfig from 'next/config'
import { usePlayerContext } from '../player'
import { useTimeLineContext } from '../timeline'
import { handleApiResponseErrors } from '../fetchHelpers'
import { useViewer } from '../viewerContext'
const { publicRuntimeConfig } = getConfig()
const NEXT_PUBLIC_API_ENDPOINT = publicRuntimeConfig.NEXT_PUBLIC_API_ENDPOINT

interface MessagesContext {
	messages?: Array<Message>
	getMessages?: (cursor, isBefore) => void
	hasMoreMessages?: boolean
	messagesLimit?: number
	pinnedMessages?: Array<Message>
	setReplayCursor?: Function
	observeNewMessages?: (resetCount?: boolean) => () => void
	newMessagesCount?: number
	messagesLoading?: boolean
	setIsPaused?: (paused: boolean) => void
	isPaused?: boolean
	MESSAGES_FETCH_LIMIT?: number
	sendMessage?: (value, currentViewer, datas) => void
}

const MESSAGES_FETCH_LIMIT = 50

const Context = createContext<MessagesContext | null>({})

export const useMessagesContext = () => useContext(Context)

export const MessagesProvider = ({ children }) => {
	/**
	 * @States
	 */

	const eventId = useRouter().query.id
	const [messagesData, setMessagesData] = useState<MessagesResponse>(null)
	const [messagesLoading, setMessagesLoading] = useState<boolean>(false)
	const [isPaused, setIsPaused] = useState<boolean>(false)
	const [displayedMessages, setDisplayedMessages] = useState<Array<Message>>([])
	const { currentDateTime, setCurrentDateTime } = useTimeLineContext()
	const { currentViewer } = useViewer()
	const [{ messages, pinnedMessages, hasMoreMessages, isFirstLoad, newMessages }, manageMessages] = useReducer(
		messagesReducer,
		messagesContextInitialState
	)
	const { listenPinnedMessages, listenModeratedMessages, getApiMessages, listenBannedUsers } = useDb()
	const { isReplay, isReady, playerData, replayChatDeactivated, displayMode } = usePlayerContext()

	/**
	 * @Effects
	 */

	useEffect(() => {
		if (!isReplay) {
			setMessagesLoading(false)
			let messageInterval = null
			if (messagesData) {
				if (!messagesData.isBefore && isPaused) {
					manageMessages({
						type: MessagesContextActions.SET_NEW_MESSAGES,
						newMessages: messagesData.data
					})
				} else {
					manageMessages({ type: MessagesContextActions.SET_MESSAGES, getMessagesdata: messagesData })
				}

				// Fetch all data if more than MESSAGES_FETCH_LIMIT messages (could be more than MESSAGES_FETCH_LIMIT messages by second)
				if (messagesData?.data?.length === MESSAGES_FETCH_LIMIT && !messagesData?.isBefore && !isFirstLoad) {
					getMessages(currentDateTime || new Date().toString(), isReplay)
				} else {
					messageInterval = setInterval(() => {
						getMessages(currentDateTime || new Date().toString(), isReplay)
					}, 2_000)
				}
			}
			return () => clearInterval(messageInterval)
		}
	}, [messagesData, isPaused])

	useEffect(() => {
		let removeListeners = null
		if (!isReplay && eventId && isReady && !!playerData) {
			getMessages(currentDateTime || new Date().toString(), true).then((_) => {
				const moderatedMessagesObserver: any = listenModeratedMessages(eventId, (messages) =>
					manageMessages({ type: MessagesContextActions.SET_MODERATED_MESSAGES, data: messages })
				)
				const pinnedMessagesObserver: any = listenPinnedMessages(eventId, (messages) => {
					manageMessages({ type: MessagesContextActions.SET_PINNED_MESSAGES, data: messages })
				})
				const bannedUsersObserver: any = listenBannedUsers(eventId, (snapshot) => {
					const bannedUsers = snapshot?.docs?.map((doc) => doc.data().viewer.id)
					manageMessages({ type: MessagesContextActions.SET_BANNED_VIEWERS, data: bannedUsers })
				})

				removeListeners = () => {
					if (moderatedMessagesObserver) moderatedMessagesObserver
					if (pinnedMessagesObserver) pinnedMessagesObserver
					if (bannedUsersObserver) bannedUsersObserver
				}
			})
		}
		return () => removeListeners
	}, [eventId, isReady, playerData, displayMode])

	useEffect(() => {
		const displayedMessages = messages.filter((message) => !shouldHideMessage(message))
		setDisplayedMessages(displayedMessages)
	}, [messages])

	useEffect(() => {
		manageMessages({ type: MessagesContextActions.RESET_NEW_MESSAGES })
	}, [isPaused])

	const getMessages = (cursor: string, before: boolean = false): Promise<void> => {
		if (replayChatDeactivated) return Promise.resolve()
		setMessagesLoading(before)
		const data = {
			cursor: cursor || new Date().toString(),
			before: before,
			filters: [],
			limit: isReplay ? 10_000 : MESSAGES_FETCH_LIMIT
		}

		return getApiMessages(eventId, data).then((messages) => {
			handleApiResponseErrors(messages, currentViewer)
			setMessagesData(messages)
			if (!isReplay && !isPaused) {
				setCurrentDateTime(lastMessageTimeStamp(messages.data))
			}
		})
	}

	const sendMessage = (value, currentViewer, datas): Promise<any> => {
		return fetch(`${NEXT_PUBLIC_API_ENDPOINT}/api/public/events/${eventId}/messages`, {
			body: JSON.stringify({
				user_displayName: currentViewer.firstName,
				viewerId: currentViewer.uid,
				value,
				event: eventId,
				locale: datas?.channel?.locale
			}),
			headers: {
				'Content-Type': 'application/json'
			},
			method: 'POST'
		})
			.then((res) => {
				const response = res?.json()
				handleApiResponseErrors(response, currentViewer)
				return response
			})
			.catch((error) => console.error(error))
	}

	const shouldHideMessage = (m: Message): boolean => {
		const moderated = m.is_moderated || m.is_pinned || m.is_banned
		const isBeforeCursor = isReplay && new Date(m.created_at).toString() > currentDateTime
		return moderated || isBeforeCursor
	}

	const values = useMemo(
		(): MessagesContext => ({
			messages: displayedMessages,
			getMessages,
			hasMoreMessages,
			pinnedMessages,
			newMessagesCount: newMessages.length,
			messagesLoading,
			setIsPaused: (paused) => {
				setIsPaused(isReplay ? false : paused)
			},
			isPaused,
			MESSAGES_FETCH_LIMIT,
			sendMessage
		}),
		[displayedMessages, hasMoreMessages, pinnedMessages, newMessages, messagesLoading, isPaused, playerData]
	)

	return <Context.Provider value={values}>{children}</Context.Provider>
}
