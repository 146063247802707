import styled from '@emotion/styled'
import { BaseFlex } from '@styles/common'
import Theme from '@styles/theme'

const PREFIX = 'first-name-drawer'

export const firstNameDrawerClasses = {
	root: PREFIX,
	title: `${PREFIX}-title`,
	subtitle: `${PREFIX}-subtitle`,
	titleWrapper: `${PREFIX}-title-wrapper`,
	fieldWrapper: `${PREFIX}-field-wrapper`
}

export const FirstNameTitleWrapper = styled(BaseFlex)`
	width: 100%;
	flex-wrap: wrap;
	justify-content: center;
	margin-top: ${Theme.spacing(6)};
	margin-bottom: ${Theme.spacing(4)};
`

export const FirstNameTitle = styled.div<{ isModal? }>`
	font-family: ${Theme.fontFamilies.Main};
	font-weight: ${Theme.fontWeights.bold};
	color: ${() => Theme.colors.text_main};
	font-size: 18em;
	width: 100%;
	text-align: ${({ isModal }) => (isModal ? 'center' : 'start')};
`

export const FieldWrapper = styled(BaseFlex)<{ isModal? }>`
	width: 100%;
	flex-wrap: wrap;
	margin-bottom: ${({ isModal }) => (isModal ? 0 : `${Theme.spacing(8)}`)};
`

export const AdditionalStatementWrapper = styled.div`
	font-family: ${Theme.fontFamilies.Second};
	font-weight: ${Theme.fontWeights.regular};
	line-height: 140%;
	font-size: 11em;
	margin-bottom: 30px;
	width: 100%;
`

export const AdditionalStatementLink = styled.span`
	font-family: ${Theme.fontFamilies.Second};
	font-weight: ${Theme.fontWeights.regular};
	line-height: 140%;
	text-decoration: underline;
	cursor: pointer;
	color: ${() => Theme.colors.text_main};
`
