import { css } from '@emotion/react'
import styled from '@emotion/styled'
import Theme from '@styles/theme'
import { Box, Flex } from 'rebass'

const styles = {
	optinText: css`
		font-family: ${Theme.fontFamilies.Second};
		font-weight: ${Theme.fontWeights.regular};
		line-height: 140%;
	`,

	selectable: css`
		cursor: pointer;
		transition: opacity 600ms ${Theme.eases.outExpo} 0ms;
	`
}

export const PopinHeader = styled(Flex)`
	width: 100%;
	justify-content: center;
	text-align: center;
	flex-wrap: wrap;
	align-items: center;
	padding-bottom: 25px;
`

export const PopinTitle = styled(Box)`
	font-family: ${Theme.fontFamilies.Main};
	font-weight: ${Theme.fontWeights.bold};
	font-size: 20px;
	width: 100%;
	color: ${() => Theme.colors.text_main};
`

export const CookieText = styled(Box)`
	font-family: ${Theme.fontFamilies.Second};
	font-weight: ${Theme.fontWeights.regular};
	line-height: 22px;
	font-size: 14px;
	width: 100%;
	color: ${Theme.colors.grey()};
	margin-top: 20px;
	margin-bottom: 25px;
`

export const PopinBody = styled(Flex)`
	width: 100%;
	justify-content: center;
	text-align: center;
	flex-wrap: wrap;
`

export const LeaveText = styled(Box)`
	${styles.optinText}
	padding: 15px 28px;
	font-size: 12px;
	text-align: center;
	cursor: pointer;
	text-decoration: underline;
	color: ${Theme.colors.grey()};
`

export const Link = styled(Box)`
	${styles.optinText}
	font-size: 14px;
	text-decoration: underline;
	cursor: pointer;
	margin-left: 3px;
`

export const LinkTos = styled(Link)`
	${styles.optinText}
	font-size: 12px;
`

export const CookieCta = styled(Flex)`
	width: 100%;
	@media ${Theme.media(0)} {
		width: 75%;
	}
`

export const TechnicalCookieOnlyCta = styled(CookieCta)`
	padding-bottom: 10px;
`
