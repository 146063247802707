import React from 'react'
import { Flex, Box } from 'rebass'
import Theme from '@styles/theme'
import Style from './style'
import Placeholder from '../Placeholder'
import { mapDispatchToProps } from '@helpers/wrappers/withReduxStore'
import { State } from '@stores/index'
import { connect } from 'react-redux'
import { css } from '@emotion/react'

interface Props {
	mt?: any
	mb?: any
	justify?: any
	storeDatas?: any
	width?: any
	height?: any
	isClickable?: boolean
}

const mapStateToProps = (state: State, props: Props) => {
	return { ...state, ...props }
}

const Logo: React.FC<Props> = ({ mt, mb, justify, storeDatas, width = [270], height = [55], isClickable = false }) => {
	let $logo = null

	if (storeDatas) {
		$logo = (
			<Placeholder
				// sizes={[[storeDatas.brand_logo.dimensions.width, storeDatas.brand_logo.dimensions.height]]}
				sizes={[[100, 30]]}
				media={storeDatas.event.event.settings.branding.logo}
				color={Theme.colors.black(0)}
				fit="contain"
			/>
		)
	}

	const clickHandler = () => {}

	return (
		<Box
			width={1}
			css={[
				Style.el,
				isClickable
					? css`
							cursor: pointer;
						`
					: null
			]}
			onClick={clickHandler}
			mt={mt}
			mb={mb}
		>
			<Flex width={1} height={height} justifyContent={justify}>
				<Box width={width}>{$logo}</Box>
			</Flex>
		</Box>
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(Logo)
