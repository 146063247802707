import { css } from '@emotion/react'
import Theme from '@styles/theme'

export default {
	el: css`
		position: absolute;
		background-color: ${Theme.colors.white()};
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		overflow-x: hidden;
		overflow-y: scroll;
	`
}
