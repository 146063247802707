import { getPanelBuild } from '@components/Notifiers/Panels/library'
import Constants from '@constants'
import Bus from '@helpers/bus'
import { PanelsActionType, PanelsContextState } from './constants'

export const panelsReducer = (state: PanelsContextState, action: any): PanelsContextState => {
	switch (action.type) {
		case PanelsActionType.OPEN_PANEL:
			const newState: PanelsContextState = handleOpenPanel(state, action)
			Bus.send(Constants.bus.player.history_changed, newState?.panelsQueue?.length || 0)
			return newState
		case PanelsActionType.CLOSE_LAST_PANEL:
			const panelsQueue = [...state.panelsQueue].slice(0, -1)
			Bus.send(Constants.bus.player.history_changed, panelsQueue?.length || 0)
			return { panelsQueue: panelsQueue, isPip: setIsPip(state.isPip, panelsQueue) }
		case PanelsActionType.CLOSE_PANEL:
			const newPanelsQueue = [...state.panelsQueue.filter((panel) => panel !== action.panel)]
			Bus.send(Constants.bus.player.history_changed, newPanelsQueue?.length || 0)
			return { panelsQueue: newPanelsQueue, isPip: setIsPip(state.isPip, newPanelsQueue) }
		case PanelsActionType.CLOSE_ALL_PANELS:
			Bus.send(Constants.bus.player.history_changed, 0)
			return { panelsQueue: [], isPip: 0 }
		case PanelsActionType.SET_PIP:
			return { ...state, isPip: setIsPip(state.isPip, state.panelsQueue) }
		default:
			return state
	}
}

const setIsPip = (isPip, panelsQueue) => {
	if (panelsQueue.length === 0) {
		return 0
	}
	return isPip !== 0 ? isPip : +new Date()
}

const handleOpenPanel = (state: PanelsContextState, { panelType, metadata }: any): PanelsContextState => {
	let newPanelsQueue = state.panelsQueue
	const panelBuild = getPanelBuild(panelType, metadata)
	const panelIndex = newPanelsQueue.findIndex((i) => i.panelType === panelBuild.panelType)
	if (panelIndex > -1) {
		newPanelsQueue.splice(panelIndex, 1)
	}
	return {
		panelsQueue: [...newPanelsQueue, panelBuild],
		isPip: state.isPip
	}
}
