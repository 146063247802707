import React from 'react'
import { classes, FloatingMenuContainer, FloatingMenuElementProps } from './styles'
import { classGenerator as cx } from '@styles/sharedLogics'

export interface Anchor {
	top?: number
	left?: number
}

interface FloatingMenuProps extends FloatingMenuElementProps {
	open?: boolean
	onClose: () => void
	children?: any
}

const FloatingMenu: React.FC<FloatingMenuProps> = ({ open, onClose, children, ...props }) => {
	return (
		<FloatingMenuContainer className={cx({ [classes.menuOpen]: open })} {...props}>
			{children}
		</FloatingMenuContainer>
	)
}

export default FloatingMenu
