import Icon from '@components/Micro/Icon'
import { useMessagesContext } from '@helpers/contexts/messages'
import { usePlayerContext } from '@helpers/contexts/player'
import { mapDispatchToProps } from '@helpers/wrappers/withReduxStore'
import { State } from '@stores/index'
import Theme from '@styles/theme'
import React, { MouseEventHandler } from 'react'
import { connect } from 'react-redux'
import { classes, ScrollToArrow, ScrollToText, ScrollToWrapper } from './style'

interface Props {
	storeDatas?: any
	scrollToBottom: MouseEventHandler<HTMLDivElement>
	hasNewMessages: boolean
	countInQueue: number
	isBlock?: boolean
	refWidth?: number
	isLandscapeAndDesktop?: boolean
}

const mapStateToProps = (state: State, props: Props) => {
	return { ...state, ...props }
}

const ScrollToButton: React.FC<Props> = ({
	hasNewMessages,
	countInQueue,
	storeDatas,
	scrollToBottom,
	isBlock,
	refWidth,
	isLandscapeAndDesktop
}) => {
	const { MESSAGES_FETCH_LIMIT } = useMessagesContext()
	const { isLive, fakeStatus } = usePlayerContext()

	const pluralizedMessagesCount = () => {
		return countInQueue > 1 ? storeDatas.ui.message_new_messages : storeDatas.ui.message_new_message
	}

	const moreThanFetchLimitPrefix = () => (countInQueue >= MESSAGES_FETCH_LIMIT ? '+ ' : '')

	const maxMessagesCount = () => (countInQueue > MESSAGES_FETCH_LIMIT ? MESSAGES_FETCH_LIMIT : countInQueue)

	const getScrollButtonText = () => {
		return `${moreThanFetchLimitPrefix()}${maxMessagesCount()} ${pluralizedMessagesCount()}`
	}

	return (
		<ScrollToWrapper
			className={classes.root}
			shown={isLive || fakeStatus === 'live'}
			onClick={scrollToBottom}
			isBlock={isBlock}
			hasNewMessages={hasNewMessages}
			refWidth={refWidth}
			isLandscapeAndDesktop={isLandscapeAndDesktop}
		>
			{hasNewMessages && (isLive || fakeStatus === 'live') ? (
				<>
					<ScrollToText color={Theme.colors.white()}>{getScrollButtonText()}</ScrollToText>
					<ScrollToArrow>
						<Icon name={'chevron'} r={90} width="100%" height="100%" fill={Theme.colors.white()} />
					</ScrollToArrow>
				</>
			) : (
				<Icon name={'chevron'} r={90} width="10px" height="10px" fill={Theme.colors.white()} />
			)}
		</ScrollToWrapper>
	)
}

export const scrollToButtonClasses = classes

export default connect(mapStateToProps, mapDispatchToProps)(ScrollToButton)
