import React from 'react'
import { DrawerTopBar } from '../style'
import { GiftIcon } from '@components/StatusManager/LiveAndReplay/components/WinningInstantButton/style'
import Icon from '@components/Micro/Icon'
import { Button, classes, Description, WinningInstantDrawerContent } from './style'
import { TimerService } from '@helpers/TimerService'
import Timer from '@components/Micro/Timer'
import Text from '@components/Micro/Text'
import { usePlayerContext } from '@helpers/contexts/player'
import Theme from '@styles/theme'
import { useNotificationsContext } from '@helpers/contexts/notifications'
import { PanelsType } from '@components/Notifiers/Panels/constants'
import { useWinningInstantContext } from '@helpers/contexts/winningInstant'

const WinningInstantDrawer: React.FC = () => {
	const { wiCounter } = useWinningInstantContext()
	const { closeDrawer, openPanel } = useNotificationsContext()
	const { playerData } = usePlayerContext()

	const handleParticipate = () => {
		closeDrawer()
		openPanel(PanelsType.WINNING_INSTANT_FORM, {
			back: playerData?.ui.panel_back
		})
	}

	return (
		<>
			<DrawerTopBar onClick={closeDrawer} />
			<WinningInstantDrawerContent>
				<GiftIcon className={classes.iconContainer}>
					<Icon name="gift" height={30} width={30} />
					<Timer timer={TimerService.formatedTimer(wiCounter)} className={classes.timer} />
				</GiftIcon>
				<Text size="20px" height="26px" weight="bold">
					{playerData?.ui?.winning_instant?.title}
				</Text>
				<Description>
					<Text size="13px" height="22px" color={Theme.colors.grey1} family="Second">
						{playerData?.ui?.winning_instant?.description_long[0]}
					</Text>
					<Text size="13px" height="22px" color={Theme.colors.grey1} family="Second">
						{playerData?.ui?.winning_instant?.description_long[1]}
					</Text>
				</Description>
				<Button label={playerData.ui?.winning_instant?.participate_cta} onClick={handleParticipate} fullWidth />
			</WinningInstantDrawerContent>
		</>
	)
}

export default WinningInstantDrawer
