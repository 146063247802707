import styled from '@emotion/styled'
import { css } from '@emotion/react'
import Theme from '@styles/theme'

export const BaseFlex = styled.div<{ justifyContent?; alignItems? }>`
	display: flex;
	justify-content: ${({ justifyContent }) => justifyContent || 'unset'};
	align-items: ${({ alignItems }) => alignItems || 'unset'};
`

export default css`
	html,
	body {
		margin: 0;
		height: 100%;
		overflow: hidden;
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		touch-action: manipulation;
	}

	body {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-rendering: optimizeLegibility;
		font-size: 1.15px;
	}

	* {
		::selection {
			background-color: ${Theme.colors.cta_second};
			color: ${Theme.colors.white()};
		}
		::-moz-selection {
			background-color: ${Theme.colors.cta_second};
			color: ${Theme.colors.white()};
		}
	}

	*:not(input):not(textarea) {
		touch-callout: none;
		user-select: none;
		user-drag: none;
		text-decoration: none;
		-webkit-tap-highlight-color: transparent;
	}

	img {
		transform: translate3d(0, 0, 0);
	}

	img,
	svg {
		pointer-events: none;
	}
	img,
	video,
	audio {
		display: block;
	}

	.wysiwyg {
		strong {
			font-weight: ${Theme.fontWeights.bold};
		}
		a {
			color: ${Theme.colors.text_main};
			text-decoration: underline;
			&:hover {
				text-decoration: underline;
			}
		}
		p {
			width: 100%;
		}
		> p,
		> ul > li {
			margin-bottom: 30px;
			&:last-child {
				margin-bottom: 0;
			}
		}
		> ul > li {
			position: relative;
			padding-left: 30px;
			&:before {
				position: absolute;
				content: '–';
				left: 0;
				top: 0;
			}
		}
		.asterisk {
			font-size: small;
		}
	}

	body {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-rendering: optimizeLegibility;
	}

	.notification-container {
		box-sizing: border-box;
		position: fixed;
		top: 0;
		left: 0;
		z-index: ${Theme.zIndices.max};
		width: 100%;
	}

	.notification {
		box-sizing: border-box;
		padding: 18px 15px 18px 40px;
		background-color: ${Theme.colors.white()};
		box-shadow: 0 0 4px ${Theme.colors.black(0.15)};
		cursor: pointer;
		font-weight: ${Theme.fontWeights.medium};
		font-size: 13em;
		position: absolute;
		left: 3vw;
		top: 3vw;
		width: 94vw;
		opacity: 1;
		border-radius: 10px;
		text-transform: uppercase;
		@media ${Theme.media(0)} {
			left: inherit;
			right: 20px;
			top: 20px;
			width: 360px;
		}
		&:before {
			position: absolute;
			top: 50%;
			left: 20px;
			display: block;
			margin-top: -7px;
			width: 13px;
			height: 13px;
		}
	}

	.notification .notification-message {
		display: flex;
		flex-direction: row-reverse;
		justify-content: space-between;
	}

	.notification .message {
		color: #fff;
		line-height: 1.2em;
		font-family: ${Theme.fontFamilies.Second};
		font-weight: ${Theme.fontWeights.bold};
		display: flex;
	}

	.notification .title {
		color: #000;
		line-height: 1.2em;
		font-family: ${Theme.fontFamilies.Second};
		font-weight: ${Theme.fontWeights.bold};
		display: flex;
	}

	.notification:hover,
	.notification:focus {
		opacity: 1;
	}

	.notification-enter {
		visibility: hidden;
		transform: translate3d(0, -100%, 0);
	}

	.notification-enter.notification-enter-active {
		visibility: visible;
		transform: translate3d(0, 0, 0);
		transition: all 0.4s;
	}

	.notification-leave {
		visibility: visible;
		transform: translate3d(0, 0, 0);
	}

	.notification-leave.notification-leave-active {
		visibility: hidden;
		transform: translate3d(0, -100%, 0);
		transition: all 0.4s;
	}

	.notification-success {
		background-color: ${Theme.colors.green()};
	}

	.notification-success:before {
		content: url("data:image/svg+xml;charset=UTF-8, <svg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 10 10'><path fill='white' d='M5,0C2.2,0,0,2.2,0,5s2.2,5,5,5s5-2.2,5-5S7.8,0,5,0z M7.1,4.2L4.6,6.9C4.4,7,4.2,7.1,4,6.9L2.6,5.6C2.2,5.2,2.8,4.6,3.2,5l1.1,1l2.2-2.3C6.9,3.2,7.5,3.8,7.1,4.2z'/></svg>");
	}

	.notification-error {
		background-color: ${Theme.colors.red()};
	}

	.notification-error:before {
		content: '😕';
		left: 15px;
	}
`
