import { BaseFlex } from './../../../../../../styles/common'
import styled from '@emotion/styled'
import Theme from '@styles/theme'
import { css } from '@emotion/react'

interface BaseProps {
	isLandscape?: boolean
	isHorizontal?: boolean
	isMobile?: boolean
	isWriting?: boolean
	hasText?: boolean
}

const PREFIX = 'chat-field'

export const chatFieldClasses = {
	root: PREFIX,
	input: `${PREFIX}-input`,
	fieldWrapper: `${PREFIX}-fieldWrapper`,
	withBorder: `${PREFIX}-withBorder`,
	icon: `${PREFIX}-icon`,
	iconActive: `${PREFIX}-icon-active`,
	floating: `floating`,
	xs: `${PREFIX}-xs`,
	sm: `${PREFIX}-sm`
}

export const ChatFieldWrapper = styled(BaseFlex)<BaseProps>`
	cursor: text;
	width: 100%;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	border-top: ${({ isWriting }) => `1px solid ${Theme.colors.black(isWriting ? 0.1 : 0)}`};
	transition: all 400ms cubic-bezier(0.52, 0.16, 0.24, 1);
	pointer-events: all;

	@media ${Theme.media(0)} {
		background: ${Theme.colors.white()};
	}
	&.${chatFieldClasses.sm} {
		min-height: 60px;
	}
	&.${chatFieldClasses.withBorder} {
		border-top: 1px solid ${Theme.colors.black(0.1)};
	}
	&.${chatFieldClasses.floating} {
		border-radius: 10px;
		transition:
			opacity 400ms ${Theme.eases.inOutCubic}100ms,
			transform 400ms ${Theme.eases.inOutCubic} 100ms,
			width 400ms ${Theme.eases.inOutCubic} 100ms;
		will-change: opacity, transform, width;
		background-color: ${Theme.colors.black(0.5)};
		height: fit-content;
		&.${chatFieldClasses.xs} {
			min-height: 15px;
		}
		backdrop-filter: blur(2px);
		& svg {
			fill: ${Theme.colors.white()};
		}
		& .${chatFieldClasses.fieldWrapper} {
			margin: 0;
			padding-left: ${Theme.spacing(3)};
			padding-right: ${Theme.spacing(3)};
		}
		${() => css([Theme.squaredEdges])}
	}
`

export const FieldWrapper = styled.div<BaseProps>`
	position: relative;
	display: flex;
	align-items: center;
	width: 100%;
	transition: width 400ms cubic-bezier(0.52, 0.16, 0.24, 1);
	margin-left: ${Theme.spacing(4)};
	margin-right: ${Theme.spacing(4)};
	gap: ${Theme.spacing(4)};
	grid-gap: ${Theme.spacing(4)};
	scrollbar-color: transparent transparent;
	*::-webkit-scrollbar {
		display: none; // Safari and Chrome
	}
`

export const InputWrapper = styled.textarea<BaseProps>`
	opacity: 1;
	width: 100%;
	height: 100%;
	background: none;
	border: none;
	outline: none;
	appearance: none;
	resize: none;
	opacity: 1;
	color: ${Theme.colors.black()};
	font-family: ${Theme.fontFamilies.Second};
	font-weight: ${Theme.fontWeights.regular};
	letter-spacing: normal;
	font-size: ${({ isLandscape, isMobile }) => (isLandscape && isMobile ? '11em' : '13em')};
	line-height: 130%;
	min-height: 20px;
	margin: 8px 0;
	padding: 0;
	&::placeholder {
		color: ${Theme.colors.black(0.5)};
	}
`

export const ExtensibleInputWrapper = styled.textarea<BaseProps>`
	opacity: 1;
	width: 100%;
	background: none;
	border: none;
	appearance: none;
	outline: none;
	resize: none;
	color: ${Theme.colors.white()};
	font-size: ${({ isLandscape, isMobile }) => (isLandscape && isMobile ? '11em' : '13em')};
	font-family: ${Theme.fontFamilies.Second};
	font-weight: ${Theme.fontWeights.regular};
	letter-spacing: normal;
	word-break: break-word;
	line-height: 130%;
	min-height: 20px;
	margin: 8px 0;
	padding: 0;
	&::placeholder {
		color: ${Theme.colors.white()};
	}
`

export const SendIcon = styled.div<{ hasText: boolean }>`
	opacity: ${({ hasText }) => (hasText ? 0.2 : 0)};
	pointer-events: ${({ hasText }) => (hasText ? 'all' : 'none')};
	transition: opacity 200ms ${Theme.eases.inOutCubic};
	cursor: ${({ hasText }) => (hasText ? 'pointer' : 'none')};
	&.${chatFieldClasses.iconActive} {
		opacity: ${({ hasText }) => (hasText ? 0.8 : 0.2)};
	}
`
