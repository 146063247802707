import { classGenerator } from '@styles/sharedLogics'
import React from 'react'
import Icon from '../Icon'
import { classes, IconBox, IconButton } from './style'

interface IconCtaProps {
	className?: string
	iconProps: IconProps
	onClick: (e?: any) => void
	innerRef?: any
}

export interface IconProps {
	url?: string
	name?: string
	fill?: string
	r?: number
}

const IconCta: React.FC<IconCtaProps> = ({ iconProps, className, innerRef, ...props }) => {
	return (
		<IconButton ref={innerRef} className={classGenerator(classes.root, className)} {...props}>
			<IconBox>
				<Icon {...iconProps} width="100%" height="100%" />
			</IconBox>
		</IconButton>
	)
}

export const iconCtaClasses = classes

export default IconCta
