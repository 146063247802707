import React from 'react'
import { Flex, Box } from 'rebass'
import Cta from '@components/Micro/Cta'
import Bus from '@helpers/bus'
import Constants from '@constants'

const SocialMedias: React.FC = () => {
	/**
	 * @Methods
	 */
	const shareHandler = (provider: string): void => {
		Bus.send(Constants.bus.player.event_shared, { type: provider })
	}
	return (
		<>
			<Flex flexWrap="wrap" justifyContent="center">
				<Flex width={1} flexWrap="wrap" mt={40} justifyContent="center">
					<Box mr={['4px', 10]} mb={[10, 0]} onClick={() => shareHandler('clipboard')}>
						<Cta type={'border'} justifyContent={'flex-start'} icon={'link'} iconOnly={true} />
					</Box>
					<Box mr={['4px', 10]} mb={[10, 0]} onClick={() => shareHandler('email')}>
						<Cta type={'border'} justifyContent={'flex-start'} icon={'email'} iconOnly={true} />
					</Box>
					<Box mr={['4px', 10]} mb={[10, 0]} onClick={() => shareHandler('twitter')}>
						<Cta type={'border'} justifyContent={'flex-start'} icon={'twitter'} iconOnly={true} />
					</Box>
					<Box mr={['4px', 10]} mb={[10, 0]} onClick={() => shareHandler('facebook')}>
						<Cta type={'border'} justifyContent={'flex-start'} icon={'facebook'} iconOnly={true} />
					</Box>
					<Box mr={['4px', 10]} mb={[10, 0]} onClick={() => shareHandler('pinterest')}>
						<Cta type={'border'} justifyContent={'flex-start'} icon={'pinterest'} iconOnly={true} />
					</Box>
					<Box mr={[0, 10]} mb={[10, 0]} onClick={() => shareHandler('linkedin')}>
						<Cta type={'border'} justifyContent={'flex-start'} icon={'linkedin'} iconOnly={true} />
					</Box>
				</Flex>
			</Flex>
		</>
	)
}

export default SocialMedias
