import React, { useRef, useState, useEffect } from 'react'
import { Flex, Box } from 'rebass'
import Style from './style'
import Placeholder from '@components/Micro/Placeholder'
import Theme from '@styles/theme'
import Fullscreen from '../Fullscreen'
import { mapDispatchToProps } from '@helpers/wrappers/withReduxStore'
import { State } from '@stores/index'
import { connect } from 'react-redux'
import Slider from '@tools/slider'
import { css } from '@emotion/react'
import Icon from '@components/Micro/Icon'
import { MediaFile } from 'types/Common'

interface Props {
	images?: MediaFile[]
	back?: boolean
	dispatch?: (action) => void
	innerRef?: any
	parallax?: number
	storeIsFullscreen?: boolean
}

const mapStateToProps = (state: State, props: Props) => {
	return { ...state, ...props }
}

const Gallery: React.FC<Props> = ({ images, parallax, dispatch, storeIsFullscreen }) => {
	let $cards = null
	let $statuses = null

	const [slider, setSlider] = useState(null)
	const [pos, setPos] = useState(0)
	const [currentIndex, setCurrentIndex] = useState(0)
	const viewportRef = useRef(null)
	const overviewRef = useRef(null)
	const cardsRefs = useRef([])

	const translatedHandler = (p) => {
		setPos(p.x)
	}

	const goToHandler = (index) => {
		setCurrentIndex((current) => {
			if (index !== current) {
				current = index
			}
			return current
		})
	}

	$cards = images.map((item, i) => {
		return (
			<Box
				width={['100vw', '100%']}
				css={Style.item}
				key={`slide-${i}`}
				flex="0 0 auto"
				ref={(el) => (cardsRefs.current[i] = el)}
			>
				<Box
					style={{ transform: `translateX(${pos / 2}px` }}
					className={'slide'}
					css={css`
						left: -${50 * i}%;
						position: relative;
						will-change: transform;
					`}
				>
					<Placeholder
						key={`slider-image-${i}`}
						sizes={[[375, 535]]}
						media={item}
						color={Theme.colors.black(0)}
						fit={'cover'}
						loading={'eager'}
						// isAnimated={true}
					/>
				</Box>
			</Box>
		)
	})

	if (images.length > 1) {
		$statuses = images.map((item, i) => {
			return (
				<Box
					width="7px"
					height="7px"
					key={`status-${i}`}
					css={[
						Style.statusItem,
						item && i === currentIndex
							? css`
									background-color: ${Theme.colors.text_main};
									border: 1px solid ${Theme.colors.white()};
								`
							: null
					]}
				/>
			)
		})
	}

	const fullscreenClickHandler = () => {
		if (storeIsFullscreen) {
			dispatch({ type: 'UNSET_FULLSCREEN' })
		} else {
			dispatch({ type: 'SET_FULLSCREEN' })
		}
		window.setTimeout(() => {
			slider.resize()
		}, 200)
	}

	useEffect(() => {
		const s = new Slider({
			elements: {
				viewport: viewportRef,
				overview: overviewRef,
				cards: cardsRefs
			},
			handlers: {
				translated: translatedHandler,
				goTo: goToHandler
			}
		})

		setSlider(s)

		return () => {
			s.destroy()
		}
	}, [])

	const previousSlideClickHandler = () => {
		slider.goPrev()
	}

	const nextSlideClickHandle = () => {
		slider.goNext()
	}

	return (
		<Flex
			ref={viewportRef}
			width={1}
			overflow="hidden"
			css={[slider ? Style.viewport : Style.viewport, storeIsFullscreen ? Style.isFullscreen : null]}
			backgroundColor={Theme.colors.background}
		>
			<Box
				onClick={previousSlideClickHandler}
				css={[
					Style.arrow,
					Style.arrowPrev,
					currentIndex === 0 ? Style.arrowHidden : null,
					images.length === 0 ? Style.arrowHidden : null,
					Theme.squaredEdges
				]}
				width={40}
				height={40}
				display={['none', 'flex']}
				p={'12px'}
				backgroundColor={Theme.colors.white()}
			>
				<Icon name="arrow_180_deg" width="100%" height="100%" fill={Theme.colors.text_main} />
			</Box>
			<Box
				onClick={nextSlideClickHandle}
				css={[
					Style.arrow,
					Style.arrowNext,
					currentIndex === images.length - 1 ? Style.arrowHidden : null,
					images.length === 0 ? Style.arrowHidden : null,
					Theme.squaredEdges
				]}
				width={40}
				height={40}
				display={['none', 'flex']}
				p={'12px'}
				backgroundColor={Theme.colors.white()}
			>
				<Icon name="arrow_0_deg" width="100%" height="100%" fill={Theme.colors.text_main} />
			</Box>
			<Flex
				ref={overviewRef}
				flexWrap="nowrap"
				css={Style.overview}
				style={{ transform: `translateX(${-pos}px) translateY(${parallax}px)` }}
				width={1}
			>
				{$cards}
			</Flex>
			<Flex css={Style.statusWrap}>{$statuses}</Flex>
			<Fullscreen
				style={[Style.fullscreen, storeIsFullscreen ? Style.fullscreenEnabled : Style.fullscreenDisabled]}
				status={storeIsFullscreen}
				onClick={fullscreenClickHandler}
			/>
		</Flex>
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(Gallery)
