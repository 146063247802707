import React, { useRef, useImperativeHandle, useState } from 'react'
import { Flex, Box } from 'rebass'
import Style from './style'
import { mapDispatchToProps } from '@helpers/wrappers/withReduxStore'
import { State } from '@stores/index'
import { connect } from 'react-redux'
import Utils from '@utils/index'
import { usePlayerContext } from '@helpers/contexts/player'
import { useTimeLineContext } from '@helpers/contexts/timeline'
import { generateStepsDuration, getPercent, getShowStartAndDuration } from './logic'

interface Props {
	innerRef?: any
	uiIsLight?: boolean
	storeDatas?: any
	storeEvent?: any
	storeStepsProgram?: any
	storeCurrentStep?: any
	storeTimecodes?: any
	dispatch?: (action) => void
	setIsDragged: (boolean) => void
	seekTo?: any
	isMobile?: boolean
}

const mapStateToProps = (state: State, props: Props) => {
	return { ...state, ...props }
}

const Timeline: React.FC<Props> = ({
	dispatch,
	innerRef,
	storeDatas,
	storeStepsProgram,
	storeCurrentStep,
	storeTimecodes,
	seekTo,
	setIsDragged,
	isMobile
}) => {
	const { fakeStatus } = usePlayerContext()
	if (storeTimecodes && storeDatas.record) {
		let $steps = []
		const { currentTime, setCurrentTime } = useTimeLineContext()
		const touchRef = useRef()
		const [pressed, setPressed] = useState(null)
		const [dragged, setDragged] = useState(null)
		const [hovered, setHovered] = useState(null)

		const checkSteps = (ct) => {
			if (fakeStatus !== 'live' || !fakeStatus) {
				let passedSteps = []
				for (let timecode of storeTimecodes) {
					const { showStart } = getShowStartAndDuration(storeDatas)
					if (timecode.ts - showStart - 1000 <= ct) {
						passedSteps.push(timecode)
					}
				}

				if (!passedSteps.length) {
					if (storeCurrentStep || storeCurrentStep === 0) {
						dispatch({ type: 'SET_CURRENT_STEP', step: null })
					}
				} else {
					if (passedSteps[passedSteps.length - 1]) {
						const t = passedSteps[passedSteps.length - 1].step
						let curStep = null
						if (storeCurrentStep || storeCurrentStep === 0) {
							curStep = storeCurrentStep.index
						}
						if (curStep !== t && storeCurrentStep !== storeStepsProgram[t]) {
							dispatch({ type: 'SET_CURRENT_STEP', step: storeStepsProgram[t] })
						}
					}
				}
			}
		}

		useImperativeHandle(
			innerRef,
			() => ({
				update(ct) {
					if (ct && ct >= 0) {
						checkSteps(ct * 1000)
						setCurrentTime(ct)
					}
				}
			}),
			[currentTime]
		)

		const secondsToString = (t) => {
			return t < 3600
				? new Date(t * 1000).toISOString().substr(14, 5)
				: new Date(t * 1000).toISOString().substr(11, 8)
		}

		const getReadableCurrentTime = () => {
			if (
				storeDatas.record.stream_start_time &&
				storeDatas.record.live_start_time &&
				storeDatas.record.replay_duration
			) {
				const { showStart, duration } = getShowStartAndDuration(storeDatas)
				const t = dragged !== null ? getTiming(dragged.p) : currentTime
				return secondsToString(t - showStart) + ' | ' + secondsToString(duration)
			} else {
				return '00:00 | 00:00'
			}
		}

		const getTiming = (percent) => {
			const p = Utils.math.clamp(percent, 0, 1)
			const { showStart, duration } = getShowStartAndDuration(storeDatas)
			return p * duration + showStart
		}

		const createTimecode = (i, x, w) => {
			return (
				<Box
					key={`timecode-${i}`}
					css={[Style.timecode, dragged !== null ? Style.timecodeIsDragged : null]}
					style={{ width: `${w}%`, left: `${x}%` }}
				/>
			)
		}

		if (storeStepsProgram.length && storeDatas && storeTimecodes) {
			const stepsDuration = generateStepsDuration(storeTimecodes, storeDatas)
			$steps = stepsDuration.map((sd) => createTimecode(sd.index, sd.start, sd.duration))
		}

		const mouseDownHandler = (e) => {
			const event = e.targetTouches ? e.targetTouches[0] : e
			//@ts-ignore
			const touchSizes = touchRef.current.getBoundingClientRect()
			const x = event.clientX - touchSizes.x
			const p = x / touchSizes.width
			setPressed({ x, p })
		}
		const mouseMoveHandler = (e) => {
			if (pressed !== null) {
				const event = e.targetTouches ? e.targetTouches[0] : e
				//@ts-ignore
				const touchSizes = touchRef.current.getBoundingClientRect()
				let x = event.clientX - touchSizes.x
				if (isMobile) x = event.clientX > touchSizes.width ? touchSizes.width : event.clientX
				const p = x / touchSizes.width
				setDragged({ x, p })
				setIsDragged(true)
			}
		}
		const mouseUp = () => {
			setHovered(false)
			if (pressed !== null) {
				seekTo(dragged === null ? getTiming(pressed.p) : getTiming(dragged.p))
				setPressed(null)
				setDragged(null)
				setIsDragged(false)
			}
		}

		const isNotReplay = () => storeDatas.event.event.status !== 'replay'

		const draggedOrHovered = dragged !== null || hovered

		if (storeDatas?.record?.stream_start_time && storeDatas?.record?.live_start_time) {
			return (
				<>
					<Box css={[Style.timing, dragged !== null ? Style.timingIsShown : null]}>
						{getReadableCurrentTime()}
					</Box>
					<Box
						onMouseOver={() => setHovered(true)}
						css={Style.touch}
						onMouseDown={mouseDownHandler}
						onMouseMove={mouseMoveHandler}
						onMouseLeave={mouseUp}
						onMouseUp={mouseUp}
						ref={touchRef}
						onTouchStart={mouseDownHandler}
						onTouchMove={mouseMoveHandler}
						onTouchEnd={mouseUp}
					/>
					<Flex
						flexWrap="wrap"
						css={[
							Style.el,
							draggedOrHovered ? Style.elIsDragged : null,
							isNotReplay() || (fakeStatus && fakeStatus === 'live') ? Style.isNotReplay : null
						]}
						width={1}
						className="timeline"
					>
						<Box
							css={Style.timeline}
							style={{
								transform: `translateX(${
									dragged === null ? getPercent(currentTime, storeDatas) : dragged.p * 100
								}%)`
							}}
						>
							<Box css={[Style.ticker, draggedOrHovered ? Style.tickerIsDragged : null]} />
						</Box>
						{$steps}
					</Flex>
				</>
			)
		} else {
			return <></>
		}
	} else {
		return <></>
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Timeline)
