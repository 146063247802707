import styled from '@emotion/styled'
import Theme from '@styles/theme'
import { RegularSecondText, Text } from '@styles/SharedTextComponents'
import { handleSquaredEdges } from '@styles/sharedLogics'

const PREFIX = 'pinned-message'

export const classes = { root: PREFIX, block: `${PREFIX}-block` }

export const MessageText = styled(RegularSecondText)<{ isBlock? }>`
	font-size: ${({ isBlock }) => (isBlock ? '13em' : '12em')};
	line-height: 130%;
	width: 100%;
	margin-top: 1px;
	width: calc(100% - 12px);
	a {
		word-wrap: break-word;
		text-decoration: underline;
		color: ${Theme.colors.white()};
	}
`

export const ViewMoreComp = styled(MessageText)`
	margin-left: 24px;
	text-decoration: underline;
	cursor: pointer;
	font-weight: ${Theme.fontWeights.bold};
`

export const AuthorName = styled(Text)<{ isBlock }>`
	font-family: ${Theme.fontFamilies.Second};
	font-weight: ${({ isBlock }) => (isBlock ? Theme.fontWeights.bold : Theme.fontWeights.semibold)};
	font-size: ${({ isBlock }) => (isBlock ? '13em' : '11em')};
	line-height: 120%;
	width: 100%;
`

export const PinnedMessageWrapper = styled.div<{ backgroundColor? }>`
	position: relative;
	display: flex;
	flex-wrap: wrap;
	padding: 8px;
	border-radius: 12px;
	pointer-events: all;
	background-color: ${({ backgroundColor }) => backgroundColor || null};
	max-height: 50vh;
	height: fit-content;
	overflow-y: scroll;
	&.${classes.block} {
		border-radius: 0;
		padding: ${Theme.spacing(3)} ${Theme.spacing(5)};
	}
	${handleSquaredEdges}
`

export const PinnedMessageContent = styled.div<{ isToggled; isLarge; isBlock }>`
	display: flex;
	flex-wrap: wrap;
	margin-left: 6px;
	width: calc(100% - 30px);
	overflow: hidden;
	height: ${({ isToggled, isLarge, isBlock }) =>
		isLarge ? (isToggled ? 'fit-content' : isBlock ? '60px' : '50px') : 'fit-content'};
`

export const PinnedIconWrapper = styled.div<{ isBlock }>`
	width: 13px;
	height: 13px;
	position: absolute;
	top: 14px;
	right: ${({ isBlock }) => (isBlock ? '24px' : '14px')};
`

export const AvatarWrapper = styled.div`
	overflow: hidden;
	* {
		border-radius: 100%;
		overflow: hidden;
	}
	width: 18px;
	height: 18px;
	display: flex;
	justify-content: center;
	align-items: center;
	${handleSquaredEdges}
`
