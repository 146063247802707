import styled from '@emotion/styled'
import Theme from '@styles/theme'
import { getPlayerDisplayModeValue } from '@helpers/contexts/player/logic'
import { scrollToButtonClasses } from '../ScrollToButton'

const PREFIX = 'chatbox'

interface ChatBoxWrapperProps {
	hidden?: boolean
	isReplay?: boolean
	translated?: boolean
	chatShift?: number
	isBlock?: boolean
	isSelectable?: boolean
	isLandscape?: boolean
	isDesktop?: boolean
	displayMode: ReturnType<typeof getPlayerDisplayModeValue>
	chatHeight?: string
	noSmoothScroll?: boolean
}

export const classes = {
	root: PREFIX,
	integrated: `${PREFIX}-integrated`,
	container: `${PREFIX}-container`,
	virtualList: `${PREFIX}-virtual-list`,
	scrollShadow: `${PREFIX}-scroll-shadow`,
	isScrolling: 'is-scrolling'
}

export const ChatBoxReplayFooter = styled.div`
	height: 5px;
`

export const ChatBoxContainer = styled.div`
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	width: 100%;
	box-sizing: border-box;
`

export const ChatBoxWrapper = styled.div<ChatBoxWrapperProps>`
	transition: all 400ms ${Theme.eases.inOutCubic};
	position: relative;
	display: flex;
	flex-grow: 1;
	width: 100%;
	& .${classes.virtualList} {
		width: 100%;
		scroll-behavior: ${({ noSmoothScroll }) => (noSmoothScroll ? 'initial' : 'smooth')};
	}
	& .${scrollToButtonClasses.root} {
		bottom: ${Theme.spacing(1)};
	}

	&.${classes.scrollShadow} {
		mask-image: linear-gradient(180deg, rgb(0 0 0 / 0%) 0%, rgb(0 0 0 / 90%) 10%, rgba(0, 0, 0, 1) 11%);

		&.${classes.isScrolling} {
			mask-image: radial-gradient(ellipse 150% 50% at 50% 50%, black 80%, transparent 100%);
		}
	}
`
