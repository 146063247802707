import React from 'react'
// import NextLink from 'next/link'
import { useRouter } from 'next/router'

const Link: React.FC<{
	to?: any
	query?: any
	target?: any
	params?: any
	children?: any
	showStatus?: boolean
	//@ts-ignore
}> = ({ to, query, target, params, children, showStatus = false }) => {
	//@ts-ignore
	const router = useRouter()
	// const link = LinkResolver.parse(to, query, params, target)

	// let className = children.props.className || ''
	// if (router.asPath.indexOf(link.as) !== -1 && showStatus) {
	// 	className = `${className} isActive`
	// }

	// let $link = null

	// if (link.type === 'in') {
	// 	$link = (
	// 		<NextLink href={link.href} as={link.as} passHref>
	// 			{React.cloneElement(children, { className })}
	// 		</NextLink>
	// 	)
	// } else {
	// 	$link = React.cloneElement(children, { href: link.href, target: link.target, className })
	// }

	return <></>
}

export default Link
