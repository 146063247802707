import Theme from '@styles/theme'
import styled from '@emotion/styled'

export type CircleSize = 'sm' | 'md' | 'lg'

enum CircleDiameter {
	md = 30
}

const PREFIX = 'card-item'

const getPaddingFromSize = ({ size }: { size: string }) => {
	return `${CircleDiameter[size]}px`
}

export const classes = {
	root: PREFIX
}

export const CircleIconContainer = styled.div<{ size: CircleSize; backgroundColor?: string }>`
	display: flex;
	justify-content: center;
	align-items: center;
	width: ${getPaddingFromSize};
	height: ${getPaddingFromSize};
	background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : Theme.colors.cta_main)};
	border-radius: 50%;
`
