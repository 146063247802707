import IconCta from '@components/Micro/IconCta'
import { css } from '@emotion/react'
import styled from '@emotion/styled'

const PREFIX = 'close-player-button'

export const closePlayerButtonClasses = {
	root: PREFIX
}

export const IconCtaWrapper = styled(IconCta)<{ hasPip: boolean }>`
	&.${closePlayerButtonClasses.root} div {
		${({ hasPip }) =>
			hasPip
				? css`
						width: 7px;
					`
				: null}
	}
`
