import React from 'react'
import { mapDispatchToProps } from '@helpers/wrappers/withReduxStore'
import { State } from '@stores/index'
import { connect } from 'react-redux'
import { pollVoteClasses, VoteContent, VoteResults, VoteWrapper } from './style'
import { AnswerAndVotes } from 'types/Interaction'

interface Props {
	maxPourcentage: number
	votes: AnswerAndVotes
	answerId: string
	answersLoaded: boolean
}

const mapStateToProps = (state: State, props: Props) => {
	return { ...state, ...props }
}

const PollVote: React.FC<Props> = ({ maxPourcentage, votes, answerId, answersLoaded }) => {
	//
	const formatedResults = () => `${votes.count} ${pluralizedVotes()} (${votes.percentage}%)`

	const pluralizedVotes = () => (votes.count !== 1 ? 'votes' : 'vote')

	const calculateUsedWidth = () => {
		const relativePourcentage = (votes.percentage * 100) / maxPourcentage
		return `${(relativePourcentage * 60) / 100}%`
	}

	const hasZeroVote = () => votes.count === 0

	return (
		<VoteWrapper>
			<VoteContent
				width={answersLoaded ? calculateUsedWidth() : 0}
				hasNoVote={hasZeroVote() || !answersLoaded}
				isUserAnswer={answerId === votes.answerId}
				className={pollVoteClasses.content}
			>
				{votes.content}
			</VoteContent>
			<VoteResults className={pollVoteClasses.results}>{formatedResults()}</VoteResults>
		</VoteWrapper>
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(PollVote)
