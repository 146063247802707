import styled from '@emotion/styled'
import { css } from '@emotion/react'
import Theme from '@styles/theme'
import { BaseFlex } from '@styles/common'

const shadowStyle = () => css`
	cursor: pointer;
`

export const PausePlayButtonWrapper = styled(BaseFlex)<{ hasShadow }>`
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;
	z-index: ${Theme.zIndices.pause};
	pointer-events: all;
	position: absolute;
	${({ hasShadow }) => (hasShadow ? shadowStyle() : null)}
`

export const PausePlayedButtonEl = styled.div`
	border-radius: 20px;
	width: 20px;
	padding: 30px 29px 30px 31px;
	background-color: ${Theme.colors.white()};
	${() => css([Theme.squaredEdges])};
`
