import { useDb } from '@helpers/contexts/dbContext'
import { useTimeLineContext } from '@helpers/contexts/timeline'
import { useCallback, useEffect, useState } from 'react'
import { Message } from 'types/Message'
import {
	FetchState,
	generateMessagesSegments,
	generateTimeIntervals,
	getClosestSegmentTimestamp,
	MessagesSegmentsData,
	RecordData
} from './logic'

const useReplayMessages = (eventId: string, record: RecordData): Message[] => {
	const [messagesSegmentsData, setMessagesSegmentsData] = useState<MessagesSegmentsData>()
	const [messages, setMessages] = useState<Message[]>([])
	const [fetchState, setFetchState] = useState<FetchState>()
	const [isFetching, setIsFetching] = useState<boolean>(false)
	const [gap, setGap] = useState<number>(0) // gap in ms between live start and stream start
	const { currentDateTime } = useTimeLineContext()
	const { getApiMessages } = useDb()

	const fetchMessages = (cursor: string) => {
		const query = {
			cursor,
			before: false,
			filters: [{ name: 'is_moderated', value: false }],
			limit: 50
		}

		setIsFetching(true)
		getApiMessages(eventId, query).then((messagesData) => {
			const timeIntervals = generateTimeIntervals(record)
			const segmentsData = generateMessagesSegments(
				timeIntervals,
				messagesData.data,
				messagesSegmentsData?.segments
			)
			setMessagesSegmentsData(segmentsData)
			if (messagesData.hasMoreMessages && messagesData.cursor) {
				const lastMessageCursor = messagesData.cursor.toString()
				const newFetchState = {
					lastMessageCursor,
					closestMessageSegment: getClosestSegmentTimestamp(segmentsData.segments, lastMessageCursor)
				}
				setFetchState(newFetchState)
			} else {
				setFetchState(undefined)
			}
			setIsFetching(false)
		})
	}

	const getMessagesUpToCursor = useCallback(
		(segmentsData: MessagesSegmentsData, cursor: number): Message[] => {
			let messages: Message[] = []

			if (!segmentsData?.segments) return messages

			const { segments, timestamps } = segmentsData

			for (let timestamp of timestamps) {
				const segment = segments[timestamp]
				if (segment.length && timestamp <= cursor) {
					if (
						timestamps.indexOf(timestamp) === timestamps.length - 1 ||
						timestamps[timestamps.indexOf(timestamp) + 1] > cursor
					) {
						// if segment is closest to video cursor
						const filteredMessages = segments[timestamp].filter((message) => {
							if (message.created_at) {
								const messageDate = new Date(message.created_at).valueOf()
								return messageDate <= cursor
							}
							return false
						})

						messages = messages.concat(filteredMessages)
					} else {
						// handle others anterior segments
						messages = messages.concat(segment)
					}
				}
			}

			return messages
		},
		[currentDateTime, messagesSegmentsData]
	)

	useEffect(() => {
		if (eventId && record) {
			fetchMessages(record.live_start_time)
			// @ts-ignore
			setGap(new Date(record.live_start_time) - new Date(record.stream_start_time))
		}
	}, [eventId, record])

	useEffect(() => {
		if (currentDateTime && messagesSegmentsData) {
			const cursorAsNumber: number = new Date(currentDateTime).valueOf() - gap
			const messageByTime = getMessagesUpToCursor(messagesSegmentsData, cursorAsNumber >= 0 ? cursorAsNumber : 0)
			if (messageByTime.length !== messages.length) {
				setMessages(messageByTime.filter((m) => !m.is_pinned))
			}
		}
	}, [currentDateTime, messagesSegmentsData])

	useEffect(() => {
		if (currentDateTime && messagesSegmentsData) {
			const cursorAsNumber: number = new Date(currentDateTime).valueOf()
			if (!isFetching && fetchState && cursorAsNumber >= fetchState?.closestMessageSegment) {
				fetchMessages(fetchState.lastMessageCursor)
			}
		}
	}, [currentDateTime, messagesSegmentsData, fetchState, isFetching])

	return messages
}

export default useReplayMessages
