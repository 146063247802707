export const clearTimeoutCallback = (timeout: NodeJS.Timeout) => {
	if (timeout) {
		clearTimeout(timeout)
	}
}

export const clearIntervalCallback = (interval: NodeJS.Timer) => {
	if (interval) {
		clearInterval(interval)
	}
}
