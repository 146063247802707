import Utils from '@utils/index'

/*

expoOut / backOut / quintOut / quartOut / cubicOut / quadOut / sineOut / linear

circOut / bounceOut / bounceInOut / sineInOut / quadInOut / bounceIn / sineIn / circInOut / quadIn
cubicInOut / backIn / backInOut / circIn / cubicIn / elasticIn / elasticInOut / elasticOut / expoIn
expoInOut / quartIn / quartInOut / quintIn / quintInOut

*/

export default class Ease {
	constructor(options = {}) {
		this.target = options.target || 0
		this.pos = options.pos || 0
		this.target = this.pos
		this.easing = options.easing || 'expoOut'
		this.duration = options.duration || 1100
		this.delta = options.delta || 0
		this.saved = this.pos
		this.last = {
			pos: this.pos,
			target: this.target
		}
		this.startTime = 0
		this.currentTime = 0
	}

	set(target) {
		this.startTime = this.currentTime
		this.saved = this.pos
		this.last.target = this.target
		this.target = target
	}

	force(target) {
		this.last.target = this.target
		this.target = target
		this.pos = target
	}

	stop() {
		this.force(this.target)
	}

	update(infos) {
		this.currentTime = infos.last
		const elapsed = (this.currentTime - this.startTime) / this.duration

		this.last.pos = this.pos

		if (elapsed < 1) {
			this.pos = this.saved + Utils.easing[this.easing](elapsed) * (this.target - this.saved)
			this.delta = this.pos - this.target
		} else {
			this.pos = this.target
			this.delta = 0
			this.startTime = 0
			this.saved = 0
		}

		return {
			target: this.target,
			pos: this.pos,
			delta: this.delta,
			lastpos: this.last.pos,
			lasttarget: this.last.target
		}
	}
	destroy() {
		Utils.nullObject(this)
	}
}
