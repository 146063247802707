import styled from '@emotion/styled'
import { css } from '@emotion/react'
import Theme from '@styles/theme'
import Spinner from '@assets/img/spinner-black_old.gif'
import { Box } from 'rebass'

const PREFIX = 'popin'

export const classes = {
	content: `${PREFIX}-content`
}

export const FireworksWrapper = styled(Box)`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
`

export default {
	el: css`
		position: absolute;
		pointer-events: none;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: ${Theme.zIndices.popin};
		display: block;
		overflow-y: auto;
	`,

	elIsOpened: css`
		pointer-events: all;
	`,

	overlay: css`
		background: ${Theme.colors.black(0.5)};
		position: fixed;
		cursor: pointer;
		transition: opacity 600ms ${Theme.eases.outExpo} 0ms;
		opacity: 0;
		right: 0;
		bottom: 0;
	`,

	overlayIsOpened: css`
		opacity: 1;
		pointer-events: all;
	`,

	content: css`
		border-radius: 20px;
		position: relative;
		transform: scale(1.05);
		opacity: 0;
		pointer-events: none;
		will-change: transform, opacity;
	`,

	contentIsOpened: css`
		pointer-events: all;
		transform: scale(1);
		opacity: 1;
		z-index: 2;
	`,

	contentIsSmall: css`
		top: 50%;
		transform: scale(1) translateY(-50%);
		margin: 0 auto;
	`,

	close: css`
		cursor: pointer;
		position: absolute;
		right: 15px;
		top: 15px;
	`,

	isSubmitting: css`
		opacity: 0.1;
		pointer-events: none;
	`,

	spinner: css`
		width: 120px;
		height: 120px;
		background: url(${Spinner}) no-repeat center;
		background-size: 120px 120px;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translateX(-50%) translateY(-50%);
		display: none;
	`,

	spinnerIsShown: css`
		display: flex;
	`,

	notClosableOverlay: css`
		cursor: auto;
	`,

	smallPadding: css`
		padding: ${Theme.spacing(8)} !important;
		width: max-content !important;
	`
}
