import { css } from '@emotion/react'
import Theme from '@styles/theme'

export default {
	el: css`
		pointer-events: all;
		z-index: ${Theme.zIndices.timeline};
		height: 6px;
		position: absolute;
		bottom: 14px;
		left: 0;
		background-color: ${Theme.colors.white(0.3)};
		transform: scale(1, 0.6);
		transition: transform 600ms ${Theme.eases.outExpo} 0ms;
		@media ${Theme.media(0)} {
			height: 7px;
		}
	`,

	elIsDragged: css`
		transform: scale(1, 1);
	`,

	timing: css`
		position: absolute;
		top: 50%;
		left: 50%;
		text-align: center;
		background-color: ${Theme.colors.white(0.2)};
		z-index: ${Theme.zIndices.timeline};
		font-size: 24em;
		font-family: ${Theme.fontFamilies.Main};
		font-weight: ${Theme.fontWeights.bold};
		color: ${Theme.colors.white()};
		text-transform: uppercase;
		backdrop-filter: blur(10px);
		transform: translateX(-50%) translateY(-50%);
		border-radius: 100px;
		padding: 13px 20px;
		white-space: nowrap;
		pointer-events: none;
		opacity: 0;
		transition: opacity 600ms ${Theme.eases.outExpo} 0ms;
	`,

	timingIsShown: css`
		opacity: 1;
	`,

	touch: css`
		pointer-events: all;
		z-index: ${Theme.zIndices.max};
		height: 30px;
		position: absolute;
		bottom: 0;
		width: 100%;
		left: 0;
		cursor: pointer;
	`,

	timeline: css`
		position: absolute;
		left: -100%;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: ${Theme.colors.red()};
	`,

	ticker: css`
		width: 20px;
		height: 20px;
		background-color: ${Theme.colors.white()};
		position: absolute;
		border-radius: 100%;
		top: -7px;
		right: -10px;
		transform: scale(0.3125, 0.521);
		transition: transform 600ms ${Theme.eases.outExpo} 0ms;
	`,

	tickerIsDragged: css`
		transform: scale(1, 1);
	`,

	//dragged

	timecode: css`
		height: 100%;
		background-color: ${Theme.colors.white(0.5)};
		position: absolute;
		border-radius: 3px;
	`,

	timecodeIsDragged: css`
		background-color: ${Theme.colors.white(0.3)};
	`,

	isNotReplay: css`
		display: none !important;
		pointer-events: none;
	`,

	stories: css`
		position: relative;
		height: 3px;
	`,

	story: css`
		height: 100%;
		background-color: ${Theme.colors.white(0.3)};
		border-radius: 3px;
		margin: 0 5px 0 0;
		overflow: hidden;
	`,

	storyStatus: css`
		width: 100%;
		height: 100%;
		background-color: ${Theme.colors.white()};
		border-radius: 3px;
		display: block;
		margin-left: -100%;
	`,

	nav: css`
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		opacity: 0;
		z-index: ${Theme.zIndices.timeline};
	`,

	navPrev: css`
		@media ${Theme.media(0)} {
			cursor: w-resize;
		}
	`,
	navNext: css`
		@media ${Theme.media(0)} {
			cursor: e-resize;
		}
	`
}
