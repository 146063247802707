import Theme from '@styles/theme'
import styled from '@emotion/styled'
import { Anchor } from '.'

export const WIDTH = '230px'
export const HEIGHT = '105px'

export interface FloatingMenuElementProps extends Anchor {
	width?: string
	height?: string
	arrowRight?: string
	backgroundColor?: string
	marginLeft?: string
}

const PREFIX = 'floating-menu'

export const classes = {
	root: PREFIX,
	menuOpen: `${PREFIX}-open`
}

export const FloatingMenuContainer = styled.div<FloatingMenuElementProps>`
	box-sizing: border-box;
	position: absolute;
	display: flex;
	flex-wrap: wrap;
	padding: ${Theme.spacing(2)};
	right: ${Theme.spacing(0)};
	top: ${Theme.spacing(9)};
	width: ${({ width }) => width || WIDTH};
	height: ${({ height }) => height || HEIGHT};
	background-color: ${({ backgroundColor }) => backgroundColor || Theme.colors.white()};
	border-radius: 10px;
	opacity: 0;
	transform: scale(0);
	transition: all 600ms ${Theme.eases.outExpo};
	z-index: ${Theme.zIndices.modal};
	&::before {
		position: absolute;
		width: 10px;
		height: 10px;
		top: -4px;
		right: ${({ arrowRight }) => arrowRight || '45px'};
		border-radius: 2px;
		content: '';
		background-color: ${({ backgroundColor }) => backgroundColor || Theme.colors.white()};
		-webkit-transform: translateX(-50%) rotate(45deg);
		-ms-transform: translateX(-50%) rotate(45deg);
		transform: translateX(-50%) rotate(45deg);
	}

	&.${classes.menuOpen} {
		opacity: 1;
		transform: scale(1);
	}

	${() => Theme.squaredEdges}
`
