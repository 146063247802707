import { usePlayerContext } from '@helpers/contexts/player'
import { useViewer } from '@helpers/contexts/viewerContext'
import { useRef, useState } from 'react'
import Field from '@components/Micro/Field'
import {
	AdditionalStatementLink,
	AdditionalStatementWrapper,
	FieldWrapper,
	firstNameDrawerClasses,
	FirstNameTitle,
	FirstNameTitleWrapper
} from './style'
import Cta from '@components/Micro/Cta'
import { Text } from '@styles/SharedTextComponents'
import Theme from '@styles/theme'
import TextWithUrlAndPhone from '@components/Micro/TextWithUrlAndPhone'
import TermsOfServicesComponent from '@components/Templates/TermsOfServices'
import Bus from '@helpers/bus'
import Constants from '@constants'
import { StoreConfig } from '@models/stores'
import { State } from '@stores/index'
import { connect } from 'react-redux'
import { mapDispatchToProps } from '@helpers/wrappers/withReduxStore'
import {
	isValidFirstname,
	VIEWER_FIRSTNAME_MAX_LENGTH,
	VIEWER_FIRSTNAME_MIN_LENGTH
} from 'src/validation/ViewerValidation'

interface Props {
	onClose?: () => void
	isModal?: boolean
	storeConfig?: StoreConfig
	dispatch?: (action: any) => void
}

const mapStateToProps = (state: State, props: Props) => {
	return { ...state, ...props }
}

const FirstNameContent: React.FC<Props> = ({ onClose, isModal, storeConfig, dispatch }) => {
	// States
	const { updateCurrentViewer, currentViewer } = useViewer()
	const { playerData } = usePlayerContext()
	const [firstname, setFirstname] = useState<string>(currentViewer?.firstName || '')
	const [firstnameIsNotValid, setFirstnameIsNotValid] = useState<boolean>(false)
	const [errorMessage, setErrorMessage] = useState<string>(null)
	const tosRef = useRef(null)

	const submit = () => {
		if (playerData.event.event.status === 'live') {
			let hasError = false
			if (!isValidFirstname(firstname)) {
				setErrorMessage(playerData.ui.errors.invalid_firstname)
				setFirstnameIsNotValid(true)
				hasError = true
			} else {
				setFirstnameIsNotValid(false)
				setErrorMessage(null)
			}
			const acceptedTos = tosRef?.current?.validate()
			if (!storeConfig?.consent?.tos && !acceptedTos) {
				tosRef?.current?.setError()
				hasError = true
			}

			if (!hasError) {
				updateCurrentViewer({ firstName: firstname }).then(() => {
					if (acceptedTos) {
						const consent = {
							...storeConfig.consent,
							tos: true
						}
						Bus.send(Constants.bus.player.legal_consented, consent)
						dispatch({ type: 'SET_CONSENT', ...consent })
					}
				})
				if (onClose) onClose()
			}
		}
	}

	const onChange = (e) => {
		setFirstnameIsNotValid(false)
		setFirstname(e.currentTarget.value)
	}

	const onAdditionalStatementClick = (e) => {
		e.stopPropagation()
		window.open(playerData.event.event.legalSettings.liveChat?.additionalStatementLink.url, '_blank')
	}

	return (
		<>
			<FirstNameTitleWrapper className={firstNameDrawerClasses.titleWrapper}>
				<FirstNameTitle className={firstNameDrawerClasses.title} isModal={isModal}>
					{playerData.ui.message_modal_title}
				</FirstNameTitle>
			</FirstNameTitleWrapper>
			<FieldWrapper className={firstNameDrawerClasses.fieldWrapper} isModal={isModal}>
				<Field
					label={`${playerData.ui.alert_sms_form_firstname_label} *`}
					name="firstname"
					type="text"
					value={firstname}
					isRequired={true}
					requiredLabel={playerData.ui.alert_sms_form_required_singular}
					hasError={firstnameIsNotValid}
					onChange={onChange}
					onEnter={submit}
					className={firstNameDrawerClasses.subtitle}
					errorMessage={errorMessage}
					constraints={{
						minLength: VIEWER_FIRSTNAME_MIN_LENGTH,
						maxLength: VIEWER_FIRSTNAME_MAX_LENGTH
					}}
				/>
				{!storeConfig?.consent?.tos && <TermsOfServicesComponent innerRef={tosRef} spacing={6} />}
				{playerData.event.event.legalSettings?.liveChat?.hasAdditionalStatement && (
					<AdditionalStatementWrapper>
						<Text color={Theme.colors.text_main}>
							<TextWithUrlAndPhone
								fieldKey="additional-statement-text"
								text={playerData.event.event.legalSettings?.liveChat?.additionalStatement}
							/>
						</Text>{' '}
						{playerData.event.event.legalSettings?.liveChat?.additionalStatementLink && (
							<AdditionalStatementLink onClick={onAdditionalStatementClick}>
								{playerData.event.event.legalSettings.liveChat?.additionalStatementLink?.label}
							</AdditionalStatementLink>
						)}
					</AdditionalStatementWrapper>
				)}
				<Cta label={playerData.ui.message_modal_submit} onClick={submit} fullWidth={true} />
			</FieldWrapper>
		</>
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(FirstNameContent)
