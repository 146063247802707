import { ModalsType } from '@components/Notifiers/Modals/constants'
import React, { createContext, useContext, useMemo, useState, useEffect } from 'react'
import { useNotificationsContext } from '../notifications'
import { usePlayerContext } from '../player'
import { useViewer } from '../viewerContext'
import { DrawersType } from '@components/Notifiers/Drawers/constants'
import getConfig from 'next/config'
import { CommercialOptinSettings, Poll, AnswerAndVotes } from 'types/Interaction'
import { PanelsType } from '@components/Notifiers/Panels/constants'
import { handleApiResponseErrors } from '../fetchHelpers'
const { publicRuntimeConfig } = getConfig()
const NEXT_PUBLIC_API_ENDPOINT = publicRuntimeConfig.NEXT_PUBLIC_API_ENDPOINT

interface PollsContext {
	setPoll?: (poll: Poll) => void
	poll?: Poll
	answersLoaded?: boolean
	maxPercentage?: number
	isSubmitting?: boolean
	submitVote?: (answerId: string) => void
	onPollClose?: () => void
	answerId?: string
	orderedVotes?: AnswerAndVotes[]
}

const Context = createContext<PollsContext | null>({})

export const usePollsContext = () => useContext(Context)

export const PollsProvider = ({ children }) => {
	/**
	 * @States
	 */
	const { currentViewer } = useViewer()
	const { openModal, openDrawer, closeDrawer, openPanel, pollPanelOpened, closePollPanel, pollDrawerOpened } =
		useNotificationsContext()
	const { playerData, isLive, isPlayed, tracker, isLandscape, isVertical } = usePlayerContext()
	const [poll, setPoll] = useState<Poll>(null)
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
	const [answersLoaded, setAnswersLoaded] = useState<boolean>(false)
	const [answerId, setAnswerId] = useState<string>(null)
	const [commercialOptinSettings, setCommercialOptinSettings] = useState<CommercialOptinSettings>({
		title: '',
		description: '',
		text: ''
	})
	const [orderedVotes, setOrderedVotes] = useState<AnswerAndVotes[]>([])
	const [askedCount, setAskedCount] = useState<number>(0)

	/**
	 * @Methods
	 */

	/**
	 * @POLLS logic
	 */

	const setAnswerAndClosePoll = (res, answerId) => {
		setPoll(res)
		setOrderedVotes(() => orderVotes(res.votes))
		setAnswerId(answerId)
		setIsSubmitting(false)
	}

	const submitVote = (answerId) => {
		if (isSubmitting) return

		setIsSubmitting(true)
		setPollAnwerVote(answerId)
			.then((res) => {
				if (res.errors) {
					handleApiResponseErrors(res, currentViewer)
					handlerSubmitError(res.errors)
				} else {
					if (res?.hasCommercialOptin && !currentViewer?.emailCommercialOptIn) {
						getCommercialOptin()
							.then((optinRes) => {
								handleApiResponseErrors(optinRes, currentViewer)
								if (optinRes.commercialOptinTitle && askedCount === 0) {
									setCommercialOptinSettings({
										title: optinRes.commercialOptinTitle,
										description: optinRes.commercialOptinDescription,
										text: optinRes.commercialOptinText
									})
									setAnswerAndClosePoll(res, answerId)
								}
							})
							.catch((error) => console.error(error))
					} else {
						setAnswerAndClosePoll(res, answerId)
					}
				}
			})
			.catch((error) => {
				handlerSubmitError(error)
			})
	}

	const handlerSubmitError = (error) => {
		setIsSubmitting(false)
		onPollClose()
		setAnswersLoaded(false)
		console.log(error)
	}

	const setPollAnwerVote = (answerId) => {
		let anonId = null
		if (tracker) {
			anonId = tracker?.current?.getAnonId()
		}
		return fetch(
			`${NEXT_PUBLIC_API_ENDPOINT}/api/channels/${playerData.channel.uuid}/events/${playerData.event.event.uuid}/polls/${poll.uuid}/vote`,
			{
				body: JSON.stringify({
					answerId: answerId,
					viewerId: currentViewer?.uid || null,
					anonId: anonId
				}),
				headers: {
					'Content-Type': 'application/json'
				},
				method: 'POST'
			}
		).then((res) => res.json())
	}

	const getCommercialOptin = () => {
		return fetch(
			`${NEXT_PUBLIC_API_ENDPOINT}/api/channels/${playerData.channel.uuid}/events/${playerData.event.event.uuid}/polls/${poll.uuid}/optin`,
			{ headers: { 'Content-Type': 'application/json' } }
		).then((res) => res.json())
	}

	const resetPoll = () => {
		setPoll(null)
		setIsSubmitting(false)
		setAnswerId(null)
		setOrderedVotes([])
		setCommercialOptinSettings({
			title: '',
			description: '',
			text: ''
		})
		setAskedCount(0)
		setAnswersLoaded(false)
	}

	const orderVotes = (votes) => votes.sort((a, b) => b.count - a.count || a.content.localeCompare(b.content))

	const showPoll = (showedPoll) => {
		setPoll(showedPoll)
		setOrderedVotes(() => orderVotes(showedPoll.votes))
	}

	const isUsingPanelsPoll = () => isLandscape && !isVertical

	const onPollClose = () => {
		closePollPanel()
		closeDrawer()
		setAnswersLoaded(false)
	}

	const openPollByDisplayMode = () => {
		if (isUsingPanelsPoll()) {
			if (!pollPanelOpened) {
				openPanel(PanelsType.POLL, {
					back: playerData?.ui.panel_back,
					onClose: onPollClose
				})
			}
		} else {
			openDrawer(DrawersType.POLL_DRAWER)
		}
	}

	/**
	 * @Effects
	 */
	/**
	 * @DISPLAY_POLL event listenner
	 */
	useEffect(() => {
		if (playerData?.event?.event?.status === 'live' && poll) {
			openPollByDisplayMode()
		}

		let interval = null
		if (poll?.ended) {
			showPoll(poll)
			interval = setInterval(resetPoll, 10_000)
		}

		return () => {
			if (interval) {
				clearInterval(interval)
			}
		}
	}, [poll])

	useEffect(() => {
		let timeout = null
		if (orderedVotes.length) {
			timeout = setTimeout(() => setAnswersLoaded(true), 400)
		}

		return () => {
			if (timeout) {
				clearTimeout(timeout)
				setAnswersLoaded(false)
			}
		}
	}, [orderedVotes])

	useEffect(() => {
		let closeInterval = null
		if (answerId && !poll?.ended) {
			closeInterval = setInterval(onPollClose, 10_000)
		}

		return () => {
			if (closeInterval) {
				clearInterval(closeInterval)
			}
		}
	}, [answerId, poll])

	useEffect(() => {
		if (!poll) {
			onPollClose()
		}
	}, [pollDrawerOpened, pollPanelOpened, poll])

	useEffect(() => {
		if (!pollDrawerOpened && !pollPanelOpened) {
			if (
				poll?.hasCommercialOptin &&
				!!answerId &&
				askedCount === 0 &&
				commercialOptinSettings?.title?.length &&
				!currentViewer?.emailCommercialOptIn &&
				!poll?.ended
			) {
				openModal(ModalsType.COMMERCIAL_OPTIN, {
					playerData: playerData,
					tracker: tracker?.current,
					commercialOptinSettings
				})
				setAskedCount(1) // Can't ask more than once
			}

			if (poll?.ended) {
				resetPoll()
			}
		}
	}, [pollDrawerOpened, pollPanelOpened])

	const values = useMemo(
		(): PollsContext => ({
			setPoll,
			poll,
			answersLoaded,
			maxPercentage: Math.max(...orderedVotes.map((votes) => votes.percentage)),
			isSubmitting,
			submitVote,
			onPollClose,
			answerId,
			orderedVotes
		}),
		[isLive, isPlayed, poll, answersLoaded, orderedVotes, isSubmitting, answerId, onPollClose]
	)

	return <Context.Provider value={values}>{children}</Context.Provider>
}
