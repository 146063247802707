import mitt from 'mitt'

class Sizes {
	constructor() {
		this.handlers = []
		this.isEnabled = false
		this.sizes = {
			width: 0,
			height: 0,
			center: {
				x: 0,
				y: 0
			}
		}

		this._bind()
		this.emitter = mitt()
	}

	_bind() {
		this._resizeHandler = this._resizeHandler.bind(this)
	}

	_smartDisabler() {
		if (!this.handlers.length) {
			this._disable()
		}
	}

	_enable() {
		if (!this.isEnabled) {
			this._observe()
			this.isEnabled = true
		}
	}

	_disable() {
		if (this.isEnabled) {
			this._unobserve()
			this.isEnabled = false
		}
	}

	_observe() {
		window.addEventListener('resize', this._resizeHandler)
	}

	_unobserve() {
		window.removeEventListener('resize', this._resizeHandler)
	}

	_resizeHandler() {
		this.sizes.width = window.innerWidth
		this.sizes.height = window.innerHeight
		this.sizes.outerHeight = window.outerHeight
		this.sizes.center.x = window.innerWidth / 2
		this.sizes.center.y = window.innerHeight / 2
		this.emitter.emit('resize', this.sizes)
	}

	subscribe(handler) {
		this._enable()
		this.handlers.push(handler)
		this.emitter.on('resize', handler)
		this._resizeHandler()
	}

	unsubscribe(handler) {
		this.emitter.off('resize', handler)
		this.handlers.splice(this.handlers.indexOf(handler), 1)
		this._smartDisabler()
	}
}

export default new Sizes()
