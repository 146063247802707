import React from 'react'
import { MenuItemContainer } from './styles'
import Icon, { IconUnion } from '@components/Micro/Icon'
import Theme from '@styles/theme'
import Text from '@components/Micro/Text'

interface MenuItemProps {
	icon?: IconUnion
	children?: any
	onClick?: () => void
}

const MenuItem: React.FC<MenuItemProps> = ({ icon, children, ...props }) => {
	return (
		<MenuItemContainer {...props}>
			<Icon name={icon} width="16px" height="16px" fill={Theme.colors.cta_main} />
			<Text variant="bold" color={Theme.colors.cta_main}>
				{children}
			</Text>
		</MenuItemContainer>
	)
}

export default MenuItem
