import { css } from '@emotion/react'
import styled from '@emotion/styled'
import Theme from '@styles/theme'

const PREFIX = 'icon-button'

export const classes = {
	root: PREFIX
}

export const IconButton = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-content: center;
	background-color: ${Theme.colors.black(0.25)};
	backdrop-filter: blur(2px);
	border-radius: 10px;
	width: 35px;
	height: 35px;
	cursor: pointer;
	&:active {
		opacity: 0.5;
	}
	@media (hover: hover) {
		&:hover {
			opacity: 0.5;
		}
	}

	${() => css([Theme.squaredEdges])}
	${Theme.fadeAndScaleOnClick()}
`

export const IconBox = styled.div`
	width: 17px;
	& > img {
		width: 100%;
	}
`
