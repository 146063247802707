import { ModalBuild, ModalsType } from './constants'
import Theme from '@styles/theme'
import SocialMedias from './SocialMedias'
import CommercialOptin from './CommercialOptin'
import { RewardsList, PositionIcon, WinnersDescription, WinningInstantRightAnswer } from './WinningInstant'
import ConversationalQuitReplay from './ConversationalQuitReplay'
import FirstNameContent from '../SharedNotifiersContent/FirstNameContent'
import RGPDOptOut from './RGPDOptOut'
import RGPDOptOutForm from './RGPDOptOut/Form'

export interface UiConfirm {
	icon: string
	title: string
	description: string
}

export const getModalBuild = (modalType, metadata): ModalBuild => {
	var { playerData, commercialOptinSettings } = metadata
	switch (modalType) {
		case ModalsType.SUCCESS:
			return {
				modalType,
				config: {
					iconProps: {
						name: metadata.ui.icon,
						bgcolor: Theme.colors.success_green
					},
					titleProps: {
						text: metadata.ui.title,
						color: Theme.colors.text_main
					},
					descriptionProps: {
						text: metadata.ui.description
					}
				}
			}
		case ModalsType.SOCIAL_MEDIAS:
			return {
				modalType,
				config: {
					iconProps: {
						name: 'share',
						bgcolor: Theme.colors.cta_main
					},
					titleProps: {
						text: playerData.ui.share_title,
						color: Theme.colors.text_main
					},
					descriptionProps: {
						text: playerData.ui.share_text,
						width: '60%'
					}
				},
				Component: <SocialMedias />
			}
		case ModalsType.COMMERCIAL_OPTIN:
			return {
				modalType,
				config: {
					iconProps: {
						name: 'heart_plain',
						bgcolor: Theme.colors.cta_main
					},
					titleProps: {
						text: commercialOptinSettings.title,
						color: Theme.colors.text_main
					},
					descriptionProps: {
						text: commercialOptinSettings.description
					}
				},
				Component: <CommercialOptin {...metadata} />
			}
		case ModalsType.COMMERCIAL_OPTIN_CONFIRM:
			return {
				modalType,
				config: {
					iconProps: {
						name: 'check_round',
						bgcolor: Theme.colors.success_green
					},
					titleProps: {
						text: playerData.ui.thank_you_optin,
						color: Theme.colors.text_main
					},
					descriptionProps: {
						text: playerData.ui.thank_you_optin_description
					}
				}
			}
		case ModalsType.WINNING_INSTANT_SUBSCRIPTION:
			return {
				modalType,
				config: {
					iconProps: {
						name: 'thumb',
						bgcolor: Theme.colors.success_green
					},
					titleProps: {
						text: playerData.ui?.winning_instant?.participated_title,
						color: Theme.colors.text_main
					},
					descriptionProps: {
						text: playerData.ui?.winning_instant?.participated_description
					}
				}
			}
		case ModalsType.WINNING_INSTANT_TIMESUP:
			return {
				modalType,
				config: {
					iconProps: {
						name: 'clock',
						bgcolor: Theme.colors.red()
					},
					titleProps: {
						text: playerData.ui?.interactions?.time_elapsed.title,
						color: Theme.colors.text_main
					},
					descriptionProps: {
						text: playerData.ui?.interactions?.time_elapsed.description
					}
				}
			}
		case ModalsType.WINNING_INSTANT_WINNER:
			return {
				modalType,
				config: {
					customIcon: <PositionIcon>{metadata.position}</PositionIcon>,
					titleProps: {
						text: playerData?.ui?.winning_instant.winner_title,
						color: Theme.colors.text_main
					},
					customDescription: <WinnersDescription {...metadata} />,
					showFireworks: true,
					onClose: metadata.onClose
				},
				Component: <RewardsList {...metadata} />
			}
		case ModalsType.WINNING_INSTANT_WRONG_ANSWER:
			return {
				modalType,
				config: {
					iconProps: {
						name: 'sad_face',
						bgcolor: Theme.colors.red()
					},
					titleProps: {
						text: playerData?.ui?.winning_instant.loser_title,
						color: Theme.colors.text_main
					},
					descriptionProps: {
						text: playerData.ui?.winning_instant.loser_description
					},
					onClose: metadata.onClose
				},
				Component: <WinningInstantRightAnswer {...metadata} />
			}
		case ModalsType.WINNING_INSTANT_LOSER:
			return {
				modalType,
				config: {
					iconProps: {
						name: 'sad_face',
						bgcolor: Theme.colors.red()
					},
					titleProps: {
						text: playerData?.ui?.winning_instant.loser_answered_title,
						color: Theme.colors.text_main
					},
					descriptionProps: {
						text: playerData.ui?.winning_instant.loser_answered_description
					},
					onClose: metadata.onClose
				}
			}
		case ModalsType.CONVERSATIONAL_QUIT_REPLAY:
			return {
				modalType,
				config: {
					iconProps: {
						name: 'heart_plain',
						bgcolor: Theme.colors.cta_main
					},
					titleProps: {
						text: playerData.ui.conversational_replay.popin.thanks,
						color: Theme.colors.text_main
					},
					onClose: metadata.onClose
				},
				Component: <ConversationalQuitReplay {...metadata} />,
				shouldOpenOnce: true
			}
		case ModalsType.FIRST_NAME:
			return {
				modalType,
				config: {
					iconProps: {
						name: 'users',
						bgcolor: Theme.colors.cta_main
					}
				},
				Component: <FirstNameContent {...metadata} />
			}
		case ModalsType.RGPD_OPT_OUT:
			return {
				modalType,
				config: {
					iconProps: {
						name: 'users',
						bgcolor: Theme.colors.cta_main
					},
					titleProps: {
						text: playerData.ui.tos_opt_out.title,
						color: Theme.colors.text_main
					},
					descriptionProps: {
						text: playerData.ui.tos_opt_out.description
					}
				},
				Component: <RGPDOptOut {...metadata} />
			}
		case ModalsType.RGPD_OPT_OUT_FORM:
			const ui = metadata.ui
			return {
				modalType,
				config: {
					iconProps: {
						name: ui.icon,
						bgcolor: Theme.colors.cta_main,
						largeIcon: metadata.largeIcon
					},
					titleProps: {
						text: ui.label,
						color: Theme.colors.text_main
					},
					descriptionProps: {
						text: ui.description
					}
				},
				Component: <RGPDOptOutForm {...metadata} ui={ui} />
			}
	}
}
