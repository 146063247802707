import { getShutterBuild } from '@components/Notifiers/Shutters/library'
import { ShutterContextState, ShuttersActionType } from './constants'

export const shuttersReducer = (state: ShutterContextState, action: any): ShutterContextState => {
	switch (action.type) {
		case ShuttersActionType.OPEN_SHUTTER:
			return { shutterOpen: true, currentShutter: getShutterBuild(action.shutterType, action.metadata) }
		case ShuttersActionType.CLOSE_SHUTTER:
			return { shutterOpen: false }
		default:
			return state
	}
}
