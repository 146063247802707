import React from 'react'
import { CardDescription, CardItemContainer, CardLabel, ChevronIconContainer, classes, LabelContainer } from './style'
import Icon, { IconUnion } from '../Icon'
import Theme from '@styles/theme'
import CircleIcon from '../CircleIcon'

interface CardItemProps {
	icon: IconUnion
	label?: string
	description?: string
	onClick?: (e: any) => void
	hasChevron?: boolean
}

const CardItem: React.FC<CardItemProps> = ({ icon, label, description, hasChevron, onClick }) => {
	return (
		<CardItemContainer className={classes.root} onClick={onClick}>
			<CircleIcon icon={icon} color={Theme.colors.white()} />
			<LabelContainer>
				<CardLabel>{label}</CardLabel>
				<CardDescription>{description}</CardDescription>
			</LabelContainer>
			{hasChevron && (
				<ChevronIconContainer>
					<Icon name="chevron" width="16px" height="16px" fill={Theme.colors.cta_main} />
				</ChevronIconContainer>
			)}
		</CardItemContainer>
	)
}

export const cardItemClasses = classes

export default CardItem
