import React, { useEffect } from 'react'
import BottomBar from '@components/StatusManager/LiveAndReplay/components/BottomBar'
import Header from '@components/StatusManager/LiveAndReplay/components/Header'
import LiveProducts from '@components/StatusManager/LiveAndReplay/components/LiveProducts'
import Timeline from '@components/StatusManager/LiveAndReplay/components/Timeline'
import ConversationalNotification from '@components/StatusManager/LiveAndReplay/components/ConversationalNotification'
import { mapDispatchToProps } from '@helpers/wrappers/withReduxStore'
import { State } from '@stores/index'
import cx from 'classnames'
import dynamic from 'next/dynamic'
import { connect } from 'react-redux'
import { classes, LivePlayerContainer, LivePlayerContent, LivePlayerShadows, livePlayerclasses } from '../style'
import { GenericPlayerTemplateProps } from '../constant'
import WinningInstantButton from '../../components/WinningInstantButton'
import PausePlayButton from '../../components/PausePlayButton'
import Panels from '@components/Notifiers/Panels'
import Drawer from '@components/Notifiers/Drawers'
import { useNotificationsContext } from '@helpers/contexts/notifications'
import Unmute from '../../components/Unmute'
import { usePlayerContext } from '@helpers/contexts/player'
import { DisplayModeNotifier } from '../../style'
import Shutters from '@components/Notifiers/Shutters'
import { mobilePortraitClasses } from './classes'
import Chat from '../../components/Chat'
import { ChatContainer } from './style'

const Livestream = dynamic(() => import('@components/StatusManager/LiveAndReplay/components/Livestream'), {
	ssr: false
})

const mapStateToProps = (state: State, props: GenericPlayerTemplateProps) => {
	return { ...state, ...props }
}

const MobilePortraitTemplate: React.FC<GenericPlayerTemplateProps> = ({
	/** @Refs */
	trackerRef,
	livestreamRef,
	timelineRef,
	chatboxRef,
	/** @Booleans */
	isCinema,
	isStarted,
	isMuted,
	isWriting,
	isTranslated,
	isReduced,
	isLive,
	isReplay,
	isReplayOrFinishing,
	isDragged,
	hasConversationalReplay,
	/** @Setters */
	onCinema,
	onDrag,
	onWrite,
	onPause,
	onPlay,
	onPlayOnReady,
	onLiveStreamPlay,
	onSeekTo,
	/** @Values */
	playerData,
	products
}) => {
	const { drawerOpen, currentDrawer, shutterOpen, currentShutter } = useNotificationsContext()
	const { isPlayed, isReady, playOnReady, hasPlayedOnReady, isVertical, fakeStatus, replayChatDeactivated } =
		usePlayerContext()

	useEffect(() => {
		if (isReady && playOnReady && !hasPlayedOnReady && !isPlayed) {
			onPlayOnReady()
		}
	}, [isPlayed, isReady, playOnReady])

	return (
		<LivePlayerContainer className={(livePlayerclasses.root, mobilePortraitClasses.root)} isReduced={isReduced}>
			<Shutters isOpen={shutterOpen}>
				{currentShutter?.Component && React.cloneElement(currentShutter.Component)}
			</Shutters>
			{!isReduced && (
				<>
					<Drawer isOpen={drawerOpen}>
						{currentDrawer?.Component && React.cloneElement(currentDrawer.Component)}
					</Drawer>
					{isMuted && <Unmute onPlayClick={onPlay} playerIsUnmuted={!isMuted} playerIsReady={isReady} />}
					<WinningInstantButton trackerRef={trackerRef} buttonTop={81} />
				</>
			)}
			<Livestream pipEnabled innerRef={livestreamRef} onPlaying={onLiveStreamPlay} />
			<LivePlayerShadows
				height={['-webkit-fill-available', '100vh', '100%']}
				className={cx('ui', {
					[classes.replay]: isReplayOrFinishing,
					[classes.visible]: !isMuted || isStarted,
					[classes.cinema]: isCinema
				})}
			>
				<Header separator actions={['CLOSE_PLAYER', 'OTHERS']} />
				{!isReduced && <PausePlayButton isDragged={isDragged} onPause={onPause} onPlay={onPlay} />}
				<LivePlayerContent
					className={cx({
						[classes.translatorLive]: isLive || fakeStatus === 'live',
						[classes.translatorReplay]: isReplayOrFinishing,
						[classes.isNotTranslatedByKeyboard]: isWriting,
						[classes.isTranslated]: !isWriting && isTranslated
					})}
				>
					{!replayChatDeactivated && (
						<ChatContainer translated={!isLive && isTranslated} isLive={isLive} hidden={isCinema}>
							<Chat
								noChatInput
								hasScrollShadow
								floating
								onWrite={onWrite}
								chatRef={chatboxRef}
								pinningPosition={isLive ? 'top' : null}
							/>
						</ChatContainer>
					)}
					<LiveProducts />
					<BottomBar
						onWriting={() => onWrite(true)}
						onUnWriting={() => onWrite(false)}
						isMobile={true}
						isTranslated={isTranslated}
						onCinemaClick={onCinema}
						isCinema={isCinema}
						products={products}
					/>
				</LivePlayerContent>
				{isReplay && hasConversationalReplay && <ConversationalNotification isHidden={isReduced} />}
				{isReplay && fakeStatus !== 'live' && (
					<Timeline innerRef={timelineRef} seekTo={onSeekTo} setIsDragged={onDrag} isMobile={true} />
				)}
			</LivePlayerShadows>
			{!isReduced && <Panels />}
			{!isVertical && !isReduced && (
				<DisplayModeNotifier isMobile={true} isHorizontal={true} isLandscape={false}>
					<div>{playerData.ui.turn_mobile_portrait}</div>
				</DisplayModeNotifier>
			)}
		</LivePlayerContainer>
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(MobilePortraitTemplate)
