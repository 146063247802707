import { css } from '@emotion/react'
import Theme from '@styles/theme'

export default {
	optinText: css`
		font-family: ${Theme.fontFamilies.Second};
		font-weight: ${Theme.fontWeights.regular};
		line-height: 140%;
	`,
	optinCheck: css`
		border-radius: 4px;
		border: 1px solid;
		cursor: pointer;
		> i {
			opacity: 0;
		}
		${Theme.squaredEdges};
	`,

	optinCheckIsChecked: css`
		> i {
			opacity: 1;
		}
	`
}
