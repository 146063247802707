import Theme from '@styles/theme'

import styled from '@emotion/styled'

export const Network = styled.div`
	width: 16px;
	height: 16px;

	&:active {
		opacity: 0.5;
	}
	transition: opacity 600ms ${Theme.eases.outExpo} 0ms;
	cursor: pointer;
	@media ${Theme.media(0)} {
		&:hover {
			opacity: 0.5;
		}
	}
`

export const NetworkContainer = styled.div<{ number: number }>`
	display: grid;
	grid-template-columns: repeat(${({ number }) => number}, 1fr);
	grid-gap: 30px;
`
