import styled from '@emotion/styled'
import { css } from '@emotion/react'
import Theme from '@styles/theme'
import { BaseFlex } from '@styles/common'
import { firstNameDrawerClasses } from '../SharedNotifiersContent/FirstNameContent/style'
import { pollClasses } from '../SharedNotifiersContent/PollContent/style'

const PREFIX = 'drawer'

export const drawerClasses = {
	root: PREFIX,
	content: `${PREFIX}-content`
}
interface OpenProps {
	isOpened: boolean
}

const wrapperOpened = (isOpened) =>
	isOpened
		? css`
				pointer-events: all;
			`
		: null

const overlayOpened = (isOpened) =>
	isOpened
		? css`
				opacity: 1;
			`
		: null

const contentOpened = (isOpened) =>
	isOpened
		? css`
				transform: translateY(0);
			`
		: null

export const DrawerWrapper = styled(BaseFlex)<OpenProps>`
	position: absolute;
	pointer-events: none;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: ${Theme.zIndices.popin - 1};
	width: 100%;
	height: 100%;
	align-items: flex-end;
	flex-wrap: wrap;
	${({ isOpened }) => wrapperOpened(isOpened)}
`

export const Overlay = styled.div<OpenProps>`
	opacity: 0;
	background: ${Theme.colors.black(0.8)};
	transition: opacity 600ms ${Theme.eases.outExpo} 0ms;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 100%;
	${({ isOpened }) => overlayOpened(isOpened)}
`

export const DrawerContent = styled(BaseFlex)<OpenProps>`
	border-top-left-radius: 30px;
	border-top-right-radius: 30px;
	transition: transform 600ms ${Theme.eases.outExpo} 0ms;
	background-color: ${Theme.colors.white()};
	transform: translateY(101%);
	width: 100%;
	justify-content: center;
	padding: 0 ${Theme.spacing(6)};
	.${firstNameDrawerClasses.root} {
		width: 100%;
	}
	.${pollClasses.root} {
		width: 100%;
	}
	${({ isOpened }) => contentOpened(isOpened)}
`

export const DrawerTopBar = styled.div`
	position: absolute;
	top: 10px;
	width: 50px;
	height: 3px;
	background-color: ${() => Theme.colors.black(0.1)};
	border-radius: 100px;
	cursor: pointer;
`
