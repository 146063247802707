import Cta from '@components/Micro/Cta'
import styled from '@emotion/styled'
import { BaseFlex } from '@styles/common'
import Theme from '@styles/theme'

const PREFIX = 'quit-replay'

export const quitReplayClasses = {
	root: PREFIX,
	boxWrapper: `${PREFIX}-box-wrapper`,
	boxIcon: `${PREFIX}-box-icon`,
	boxText: `${PREFIX}-box-text`,
	boxButton: `${PREFIX}-box-button`,
	desktopLandscape: `${PREFIX}-desktop-landscape`,
	quitButton: `${PREFIX}-quit-button`,
	question: `${PREFIX}-question`,
	description: `${PREFIX}-description`
}

export const ConversationalContent = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	border: 2px solid ${() => Theme.colors.text_main};
	border-radius: 20px;
	padding: 20px 50px 30px 50px;
	& > * {
		text-align: center;
	}
`

export const QuitReplayContentWrapper = styled(BaseFlex)`
	flex-wrap: wrap;
	text-align: left;
	margin-top: ${Theme.spacing(6)};
	width: 100%;
	&.${quitReplayClasses.desktopLandscape} {
		.${quitReplayClasses.boxWrapper} {
			padding: ${Theme.spacing(4)};
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			border-width: 1px;

			.${quitReplayClasses.boxIcon} {
				margin-bottom: unset;
			}

			.${quitReplayClasses.boxButton} {
				margin-top: 0;
			}

			.${quitReplayClasses.boxText} {
				text-align: start;

				.${quitReplayClasses.question} {
					font-size: 12px;
				}

				.${quitReplayClasses.description} {
					margin-top: 4px;
				}
			}
		}

		.${quitReplayClasses.quitButton} {
			width: auto;
			margin: 40px auto 0 auto;
		}
	}
`

export const Question = styled.div`
	font-size: 14px;
	font-weight: 700;
	line-height: 18px;
	color: ${() => Theme.colors.text_main};
	font-family: ${() => Theme.fontFamilies.Main};
`
export const ConversationalDescription = styled.div`
	font-size: 12px;
	line-height: 14px;
	color: ${() => Theme.colors.text_second};
	font-family: ${() => Theme.fontFamilies.Second};
	margin-top: 10px;
`

export const StartConversationButton = styled.div`
	margin-top: 20px;
	border-radius: 8px;
	background-color: ${() => Theme.colors.cta_main};
	padding: 4px 10px;
	color: #fff;
	font-size: 13px;
	font-family: ${() => Theme.fontFamilies.Main};
	font-weight: ${() => Theme.fontWeights.bold};
	line-height: 22px;
	width: fit-content;
	align-self: center;
	cursor: pointer;

	i {
		margin: 6px 0;
	}
`

export const QuitReplayButton = styled(Cta)`
	margin-top: 30px;
`

export const ConversationIcon = styled.div`
	height: 40px;
	width: 40px;
	border-radius: 50%;
	align-self: center;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 15px;
	background-color: ${Theme.colors.white()};
	box-shadow: inset 0 0 0 1px ${Theme.colors.black(0.1)};
`
