import { css } from '@emotion/react'
import Theme from '@styles/theme'
import styled from '@emotion/styled'

const spaceDesktop = 2
const spaceTablet = 1.25
const spaceMobile = 0.5

export default {
	el: css`
		position: relative;
		transform: translate3d(0, 0, 0);
		cursor: pointer;
		width: calc((100% / 2) - (${spaceMobile}rem / 2));
		margin-right: ${spaceMobile}rem;
		&:nth-of-type(2n) {
			margin-right: 0;
		}

		@media ${Theme.media(0)} {
			width: calc((100% / 4) - (${spaceTablet}rem / 1.33));
			margin-right: ${spaceTablet}rem;
			&:nth-of-type(2n) {
				margin-right: ${spaceTablet}rem;
			}
			&:nth-of-type(3n) {
				margin-right: ${spaceTablet}rem;
			}
			&:nth-of-type(4n) {
				margin-right: 0;
			}
		}

		@media ${Theme.media(0)} {
			width: calc((100% / 4) - (${spaceDesktop}rem / 1.33));
			margin-right: ${spaceDesktop}rem;
			&:nth-of-type(2n) {
				margin-right: ${spaceDesktop}rem;
			}
			&:nth-of-type(3n) {
				margin-right: ${spaceDesktop}rem;
			}
			&:nth-of-type(4n) {
				margin-right: 0;
			}
		}
	`,

	imageIsOutOfStock: css`
		opacity: 0.4;
	`,

	outOfStock: css`
		background-color: ${Theme.colors.red()};
		color: ${Theme.colors.white()};
		position: absolute;
		right: 10px;
		bottom: 15px;
		font-size: 10em;
		font-family: ${Theme.fontFamilies.Main};
		font-weight: ${Theme.fontWeights.bold};
		text-transform: uppercase;
		padding: 5px 10px;
		border-radius: 10px;
	`,

	twoByColumns: css`
		@media ${Theme.media(0)} {
			width: calc((100% / 2) - (${spaceMobile * 2}rem / 2)) !important;
			margin-right: ${spaceMobile * 2}rem !important;
			&:nth-of-type(2n) {
				margin-right: 0 !important;
			}
			margin-bottom: 30px;
		}
	`,

	image: css`
		border-radius: 10px;
		overflow: hidden;
		position: relative;
		transform: translate3d(0, 0, 0);
		border: 1px solid ${Theme.colors.black(0.04)};
	`,

	imageBtn: css`
		/* border-radius: 10px; */
		overflow: hidden;
		transition: opacity 2000ms ${Theme.eases.outExpo} 400ms;
		&:active {
			opacity: 0.5;
			transition: opacity 0ms ${Theme.eases.outExpo} 0ms;
		}
		//isCurrentItem
	`,

	isCurrentItem: css`
		opacity: 0.5;
	`,

	index: css`
		font-family: ${Theme.fontFamilies.Second};
		font-weight: ${Theme.fontWeights.regular};
	`,

	status: css`
		font-family: ${Theme.fontFamilies.sansSerif};
		font-weight: ${Theme.fontWeights.bold};
		text-transform: uppercase;
		position: relative;
		border-radius: 15px;
		padding: 4px 7px 3px 20px;
	`,

	statusIcon: css`
		position: absolute;
		left: 5px;
		top: 50%;
		transform: translateY(-50%);
	`,

	title: css`
		font-family: ${Theme.fontFamilies.Main};
		font-weight: ${Theme.fontWeights.bold};
		line-height: 130%;
		font-size: 14px;
		line-height: 18px;
	`,

	price: css`
		font-family: ${Theme.fontFamilies.Second};
		font-weight: ${Theme.fontWeights.regular};
	`,

	notif: css`
		position: absolute;
		right: 12px;
		top: 12px;
		z-index: 1;
		border-radius: 10px;
		border: none;
	`
}

export const Prices = styled.div`
	display: flex;
	align-items: center;
	margin-top: ${Theme.spacing(2)};
`

const PriceStyle = styled.div`
	font-size: 12px;
	line-height: 14px;
	font-family: ${Theme.fontFamilies.Second};
	font-weight: ${Theme.fontWeights.regular};
	color: ${Theme.colors.text_second};
`
export const Price = styled(PriceStyle)<{ isCrossedOut: boolean }>`
	${({ isCrossedOut }) =>
		isCrossedOut &&
		css`
			font-weight: ${Theme.fontWeights.bold};
			color: ${Theme.colors.text_main};
			border: 1px solid ${Theme.colors.text_main};
			border-radius: 20px;
			padding: 4px 5px;
		`};
`
export const CrossOutPrice = styled(PriceStyle)`
	text-decoration: line-through;
	margin-left: ${Theme.spacing(2)};
`
