import styled from '@emotion/styled'
import { css } from '@emotion/react'
import Theme from '@styles/theme'
import { BaseAuthorName, BaseMessageText } from '../style'

const isAdminStyle = (isAdmin) =>
	isAdmin &&
	css`
		padding: 2px 5px;
		border-radius: 4px;
		margin-right: 5px;
	`

const handleShadow = (isBlock) =>
	isBlock
		? css`
				text-shadow: none;
			`
		: css`
				text-shadow: 0px 0px 4px ${Theme.colors.black(0.5)};
			`

export const AuthorName = styled(BaseAuthorName)<{ backgroundColor?; isAdmin; isBlock }>`
	font-family: ${Theme.fontFamilies.Second};
	background-color: ${({ backgroundColor }) => backgroundColor || null};
	line-height: 120%;
	${({ isAdmin }) => isAdminStyle(isAdmin)}
	${({ isBlock }) => handleShadow(isBlock)};
`

export const MessageText = styled(BaseMessageText)`
	line-height: 130%;
	width: 100%;
	${({ isBlock }) => handleShadow(isBlock)}
	a {
		word-wrap: break-word;
		text-decoration: underline;
		color: ${Theme.colors.white()};
	}
`

export const MessageLine = styled.div<{ isBlock? }>`
	padding: ${({ isBlock }) => isBlock && 0};
	padding-left: 8px;
	text-shadow: 0px 0px 4px ${Theme.colors.black(0.5)};
	border-radius: 15px;
`
export const BaseMessageWrapper = styled.div<{ isBlock? }>`
	&:last-child {
		margin-bottom: 0px;
	}
	margin-bottom: ${({ isBlock }) => isBlock && '0px'};
`
