import React, { useState, useImperativeHandle, useEffect } from 'react'
import { Flex, Box } from 'rebass'
import Style from './style'
import Theme from '@styles/theme'
import Icon from '@components/Micro/Icon'
import Countries from './countries.json'
import Prefixes from './prefixes.json'
import Utils from '@utils/index'
import { css } from '@emotion/react'

const PhoneField: React.FC<{
	innerRef?: any
	label?: string
	onChange?: any
	onEnter?: any
	hasError?: boolean
	isDisabled?: boolean
	isHidden?: boolean
	requiredLabel?: string
	className?: string
	defaultNumber?: string
	defaultCountryCode?: string
}> = ({
	label,
	onChange,
	onEnter,
	hasError,
	innerRef,
	isDisabled = false,
	isHidden = false,
	requiredLabel,
	className,
	defaultNumber,
	defaultCountryCode
}) => {
	const { navigatorPrefix, navigatorCountryCode } = getDefaults()
	const [prefix, setPrefix] = useState(
		defaultCountryCode ? Prefixes[defaultCountryCode] : navigatorPrefix ? navigatorPrefix : '33'
	)

	const [number, setNumber] = useState(defaultNumber ? defaultNumber.replace(`+${prefix}`, '') : '')
	const [countryCode, setCountryCode] = useState(defaultCountryCode || navigatorCountryCode)
	const [isFocus, setIsFocus] = useState(false)

	const prefixChangeHandler = (e) => {
		const prefix = e.target.value
		const c = options.find((opt) => opt.prefix === prefix).code
		setPrefix(prefix)
		setCountryCode(c)
	}

	const valueChangeHandler = (e) => {
		const number = e.target.value
		setNumber(number)
	}

	useEffect(() => {
		onChange(number, countryCode)
	}, [number, countryCode])

	useImperativeHandle(
		innerRef,
		() => ({
			reset() {
				setNumber('')
			}
		}),
		[]
	)

	const onKeyUp = (e) => {
		if (e.keyCode === 13) {
			if (onEnter) {
				onEnter()
			}
		}
	}

	const onFocus = () => setIsFocus(true)
	const onBlur = () => setIsFocus(false)

	const options = allOptions()

	return (
		<Box className={className} width={1} mb={20} css={isHidden ? Style.isHidden : null}>
			<Box
				css={[Style.inputLabel, hasError ? Style.errorText : null]}
				fontSize={'10em'}
				mb={10}
				color={Theme.colors.text_main}
			>
				{label}
			</Box>
			<Box
				css={[
					Style.inputWrap,
					hasError ? Style.error : null,
					isDisabled ? Style.isDisabled : null,
					isFocus ? Style.isFocus : null,
					Theme.squaredEdges
				]}
			>
				<Flex
					css={[Style.prefix, Theme.squaredEdges]}
					backgroundColor={Theme.colors.background}
					justifyContent="center"
					alignItems="center"
				>
					<Box as={'select'} value={prefix} onChange={prefixChangeHandler} css={Style.selector}>
						{options.map((item, i) => (
							<option key={`prefix-${i}`} value={item.prefix}>
								{item.country} ({item.prefix})
							</option>
						))}
					</Box>
					<Box
						css={[Style.prefixValue, hasError ? Style.errorText : null]}
						height={[11]}
						fontSize={'13em'}
						color={Theme.colors.text_main}
					>
						+{prefix}
					</Box>
					<Box width={[14]} height={[9]} css={Style.chevron} ml="3px">
						<Icon name={'chevron'} r={90} width="100%" height="100%" fill={Theme.colors.text_main} />
					</Box>
				</Flex>
				<Flex
					as="input"
					inputMode={'tel'}
					name="tel"
					type="text"
					autoComplete="tel"
					value={number}
					onChange={valueChangeHandler}
					onKeyUp={onKeyUp}
					onFocus={onFocus}
					onBlur={onBlur}
					css={[
						Style.input,
						css`
							color: ${Theme.colors.text_main};
						`,
						hasError ? Style.errorText : null
					]}
				/>
			</Box>
			{requiredLabel && (
				<Box
					css={[
						Style.required,
						css`
							font-family: ${Theme.fontFamilies.Second};
							font-weight: ${Theme.fontWeights.regular};
						`
					]}
					color={Theme.colors.text_second}
					fontSize={'11em'}
					textAlign="right"
					mt={'10px'}
				>
					* {requiredLabel}
				</Box>
			)}
		</Box>
	)
}

export default PhoneField

const getDefaults = () => {
	if (navigator.language) {
		const parts = navigator.language.split('-')
		const country = parts[parts.length - 1]
		const up = country.toUpperCase()
		const target = Prefixes[up] ? up : 'FR'
		return { navigatorPrefix: Prefixes[target], navigatorCountryCode: target }
	}
	return { navigatorPrefix: null, navigatorCountryCode: null }
}

const allOptions = () => {
	const optionsUnordered = Object.keys(Prefixes).map((code) => ({
		code: code,
		prefix: Prefixes[code],
		country: Countries[code]
	}))

	return Utils.sortByKey(optionsUnordered, 'country')
}
