import Theme from '@styles/theme'
import styled from '@emotion/styled'
import { DisplayMode } from '@helpers/contexts/player'
import { PanelContentContainer, widthByDisplayMode } from '../../style'

export const ProductDescriptionWrapper = styled.div`
	display: grid;
	gap: ${Theme.spacing(6)};
	justify-items: center;
	align-items: flex-start;
	width: 100%;
	margin-top: 0;
	.slider-control-centerleft,
	.slider-control-centerright {
		display: none;
	}
	.slider-control-bottomcenter > ul {
		top: -20px !important;
	}
	.paging-item {
		&.active {
			background: ${Theme.colors.black()};
			border: 1px solid white;
		}
		svg {
			display: none;
		}
		background: ${Theme.colors.white()};
		border-radius: 100%;
		width: 9px;
		height: 9px;
		margin: 0 ${Theme.spacing(1)};
		border: 1px solid black;
	}
`

export const ProductTitleWrapper = styled(PanelContentContainer)`
	display: grid;
`

export const ProductImageWrapper = styled.div<{ displayMode: DisplayMode }>`
	display: flex;
	width: 100%;

	@media ${Theme.media(0)} {
		border-radius: 20px;
		${() => Theme.squaredEdges}

		${({ displayMode }) => widthByDisplayMode(displayMode)}
	}
	overflow-x: hidden;
`

export const ProductInfosWrapper = styled(PanelContentContainer)`
	display: grid;
	justify-items: center;
	gap: ${Theme.spacing(5)};
	margin-bottom: ${Theme.spacing(2)};
`

export const ProductTitle = styled.div`
	font-family: ${() => Theme.fontFamilies.Main};
	font-weight: ${() => Theme.fontWeights.bold};
	line-height: 130%;
	width: 100%;
	margin-top: ${Theme.spacing(5)};
	color: ${() => Theme.colors.text_main};
	font-size: 20em;
`
