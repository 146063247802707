import { css } from '@emotion/react'
import Theme from '@styles/theme'

export default {
	date: css`
		font-family: ${Theme.fontFamilies.Second};
		font-weight: ${Theme.fontWeights.regular};
		text-transform: uppercase;
	`,

	number: css`
		font-family: ${Theme.fontFamilies.Main};
		font-weight: ${Theme.fontWeights.bold};
		transition: opacity 2000ms ${Theme.eases.outExpo} 0ms;
	`,

	numberWrap: css`
		border-radius: 15px;
		width: 70px;
		height: 70px;
		margin: 0 5px;
		@media ${Theme.media(0)} {
			margin: 0 10px;
		}
	`,

	unit: css`
		font-family: ${Theme.fontFamilies.Second};
		font-weight: ${Theme.fontWeights.regular};
		text-transform: uppercase;
	`,

	calendarPopin: css`
		position: fixed;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 100;
	`,

	close: css`
		cursor: pointer;
		position: absolute;
		right: 15px;
		top: 15px;
	`,

	stickyBar: css`
		position: fixed;
		bottom: 0;
		left: 0;
		background-color: ${Theme.colors.white()};
		border-top: 1px solid ${Theme.colors.black(0.1)};
		z-index: 90;
		transform: translateY(100%);
		transition: transform 600ms ${Theme.eases.outExpo} 0ms;
	`,

	stickyIsShown: css`
		transform: translateY(0);
	`,

	popOverlay: css`
		position: absolute;
		background-color: ${Theme.colors.black(0.5)};
		width: 100%;
		height: 100%;
		cursor: pointer;
	`,

	title: css`
		font-family: ${Theme.fontFamilies.Main};
		font-weight: ${Theme.fontWeights.bold};
		line-height: 140%;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		overflow: hidden;
		@media ${Theme.media(0)} {
			line-height: 130%;
			-webkit-line-clamp: 1;
		}
	`,

	calendarPopinContent: css`
		background-color: ${Theme.colors.white()};
		width: 90%;
		max-width: 600px;
		border-radius: 20px;
		text-align: center;
		position: relative;
	`,

	icon: css`
		border-radius: 100%;
	`,

	popTitle: css`
		font-family: ${Theme.fontFamilies.Main};
		font-weight: ${Theme.fontWeights.bold};
		font-size: 20px;
	`,

	popText: css`
		font-family: ${Theme.fontFamilies.Second};
		font-weight: ${Theme.fontWeights.regular};
		line-height: 140%;
		font-size: 14px;
	`,

	poplabel: css`
		font-family: ${Theme.fontFamilies.Second};
		font-weight: ${Theme.fontWeights.bold};
		text-transform: uppercase;
		font-size: 10px;
		text-align: left;
	`,

	hasSubscribed: css`
		opacity: 1;
		border: 1px solid ${Theme.colors.green()} !important;
		color: ${Theme.colors.green()} !important;
		pointer-events: none;
		svg {
			fill: ${Theme.colors.green()} !important;
		}
	`,

	isBlinking: css`
		opacity: 0.1;
	`,

	discount: css`
		border-radius: 15px;
		line-height: 130%;
	`,

	discountHasSubscribed: css`
		background-color: ${Theme.colors.green()};
		color: #fff;
	`,

	ctaIsSubmiting: css`
		opacity: 0.5;
		pointer-events: none;
	`,

	discountRate: css`
		font-family: ${Theme.fontFamilies.Main};
		font-weight: ${Theme.fontWeights.bold};
		line-height: 120%;
	`,

	discountText: css`
		font-family: ${Theme.fontFamilies.Second};
		font-weight: ${Theme.fontWeights.regular};
		line-height: 130%;
	`
}
