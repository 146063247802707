import React from 'react'
import { mapDispatchToProps } from '@helpers/wrappers/withReduxStore'
import { State } from '@stores/index'
import { connect } from 'react-redux'
import { Box } from 'rebass'
import Style, {
	panelsClasses,
	CloseButton,
	HeaderTitle,
	PanelBody,
	PanelContent,
	PanelHeader,
	PanelHeaderContainer,
	PanelWrapper,
	HeaderContentWrapper
} from './style'
import Theme from '@styles/theme'
import Icon from '@components/Micro/Icon'
import { TransitionGroup } from 'react-transition-group'
import { useNotificationsContext } from '@helpers/contexts/notifications'
import { TranslateXAndFadeTransition } from '@styles/transitions/TranslateXAndFadeTransition'
import { usePlayerContext } from '@helpers/contexts/player'

interface Props {
	storeIsFullscreen?: any
	isLandscape?: boolean
}

const mapStateToProps = (state: State, props: Props) => {
	return { ...state, ...props }
}

const PanelsManager: React.FC<Props> = ({ storeIsFullscreen, isLandscape }) => {
	/**
	 * @States
	 */
	const { closeLastPanel, panelsQueue } = useNotificationsContext()
	const { displayMode } = usePlayerContext()

	/**
	 * @Methods
	 */
	const onClose = () => {
		closeLastPanel()
	}

	return (
		<PanelWrapper isOpen={panelsQueue.length > 0} isLandscape={isLandscape} className={panelsClasses.wrapper}>
			<PanelContent className={panelsClasses.root} hasPanels={panelsQueue.length > 0} isLandscape={isLandscape}>
				<PanelHeaderContainer height={[60, 66]} isFullScreen={storeIsFullscreen}>
					<TransitionGroup>
						{panelsQueue?.map((panel, i) => {
							return (
								<TranslateXAndFadeTransition
									key={`back-${i}`}
									timeout={400}
									in={panelsQueue.length !== 0}
									classNames="back"
									translate="25%"
									unmountOnExit
								>
									<PanelHeader
										css={[
											i === panelsQueue.length - 1 ? Style.movableItemIsCurrent : null,
											i < panelsQueue.length - 1 ? Style.movableItemIsPassed : null
										]}
									>
										<HeaderContentWrapper displayMode={displayMode}>
											{!panel?.hideCloseButton && (
												<CloseButton onClick={onClose}>
													<Box
														width={30}
														height={30}
														p={'9px'}
														css={[Style.close, Theme.squaredEdges]}
													>
														<Icon
															name="chevron"
															r={180}
															fill={Theme.colors.text_main}
															width="100%"
															height="100%"
														/>
													</Box>
												</CloseButton>
											)}
											<HeaderTitle>{panel.backText}</HeaderTitle>
										</HeaderContentWrapper>
									</PanelHeader>
								</TranslateXAndFadeTransition>
							)
						})}
					</TransitionGroup>
				</PanelHeaderContainer>
				<TransitionGroup>
					{panelsQueue?.map((panel, i) => (
						<TranslateXAndFadeTransition
							key={`content-${i}`}
							timeout={400}
							classNames="content"
							translate="25%"
						>
							<PanelBody
								isFullScreen={storeIsFullscreen}
								css={[
									i === panelsQueue.length - 1 ? Style.movableItemIsCurrent : null,
									i < panelsQueue.length - 1 ? Style.movableItemIsPassed : null
								]}
							>
								{panel.Component}
							</PanelBody>
						</TranslateXAndFadeTransition>
					))}
				</TransitionGroup>
			</PanelContent>
		</PanelWrapper>
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(PanelsManager)
