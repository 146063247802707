import styled from '@emotion/styled'
import { BaseFlex } from '@styles/common'
import { RegularSecondText } from '@styles/SharedTextComponents'
import Theme from '@styles/theme'

export const RatingsWrapper = styled(BaseFlex)`
	align-items: center;
	justify-content: center;
`

export const StarWrapper = styled.div`
	cursor: pointer;
	padding-right: ${Theme.spacing(5)};

	&:last-of-type {
		padding-right: 0;
	}

	.star {
		position: relative;
	}
`

export const StarText = styled(RegularSecondText)`
	font-size: 12px;
	line-height: 14px;
	position: absolute;
	bottom: -30px;
	right: 50%;
	transform: translateX(50%);
	white-space: nowrap;
	text-align: center;
	color: ${Theme.colors.grey1};
`
