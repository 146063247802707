import React, { useState } from 'react'
import { Box } from 'rebass'
import Style from './style'
import Utils from '@utils/index'
import Theme from '@styles/theme'
import { css } from '@emotion/react'

import getConfig from 'next/config'
const { publicRuntimeConfig } = getConfig()
const NEXT_PUBLIC_IMGIX_DOMAIN = publicRuntimeConfig.NEXT_PUBLIC_IMGIX_DOMAIN

const Placeholder: React.FC<{
	sizes?: any
	media?: any
	style?: any
	color?: any
	fit?: string
	overlayOpacity?: number
	overlayColor?: any
	isAnimated?: boolean
	children?: any
	hoverScale?: boolean
	loading?: any
	onLoad?: any
	fade?: boolean
}> = ({
	sizes,
	media,
	style,
	color = Theme.colors.black(0.04),
	fit = 'cover',
	children,
	hoverScale = false,
	onLoad,
	loading = 'lazy', // eager or lazy
	fade = true
}) => {
	//

	const [isLoaded, setIsLoaded] = useState(false)

	sizes = !Utils.is.isArray(sizes[0]) ? [sizes] : sizes
	const paddings = []

	for (const size of sizes) {
		paddings.push(100.0 / (size[0] / size[1]) + '%')
	}

	let $picture = null

	const loadHandler = () => {
		if (onLoad) {
			onLoad()
		}
		setIsLoaded(true)
	}

	if (!media) {
		media = {
			url: 'medias/default/cover-1600x900.jpg',
			alt: 'default image'
		}
	}

	$picture = (
		<>
			<Box as="picture">
				<Box
					as="img"
					src={NEXT_PUBLIC_IMGIX_DOMAIN + '/' + media.url + '?auto=format'}
					css={[
						Style.img,
						fade ? Style.fade : null,
						isLoaded ? Style.isLoaded : null,
						css`
							object-fit: ${fit};
						`,
						Theme.squaredEdges
					]}
					loading={loading}
					onLoad={loadHandler}
					alt={media.alt}
					className="media"
				/>
			</Box>
		</>
	)

	return (
		<>
			<Box
				backgroundColor={color}
				css={[Style.wrap, style, hoverScale ? Style.hoverScale : null, Theme.squaredEdges]}
				className="placeholder-wrapper"
			>
				<Box css={Style.placeholder} pt={paddings}></Box>
				{$picture}
				{children}
			</Box>
		</>
	)
}

export default Placeholder
