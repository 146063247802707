import { css } from '@emotion/react'
import Theme from '@styles/theme'
import styled from '@emotion/styled'

const PREFIX = 'live-products'

export const classes = {
	root: PREFIX,
	card: `${PREFIX}-card`,
	container: `${PREFIX}-container`,
	transitionGroup: `${PREFIX}-transition-group`,
	transition: `${PREFIX}-transition`,
	image: `${PREFIX}-image`,
	description: `${PREFIX}-description`,
	replay: `${PREFIX}-replay`,
	opened: `${PREFIX}-opened`,
	closed: `${PREFIX}-closed`,
	title: `${PREFIX}-title`,
	price: `${PREFIX}-price`,
	block: `${PREFIX}-block`,
	button: `${PREFIX}-button`
}

export const FloatingPrice = styled.div`
	position: absolute;
	background-color: ${Theme.colors.black()};
	color: ${Theme.colors.white()};
	font-size: 10px;
	line-height: 14px;
	font-weight: 700;
	font-family: ${Theme.fontFamilies.Main};
	text-align: center;
	width: auto;
	min-width: 34px;
	padding: 0 5px;
	border-radius: 56px;
	left: 50%;
	transform: translate(-50%, 0);
	white-space: nowrap;
	text-transform: uppercase;
	bottom: ${Theme.spacing(1)};
	z-index: 8;
`

export const Prices = styled.div`
	display: flex;
`
const PriceStyle = styled.div`
	font-size: 12px;
	line-height: 14px;
	font-family: ${Theme.fontFamilies.Second};
	font-weight: ${Theme.fontWeights.regular};
	color: ${Theme.colors.text_second};
`

export const Price = styled(PriceStyle)<{ isCrossedOut: boolean }>`
	${({ isCrossedOut }) =>
		isCrossedOut &&
		css`
			font-weight: ${Theme.fontWeights.bold};
			color: ${Theme.colors.text_main};
			border: 1px solid ${Theme.colors.text_main};
			border-radius: 20px;
			padding: 4px 5px;
		`};
`
export const CrossOutPrice = styled.span`
	text-decoration: line-through;
	margin-left: ${Theme.spacing(2)};
	color: ${Theme.colors.text_second};
`

export const LiveProductContainer = styled.div`
	box-sizing: border-box;
	position: fixed;
	display: flex;
	align-items: center;
	width: 100%;
	height: 94px;
	padding: ${Theme.spacing(3)};
	bottom: 0;
	z-index: ${Theme.zIndices.liveProducts};
	pointer-events: all;
	@media ${Theme.media(0)} {
		bottom: 6px;
		position: absolute;
	}

	&.${classes.replay} {
		bottom: ${Theme.spacing(5)};
	}
	& .${classes.transitionGroup} {
		width: 100%;
		padding: 0 ${Theme.spacing(3)};
	}
`
export const LiveProductWrapper = styled.div`
	box-sizing: border-box;
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: nowrap;
	height: 70px;
	width: 100%;
	background-color: ${Theme.colors.white()};
	border-radius: 10px;
	box-shadow: 0 0 4px ${Theme.colors.black(0.15)};
	z-index: ${Theme.zIndices.liveProducts};
	${() => css([Theme.squaredEdges])};
	&.${classes.opened} {
		& .${classes.description} {
			transition: all 800ms ${Theme.eases.inOutSine};
			opacity: 1;
			max-width: 350px;

			& .${classes.title}, .${classes.price} {
				transition: opacity 200ms linear 400ms;
				opacity: 1;
			}
		}
		${FloatingPrice} {
			opacity: 0;
		}
	}
	&.${classes.closed} {
		& .${classes.description} {
			transition: all 800ms ${Theme.eases.inOutSine};
			opacity: 0;
			max-width: 0px;

			& .${classes.title}, .${classes.price} {
				transition: opacity 200ms linear;
				opacity: 0;
			}
		}
		${FloatingPrice} {
			transition: all 200ms ${Theme.eases.inOutSine} 600ms;
			opacity: 1;
		}
	}
	&.${classes.block} {
		box-shadow: none;
		border-radius: 0px;
		border-bottom: 1px solid ${Theme.colors.black(0.1)};
		min-height: 70px;
	}
`
export const LiveProductDescription = styled.div`
	display: flex;
	flex-grow: 1;
	overflow: hidden;
	align-items: center;
`
export const LiveProductText = styled.div`
	flex: 1 1 80%;
	cursor: pointer;
	&:active {
		opacity: 0.5;
	}
	& .${classes.title} {
		font-weight: ${Theme.fontWeights.bold};
		line-height: 120%;
		overflow: hidden;
		text-overflow: ellipsis;
		-webkit-line-clamp: 2;
		display: -webkit-box;
		-webkit-box-orient: vertical;
	}
	& .${classes.price} {
		font-family: ${Theme.fontFamilies.Second};
		font-weight: ${Theme.fontWeights.regular};
		margin-top: ${Theme.spacing(1)};
	}
`
export const LiveProductButtonContainer = styled.div`
	display: flex;
	flex-shrink: 0;
	justify-content: flex-end;
	align-items: center;
	margin: 0 ${Theme.spacing(2)};
`
export const LiveProductButton = styled.div`
	width: fit-content;
	height: fit-content;
	white-space: break-spaces !important;
	background-color: ${() => Theme.colors.cta_main};
	color: ${Theme.colors.white()};
	font-size: 12em;
	text-align: center;
	padding: 8px 10px;
	border-radius: 8px;
	cursor: pointer;
	font-family: ${Theme.fontFamilies.Second};
	font-weight: ${Theme.fontWeights.bold};
	transition: opacity 600ms ${Theme.eases.outExpo} 0ms;
	@media ${Theme.media(3)} {
		&:hover {
			opacity: 0.5;
		}
		white-space: unset !important;
	}
	${() => css([Theme.squaredEdges])}
`
