import { PanelBuild, PanelsType } from './constants'
import Catalog from './Catalog'
import Product from './Product'
import WinningInstantForm from './WinningInstantForm'
import React from 'react'
import Chat from '@components/StatusManager/LiveAndReplay/components/Chat'
import PollPanel from './PollPanel'

interface PanelMetadata {
	data?: any
	chatRef?: any
	isMobile?: boolean
	onWrite?: (value: any) => void
	back?: string
	title?: string
}

export const getPanelBuild = (panelType, metadata: PanelMetadata): PanelBuild => {
	var { data, title, back, chatRef, onWrite, isMobile } = metadata
	switch (panelType) {
		case PanelsType.CATALOG:
			return {
				panelType,
				Component: <Catalog title={title} />,
				backText: back,
				products: data
			}
		case PanelsType.PRODUCT:
			return {
				panelType,
				productId: data.uuid,
				Component: <Product product={data} />,
				backText: back
			}
		case PanelsType.WINNING_INSTANT_FORM:
			return {
				panelType,
				Component: <WinningInstantForm />,
				backText: back
			}
		case PanelsType.LANDSCAPE_HORIZONTAL_CHAT:
			return {
				panelType,
				Component: (
					<Chat
						fieldBorderActive
						onWrite={onWrite}
						chatRef={chatRef}
						pinningPosition={isMobile ? 'bottom' : 'top'}
					/>
				),
				backText: back,
				hideCloseButton: true
			}
		case PanelsType.POLL:
			return {
				panelType,
				Component: <PollPanel />,
				backText: back
			}
	}
}
