import styled from '@emotion/styled'
import Theme from '@styles/theme'
import Spinner from '@assets/img/spinner-black.gif'
import { LivePlayerContainer as BasePlayerContainer } from '../style'
import { liveProductsClasses } from '../../components/LiveProducts'
import { chatFieldClasses } from '../../components/ChatField/Modular/style'
import { desktopPortraitClasses } from './classes'

export const LeftPanel = styled.div<{ width: number }>`
	display: none;
	height: 100%;
	max-width: ${({ width }) => (width ? width + 'px' : 'auto')};
	z-index: ${Theme.zIndices.panels - 1};
	@media ${Theme.media(0)} {
		display: flex;
		opacity: 0;
		z-index: 0;
		pointer-events: none;
		background-color: ${Theme.colors.white(1)};
		transform: translateX(100%);
		position: relative;
		overflow: hidden;
		box-shadow: 0px 0 2px 0 ${Theme.colors.black(0.3)};
	}
	&.${desktopPortraitClasses.chatIsVisible} {
		@media ${Theme.media(0)} {
			opacity: 1;
			pointer-events: all;
			transform: translateX(0);
			transition:
				transform 800ms ${Theme.eases.outExpo},
				opacity 800ms ${Theme.eases.outExpo};
			display: flex;
			flex-direction: column;
		}
	}
	&.${desktopPortraitClasses.replaySpinner} {
		background: ${Theme.colors.white()} url(${Spinner}) no-repeat center;
	}

	& .${chatFieldClasses.root} {
		min-height: 70px;
	}
`

export const LeftPanelTitle = styled.div`
	box-sizing: border-box;
	border-bottom: 1px solid ${Theme.colors.black(0.1)};
	background-color: ${Theme.colors.white()};
	padding-left: 25px;
	font-family: ${Theme.fontFamilies.Main};
	font-weight: ${Theme.fontWeights.bold};
	font-size: 20px;
	width: 100%;
	min-height: 66px;
	flex-wrap: wrap;
	align-items: center;
	display: none;
	z-index: ${Theme.zIndices.header};
	@media ${Theme.media(0)} {
		display: flex;
	}
`

export const LivePlayerContainer = styled(BasePlayerContainer)`
	.${liveProductsClasses.opened} {
		.${liveProductsClasses.description} {
			max-width: unset !important;
		}
		.${liveProductsClasses.button} {
			white-space: nowrap;
		}
	}
`
