import React, { useState, useRef, useEffect, useImperativeHandle } from 'react'
import { mapDispatchToProps } from '@helpers/wrappers/withReduxStore'
import { State } from '@stores/index'
import { connect } from 'react-redux'
import { Box } from 'rebass'
import Style, { classes } from './style'
import Theme from '@styles/theme'
import Utils from '@utils/index'
import Ease from '@tools/ease'
import { css } from '@emotion/react'

import Sizes from '@tools/sizes'
import Raf from '@tools/raf'
import { classGenerator } from '@styles/sharedLogics'
import { usePlayerContext } from '@helpers/contexts/player'

interface Props {
	innerRef?: any
	dispatch?: any
	width?: any
	bottomShift?: number
	heightPercent?: number
	storeDatas?: any
	className?: string
}

const mapStateToProps = (state: State, props: Props) => {
	return { ...state, ...props }
}

const Canvas: React.FC<Props> = ({
	innerRef,
	width = 121,
	bottomShift = 95,
	heightPercent = 0.75,
	storeDatas,
	className
}) => {
	const { displayMode, isVertical } = usePlayerContext()

	const el = useRef(null)
	const canvas = useRef(null)
	const [sizes, setSizes] = useState(Sizes.sizes)
	const [isStarted, setIsStarted] = useState(false)
	let ctx = null
	let interval = null
	let hearts = []

	const heartSVG =
		'M18,2c-1-1.1-2.4-2-4.2-2s-3.1,0.7-4.1,1.3C8.8,0.7,7.5,0,5.7,0S2.4,0.8,1.5,2 C0.5,3.3,0,4.9,0,6.5c0,2.8,1.1,4.9,2.9,6.5c1.8,1.6,3.9,2.9,6.5,4.6l0.3,0.3l0.5-0.3c2.4-1.6,4.7-2.9,6.5-4.6 c1.8-1.6,2.9-3.7,2.9-6.5C19.7,4.9,19,3.3,18,2z'

	const SVG_PATH = new Path2D(heartSVG)

	useImperativeHandle(
		innerRef,
		() => ({
			like() {
				createHeart(true)
			}
		}),
		[]
	)

	const fakeLike = () => {
		return window.setTimeout(
			() => {
				createHeart(false)
				window.clearTimeout(interval)
				return fakeLike()
			},
			Utils.math.rand(200, Utils.math.rand(4000, 8000))
		)
	}

	const sizesHandler = (s) => {
		setSizes(s)
	}

	const createHeart = (isUser) => {
		const x = width - 46
		const y = sizes.height * heightPercent - bottomShift - 30

		hearts.push({
			x,
			y,
			isUser,
			c: Theme.colors.red(),
			speed: Utils.math.rand(4, 8),
			osc: Utils.math.rand(-200, 200),
			easeX: new Ease({
				pos: 100,
				duration: 150,
				easing: 'expoOut'
			}),
			easeY: new Ease({
				pos: y,
				duration: 150,
				easing: 'expoOut'
			})
		})
	}

	const getHeartColor = (isUser) => {
		if (displayMode === 'MOBILE_LANDSCAPE' && isVertical) {
			return isUser ? Theme.colors.red() : Theme.colors.pink()
		}
		return isUser ? Theme.colors.white() : Theme.colors.red()
	}

	const drawHeart = (item, status) => {
		if (status < 1.1) {
			const scale = 1 + status * 0.75
			ctx.fillStyle = getHeartColor(item.isUser)
			// ctx.shadowColor = Theme.colors.black(0.4)
			// ctx.shadowBlur = 2
			ctx.save()
			ctx.translate(item.easeX.pos, item.easeY.pos)
			ctx.scale(scale, scale)
			ctx.fill(SVG_PATH)
			ctx.restore()
		} else {
			Utils.deleteFromArray(item, hearts)
		}
	}

	useEffect(() => {
		ctx = canvas.current.getContext('2d')

		const rafHandler = (t) => {
			if (ctx) {
				ctx.clearRect(0, 0, width, sizes.height * heightPercent - bottomShift)
				let i = hearts.length
				while (i--) {
					const h = hearts[i]
					const status = 1 - h.easeY.pos / (sizes.height * heightPercent - bottomShift - 30)
					h.easeX.set(h.x + status * Math.sin((h.easeY.pos - h.osc) / 100) * 20)
					h.easeY.set(h.easeY.pos - h.speed)
					h.easeX.update(t)
					h.easeY.update(t)
					drawHeart(h, status)
				}
			}
		}

		if (storeDatas.event.event.status === 'live' && !isStarted) {
			setIsStarted(true)

			Raf.subscribe(rafHandler)
			Sizes.subscribe(sizesHandler)

			interval = fakeLike()
		}

		window.addEventListener('focus', () => {
			hearts = []
		})

		return () => {
			Raf.unsubscribe(rafHandler)
			Sizes.unsubscribe(sizesHandler)
		}
	}, [])

	return (
		<Box
			ref={el}
			className={classGenerator(classes.root, className)}
			css={[
				Style.el,
				storeDatas.event.event.status === 'replay' || storeDatas.event.event.status === 'finishing'
					? Style.hidden
					: null,
				css`
					height: ${sizes.height * heightPercent};
				`
			]}
		>
			<canvas css={Style.canvas} ref={canvas} width={width} height={sizes.height * heightPercent - bottomShift} />
		</Box>
	)
}

export const likesBoxClasses = classes

export default connect(mapStateToProps, mapDispatchToProps)(Canvas)
