import styled from '@emotion/styled'
import Theme from '@styles/theme'
import { css } from '@emotion/react'
import { Flex } from 'rebass'
import { iconCtaClasses } from '@components/Micro/IconCta'
import { likesBoxClasses } from '../components/LiveActions/LikeButton/LikesBox'

const PREFIX = 'player-wrapper'

export const livePlayerclasses = {
	root: PREFIX
}

const reducedHiddenClasses = `
	.stories,
	.liveproducts,
	.header,
	.bottom-bar,
	.nav,
	.chatbox,
	.pinned-message,
	.likes-box,
	.timeline
`

const isReducedWrapperStyle = (isReduced) => {
	return isReduced
		? css`
				width: 100%;
				height: 100%;
				grid-template-columns: 1fr;
			`
		: null
}

const isReducedContainerStyler = (isReduced) => {
	return isReduced
		? css`
				${reducedHiddenClasses} {
					opacity: 0;
				}
				.ui:before,
				.ui:after {
					display: none;
				}
			`
		: null
}

const landscapeStyle = (isLandscape, isMobile, isVertical) => {
	return isLandscape
		? isMobile && !isVertical
			? css`
					display: flex;
					width: 100%;
				`
			: css`
					display: flex;
					aspect-ratio: 16/9;
				`
		: css`
				display: grid;
			`
}

const verticalStyle = (isVertical) => {
	return isVertical
		? css`
				flex-direction: column;
			`
		: null
}

const verticalLandscapeStyle = (isVertical, isLandscape) => {
	return isVertical && isLandscape
		? css`
				width: 100%;
			`
		: null
}

export const classes = {
	root: PREFIX,
	replay: `${PREFIX}-replay`,
	visible: `${PREFIX}-visible`,
	cinema: `${PREFIX}-cinema`,
	shadow: `${PREFIX}-shadow`,
	shadowHideActions: `${PREFIX}-shadow-hide-actions`,
	translatorLive: `${PREFIX}-translatorLive`,
	translatorReplay: `${PREFIX}-translatorReplay`,
	isTranslated: `${PREFIX}-isTranslated`,
	isNotTranslatedByKeyboard: `${PREFIX}-isNotTranslatedByKeyboard`,
	desktopChatIsShown: `${PREFIX}-desktopChatIsShown`
}

export const LiveWrapper = styled.div<{ isLandscape?: boolean; isHorizontal?: boolean; isMobile?: boolean }>`
	position: absolute;
	display: flex;
	align-items: center;
	width: 100%;
	height: 100%;
	padding: ${({ isLandscape, isHorizontal, isMobile }) => (isLandscape && isHorizontal && isMobile ? 0 : '0 20px')};
	@media ${Theme.media(0)} {
		height: 100vh;
		position: relative;
	}
`

interface LiveContentWrapperProps {
	height?: number
	isReduced?: boolean
	isLandscape?: boolean
	isVertical?: boolean
	isMobile?: boolean
}

export const LiveContentWrapper = styled.div<LiveContentWrapperProps>`
	position: relative;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
	height: ${({ height, isLandscape }) => (height ? `${height}px` : isLandscape ? '100%' : 'auto')};
	grid-template-columns: repeat(3, 1fr);
	flex-direction: ${({ isVertical }) => (isVertical ? 'column' : 'row')};

	${({ isReduced }) => isReducedWrapperStyle(isReduced)}
	${({ isLandscape, isMobile, isVertical }) => landscapeStyle(isLandscape, isMobile, isVertical)}
	${({ isVertical }) => verticalStyle(isVertical)}
	${({ isVertical, isLandscape }) => verticalLandscapeStyle(isVertical, isLandscape)}
`

interface LivePlayerContainerProps {
	isReduced?: boolean
	width?: string
	panelsOpened?: boolean
	isLandscape?: boolean
}

const livePlayerContainerLandscapeStyle = (isLandscape) => {
	return isLandscape
		? css`
				flex-shrink: 0;
			`
		: css`
				height: 100%;
				flex-shrink: unset;
			`
}

export const LivePlayerContainer = styled.div<LivePlayerContainerProps>`
	width: ${({ width, panelsOpened }) => (panelsOpened ? 'auto' : width || '100%')};
	position: relative;
	overflow: hidden;
	box-shadow: 0px 0 2px 0 ${Theme.colors.black(0.3)};
	z-index: 1;
	background: ${Theme.colors.black(1)};
	flex-grow: 1;
	${({ isReduced }) => isReducedContainerStyler(isReduced)}
	${({ isLandscape }) => livePlayerContainerLandscapeStyle(isLandscape)}
`

export const LivePlayerShadows = styled(Flex)<{ isReplay?: boolean }>`
	position: absolute;
	left: 0;
	bottom: 0;
	top: 0;
	opacity: 0;
	pointer-events: none;
	height: 100%;
	width: 100%;
	transition: opacity 200ms cubic-bezier(0.52, 0.16, 0.24, 1);
	&:before,
	&:after {
		position: absolute;
		content: '';
		width: 100%;
		left: 0;
		z-index: 1;
		pointer-events: none;
	}
	&:before {
		height: 60px;
		top: 0;
		background: linear-gradient(180deg, ${Theme.colors.black(0.5)} 0%, ${Theme.colors.black(0)} 100%);
	}
	&:after {
		height: 400px;
		bottom: 0;
		background: linear-gradient(180deg, ${Theme.colors.black(0)} 0%, ${Theme.colors.black(0.5)} 100%);
	}

	&.${classes.replay} {
		&:before {
			height: 120px;
			top: 0;
			background: linear-gradient(180deg, ${Theme.colors.black(0.8)} 0%, ${Theme.colors.black(0)} 100%);
		}
	}
	&.${classes.visible} {
		opacity: 1;
		pointer-events: all;
	}
	&.${classes.shadowHideActions} {
		&:before,
		&::after,
		& .${iconCtaClasses.root}, .${likesBoxClasses.root} {
			opacity: 0;
			pointer-events: none;
		}
	}
`

export const LivePlayerContent = styled(Flex)`
	width: 100%;
	&.${classes.translatorLive} {
		transition: transform 400ms cubic-bezier(0.52, 0.16, 0.24, 1);
		z-index: 2;
		transform: translate3d(0, 84px, 0);
		pointer-events: none;
		@media ${Theme.media(0)} {
			transform: translate3d(0, 88px, 0);
		}
	}
	&.${classes.translatorReplay} {
		transition: transform 400ms cubic-bezier(0.52, 0.16, 0.24, 1);
		z-index: 2;
		transform: translate3d(0, 114px, 0);
		pointer-events: none;
		@media ${Theme.media(0)} {
			transform: translate3d(0, 118px, 0);
		}
	}
	&.${classes.isNotTranslatedByKeyboard} {
		transition: transform 400ms cubic-bezier(0.52, 0.16, 0.24, 1);
		z-index: 2;
		pointer-events: none;
	}
	&.${classes.isTranslated} {
		transition: transform 400ms cubic-bezier(0.52, 0.16, 0.24, 1);
		transform: translate3d(0, 0, 0);
		@media ${Theme.media(0)} {
			transform: translate3d(0, 0, 0);
		}
	}
`
