import Constants from '@constants'
import Bus from '@helpers/bus'
import { mapDispatchToProps } from '@helpers/wrappers/withReduxStore'
import { State } from '@stores/index'
import { useDb } from 'helpers/contexts/dbContext'
import {
	Registration,
	Viewer,
	ViewerOptout,
	ViewerOptoutType,
	ViewerRegistrationResult,
	ViewerUnregisterResult,
	WinningInstantParticipation
} from 'models/stores'
import getConfig from 'next/config'
import { useRouter } from 'next/router'
import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { handleApiResponseErrors } from './fetchHelpers'
import bus from '@helpers/bus'

const { publicRuntimeConfig } = getConfig()
const NEXT_PUBLIC_API_ENDPOINT = publicRuntimeConfig.NEXT_PUBLIC_API_ENDPOINT

interface ViewerContext {
	currentViewer?: Viewer
	updateCurrentViewer: (updates: Partial<Viewer>, callback?: Function) => Promise<void>
	optoutViewer: (updates: ViewerOptout, type: ViewerOptoutType) => Promise<void>
	registerViewer: (
		firstName: string,
		phoneNumber: string,
		phoneCountryCode: string,
		hasCommercialOptin: boolean,
		path: string,
		eventId: string
	) => Promise<ViewerRegistrationResult>
	unregisterViewer: (
		eventId: string,
		phone: string,
		countryCode: string,
		hasCommercialOptout: boolean
	) => Promise<ViewerUnregisterResult>
	viewerRegistration: (eventId: string) => Registration | null
	setViewerIsBanned: (isBanned: boolean) => void
	viewerIsBanned: () => boolean
	getWIParticipation: (interactionId: string) => WinningInstantParticipation | null
	manageCookie: (cookieType: string, value: boolean) => void
	setViewerIsUnsubscribed: (unsubscribed: boolean) => void
	viewerIsUnsubscribed: boolean
}

const Context = createContext<ViewerContext | null>(null)

export const useViewer = () => useContext(Context)

interface ViewerProviderProps {
	storeConfig?: any
	children: any
}

const mapStateToProps = (state: State, props: ViewerProviderProps) => {
	return { ...state, ...props }
}

export const ViewerProvider: React.FC<ViewerProviderProps> = ({ children, storeConfig }) => {
	const { listenBannedUsers } = useDb()

	const [currentViewer, setCurrentViewer] = useState<Viewer>(null)
	const [isBanned, setIsBanned] = useState(false)
	const [isUnsubscribed, setIsUnsubscribed] = useState(false)

	const eventId = useRouter().query.id as string

	useEffect(() => {
		initViewer()
	}, [storeConfig.viewerId, eventId, currentViewer])

	const manageCookie = (cookieType: string, value: boolean) => {
		Bus.send(Constants.bus.player.manage_cookie, { cookieType, value })
	}

	const updateCurrentViewer = (updates: Partial<Viewer>): Promise<void> => {
		return fetch(
			`${NEXT_PUBLIC_API_ENDPOINT}/api/public/viewers/${currentViewer?.uid}?` +
				new URLSearchParams({
					domainId: currentViewer?.domainId,
					eventId: eventId
				}),
			{
				body: JSON.stringify(updates),
				headers: {
					'Content-Type': 'application/json'
				},
				method: 'PATCH'
			}
		)
			.then((r) => r.json())
			.then((json) => {
				if (!json?.errors) {
					const viewer = json as Viewer
					setCurrentViewer(viewer)
				} else {
					handleApiResponseErrors(json, currentViewer)
				}
			})
	}

	const optoutViewer = (updates: ViewerOptout, type: ViewerOptoutType): Promise<void> => {
		return fetch(
			`${NEXT_PUBLIC_API_ENDPOINT}/api/public/viewers/optout/${type}?` +
				new URLSearchParams({
					domainId: currentViewer.domainId
				}),
			{
				body: JSON.stringify(updates),
				headers: {
					'Content-Type': 'application/json'
				},
				method: 'PATCH'
			}
		).then((_) => {
			switch (type) {
				case 'phone':
					const updated = {
						...currentViewer,
						registrations: [],
						phoneCommercialOptIn: null,
						phoneNumber: null,
						phoneCountryCode: null
					}
					setCurrentViewer(updated)
					break
				case 'email':
					setCurrentViewer({
						...currentViewer,
						emailCommercialOptIn: null,
						email: null
					})
					break
			}
		})
	}

	const getViewer = (viewerId: string, domainId: string): Promise<Viewer> => {
		return fetch(
			`${NEXT_PUBLIC_API_ENDPOINT}/api/public/viewers/${viewerId}?` +
				new URLSearchParams({
					domainId: domainId,
					eventId: eventId
				}),
			{
				headers: {
					'Content-Type': 'application/json'
				},
				method: 'GET'
			}
		)
			.then((r) => r.json())
			.then((json) => {
				handleApiResponseErrors(json, currentViewer)
				const viewer = json as Viewer
				setCurrentViewer(viewer)
				return viewer
			})
	}

	const initViewer = () => {
		if (storeConfig?.domain && eventId && storeConfig?.viewerId && !currentViewer) {
			getViewer(storeConfig.viewerId, storeConfig.domain).then((viewer) => {
				bus.send(Constants.bus.player.viewer_loaded, viewer)
				listenBannedUsers(eventId, (snapshot) => {
					const isBanned = snapshot?.docs
						?.map((doc) => doc.data())
						.find((doc) => doc.viewer.id === viewer.uid)
					setIsBanned(!!isBanned)
				})
			})
		}
	}

	const registerViewer = (
		firstName: string,
		phoneNumber: string,
		phoneCountryCode: string,
		hasCommercialOptin: boolean,
		path: string,
		eventId: string
	): Promise<ViewerRegistrationResult> => {
		const callApi = () =>
			fetch(`${NEXT_PUBLIC_API_ENDPOINT}/api/public/events/${eventId}/registrations`, {
				body: JSON.stringify({
					eventId: eventId,
					viewerId: currentViewer.uid,
					hasCommercialOptin: hasCommercialOptin,
					phoneCountryCode: phoneCountryCode,
					phoneReadable: phoneNumber,
					phoneUsable: phoneNumber,
					domainId: currentViewer.domainId,
					viewerName: firstName,
					path: path
				}),
				headers: {
					'Content-Type': 'application/json'
				},
				method: 'POST'
			}).then((res) => res.json())

		if (currentViewer) {
			if (currentViewer.registrations.find((r) => r.eventId === eventId)) {
				return Promise.resolve({ _tag: 'ViewerAlreadyRegistered' })
			} else {
				return callApi().then((registration) => {
					if (registration.errors) {
						const alreadyRegistered = registration.errors.find(
							(e) => e.code === 'registration.already-registered'
						)
						const invalidPhoneNumber = registration.errors.find(
							(e) => e.code === 'registration.invalid-phone-number'
						)
						const noNotificationConfig = registration.errors.find(
							(e) => e.code === 'registration.no-notif-config'
						)

						if (alreadyRegistered) {
							return Promise.resolve({ _tag: 'ViewerAlreadyRegistered' })
						} else if (invalidPhoneNumber) {
							return Promise.resolve({ _tag: 'InvalidPhoneNumberError' })
						} else if (noNotificationConfig) {
							return Promise.resolve({ _tag: 'NoNotificationConfiguration' })
						}

						return Promise.reject(`Unknown error while registering ${JSON.stringify(registration)}`)
					} else {
						setViewerIsUnsubscribed(false)
						updateCurrentViewer({
							firstName: firstName,
							phoneCommercialOptIn: hasCommercialOptin,
							phoneNumber: phoneNumber,
							registrations: currentViewer.registrations.concat([registration]),
							phoneCountryCode: phoneCountryCode
						})

						return Promise.resolve({ _tag: 'ViewerSuccesfullyRegistered' })
					}
				})
			}
		} else {
			return Promise.reject('Not user to register')
		}
	}

	const unregisterViewer = (
		eventId: string,
		phone: string,
		countryCode: string,
		hasCommercialOptout: boolean
	): Promise<ViewerUnregisterResult> => {
		const apiCall = () =>
			fetch(
				`${NEXT_PUBLIC_API_ENDPOINT}/api/public/events/${eventId}/registrations?` +
					new URLSearchParams({
						phone: phone,
						commercialOptout: hasCommercialOptout.toString(),
						countryCode: countryCode
					}),
				{
					headers: {
						'Content-Type': 'application/json'
					},
					method: 'DELETE'
				}
			).then((r) => r.json())

		return apiCall().then((result) => {
			const filtered = currentViewer.registrations.filter((r) => r.eventId !== eventId)
			const commercialOptin = hasCommercialOptout ? false : currentViewer.phoneCommercialOptIn
			const phoneUpdated = hasCommercialOptout ? null : currentViewer.phoneNumber
			const countryCode = hasCommercialOptout ? null : currentViewer.phoneCountryCode
			setCurrentViewer({
				...currentViewer,
				registrations: filtered,
				phoneCommercialOptIn: commercialOptin,
				phoneNumber: phoneUpdated,
				phoneCountryCode: countryCode
			})

			return result.errors ? { _tag: 'ViewerUnregisteredError' } : { _tag: 'ViewerSuccesfullyUnregistered' }
		})
	}

	const viewerRegistration = (eventId: string): Registration | null => {
		return currentViewer?.registrations.find((e) => e.eventId === eventId)
	}

	const setViewerIsBanned = setIsBanned
	const viewerIsBanned = () => isBanned

	const viewerIsUnsubscribed = isUnsubscribed
	const setViewerIsUnsubscribed = setIsUnsubscribed

	const getWIParticipation = (interactionId: string): WinningInstantParticipation | null => {
		return currentViewer?.wiParticipations?.find((p) => p.winningInstantId === interactionId)
	}

	const values = useMemo(
		(): ViewerContext => ({
			currentViewer,
			updateCurrentViewer,
			unregisterViewer,
			registerViewer,
			viewerRegistration,
			setViewerIsBanned,
			viewerIsBanned,
			optoutViewer,
			getWIParticipation,
			manageCookie,
			setViewerIsUnsubscribed,
			viewerIsUnsubscribed
		}),
		[currentViewer, isBanned, viewerIsUnsubscribed, storeConfig]
	)

	return <Context.Provider value={values}>{children}</Context.Provider>
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewerProvider)
