import Icon from '@components/Micro/Icon'
import Theme from '@styles/theme'
import { useState } from 'react'
import { RatingsWrapper, StarText, StarWrapper } from './style'
interface StarProps {
	marked: boolean
	starId: number
	textValue: string
	selection: number
	onMouseOut
	onClick
	onMouseOver
}

const Star: React.FC<StarProps> = ({ marked, starId, textValue, selection, onMouseOut, onMouseOver, onClick }) => {
	const showText = () => {
		return `${selection}` === `${starId}` || (!selection && ['1', '5'].indexOf(`${starId}`) !== -1)
	}

	return (
		<StarWrapper onMouseOver={onMouseOver} onClick={onClick} onMouseOut={onMouseOut}>
			<div className="star" role="button">
				<Icon
					height={30}
					width={30}
					name={marked ? 'star_fill' : 'star_empty'}
					fill={marked ? Theme.colors.text_main : Theme.colors.white()}
					stroke={marked ? null : Theme.colors.text_main}
					strokeWidth="2px"
				></Icon>
				{textValue && showText() && <StarText color={Theme.colors.grey2}>{textValue}</StarText>}
			</div>
		</StarWrapper>
	)
}

interface StarRatingProps {
	textRatingValues?: Record<string, any>
	rating: number | null
	setRating: (rating: number) => void
}

const StarRating: React.FC<StarRatingProps> = ({ textRatingValues, rating, setRating }) => {
	const [selection, setSelection] = useState(0)

	const hoverOver = (val = 0) => setSelection(val)
	const onClick = (val) => setRating(val)

	return (
		<RatingsWrapper>
			{[1, 2, 3, 4, 5].map((_, i) => (
				<Star
					starId={i + 1}
					key={`rating-${i}`}
					marked={selection ? selection >= i + 1 : rating >= i + 1}
					textValue={textRatingValues && textRatingValues[i]}
					selection={selection || rating}
					onMouseOut={() => hoverOver(null)}
					onMouseOver={() => hoverOver(i + 1)}
					onClick={() => onClick(i + 1)}
				/>
			))}
		</RatingsWrapper>
	)
}

export default StarRating
