import styled from '@emotion/styled'

const PREFIX = 'text-with-url-and-phone'

export const classes = {
	link: `${PREFIX}-link`,
	phone: `${PREFIX}-phone`
}

export const SpanText = styled.span<{ selectable? }>`
	user-select: ${({ selectable }) => (selectable ? 'text' : 'none')};
`

export const DivText = styled.div<{ selectable?: boolean }>`
	user-select: ${({ selectable }) => (selectable ? 'text' : 'none')};
`

export const FormattedText = styled.div`
	display: flex;
	flex-direction: column;
`
