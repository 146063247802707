import { css } from '@emotion/react'
import Theme from '@styles/theme'

export default {
	el: css`
		/* border: 1px solid ${Theme.colors.black(0.3)}; */
		border-radius: 5px;
		cursor: pointer;
	`
}
