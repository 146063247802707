import React from 'react'
import Icon from '../Icon'
import { CheckBoxContainer, CheckBoxInput, CheckBoxLabel, classes } from './style'
import Theme from '@styles/theme'
import { classGenerator as cx } from '@styles/sharedLogics'

interface CheckBoxProps {
	value: boolean
	onClick: () => void
	children: any
	hasError?: boolean
	color?: string
}

const CheckBox: React.FC<CheckBoxProps> = ({ value, onClick, children, hasError, color }) => {
	const handleClick = () => {
		if (onClick) onClick()
	}

	return (
		<CheckBoxContainer className={cx(classes.root, { [classes.error]: hasError })} onClick={handleClick}>
			<CheckBoxInput color={Theme.colors.text_main}>
				{value && <Icon name="check" width="100%" height="100%" fill={Theme.colors.text_main} />}
			</CheckBoxInput>
			<CheckBoxLabel color={color}>{children}</CheckBoxLabel>
		</CheckBoxContainer>
	)
}

export const checkBoxClasses = classes

export default CheckBox
