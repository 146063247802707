export interface PlayerContext {
	playerData?: any
	isMobile?: boolean
	isDesktop?: boolean
	isTablet?: boolean
	isReady?: boolean
	isStreaming?: boolean
	isPlayed?: boolean
	isMuted?: boolean
	toFinish?: boolean // needed to know that event passed by 'finishing' status
	playOnReady?: boolean
	/**
	 * @eventStatus check
	 */
	isReplayOrFinishing?: boolean
	isReplay?: boolean
	isLive?: boolean
	isFinishing?: boolean
	isStarted?: boolean
	hasPlayedOnReady?: boolean
	/**
	 * @Setters
	 */
	setIsReady?: (isReady: boolean) => void
	setIsStreaming?: (isStreaming: boolean) => void
	setIsPlayed?: (isPlayed: boolean) => void
	setIsMuted?: (isMuted: boolean) => void
	setIsStarted?: (isStarted: boolean) => void
	setPlayerData?: (playerData: any) => void
	setPlayOnReady?: (playOnReady: boolean) => void
	handlePlay?: (livestreamRef) => void
	handlePause?: (livestreamRef) => void
	manageIVSAction?: (actionType: string) => void
}

export enum DeviceTypes {
	mobile = 'mobile',
	tablet = 'tablet',
	desktop = 'desktop'
}

export type VideoMode = 'portrait' | 'landscape'

export interface PlayerState {
	playerData?: any
	isReady?: boolean
	isStreaming?: boolean
	isPlayed?: boolean
	isMuted?: boolean
	isStarted?: boolean
	toFinish?: boolean
	playOnReady?: boolean
	hasPlayedOnReady: boolean
	eventStatus?: string
	lastEventStatus?: string
	hasConversationalReplay?: boolean
	videoMode?: VideoMode
}

export const playerInitialState: PlayerState = {
	isReady: false,
	isStreaming: false,
	isPlayed: false,
	isMuted: true,
	isStarted: false,
	toFinish: false,
	hasConversationalReplay: false,
	playerData: {
		ui: {},
		event: {},
		record: {},
		channel: {}
	},
	playOnReady: false,
	hasPlayedOnReady: false
}

export enum EventStatuses {
	LIVE = 'live',
	REPLAY = 'replay',
	FINISHING = 'finishing'
}

export enum PlayerActions {
	SET_IS_STREAMING = 'set_is_stream_player',
	SET_IS_PLAYED = 'set_is_played_player',
	SET_IS_MUTED = 'set_is_muted_player',
	SET_IS_STARTED = 'set_is_started',
	SET_IS_READY = 'set_is_ready',
	SET_PLAYER_DATA = 'set_player_data',
	SET_PLAY_ON_READY = 'set_play_on_ready',
	HANDLE_PLAY = 'handle_play_click',
	HANDLE_PLAY_ON_READY = 'handle_play_on_ready',
	HANDLE_PAUSE = 'handle_pause_click'
}

export enum IVSPlayerActions {
	BUFFERING = 'buffering',
	READY = 'ready',
	REBUFFERING = 'rebuffering',
	IDLE = 'idle',
	ERROR = 'error',
	PLAYING = 'playing',
	TEXT_METADATA_CUE = 'text_metadata_cue'
}
