import React, { useEffect, useRef, useState } from 'react'
import {
	AuthorName,
	AvatarWrapper,
	classes,
	MessageText,
	PinnedIconWrapper,
	PinnedMessageContent,
	PinnedMessageWrapper,
	ViewMoreComp
} from './style'
import Theme from '@styles/theme'
import Icon from '@components/Micro/Icon'
import Placeholder from '@components/Micro/Placeholder'
import TextWithUrlAndPhone from '@components/Micro/TextWithUrlAndPhone'
import { Message } from 'types/Message'
import { useBrandContext } from '@helpers/contexts/brands'
import { State } from '@stores/index'
import { connect } from 'react-redux'
import { mapDispatchToProps } from '@helpers/wrappers/withReduxStore'
import { classGenerator as cx } from '@styles/sharedLogics'

interface Props {
	pinnedMessage: Message
	isBlock?: boolean
	storeDatas?: Record<string, any>
}

const LARGE_HEIGHT_MOBILE_OFFSET = 53
const LARGE_HEIGHT_DESKTOP_OFFSET = 60

const mapStateToProps = (state: State, props: Props) => {
	return { ...state, ...props }
}

const PinnedMessage: React.FC<Props> = ({ pinnedMessage, isBlock, storeDatas }) => {
	/**
	 * @States
	 */
	const { brand } = useBrandContext()
	const [contentHeight, setContentHeight] = useState<number>(null)
	const [isLarge, setIsLarge] = useState<boolean>(false)
	const [isToggled, setIsToggled] = useState<boolean>(false)

	const contentRef: React.MutableRefObject<any> = useRef(null)

	/**
	 * @Methods
	 */

	const getReadMoreTranslations = (): string => {
		return isToggled ? storeDatas?.ui?.read_less : storeDatas?.ui?.read_more
	}

	/**
	 * @Effects
	 */

	useEffect(() => {
		if (contentRef?.current) {
			setContentHeight(contentRef.current.getBoundingClientRect()?.height)
		}
	}, [contentRef])

	useEffect(() => {
		if (contentHeight && contentHeight > (isBlock ? LARGE_HEIGHT_DESKTOP_OFFSET : LARGE_HEIGHT_MOBILE_OFFSET)) {
			setIsLarge(true)
		} else {
			setIsLarge(false)
		}
	}, [contentHeight])

	return (
		<PinnedMessageWrapper className={cx(classes.root, { [classes.block]: isBlock })} backgroundColor={brand.color}>
			<AvatarWrapper>
				<Placeholder sizes={[[100, 100]]} media={brand.avatar} color={Theme.colors.background} fit="cover" />
			</AvatarWrapper>
			<PinnedMessageContent ref={contentRef} isToggled={isToggled} isLarge={isLarge} isBlock={isBlock}>
				<AuthorName color={Theme.colors.white()} isBlock={isBlock}>
					{brand.name}
				</AuthorName>
				<MessageText color={Theme.colors.white()} isBlock={isBlock}>
					<TextWithUrlAndPhone
						text={pinnedMessage.value}
						fieldKey="pinned-message"
						showClickablePhone={true}
						isSelectable={true}
					/>
				</MessageText>
				<PinnedIconWrapper isBlock={isBlock}>
					<Icon name={'pinned'} r={0} width="100%" height="100%" fill={Theme.colors.white()} />
				</PinnedIconWrapper>
			</PinnedMessageContent>
			{isLarge && (
				<ViewMoreComp color={Theme.colors.white()} isBlock={isBlock} onClick={() => setIsToggled(!isToggled)}>
					{getReadMoreTranslations()}
				</ViewMoreComp>
			)}
		</PinnedMessageWrapper>
	)
}

export const pinnedMessageClasses = classes

export default connect(mapStateToProps, mapDispatchToProps)(PinnedMessage)
