import React from 'react'
import { Flex, Box } from 'rebass'
import Style from './style'
import Theme from '@styles/theme'
import Grid from '@components/Utils/Grid'

import { mapDispatchToProps } from '@helpers/wrappers/withReduxStore'
import { State } from '@stores/index'
import { connect } from 'react-redux'
import Placeholder from '@components/Micro/Placeholder'
import Bus from '@helpers/bus'
import Constants from '@constants'

interface Props {
	storeDatas?: any
}

const mapStateToProps = (state: State, props: Props) => {
	return { ...state, ...props }
}

const Header: React.FC<Props> = ({ storeDatas }) => {
	let $avatar = null
	let $title = null

	if (storeDatas) {
		$avatar = (
			<Placeholder
				sizes={[[100, 100]]}
				media={storeDatas.event.event.settings.branding.avatar}
				color={'rgba(0,0,0,0)'}
				fit="cover"
			/>
		)
		$title = storeDatas.event.event.settings.branding.brandName
	}

	const closeClickHandler = () => {
		Bus.send(Constants.bus.player.closed)
	}

	return (
		<Flex width={1} justifyContent="center" height={70} css={Style.el} backgroundColor={Theme.colors.cta_main}>
			<Grid
				justifyContent="center"
				flexWrap={'wrap'}
				alignContent={'center'}
				alignItems={'center'}
				className="prelive-header"
			>
				<Box width={51}>
					<Flex
						css={[Style.avatar, Theme.squaredEdges]}
						width={40}
						height={40}
						justifyContent={'center'}
						alignItems={'center'}
					>
						{$avatar}
					</Flex>
				</Box>
				<Flex width={'calc(100% - 81px)'} alignItems="center">
					<Box width={1} fontSize={'16em'} css={Style.title}>
						{$title}
					</Box>
				</Flex>
				<Box css={[Style.close, Theme.squaredEdges]} onClick={closeClickHandler} />
			</Grid>
		</Flex>
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
