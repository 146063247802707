import React from 'react'
import { ContentWrapper, TitleWrapper } from './style'
import Items from '@components/StatusManager/LiveAndReplay/components/Items'
import { usePlayerContext } from '@helpers/contexts/player'
import { useNotificationsContext } from '@helpers/contexts/notifications'
import { PanelsType } from '../constants'
import { PanelContentContainer } from '../style'

interface CatalogProps {
	title?: string
}

const Catalog: React.FC<CatalogProps> = ({ title }) => {
	const { playerData, displayMode } = usePlayerContext()
	const { panelsQueue } = useNotificationsContext()

	return (
		<ContentWrapper justifyContent="center" alignItems="flex-start">
			<PanelContentContainer displayMode={displayMode}>
				{title && <TitleWrapper>{title}</TitleWrapper>}
				{
					<Items
						panelActive
						showIndexes={true}
						showPrices={true}
						showStatuses={
							!(
								playerData.event.event.status === 'replay' ||
								playerData.event.event.status === 'finishing'
							)
						}
						products={panelsQueue.find((panel) => panel.panelType === PanelsType.CATALOG)?.products}
						twoByColumns={true}
						playerData={playerData}
					/>
				}
			</PanelContentContainer>
		</ContentWrapper>
	)
}

export default Catalog
