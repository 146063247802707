import styled from '@emotion/styled'
import { css } from '@emotion/react'
import Theme from '@styles/theme'
import Spinner from '@assets/img/spinner-white.gif'

const PREFIX = 'video-player'

export const videoPlayerClasses = {
	root: PREFIX
}

const isPlayedStyle = css`
	background: ${Theme.colors.black()};
	video {
		opacity: 1;
	}
`

const isPipStyle = css`
	position: fixed;
	z-index: ${Theme.zIndices.pip};
	box-shadow: 0 0 50px ${Theme.colors.black(0.4)};
	will-change: transform;
	background-size: 40px 40px;
	overflow: hidden;
	border-radius: 10px;
`

const shrinkedStyle = css`
	video {
		height: auto;
		top: 50%;
		transform: translateY(-50%);
	}
`

export const VideoPlayer = styled.video``

export const VideoPlayerWrapper = styled.div<{ isPlayed; isPip; hiddenPip; isShrinked; hasLetterbox }>`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 0;
	background: ${Theme.colors.black()} url(${Spinner}) no-repeat center;
	background-size: 40px 40px;
	display: ${({ hiddenPip }) => (hiddenPip ? 'none' : 'flex')};
	width: ${({ hasLetterbox }) => (hasLetterbox ? 'calc(100vh * 16 / 9)' : '100%')};
	margin: ${({ isPip }) => (isPip ? 'unset' : '0 auto')};

	video {
		opacity: 0;
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		pointer-events: none;
	}
	.vjs-control-bar,
	.vjs-loading-spinner,
	.vjs-error-display {
		display: none !important;
	}
	> div {
		border: none;
		outline: none;
	}

	${({ isPlayed }) => isPlayed && isPlayedStyle}
	${({ isPip }) => isPip && isPipStyle}
	${({ isShrinked }) => isShrinked && shrinkedStyle};
`
