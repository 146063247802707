import React, { useState } from 'react'
import Theme from '@styles/theme'
import { mapDispatchToProps } from '@helpers/wrappers/withReduxStore'
import { State } from '@stores/index'
import { connect } from 'react-redux'

interface Props {
	innerRef?: any
	dispatch?: (action) => void
	storeDatas?: any
}

const mapStateToProps = (state: State, props: Props) => {
	return { ...state, ...props }
}

const Themer: React.FC<Props> = ({ storeDatas }) => {
	const [customStylesheet, setCustomStylesheet] = useState(null)
	if (storeDatas) {
		Theme.colors.text_main = storeDatas.event.event.settings.branding.colors.textMainColor
		Theme.colors.text_second = storeDatas.event.event.settings.branding.colors.textSecondary
		Theme.colors.background = storeDatas.event.event.settings.branding.colors.backgroundColor
		Theme.colors.cta_main = storeDatas.event.event.settings.branding.colors.buttonMainColor
		Theme.colors.cta_second = storeDatas.event.event.settings.branding.colors.buttonSecondColor
		Theme.squaredEdges = storeDatas.event.event.settings.branding.squaredEdges
			? { borderRadius: '0px !important' }
			: null
		if (storeDatas.event.event.settings.branding.customCss && !customStylesheet) {
			const cssPath = `${process.env.NEXT_PUBLIC_CDN_DOMAIN}/medias/brandings/${storeDatas.event.event.settings.branding.uuid}/custom.css`
			const link = document.createElement('link')
			link.rel = 'stylesheet'
			link.type = 'text/css'
			link.href = cssPath
			document.head.appendChild(link)
			setCustomStylesheet(link)
		}
	}

	return <></>
}

export default connect(mapStateToProps, mapDispatchToProps)(Themer)
