import Spinner from '@assets/img/spinner.gif'
import { css } from '@emotion/core'

const Style = {
	spinning: css`
		background: url(${Spinner}) no-repeat center;
		background-size: 30px 30px;
	`
}

export default Style
