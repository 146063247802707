import React, { useState, useEffect } from 'react'
import { mapDispatchToProps } from '@helpers/wrappers/withReduxStore'
import { State } from '@stores/index'
import { connect } from 'react-redux'
import Icon from '@components/Micro/Icon'
import Theme from '@styles/theme'
import Bus from '@helpers/bus'
import Constants from '@constants'
import {
	NotificationWrapper,
	NewMessages,
	Ico,
	Description,
	Content,
	TextWrap,
	CtaWrap,
	Title,
	Subtitle,
	Cta
} from './style'
import { useConversationalReplayContext } from '@helpers/contexts/conversationalReplay'
import { usePlayerContext } from '@helpers/contexts/player'

const mapStateToProps = (state: State, props: Props) => {
	return { ...state, ...props }
}

interface Props {
	innerRef?: any
	dispatch?: any
	storeDatas?: any
	isHidden?: boolean
}

export const convReplayButtonClasses = {
	root: 'conversationReplayButton'
}

const ConversationalReplay: React.FC<Props> = ({ storeDatas, isHidden }) => {
	const [timer, setTimer] = useState(null)
	const [initialTimeout, setInitialTimeout] = useState(null)
	const [isExpanded, setIsExpanded] = useState(false)
	const [newMessages, setNewMessages] = useState(0)
	const [isAlreadyNotified, setIsAlreadyNotified] = useState(false)

	const { setHasStartedConversation, iAdvizeIsLoaded, setIsConversing } = useConversationalReplayContext()
	const { isLandscape, isDesktop } = usePlayerContext()

	const startTime = 5000
	const newMessagesLoopTime = 5000
	const defaultLoopTime = 60000
	const shownTime = 4000

	const expand = () => {
		setIsExpanded(true)
		window.setTimeout(() => {
			setIsExpanded(false)
		}, shownTime)
	}

	useEffect(() => {
		setInitialTimeout(
			window.setTimeout(() => {
				expand()
				setTimer(window.setInterval(expand, defaultLoopTime))
			}, startTime)
		)

		window.setInterval(() => {
			let reduced = document.querySelector('iframe[name="iAdvize chatbox reduced"]')
			if (reduced) {
				//@ts-ignore
				let unread = reduced.contentWindow.document.querySelector('div[class^="UnreadMessagesCountBadge"]')
				if (unread) {
					setNewMessages(parseInt(unread.textContent, 10))
				}
			}
		}, 1000)
	}, [])

	const chatboxNotifiedHandler = (datas) => {
		if (datas.unread) {
			setNewMessages(parseInt(datas.unread, 10))
		}
	}

	useEffect(() => {
		Bus.on(Constants.bus.library.iadvize_chatbox_notified, chatboxNotifiedHandler)
		if (newMessages !== 0 && !isAlreadyNotified) {
			setIsAlreadyNotified(true)
			window.clearTimeout(timer)
			window.setTimeout(() => {
				expand()
				setTimer(window.setInterval(() => expand(), defaultLoopTime))
			}, newMessagesLoopTime)
		}
		return () => {
			Bus.off(Constants.bus.library.iadvize_chatbox_notified, chatboxNotifiedHandler)
		}
	}, [newMessages])

	useEffect(() => {
		if (document.querySelector('iframe[name="iAdvize chatbox opened"]') && !!timer) {
			window.clearTimeout(timer)
			window.clearTimeout(initialTimeout)
		}
		if (document.querySelector('iframe[name="iAdvize chatbox reduced"]') && !timer) {
			setIsAlreadyNotified(false)
		}
	}, [isExpanded])

	const clickHandler = (e) => {
		e.preventDefault()
		e.stopPropagation()
		window.clearTimeout(initialTimeout)
		window.clearTimeout(timer)
		setIsExpanded(false)
		setHasStartedConversation(true)
		setIsConversing(true)
		Bus.send(Constants.bus.player.conversational_replay_clicked)
		// No timeout when on desktop landscape
		if (isDesktop && isLandscape) return setNewMessages(0)
		window.setTimeout(() => {
			setNewMessages(0)
		}, 300)
	}

	return (
		iAdvizeIsLoaded && (
			<NotificationWrapper
				className={convReplayButtonClasses.root}
				isExpanded={isExpanded}
				isHidden={isHidden}
				onClick={clickHandler}
			>
				<NewMessages isShown={newMessages !== 0 && !isExpanded}>
					<span>{newMessages}</span>
				</NewMessages>
				<Content>
					<Ico isExpanded={isExpanded}>
						<Icon name="bubble_idz" width="100%" height="100%" fill={Theme.colors.cta_main} />
					</Ico>
					<Description isExpanded={isExpanded}>
						<TextWrap>
							<Title>
								{newMessages
									? `${newMessages} ${
											newMessages > 1
												? storeDatas.ui.message_new_messages
												: storeDatas.ui.message_new_message
										}`
									: storeDatas.ui.conversational_replay.notification.need_help}
							</Title>
							<Subtitle>
								{newMessages
									? storeDatas.ui.conversational_replay.notification.expert_answered
									: storeDatas.ui.conversational_replay.notification.here_to_help}
							</Subtitle>
						</TextWrap>
						<CtaWrap>
							<Cta>
								{newMessages
									? storeDatas.ui.conversational_replay.notification.see
									: storeDatas.ui.conversational_replay.notification.chat}
							</Cta>
						</CtaWrap>
					</Description>
				</Content>
			</NotificationWrapper>
		)
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(ConversationalReplay)
