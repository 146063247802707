import { usePlayerContext } from '@helpers/contexts/player'
import { classGenerator as cx } from '@styles/sharedLogics'
import React from 'react'
import CartButton from './CartButton'
import { CatalogButton } from './CatalogButton'
import ClosePlayerButton from './ClosePlayerButton'
import LikeButton from './LikeButton'
import MuteButton from './MuteButton'
import OpenChatButton from './OpenChatButton'
import OthersActionsButton from './OtherActionsButton'
import { LiveActionsContainer, classes } from './style'

export type AvailableAction =
	| 'CART'
	| 'CATALOG'
	| 'LIKE'
	| 'ROTATE'
	| 'CINEMA'
	| 'OTHERS'
	| 'CLOSE_PLAYER'
	| 'OPEN_CHAT'

export const liveActionsClasses = classes

interface LiveActionsProps {
	chatRef?: any
	products?: any
	column?: boolean
	actions?: Array<AvailableAction>
	className?: string
}

const LiveActions: React.FC<LiveActionsProps> = ({ chatRef, products, actions, column, className }) => {
	const { playerData, isMobile, videoMode } = usePlayerContext()

	const isActionActive = (action: AvailableAction) => {
		return actions.includes(action)
	}
	return (
		<LiveActionsContainer className={cx(classes.root, className)} column={column}>
			<LikeButton show={isActionActive('LIKE') && playerData.event.event.status === 'live'} />
			<CatalogButton
				products={products}
				playerData={playerData}
				show={isActionActive('CATALOG') && (isMobile || videoMode == 'landscape')}
			/>
			<CartButton show={isActionActive('CART') && playerData.event.event.settings.options.hasCart} />
			{/* <RottateButton show={isActionActive('ROTATE')} /> */}
			<MuteButton show={isActionActive('CINEMA')} />
			<OpenChatButton show={isActionActive('OPEN_CHAT')} chatRef={chatRef} />
			<OthersActionsButton show={isActionActive('OTHERS')} />
			<ClosePlayerButton show={isActionActive('CLOSE_PLAYER')} />
		</LiveActionsContainer>
	)
}

export default LiveActions
