import styled from '@emotion/styled'
import { BaseFlex } from '@styles/common'
import Theme from '@styles/theme'

export const TermsOfServices = styled(BaseFlex)<{ spacing?: number }>`
	width: 100%;
	font-family: ${Theme.fontFamilies.Second};
	margin-bottom: ${({ spacing }) => Theme.spacing(spacing || 0)};
`

export const LinkTos = styled.span`
	font-size: 14px;
	font-family: ${Theme.fontFamilies.Second};
	font-weight: ${Theme.fontWeights.regular};
	line-height: 140%;
	text-decoration: underline;
	cursor: pointer;
	margin-left: 3px;
	font-size: 12px;
`
