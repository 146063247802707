import React from 'react'
import { Flex, Box } from 'rebass'
import Style from './style'
import { mapDispatchToProps } from '@helpers/wrappers/withReduxStore'
import { State } from '@stores/index'
import { connect } from 'react-redux'
import Icon from '@components/Micro/Icon'
import Theme from '@styles/theme'
import Bus from '@helpers/bus'
import Constants from '@constants'
import { usePlayerContext } from '@helpers/contexts/player'
import { unmuteButtonClasses } from './classes'
import { ProgramStep } from 'types/ReplayProgram'

interface Props {
	innerRef?: any
	dispatch?: (action) => void
	onPlayClick?: any
	storeEvent?: any
	storeStepsProgram?: ProgramStep[]
	playerIsUnmuted?: boolean
	playerIsReady?: boolean
}

const mapStateToProps = (state: State, props: Props) => {
	return { ...state, ...props }
}

const Unmute: React.FC<Props> = ({ onPlayClick, playerIsUnmuted, playerIsReady, storeStepsProgram }) => {
	const { isStarted } = usePlayerContext()

	const onClick = () => {
		if (!isStarted) {
			Bus.send(Constants.bus.player.event_participated, {
				steps: storeStepsProgram
			})
		}
		onPlayClick()
	}
	return (
		<>
			<Flex
				width={1}
				height="100%"
				flexWrap="wrap"
				onClick={onClick}
				css={[Style.el, playerIsUnmuted || !playerIsReady ? Style.isUnmuted : null]}
				justifyContent="center"
				alignItems="center"
				className={unmuteButtonClasses.root}
			>
				<Box width={80} className="play" css={[Style.play, Theme.squaredEdges]} p="30px 29px 30px 31px">
					<Icon name="play" width="100%" height="100%" fill={Theme.colors.text_main} />
				</Box>
			</Flex>
		</>
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(Unmute)
