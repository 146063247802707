import React, { useState } from 'react'
import Theme from '@styles/theme'
import { useNotificationsContext } from '@helpers/contexts/notifications'
import { DrawersType } from '@components/Notifiers/Drawers/constants'
import { usePlayerContext } from '@helpers/contexts/player'
import FloatingMenu from '@components/Micro/FloatingMenu'
import OtherActionsContent from '@components/Notifiers/SharedNotifiersContent/OtherActionsContent'
import { classGenerator } from '@styles/sharedLogics'
import { classes, OtherActionsCta } from './style'

interface OthersActionsButtonProps {
	show?: boolean
}

const OthersActionsButton: React.FC<OthersActionsButtonProps> = ({ show = true }) => {
	const { displayMode, isVertical } = usePlayerContext()
	const { openDrawer } = useNotificationsContext()
	const [openMenu, setOpenMenu] = useState<boolean>(false)

	const isDrawerDisabled = () => {
		return displayMode === 'DESKTOP_LANDSCAPE' || (displayMode === 'MOBILE_LANDSCAPE' && !isVertical)
	}

	const handleClick = (e) => {
		e.preventDefault()
		e.stopPropagation()
		if (isDrawerDisabled()) {
			setOpenMenu(!openMenu)
		} else {
			openDrawer(DrawersType.SHARE_AND_OPTOUT_DRAWER)
		}
	}

	return (
		<>
			{show && (
				<>
					<OtherActionsCta
						className={classGenerator({ [classes.active]: openMenu })}
						iconProps={{
							name: 'ellipsis',
							fill: openMenu ? Theme.colors.black() : Theme.colors.white()
						}}
						onClick={handleClick}
					/>
					{isDrawerDisabled() && (
						<FloatingMenu open={openMenu} onClose={() => setOpenMenu(false)}>
							<OtherActionsContent variant="floating-menu" onItemClick={() => setOpenMenu(false)} />
						</FloatingMenu>
					)}
				</>
			)}
		</>
	)
}

export default OthersActionsButton
