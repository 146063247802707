import { css } from '@emotion/react'
import Theme from '@styles/theme'

const PREFIX = 'cta'

export const classes = {
	root: PREFIX
}

export default {
	cta: css`
		text-align: center;
		cursor: pointer;
		font-family: ${Theme.fontFamilies.Second};
		font-weight: ${Theme.fontWeights.bold};
		color: ${Theme.colors.white()};
		position: relative;
		transition: opacity 600ms ${Theme.eases.outExpo} 0ms;

		/* sizes */

		&.small {
			font-size: 12em;
			border-radius: 10px;
			padding: 9px 20px;
			height: 50px;
		}

		&.medium {
			font-size: 14em;
			border-radius: 10px;
			&.plain {
				padding: 19px 45px;
			}
			&.border,
			&.border-selected {
				padding: 18px 44px;
			}
		}

		${Theme.fadeAndScaleOnClick()}
	`,

	hasIcon: css`
		@media ${Theme.media(0)} {
			&.medium {
				font-size: 14em;
				border-radius: 10px;
				&.plain {
					padding: 19px 25px 19px 45px;
				}
				&.border,
				&.border-selected {
					padding: 18px 24px 18px 44px;
				}
			}
		}
	`,

	icon: css`
		display: inline-block;
		position: absolute;
		left: 20px;
		top: 50%;
		transform: translateY(-50%);
	`,
	iconOnly: css`
		display: inline-block;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translateX(-50%) translateY(-50%);
	`
}
