import styled from '@emotion/styled'
import { Flex } from 'rebass'
import Theme from '@styles/theme'
import { BaseFlex } from '@styles/common'

const PREFIX = 'checkbox'

export const classes = {
	root: PREFIX,
	error: `${PREFIX}-error`
}

export const CheckBoxLabel = styled.div<{ color? }>`
	font-family: ${Theme.fontFamilies.Second};
	font-weight: ${Theme.fontWeights.regular};
	color: ${({ color }) => color || Theme.colors.text_main};
	line-height: 16px;
	font-size: 12px;
`

export const CheckBoxInput = styled(Flex)`
	font-size: 12px;
	min-width: 16px;
	max-width: 16px;
	height: 16px;
	padding: 3px;
	margin-right: 10px;
	border-radius: 4px;
	cursor: pointer;
	transition: opacity 600ms ${Theme.eases.outExpo} 0ms;
	border: 1px solid;
	${() => Theme.squaredEdges};
`

export const CheckBoxContainer = styled(BaseFlex)`
	width: 100%;
	&.${classes.error} {
		& ${CheckBoxLabel} {
			color: ${Theme.colors.red()};
		}

		& ${CheckBoxInput} {
			color: ${Theme.colors.red()};
		}
	}
`
