import { css } from '@emotion/react'
import Theme from '@styles/theme'
import styled from '@emotion/styled'
import { iconClasses } from '@components/Micro/Icon'

const PREFIX = 'product-panel'

export const classes = {
	root: PREFIX,
	processing: 'processing',
	outOfStock: 'out-of-stock'
}

export const AddToCartFloatingButton = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: nowrap;
	width: 100%;
	height: 70px;
	background-color: ${() => Theme.colors.cta_main};
	color: ${() => Theme.colors.white()};
	bottom: 0;
	z-index: 1;
	transition: transform 600ms ${Theme.eases.outExpo} 0ms;
	position: relative;
	cursor: pointer;
	&:hover {
		& > div {
			${Theme.fadeAndScaleOnClick(false)}
		}
	}

	&.${classes.processing} {
		pointer-events: none;
		opacity: 0.8;
	}
	&.${classes.outOfStock} {
		pointer-events: none;
		background-color: ${Theme.colors.grey1};
	}

	& > .${iconClasses.root} {
		margin: ${Theme.spacing(4)};
		min-width: 18px;
	}

	& > div {
		text-align: center;
		font-family: ${Theme.fontFamilies.Second};
		font-weight: ${Theme.fontWeights.bold};
		font-size: 14em;
		line-height: 14px;
	}
`

export default {
	el: css``,

	content: css`
		overflow: auto;
		background-color: ${Theme.colors.white()};
	`,

	title: css`
		font-family: ${Theme.fontFamilies.Main};
		font-weight: ${Theme.fontWeights.bold};
	`,

	text: css`
		font-family: ${Theme.fontFamilies.Second};
		font-weight: ${Theme.fontWeights.regular};
		line-height: 130%;
	`,

	icon: css`
		display: inline-block;
		position: absolute;
		left: 20px;
		top: 50%;
		transform: translateY(-50%);
	`
}
