import { css } from '@emotion/react'
import Theme from '@styles/theme'
import styled from '@emotion/styled'

const PREFIX = 'toast'

export enum Variant {
	SUCCESS = 'success',
	DANGER = 'danger',
	WARNING = 'warning'
}

const getVariantStyle = (variant: Variant) => {
	switch (variant) {
		default:
			return css`
				background-color: ${Theme.colors.white()};
			`
	}
}

export const classes = {
	root: PREFIX
}

export const ToastContainer = styled.div<{ variant: Variant }>`
	position: fixed;
	display: flex;
	padding: ${Theme.spacing(4)};
	max-width: 360px;
	border-radius: 12px;
	${({ variant }) => getVariantStyle(variant)};
	cursor: pointer;
	${() => css([Theme.squaredEdges])};
`
