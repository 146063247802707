import { WinningInstant } from 'types/WinningInstant'

interface WinningInstantContextState {
	winningInstant: WinningInstant
	hasBeenDisplayed: boolean
	hasBeenClicked: boolean
}

interface WinningInstantContextAction {
	type: WinningInstantActionUnion
	data?: any
	metadata?: any
}

export type WinningInstantActionUnion =
	| 'SET_WINNING_INSTANT'
	| 'SET_HAS_BEEN_DISPLAYED'
	| 'SET_HAS_BEEN_CLICKED'
	| 'CLEAR_WINNING_INSTANT'

export const winningInstantContextInitialState: WinningInstantContextState = {
	winningInstant: null,
	hasBeenDisplayed: false,
	hasBeenClicked: false
}

export const winningInstantReducer = (
	state: WinningInstantContextState,
	action: WinningInstantContextAction
): WinningInstantContextState => {
	switch (action.type) {
		case 'SET_WINNING_INSTANT':
			return { ...state, winningInstant: { ...state.winningInstant, ...action.data } }
		case 'SET_HAS_BEEN_DISPLAYED':
			return { ...state, hasBeenDisplayed: action.data }
		case 'SET_HAS_BEEN_CLICKED':
			return { ...state, hasBeenClicked: action.data }
		case 'CLEAR_WINNING_INSTANT':
			return { ...state, hasBeenClicked: false, hasBeenDisplayed: false }
		default:
			return { ...state }
	}
}
