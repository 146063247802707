import { css } from '@emotion/react'
import Theme from '@styles/theme'

export default {
	el: css`
		border-radius: 5px;
		transform: translate3d(0, 0, 0);
		cursor: pointer;
		&:active {
			opacity: 0.5;
		}
	`,

	text: css`
		font-family: ${Theme.fontFamilies.Second};
		font-weight: ${Theme.fontWeights.bold};
	`,

	btn: css`
		background-color: ${Theme.colors.red()};
	`
}
