import { css, keyframes } from '@emotion/react'
import Theme from '@styles/theme'

const bounce = keyframes`
	0% { transform: scale(1); }
	40% {  transform: scale(1); }
	50% { transform: scale(1.025); }
	60% { transform: scale(1); }
	0% { transform: scale(1); }
`

export default {
	el: css`
		background-color: ${Theme.colors.black(0.4)};
		backdrop-filter: blur(10px);
		position: absolute;
		left: 0;
		bottom: 0;
		right: 0;
		top: 0;
		z-index: ${Theme.zIndices.unmute};
		opacity: 1;
		pointer-events: all;
		overflow: hidden;
		cursor: pointer;
		.play {
			opacity: 1;
			transition: opacity 800ms cubic-bezier(0.52, 0.16, 0.24, 1) 500ms;
		}
	`,

	play: css`
		border-radius: 20px;
		background-color: ${Theme.colors.white()};
		animation: ${bounce} 2000ms ${Theme.eases.bounceIn} infinite;
	`,

	isUnmuted: css`
		opacity: 0;
		transition: opacity 500ms cubic-bezier(0.52, 0.16, 0.24, 1);
		pointer-events: none;
		.play {
			opacity: 0;
			transition: opacity 200ms cubic-bezier(0.52, 0.16, 0.24, 1);
		}
	`
}
