import { Message } from 'types/Message'

interface MessagesContextState {
	messages: Message[]
	bannedViewers: string[]
	pinnedMessages: Message[]
	moderatedMessages: Message[]
	hasMoreMessages: boolean
	isFirstLoad: boolean
	newMessages: Message[]
}

export enum MessagesContextActions {
	SET_MESSAGES = 'set_messages',
	SET_BANNED_VIEWERS = 'set_banned_viewers',
	SET_PINNED_MESSAGES = 'set_pinned_messages',
	SET_MODERATED_MESSAGES = 'set_moderated_messages',
	RESET_MESSAGES = 'reset_messages',
	UPDATE_MESSAGES = 'update_messages',
	SET_CURSOR = 'set_cursor',
	SET_NEW_MESSAGES = 'set_new_messages',
	RESET_NEW_MESSAGES = 'reset_new_messages'
}

export const messagesContextInitialState: MessagesContextState = {
	messages: [],
	bannedViewers: [],
	pinnedMessages: [],
	moderatedMessages: [],
	hasMoreMessages: true,
	isFirstLoad: true,
	newMessages: []
}

export const messagesReducer = (state: MessagesContextState, action: any): MessagesContextState => {
	switch (action.type) {
		case MessagesContextActions.SET_MESSAGES:
			return mergeMessages(state, action.getMessagesdata)
		case MessagesContextActions.RESET_MESSAGES:
			return messagesContextInitialState
		case MessagesContextActions.UPDATE_MESSAGES:
			return {
				...state,
				messages: state.messages.map((message) =>
					message.uuid === action.updateMessageData.uuid ? action.updateMessageData : message
				)
			}
		case MessagesContextActions.SET_BANNED_VIEWERS:
			return {
				...state,
				bannedViewers: action.data,
				messages: state.messages.map((message) =>
					setMessageAttributes(message, action.data, state.moderatedMessages, state.pinnedMessages)
				)
			}
		case MessagesContextActions.SET_PINNED_MESSAGES:
			return {
				...state,
				pinnedMessages: action.data,
				messages: state.messages.map((message) =>
					setMessageAttributes(message, state.bannedViewers, state.moderatedMessages, action.data)
				)
			}

		case MessagesContextActions.SET_MODERATED_MESSAGES:
			return {
				...state,
				moderatedMessages: action.data,
				messages: state.messages.map((message) =>
					setMessageAttributes(message, state.bannedViewers, action.data, state.pinnedMessages)
				)
			}

		case MessagesContextActions.SET_NEW_MESSAGES:
			const newState = mergeMessages(state, [...state.messages, ...action.newMessages])
			return {
				...state,
				newMessages: removeDuplicateMessage('uuid', [...newState.newMessages, ...action.newMessages])
			}
		case MessagesContextActions.RESET_NEW_MESSAGES:
			return {
				...state,
				newMessages: []
			}
		default:
			break
	}
}

const mergeMessages = (state: MessagesContextState, messagesData: any): MessagesContextState => {
	const newState = { ...state }
	if (messagesData.data?.length) {
		const newMessages = messagesData.data.map((message) =>
			setMessageAttributes(message, state.bannedViewers, state.moderatedMessages, state.pinnedMessages)
		)

		const messagesBuffer = messagesData.isBefore
			? [...newMessages, ...state.messages]
			: [...state.messages, ...newMessages]

		newState.messages = removeDuplicateMessage('uuid', messagesBuffer)
	}

	if (messagesData.isBefore) {
		newState.hasMoreMessages = messagesData.hasMoreMessages
	}

	return { ...newState, isFirstLoad: false }
}

const removeDuplicateMessage = (prop, arr): Message[] =>
	Array.from(
		arr
			.reduce(
				(acc, item) => (item && item[prop] && acc.set(item[prop], item), acc), // using map (preserves ordering)
				new Map()
			)
			.values()
	)

export const lastMessageTimeStamp = (messages: Array<Message>) => {
	return messages.length ? messages[messages.length - 1].created_at.toString() : new Date().toString()
}

const setMessageAttributes = (
	message: Message,
	bannedViewers: Array<string>,
	moderatedMessages: Array<Message>,
	pinnedMessages: Array<Message>
): Message => {
	return {
		...message,
		is_banned: !!bannedViewers.find(
			(banned) => message.viewerId === banned || message.parent_viewer_uid === banned
		),
		is_moderated: !!moderatedMessages.find((moderated) => message.uuid === moderated.uuid),
		is_pinned: !!pinnedMessages.find((pinned) => pinned.uuid === message.uuid)
	}
}
