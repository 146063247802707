import React, { createContext, useContext, useMemo, useState } from 'react'
import { ProviderBaseProps } from 'types/Common'
import { usePlayerContext } from '../player'

interface TimeLineContext {
	currentTime?: number
	currentDateTime?: string
	setCurrentTime?: (newCurrentTime: number) => void
	setCurrentDateTime?: (newCurrentDateTime: string) => void
}

const Context = createContext<TimeLineContext | null>({})

export const useTimeLineContext = () => useContext(Context)

export const TimeLineProvider: React.FC<ProviderBaseProps> = ({ children }) => {
	/**
	 * @States
	 */
	const { playerData } = usePlayerContext()
	const [currentTime, setCurrentTime] = useState<number>(0)
	const [currentDateTime, setCurrentDateTime] = useState<string>(new Date().toString())

	const values = useMemo(
		(): TimeLineContext => ({
			currentTime,
			currentDateTime,
			setCurrentTime: (ct) => {
				setCurrentTime(ct)
				setCurrentDateTime(new Date(Date.parse(playerData.record.live_start_time) + ct * 1000).toString())
			},
			setCurrentDateTime
		}),
		[currentTime, currentDateTime, playerData?.record?.live_start_time]
	)

	return <Context.Provider value={values}>{children}</Context.Provider>
}
