import { css } from '@emotion/react'
import Theme from '@styles/theme'

export default {
	reassurance: css`
		font-family: ${Theme.fontFamilies.Second};
		font-weight: ${Theme.fontWeights.regular};
		line-height: 140%;
		white-space: pre-wrap;
		> p,
		> h2 {
			margin-bottom: 10px;
			&:last-child {
				margin-bottom: 0;
			}
		}
		strong {
			font-weight: ${Theme.fontWeights.bold};
		}
	`,

	optinSelectable: css`
		cursor: pointer;
		transition: opacity 600ms ${Theme.eases.outExpo} 0ms;
		@media ${Theme.media(0)} {
			&:hover {
				opacity: 0.5;
			}
		}
	`,

	hideForm: css`
		display: none !important;
	`,

	legals: css`
		font-family: ${Theme.fontFamilies.Second};
		font-weight: ${Theme.fontWeights.regular};
		line-height: 140%;
		font-size: 9em;
	`,

	icon: css`
		border-radius: 100%;
	`,

	title: css`
		font-family: ${Theme.fontFamilies.Main};
		font-weight: ${Theme.fontWeights.bold};
	`,

	text: css`
		font-family: ${Theme.fontFamilies.Second};
		font-weight: ${Theme.fontWeights.regular};
		line-height: 140%;
	`,

	optinText: css`
		font-family: ${Theme.fontFamilies.Second};
		font-weight: ${Theme.fontWeights.regular};
		line-height: 140%;
	`,

	optinTextLink: css`
		font-family: ${Theme.fontFamilies.Second};
		font-weight: ${Theme.fontWeights.regular};
		line-height: 140%;
		text-decoration: underline;
		cursor: pointer;
	`,

	optinCheck: css`
		border-radius: 4px;
		> i {
			opacity: 0;
		}
	`,

	optinCheckIsChecked: css`
		> i {
			opacity: 1;
		}
	`,

	optinIsNotValid: css`
		color: ${Theme.colors.red()};
	`,

	optinCheckIsNotValid: css`
		border: 1px solid ${Theme.colors.red()};
	`
}
