import Cta from '@components/Micro/Cta'
import Placeholder from '@components/Micro/Placeholder'
import StarRating from '@components/Micro/StarRating'
import { useBrandContext } from '@helpers/contexts/brands'
import { usePlayerContext } from '@helpers/contexts/player'
import Theme from '@styles/theme'
import { TranslateYAndFadeTransition } from '@styles/transitions/TranslateYAndFadeTransition'
import { useEffect, useState } from 'react'
import { BrandAvatar, CommentaryLabel, SurveyH1, SurveyWrapper, TextAreaComponent, TextAreaWrapper } from './style'
import getConfig from 'next/config'
import { useViewer } from '@helpers/contexts/viewerContext'
import { useNotificationsContext } from '@helpers/contexts/notifications'
import { ShuttersType } from '../constants'
import Bus from '@helpers/bus'
import Constants from '@constants'

const { publicRuntimeConfig } = getConfig()
const NEXT_PUBLIC_API_ENDPOINT = publicRuntimeConfig.NEXT_PUBLIC_API_ENDPOINT

const SatisfactionSurveyForm: React.FC = () => {
	const { playerData, isMobile } = usePlayerContext()
	const { brand } = useBrandContext()
	const { currentViewer, updateCurrentViewer } = useViewer()
	const { openShutter, currentShutter } = useNotificationsContext()
	const [rating, setRating] = useState<number>(0)
	const [comment, setComment] = useState<string>(null)
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

	const starRatingTextValues = playerData.ui.csat.ratings

	const onChange = (e) => {
		setComment(e.target.value)
	}
	const submit = () => {
		setIsSubmitting(true)
		const data = {
			rating,
			comment,
			viewerId: currentViewer.uid
		}
		fetch(`${NEXT_PUBLIC_API_ENDPOINT}/api/public/events/${playerData.event.event.uuid}/csat`, {
			body: JSON.stringify(data),
			headers: {
				'Content-Type': 'application/json'
			},
			method: 'POST'
		})
			.then((res) => res.json())
			.then(() => {
				setIsSubmitting(false)
				updateCurrentViewer({})
				openShutter(ShuttersType.CSAT_SHUTTER_EXIT, { onClose: currentShutter.config.onClose })
			})
			.catch((error) => {
				setIsSubmitting(false)
				console.error(error)
			})
	}

	useEffect(() => {
		Bus.send(Constants.bus.player.csat_viewed)
	}, [])

	return (
		<SurveyWrapper>
			<BrandAvatar isMobile={!!isMobile}>
				<Placeholder sizes={[[100, 100]]} media={brand.avatar} color={brand.color} fit="cover" fade={false} />
			</BrandAvatar>
			<SurveyH1 color={Theme.colors.text_main}>{playerData.ui.csat.question}</SurveyH1>
			<StarRating textRatingValues={starRatingTextValues} setRating={setRating} rating={rating} />
			<TranslateYAndFadeTransition
				timeout={400}
				classNames="survey-form-end"
				in={!!rating}
				translate="200px"
				unmountOnExit
			>
				<div>
					<TextAreaWrapper>
						<CommentaryLabel>{playerData.ui.csat.add_comment}</CommentaryLabel>
						<TextAreaComponent name="area" id="" rows={7} onChange={onChange} isMobile={!!isMobile} />
					</TextAreaWrapper>
					<Cta
						label={playerData.ui.message_modal_submit}
						onClick={submit}
						justifyContent="center"
						disabled={isSubmitting}
					/>
				</div>
			</TranslateYAndFadeTransition>
		</SurveyWrapper>
	)
}

export default SatisfactionSurveyForm
