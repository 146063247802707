import { Message } from 'types/Message'

export type RecordData = {
	live_end_time: string
	live_start_time: string
	stream_start_time: string
	replay_duration: number
}

export type MessagesSegments = Record<number, Message[]>

export type MessagesSegmentsData = {
	segments: MessagesSegments
	timestamps: number[]
}

export type FetchState = {
	lastMessageCursor: string
	closestMessageSegment: number
}

export const getClosestSegmentTimestamp = (segments: MessagesSegments, dateString: string): number | null => {
	const targetTimestamp = new Date(dateString).valueOf()

	const eligibleTimestamps = Object.keys(segments)
		.map(Number)
		.filter((timestamp) => timestamp <= targetTimestamp)

	if (eligibleTimestamps.length === 0) return null

	return Math.max(...eligibleTimestamps)
}

export const generateTimeIntervals = (record: RecordData): number[] => {
	let currentInterval = 0
	const intervals: number[] = []
	const { live_start_time, replay_duration: duration } = record
	const start = new Date(live_start_time).valueOf()

	while (currentInterval <= duration) {
		intervals.push(new Date(start + currentInterval).valueOf())
		currentInterval += 20000
	}

	return intervals
}

export const generateMessagesSegments = (
	intervals: number[],
	messages: Message[],
	messagesSegments: MessagesSegments = { '0': [] }
): MessagesSegmentsData => {
	const uniqueMessages = [...Object.values(messagesSegments).flatMap((segment) => segment), ...messages]
	const result: MessagesSegments = {}

	intervals.forEach((interval) => (result[interval] = []))

	removeDuplicatedMessages(uniqueMessages)
		.sort((a, b) => {
			const dateA = a.created_at ? new Date(a.created_at).getTime() : 0
			const dateB = b.created_at ? new Date(b.created_at).getTime() : 0
			return dateA - dateB
		})
		.forEach((message) => {
			for (let i = 0; i < intervals.length; i++) {
				const createdAtTs = new Date(message.created_at).valueOf()
				if (createdAtTs >= intervals[i] && createdAtTs < intervals[i + 1]) {
					result[intervals[i]].push(message)
					break
				}
			}
		})

	return {
		segments: result,
		timestamps: Object.keys(result)
			.map(Number)
			.sort((a, b) => a - b)
	}
}

export const removeDuplicatedMessages = (messages: Message[]): Message[] => {
	const uniqueMessages = new Set<string>()

	const deduplicatedArray = messages.filter((message) => {
		if (!uniqueMessages.has(message.uuid)) {
			uniqueMessages.add(message.uuid)
			return true
		}
		return false
	})
	return deduplicatedArray
}
