import { css } from '@emotion/react'
import Theme from '@styles/theme'
import styled from '@emotion/styled'
import { BaseFlex } from '@styles/common'

export const FooterLink = styled.a`
	padding: 12px 28px;
	color: ${() => Theme.colors.text_second};
	font-size: 12em;
	font-family: ${Theme.fontFamilies.Second};
	font-weight: ${Theme.fontWeights.regular};
	line-height: 140%;
	text-align: center;
	cursor: pointer;
	&:hover {
		text-decoration: underline;
	}
`

export default {
	el: css`
		border-top: 1px solid ${Theme.colors.black(0.1)};
	`
}

export const LogoAndNetworks = styled.div`
	display: grid;
	align-items: center;
	grid-template-columns: repeat(1, 1fr);
	row-gap: ${Theme.spacing(8)};
	margin-bottom: ${Theme.spacing(8)};

	@media ${Theme.media(1)} {
		grid-template-rows: unset;
		grid-template-columns: repeat(3, 1fr);
		margin-top: 0;
		margin-bottom: 0;
		height: 230px;
	}
`

export const CopyrightWrapper = styled(BaseFlex)`
	justify-items: center;
	@media ${Theme.media(1)} {
		margin-top: 0;
	}
`
export const LogoWrapper = styled(BaseFlex)`
	justify-items: center;
	justify-content: center;

	@media ${Theme.media(1)} {
		margin-top: 0;
	}
`

export const NetworksWrapper = styled(BaseFlex)`
	justify-items: center;
	justify-content: center;

	@media ${Theme.media(1)} {
		margin-top: 0;
		margin-bottom: 0;
	}
`

export const FooterLinks = styled(BaseFlex)`
	width: 100%;
	justify-content: center;
	padding-top: ${Theme.spacing(8)};
	padding-bottom: ${Theme.spacing(22)};
	border-top: 1px solid ${Theme.colors.black(0.1)};
`
