import React from 'react'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import Theme from '@styles/theme'

const handleTextVariant = (props) => {
	const { variant, color, weight, size, height } = props
	return css`
		font-size: ${size ? size : Theme.text[variant]?.fontSize};
		font-weight: ${weight ? weight : Theme.text[variant]?.fontWeight};
		line-height: ${height ? height : Theme.text[variant]?.lineHeight};
		color: ${color ? color : Theme.text[variant]?.color};
	`
}

const PREFIX = 'text'

export const textClasses = {
	root: PREFIX
}

interface TextInterfaceProps {
	variant?: string
	fullWidth?: boolean
	className?: string
	size?: string
	height?: string
	weight?: string
	color?: string
	fontCase?: 'capitalize' | 'uppercase' | 'lowercase' | 'none'
	family?: 'Main' | 'Second'
	children: any
}

const TextWrapper = styled('p')<TextInterfaceProps>`
	min-width: fit-content;
	font-family: ${({ family }) => Theme.fontFamilies[family]};
	width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
	${(props) => handleTextVariant(props)}
	text-transform: ${({ fontCase }) => (fontCase ? fontCase : '')};
`

const Text: React.FC<TextInterfaceProps> = ({ children, family = 'Main', ...props }) => {
	return (
		<TextWrapper className={textClasses.root} {...props} family={family}>
			{children}
		</TextWrapper>
	)
}

export default Text
