import React from 'react'
import { ActionsContainer, RGPDOptoutWrapper } from './style'
import CardItem from '@components/Micro/CardItem'
import { usePlayerContext } from '@helpers/contexts/player'
import { useNotificationsContext } from '@helpers/contexts/notifications'
import { ModalsType } from '../constants'
import { getUiByType } from './logic'

export type RGPDOptOutType = 'phone' | 'email' | 'cookies'

const LARGE_ICONS = ['cookies']

const RGPDOptOut: React.FC = () => {
	const { playerData } = usePlayerContext()
	const { openModal } = useNotificationsContext()

	/**
	 * @Methods
	 */
	const getUi = (field: string) => {
		if (playerData) {
			return playerData.ui.tos_opt_out[field]
		}
		return {}
	}

	const hasLargeIcon = (iconName) => LARGE_ICONS.indexOf(iconName) !== -1

	const handleClick = (type: RGPDOptOutType) => {
		const ui = getUiByType(type, playerData.ui)
		const largeIcon = hasLargeIcon(type)
		openModal(ModalsType.RGPD_OPT_OUT_FORM, { playerData, ui, type, largeIcon }, true)
	}

	return (
		<RGPDOptoutWrapper>
			<ActionsContainer>
				{!playerData?.event.event.legalSettings.optout.phoneOptoutDisabled && (
					<CardItem
						hasChevron
						icon="phone"
						label={getUi('phone').label}
						onClick={() => handleClick('phone')}
					/>
				)}
				{!playerData?.event.event.legalSettings.optout.emailOptoutDisabled && (
					<CardItem
						hasChevron
						icon="email"
						label={getUi('email').label}
						onClick={() => handleClick('email')}
					/>
				)}
				{!playerData?.event.event.legalSettings.optout.cookiesOptoutDisabled && (
					<CardItem
						hasChevron
						icon="cookies"
						label={getUi('cookies').label}
						onClick={() => handleClick('cookies')}
					/>
				)}
			</ActionsContainer>
		</RGPDOptoutWrapper>
	)
}

export default RGPDOptOut
