import React from 'react'
import { Flex } from 'rebass'
import { State } from '@stores/index'
import { connect } from 'react-redux'
import Product from '@components/StatusManager/LiveAndReplay/components/Items/Product'
import { ProductPanelButton } from '@components/Notifiers/Panels/Product'
import { usePlayerContext } from '@helpers/contexts/player'

interface Props {
	uiIsLight?: boolean
	storeDatas?: any
	storeEvent?: any
	storeStepsProgram?: any
	storeCurrentStep?: any
	storeTimecodes?: any
	showIndexes?: boolean
	showStatuses?: boolean
	showPrices?: boolean
	panelActive?: boolean
	exception?: any
	onClick?: (e?: any) => void
	twoByColumns?: any
	products?: any
	playerData?: any
}

const mapStateToProps = (state: State, props: Props) => {
	return { ...state, ...props }
}

const Items: React.FC<Props> = ({
	showIndexes,
	storeCurrentStep,
	storeStepsProgram,
	storeTimecodes,
	showStatuses,
	showPrices,
	twoByColumns,
	products,
	playerData,
	panelActive,
	onClick
}) => {
	let $items = null
	const { videoMode, isMobile } = usePlayerContext()

	if (products) {
		const final = []

		const isAPreviousProduct = (id) => {
			const oldSteps = []
			let i = storeTimecodes.length

			while (i--) {
				let cs = storeTimecodes[i].step
				if (cs !== null) {
					if (oldSteps.indexOf(cs) === -1) {
						oldSteps.push(cs)
					}
				}
			}

			let j = oldSteps.length

			while (j--) {
				let targetStep = storeStepsProgram[oldSteps[j]]
				if (targetStep) {
					let products = targetStep.products
					let k = products.length
					while (k--) {
						if (products[k].uuid === id) {
							return true
						}
					}
				}
			}

			return false
		}

		const isACurrentProduct = (id) => {
			const products = []
			if (storeCurrentStep) {
				storeStepsProgram.map((step) => {
					if (step.index === storeCurrentStep.index) {
						let j = step.products.length
						while (j--) {
							products.push(step.products[j].uuid)
						}
					}
				})
			}
			return products.indexOf(id) !== -1
		}

		const setProductStatus = (uuid) => {
			let status = 'waiting'

			if (isACurrentProduct(uuid)) {
				status = 'live'
			} else if (isAPreviousProduct(uuid)) {
				status = 'passed'
			}
			return status
		}

		products.map((item) => {
			if (item) {
				//@ts-ignore
				item.status = setProductStatus(item.uuid)
				final.push(item)
			}
		})

		$items = final.map((product, i) => {
			return panelActive ? (
				<ProductPanelButton
					key={`products-items-${i}`}
					playerData={playerData}
					product={product}
					isLandscape={videoMode === 'landscape'}
					isMobile={isMobile}
				>
					<Product
						index={i}
						data={product}
						showStatus={showStatuses}
						showIndex={showIndexes}
						showPrice={showPrices}
						twoByColumns={twoByColumns}
					/>
				</ProductPanelButton>
			) : (
				<Product
					index={i}
					data={product}
					showStatus={showStatuses}
					showIndex={showIndexes}
					showPrice={showPrices}
					twoByColumns={twoByColumns}
					onClick={onClick}
					key={`products-items-panel-inactive-${i}`}
				/>
			)
		})
	}

	return (
		<Flex width={1} flexWrap={'wrap'} justifyContent="center" mt={twoByColumns ? 15 : 10}>
			<Flex width={1} alignContent={['flex-start']} flexWrap={'wrap'} pt={15}>
				{$items}
			</Flex>
		</Flex>
	)
}

export default connect(mapStateToProps, null)(Items)
