import React, { createContext, useContext, useEffect, useMemo, useReducer } from 'react'
import Modal from '@components/Notifiers/Modals'
import { ModalsType, WinningInstantResultsModals } from '@components/Notifiers/Modals/constants'
import {
	DrawersActionType,
	drawersInitialContextState,
	ModalsActionType,
	modalsInitialContextState,
	PanelsActionType,
	panelsInitialContextState,
	ShuttersActionType,
	shuttersInitialContextState
} from './constants'
import { modalsReducer } from './modal.logic'
import { panelsReducer } from './panel.logic'
import { PanelBuild, PanelsType } from '@components/Notifiers/Panels/constants'
import { drawersReducer } from './drawer.logic'
import { DrawerBuild } from '@components/Notifiers/Drawers/constants'
import { Product } from 'types/Product'
import { shuttersReducer } from './shutters.logic'
import { ShutterBuild, ShuttersType } from '@components/Notifiers/Shutters/constants'
import { ProviderBaseProps } from 'types/Common'

interface NotificationsContext {
	openModal?: (modalType: ModalsType, metadata?, closeCurrent?: boolean) => void
	openPanel?: (panelType: string, metadata?, closeCurrent?: boolean) => void
	openDrawer?: (drawerType: string) => void
	openShutter?: (shutterType: ShuttersType, metadata?: Record<string, any>) => void
	closeLastPanel?: () => void
	closeAllPanels?: () => void
	closeDrawer?: () => void
	panelsQueue?: PanelBuild[]
	isPip?: number
	drawerOpen?: boolean
	shutterOpen?: boolean
	winningInstantFormOpened?: boolean
	modalHistory?: ModalsType[]
	catalogOpened?: boolean
	landscapeHorizontalChatOpened?: boolean
	closeChat?: () => void
	closeModal?: (onClose?: Function) => void
	closeShutter?: () => void
	closePollPanel?: () => void
	currentDrawer?: DrawerBuild
	currentShutter?: ShutterBuild
	firstNameModalOpened?: boolean
	firstNameDrawerOpened?: boolean
	pollPanelOpened?: boolean
	pollDrawerOpened?: boolean
	productPageOpened?: (product: Product) => boolean
	productsCatalogPageOpened?: (products: Product[]) => boolean
	winningInstantResultsModalOpened?: () => boolean
}

const Context = createContext<NotificationsContext | null>({})

export const useNotificationsContext = () => useContext(Context)

export const NotificationsProvider: React.FC<ProviderBaseProps> = ({ children }) => {
	/**
	 * @States
	 */
	const [{ modalOpen, currentModal, modalsQueue, modalHistory }, manageModals] = useReducer(
		modalsReducer,
		modalsInitialContextState
	)
	const [{ panelsQueue, isPip }, managePanels] = useReducer(panelsReducer, panelsInitialContextState)
	const [{ drawerOpen, currentDrawer }, manageDrawers] = useReducer(drawersReducer, drawersInitialContextState)
	const [{ shutterOpen, currentShutter }, manageShutters] = useReducer(shuttersReducer, shuttersInitialContextState)

	/**
	 * @Effects
	 */
	useEffect(() => {
		if (modalsQueue.length > 0) {
			manageModals({ type: ModalsActionType.OPEN_NEXT_MODAL })
		}
	}, [modalsQueue])

	useEffect(() => {
		if (panelsQueue.length > 0) {
			setTimeout(() => {
				managePanels({ type: PanelsActionType.SET_PIP })
			}, 400)
		}
	}, [panelsQueue])

	/**
	 * @Methods
	 */
	const handleModalClose = (onClose: () => void) => {
		if (onClose) onClose()
		manageModals({ type: ModalsActionType.CLOSE_MODAL })
	}

	const openPanel = (panelType, metadata = {}, closeCurrent) =>
		managePanels({ type: PanelsActionType.OPEN_PANEL, panelType, metadata, closeCurrent })

	const closePanelByType = (panelType) => {
		const panel = panelsQueue.find((panel) => panel.panelType === panelType)
		if (panel) {
			managePanels({ type: PanelsActionType.CLOSE_PANEL, panel })
		}
	}

	// Panels open status
	const isWinningInstantFormOpened = () => !!panelsQueue.find((panel) => panel.panelType === 'winning_instant_form')
	const isCalalogOpened = () => panelsQueue.length > 0 && panelsQueue[panelsQueue.length - 1]?.panelType === 'catalog'
	const productPageOpened = (product: Product) =>
		!!panelsQueue.find((panel) => panel.panelType === PanelsType.PRODUCT && panel.productId === product.uuid)
	const productsCatalogPageOpened = (products: Product[]) =>
		!!panelsQueue.find((panel) => panel.panelType === PanelsType.CATALOG && panel.products === products)
	const winningInstantResultsModalOpened = () =>
		!!modalsQueue.find((modal) => !!WinningInstantResultsModals.find((modalType) => modalType === modal.modalType))

	const values = useMemo(
		(): NotificationsContext => ({
			openModal: (modalType, metadata = {}, closeCurrent) =>
				manageModals({ type: ModalsActionType.OPEN_MODAL, modalType, metadata, closeCurrent }),
			openPanel: openPanel,
			openDrawer: (drawerType: string) => {
				manageDrawers({ type: DrawersActionType.CLOSE_DRAWER })
				setTimeout(() => manageDrawers({ type: DrawersActionType.OPEN_DRAWER, drawerType }), 100)
			},
			openShutter: (shutterType: string, metadata: {}) =>
				manageShutters({ type: ShuttersActionType.OPEN_SHUTTER, shutterType, metadata }),
			closeLastPanel: () => managePanels({ type: PanelsActionType.CLOSE_LAST_PANEL }),
			closeAllPanels: () => managePanels({ type: PanelsActionType.CLOSE_ALL_PANELS }),
			closeDrawer: () => manageDrawers({ type: DrawersActionType.CLOSE_DRAWER }),
			closeShutter: () => manageShutters({ type: ShuttersActionType.CLOSE_SHUTTER }),
			closeChat: () => closePanelByType('landscape_horizontal_chat'),
			closeModal: handleModalClose,
			closePollPanel: () => closePanelByType('poll'),
			panelsQueue,
			isPip,
			drawerOpen,
			shutterOpen,
			currentDrawer,
			currentShutter,
			firstNameModalOpened: !!modalsQueue.find((modal) => modal.modalType === 'first_name'),
			firstNameDrawerOpened: currentDrawer?.drawerType === 'first_name_drawer',
			winningInstantFormOpened: isWinningInstantFormOpened(),
			catalogOpened: isCalalogOpened(),
			pollPanelOpened: !!panelsQueue.find((panel) => panel.panelType === 'poll'),
			pollDrawerOpened: currentDrawer?.drawerType === 'poll_drawer',
			landscapeHorizontalChatOpened: !!panelsQueue.find(
				(panel) => panel.panelType === 'landscape_horizontal_chat'
			),
			modalHistory,
			productPageOpened,
			productsCatalogPageOpened,
			winningInstantResultsModalOpened
		}),
		[panelsQueue, isPip, currentDrawer, drawerOpen, modalsQueue, modalHistory, currentShutter, shutterOpen]
	)

	return (
		<Context.Provider value={values}>
			{children}
			<Modal
				isOpen={modalOpen}
				{...currentModal?.config}
				onClose={() => handleModalClose(currentModal.config.onClose)}
			>
				{currentModal?.Component &&
					React.cloneElement(currentModal.Component, {
						onClose: () => handleModalClose(currentModal.config.onClose)
					})}
			</Modal>
		</Context.Provider>
	)
}
