import { useEffect, useRef, useState } from 'react'

const useHasScrolled = (selector: string, callback: (hasScrolled: boolean) => void): boolean => {
	const [hasScrolled, setHasScrolled] = useState(false)
	const hasScrolledRef = useRef(null)

	useEffect(() => {
		document.querySelector(selector).addEventListener('scroll', (e) => {
			//@ts-ignore
			const { scrollTop, scrollHeight, clientHeight } = e.target
			clearTimeout(hasScrolledRef?.current)
			hasScrolledRef.current = setTimeout(() => {
				const newVal = scrollTop + clientHeight + 20 <= scrollHeight
				setHasScrolled(newVal)
				callback(newVal)
			}, 200)
		})
	}, [selector])

	return hasScrolled
}

export default useHasScrolled
