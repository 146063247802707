import React from 'react'
import { Box } from 'rebass'
import Style from './style'

import Header from '@components/StatusManager/PreLive/Header'
import Subscribe from '@components/StatusManager/PreLive/Subscribe'
import Poster from '@components/StatusManager/PreLive/Poster'
import Introduction from '@components/StatusManager/PreLive/Introduction'
import Program from '@components/StatusManager/PreLive/Program'
import Shopper from '@components/StatusManager/PreLive/Shopper'
import Footer from '@components/StatusManager/PreLive/Footer'

interface Props {}

const PreLive: React.FC<Props> = () => {
	return (
		<>
			<Box width={1} css={Style.el}>
				<Header />
				<Subscribe />
				<Poster />
				<Introduction />
				<Program />
				<Shopper />
				<Footer />
			</Box>
		</>
	)
}

export default PreLive
