class _Math {
	static lerp(ratio, start, end) {
		return start + (end - start) * ratio
	}

	static rand(min, max) {
		return this.lerp(Math.random(), min, max)
	}

	static clamp(num, min, max) {
		return Math.min(Math.max(num, min), max)
	}

	static range(a) {
		a.sort()
		return a[a.length - 1] - a[0]
	}

	static gcg(a, b) {
		return b === 0 ? a : this.gcd(b, a % b)
	}

	static ratio(w, h) {
		const r = this.gcd(w, h)

		return {
			x: w / r,
			y: h / r
		}
	}

	static constrain(num, min, max) {
		return Math.min(Math.max(num, Math.min(min, max)), Math.max(min, max))
	}

	static closest(num, arr) {
		let curr = arr[0]
		let diff = Math.abs(num - curr)
		for (let val = 0; val < arr.length; val++) {
			const newdiff = Math.abs(num - arr[val])
			if (newdiff < diff) {
				diff = newdiff
				curr = arr[val]
			}
		}
		return curr
	}

	static average(array) {
		let m = 0
		let n = 0

		for (let o = 0, x = array.length; o < x; o++) {
			if (typeof array[o] === 'number') {
				n += array[o]
				m += 1
			}
		}

		return n / m
	}

	static toDegrees(rad) {
		return rad * (180 / Math.PI)
	}

	static toRadians(deg) {
		return deg * (Math.PI / 180)
	}

	static toPolar(p) {
		const r = Math.sqrt(p.x * p.x + p.y * p.y)
		const t = this.atan2D(p.y, p.x)

		return {
			r,
			t
		}
	}

	static toCartisian(p) {
		const x = p.r * this.cosD(p.t)
		const y = p.r * this.sinD(p.t)

		return {
			x,
			y
		}
	}

	static rotationByPoints(start, end, lastRotation = 0, angularVelocity = 7) {
		let rotation = lastRotation
		const path = this.dist(start, end)
		const target = Math.atan2(path.y, path.x)
		const delta = rotation - target

		if (delta > 0.1 || delta < -0.1) {
			let _delta = delta
			if (_delta < 0) {
				_delta += Math.PI * 2
			}

			if (delta < -Math.PI || (delta > 0 && delta < Math.PI)) {
				rotation -= _delta / angularVelocity
			} else {
				rotation -= (_delta - Math.PI * 2) / angularVelocity
			}

			// Reduce character rotation into the -PI thru PI range
			rotation = ((rotation + 3 * Math.PI) % (2 * Math.PI)) - Math.PI
		}

		return rotation
	}

	static dist(p1, p2) {
		const data = {
			x: p2.x - p1.x,
			y: p2.y - p1.y
		}
		data.len = Math.sqrt(data.x * data.x + data.y * data.y)
		return data
	}

	static sinD(a) {
		return Math.sin(a * (Math.PI / 180))
	}

	static cosD(a) {
		return Math.cos(a * (Math.PI / 180))
	}

	static tanD(a) {
		return Math.tan(a * (Math.PI / 180))
	}

	static atan2D(y, x) {
		return Math.atan2(y, x) * (180 / Math.PI)
	}

	static angleOfLine(x1, y1, x2, y2) {
		return this.atan2D(y2 - y1, x2 - x1)
	}

	static asinD(r) {
		return Math.asin(r) * (180 / Math.PI)
	}

	static acosD(r) {
		return Math.acos(r) * (180 / Math.PI)
	}

	static mean(a) {
		let m = 0
		const len = a.length
		let i

		for (i = a.length; --i > -1; ) {
			m = a[i] + m
		}

		return m / len
	}

	static median(a) {
		const len = a.length

		a.sort()
		if (len === 0) return 0

		if (len === 1) return a[0]

		if (len % 2 === 1) {
			return a[Math.floor(len * 0.5)]
		} else {
			return this.mean([a[len * 0.5 - 1], a[len * 0.5]])
		}
	}

	static angle(p1, p2) {
		return (Math.atan2(p2.y - p1.y, p2.x - p1.x) * 180) / Math.PI
	}

	static gcd(a, b) {
		let _a = Math.abs(a)
		let _b = Math.abs(b)
		if (_b > _a) {
			const temp = _a
			_a = _b
			_b = temp
		}
		while (true) {
			if (_b === 0) return _a
			_a %= _b
			if (_a === 0) return _b
			_b %= _a
		}
	}

	static lcm(a, b) {
		return (a / Math.gcd(a, b)) * b
	}

	static roundDecimal(nombre, precision) {
		var precision = precision || 2
		var tmp = Math.pow(10, precision)
		let dec = Math.round(nombre * tmp) / tmp

		if (Math.round(nombre) === dec) {
			return dec + '.0'
		} else {
			return dec
		}
	}
}

export default _Math
