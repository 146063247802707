import { css, keyframes } from '@emotion/react'
import Theme from '@styles/theme'
import styled from '@emotion/styled'

const PREFIX = 'product-image'

const translateFrom = {
	2: '5%',
	3: '10%'
}
const translateTo = {
	2: '10%',
	3: '15%'
}

const disapear = (length: number) =>
	keyframes`
		from {
			opacity: 1;
			transform: scale(1) translateY(${length > 3 ? 0 : translateFrom[length]});
			z-index: ${Theme.zIndices.liveProducts + 4};
		}
		to {
			opacity: 0;
			transform: scale(1.2) translateY(${length > 3 ? '25%' : translateTo[length]});
			z-index: ${Theme.zIndices.liveProducts + 4};
		}	
	`

const handleProductLength = (length: number) =>
	length > 1
		? css`
				&.${PREFIX + '-' + length} {
					animation: ${disapear(length)} 1.5s ${Theme.eases.inOutSine} 0s 1 normal;
				}
			`
		: css`
				transition: none !important;
			`

export const classes = {
	root: PREFIX,
	wrapper: `${PREFIX}-wrapper`,
	shadow: `shadow`,
	firstImage: `${PREFIX}-1`,
	secondImage: `${PREFIX}-2`,
	thirdImage: `${PREFIX}-3`,
	hidden: `hidden`
}

export const ProductsImageWrapper = styled.div`
	position: relative;
	min-width: 34px;
	height: 50px;
	margin: 10px;
`

export const ProductImage = styled.div<{ length }>`
	position: absolute;
	width: 100%;
	height: 100%;
	overflow: hidden;
	cursor: pointer;
	transform-origin: top;
	transform: scale(0.55);
	border-radius: 5px;
	& > * {
		border-radius: 5px;
	}
	&.${classes.shadow} {
		box-shadow: 0px 0 2px 0 ${Theme.colors.black(0.3)};
	}
	&.${classes.firstImage} {
		transform: scale(1);
		z-index: ${Theme.zIndices.liveProducts + 3};
		transition: transform 1.5s ${Theme.eases.inOutSine};
	}
	&.${classes.secondImage} {
		top: -3px;
		z-index: ${Theme.zIndices.liveProducts + 2};
		transform: scale(0.85);
		transition: transform 1.5s ${Theme.eases.inOutSine};
	}
	&.${classes.thirdImage} {
		top: -5px;
		z-index: ${Theme.zIndices.liveProducts + 1};
		transform: scale(0.75);
		transition: transform 1.5s ${Theme.eases.inOutSine};
	}
	${({ length }) => handleProductLength(length)}
	${() => css([Theme.squaredEdges])}
`
