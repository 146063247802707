import Cta from '@components/Micro/Cta'
import Icon from '@components/Micro/Icon'
import TextWithUrlAndPhone from '@components/Micro/TextWithUrlAndPhone'
import Popin from '@components/Utils/Popin'
import { mapDispatchToProps } from '@helpers/wrappers/withReduxStore'
import { State } from '@stores/index'
import Theme from '@styles/theme'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { appendEventIdToTosLink as formatTosLink } from 'utils/tos'
import {
	CookieCta,
	CookieText,
	LeaveText,
	Link,
	PopinBody,
	PopinHeader,
	PopinTitle,
	TechnicalCookieOnlyCta
} from './style'

interface Props {
	storeDatas: any
	onAccept: (technicalCookie, analyticsCookie) => void
	onRefuse: () => void
}

const mapStateToProps = (state: State, props: Props) => {
	return { ...state, ...props }
}

const CookieConsent: React.FC<Props> = ({ storeDatas, onAccept, onRefuse }) => {
	const [cookiesRefused, setCookiesRefused] = useState(false)

	const settings = storeDatas.event.event.legalSettings.cookies

	const cookieLink = cookiesRefused ? settings.cookiesConsentRefused.link : settings.cookiesConsent.link

	return storeDatas ? (
		<Popin isOpened={true} isSubmitting={false} closable={false}>
			<PopinHeader>
				<Icon
					name="cookie"
					width="50px"
					height="50px"
					fill={cookiesRefused ? Theme.colors.red() : Theme.colors.black()}
				/>
			</PopinHeader>
			<PopinBody>
				<PopinTitle>
					{cookiesRefused ? settings.cookiesConsentRefused.title : settings.cookiesConsent.title}
				</PopinTitle>

				<CookieText>
					<TextWithUrlAndPhone
						text={
							cookiesRefused
								? settings.cookiesConsentRefused.description
								: settings.cookiesConsent.description
						}
						fieldKey="title"
					/>
					{cookieLink && (
						<Link
							as="span"
							onClick={(e) => {
								e.stopPropagation()
								window.open(formatTosLink(storeDatas.event.event.uuid, cookieLink.url), '_blank')
							}}
						>
							{cookieLink.label}
						</Link>
					)}
				</CookieText>

				<TechnicalCookieOnlyCta width={'100%'} pb={'10px'}>
					<Cta
						label={storeDatas.ui.showroom_cookies_tos.accept_functionnal_cookie}
						size={'small'}
						fullWidth={true}
						type={'border'}
						onClick={() => {
							onAccept(true, false)
						}}
					/>
				</TechnicalCookieOnlyCta>

				<CookieCta>
					<Cta
						label={storeDatas.ui.showroom_cookies_tos.accept_all}
						size={'small'}
						fullWidth={true}
						onClick={() => {
							onAccept(true, true)
						}}
					/>
				</CookieCta>

				{!cookiesRefused && (
					<LeaveText as="a" onClick={() => setCookiesRefused(true)}>
						{storeDatas.ui.showroom_cookies_tos.reject_all}
					</LeaveText>
				)}
				{cookiesRefused && (
					<LeaveText as="a" onClick={onRefuse}>
						{storeDatas.ui.showroom_cookies_tos.leave}
					</LeaveText>
				)}
			</PopinBody>
		</Popin>
	) : (
		<></>
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(CookieConsent)
