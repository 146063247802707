import styled from '@emotion/styled'
import { BaseFlex } from '@styles/common'
import Theme from '@styles/theme'

export const DeactivatedChatWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	height: 100%;
	color: black;
	font-size: 12px;
	gap: ${Theme.spacing(2)};
	text-align: center;
	margin: 0 ${Theme.spacing(8)};
`

export const IconWrapper = styled(BaseFlex)<{ backgroundColor }>`
	align-items: center;
	justify-content: center;
	border-radius: 30px;
	padding: 12.5px;
	background-color: ${({ backgroundColor }) => backgroundColor || Theme.colors.black()};
	margin-bottom: ${Theme.spacing(2)};
`
