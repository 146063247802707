import React, { useEffect, useRef, useState } from 'react'
import Theme from '@styles/theme'
import { BackdropFilter, BackgroundShadow, Poster, PosterPlay, SoundActivate, VideoPlayer, VideoWrapper } from './style'
import getConfig from 'next/config'
import Bus from '@helpers/bus'
import Constants from '@constants'
import { usePlayerContext } from '@helpers/contexts/player'
import Icon from '@components/Micro/Icon'
import Text from '@components/Micro/Text'
import { useOnScreen } from '@helpers/hooks/useOnScreen'
const { publicRuntimeConfig } = getConfig()
const NEXT_PUBLIC_CDN_DOMAIN = publicRuntimeConfig.NEXT_PUBLIC_CDN_DOMAIN

const PrelivePoster: React.FC = () => {
	const { isDesktop, playerData } = usePlayerContext()
	const [isFinished, setIsFinished] = useState<boolean>(false)
	const [endTime, setEndTime] = useState<number>(0)
	const [startTime, setStartTime] = useState<number>(0)
	const [isLoaded, setIsLoaded] = useState<boolean>(false)
	const [isMuted, setIsMuted] = useState<boolean>(true)

	const video = useRef<HTMLVideoElement>()
	const videoWrapperRef = useRef<HTMLDivElement>()
	const hasBeenSeen = useOnScreen(videoWrapperRef)

	const preliveMedias = () => playerData?.event?.event?.content?.preliveMedias
	const hasMedia = () => !!preliveMedias()?.media
	const imageLoadHandler = () => setIsLoaded(true)

	const playClickHandler = (e) => {
		e.stopPropagation()
		e.preventDefault()
		if (hasMedia()) {
			if (video) {
				if (!!isMuted) {
					onPlayHandler()
					video.current?.play()
				} else {
					onPauseHandler()
				}
			}
		}
	}

	const onTimeUpdateHandler = (e) => {
		setEndTime(e?.currentTarget?.currentTime || 0)
	}

	const onEndedHandler = () => {
		setIsFinished(true)
		onPauseHandler()
		if (!isDesktop && document?.exitFullscreen && document.fullscreenElement) {
			document?.exitFullscreen()
		}
		// @ts-ignore
		if (!isDesktop && video.current?.webkitExitFullScreen) {
			// @ts-ignore
			video.current.webkitExitFullScreen()
		}
		// @ts-ignore
		if (!isDesktop && video.current?.mozCancelFullScreen) {
			// @ts-ignore
			video.current.mozCancelFullScreen()
		}
	}

	const onPauseHandler = () => {
		setIsMuted(true)
		const videoDuration = video.current.duration
		const sessionTime = endTime - startTime
		Bus.send(Constants.bus.player.video_teasing_watched, { startTime, endTime, sessionTime, videoDuration })
	}

	const onPlayHandler = () => {
		setIsFinished(false)
		setIsMuted(false)
		setStartTime(video.current?.currentTime || 0)
		if (video && hasBeenSeen) {
			// Set time for analytics
			Bus.send(Constants.bus.player.video_teasing_viewed)
		}
	}

	const isLarge = () => {
		return isDesktop && video.current?.played && !isFinished && !video.current?.muted
	}

	const getAspectRatio = () => {
		if (video.current) {
			return video.current.videoWidth / video.current.videoHeight
		}
	}

	useEffect(() => {
		if (video.current) {
			video.current.muted = isMuted
		}
	}, [isMuted])

	useEffect(() => {
		if (video.current && !isDesktop && !video.current?.muted) {
			if (video.current.requestFullscreen) {
				video.current.requestFullscreen()
			}
			// @ts-ignore
			if (video.current?.webkitEnterFullScreen) {
				// @ts-ignore
				video.current.webkitEnterFullScreen()
			}
			// @ts-ignore
			if (video.current?.mozRequestFullScreen) {
				// @ts-ignore
				video.current.mozRequestFullScreen()
			}
		}
	}, [video.current?.muted])

	const fullscreenchanged = () => {
		// document.fullscreenElement will point to the element that
		// is in fullscreen mode if there is one. If not, the value
		// of the property is null.
		if (!!document?.fullscreenElement) {
			setIsMuted(false)
		} else {
			setIsMuted(true)
		}
	}

	// DARK MAGIC
	useEffect(() => {
		if (video.current) {
			video.current.addEventListener('fullscreenchange', fullscreenchanged)
			video.current.addEventListener('webkitbeginfullscreen', () => setIsMuted(false), false)
			video.current.addEventListener('webkitendfullscreen', () => setIsMuted(true), false)
		}

		return () => {
			if (video.current) {
				video.current.removeEventListener('fullscreenchange', fullscreenchanged)
				video.current.removeEventListener('webkitbeginfullscreen', () => setIsMuted(false), false)
				video.current.removeEventListener('webkitendfullscreen', () => setIsMuted(true), false)
			}
		}
	}, [video.current])

	return !!preliveMedias() ? (
		<>
			<VideoWrapper ref={videoWrapperRef}>
				<Poster
					sizes={[[800, 450]]}
					media={preliveMedias()?.poster || { url: 'medias/default/cover-1600x900.jpg' }}
					color={Theme.colors.background}
					fit="cover"
					onLoad={imageLoadHandler}
				/>
				{hasMedia() && !isFinished && <BackdropFilter />}
				{hasMedia() && hasBeenSeen && (
					<>
						<VideoPlayer
							ref={video}
							src={NEXT_PUBLIC_CDN_DOMAIN + '/' + preliveMedias()?.media?.url}
							onEnded={onEndedHandler}
							onClick={playClickHandler}
							onPlay={(e) => e.preventDefault()}
							onTimeUpdate={onTimeUpdateHandler}
							hidden={isFinished}
							large={isLarge()}
							aspectRatio={getAspectRatio()}
							autoPlay
							muted
							playsInline
						/>
						{isFinished && (
							<PosterPlay className={'poster-play'} hidden={!isLoaded} onClick={playClickHandler}>
								<Icon name="play" width={20} height={20} fill={Theme.colors.text_main} />
							</PosterPlay>
						)}
						{isMuted && !isFinished && (
							<SoundActivate onClick={playClickHandler}>
								<Icon name="full_screen" width={20} height={20} fill={Theme.colors.white()} />
								<Text variant="bold" color={Theme.colors.white()}>
									{playerData.ui.prelive_mobile_video_sound}
								</Text>
							</SoundActivate>
						)}
					</>
				)}
			</VideoWrapper>
			{isLarge() && isDesktop && <BackgroundShadow onClick={onPauseHandler} />}
		</>
	) : (
		<></>
	)
}

export default PrelivePoster
