import { iconClasses } from '@components/Micro/Icon'
import { iconCtaClasses } from '@components/Micro/IconCta'
import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import Theme from '@styles/theme'

const PREFIX = 'like-button'

const bounce = keyframes`
	0% { transform: scale(1); }
	20% {  transform: scale(1); }
	24% { transform: scale(1.1); }
	28% { transform: scale(1); }
	0% { transform: scale(1); }
`

export const classes = {
	root: PREFIX,
	hidden: `hidden`,
	heartBox: `${PREFIX}-heart-box`
}

export const LikeButtonWrapper = styled.div`
	position: relative;
	height: fit-content;
	& .${iconCtaClasses.root} {
		background-color: ${Theme.colors.red()} !important;
		animation: ${bounce} 10000ms ${Theme.eases.bounceIn} infinite;
		transition: opacity 600ms ${Theme.eases.outExpo} 0ms;
		@media (hover: hover) {
			&:hover {
				opacity: 0.5;
			}
		}
		&:active {
			opacity: 0.5;
		}
		&.${classes.hidden} {
			display: none;
		}
		& > .${iconClasses.root} {
			width: 19px;
			height: 17px;
		}
	}
	& .${classes.heartBox} {
		right: -19px;
	}
`
