import React, { Dispatch, ReactElement, SetStateAction, useEffect, useRef, useState } from 'react'
import { Flex, Box } from 'rebass'
import Placeholder from '@components/Micro/Placeholder'
import Theme from '@styles/theme'
import { State } from '@stores/index'
import { connect } from 'react-redux'
import Icon from '@components/Micro/Icon'
import GoToReplay from '@components/Micro/GoToReplay'
import Style from './style'
import { useNotificationsContext } from '@helpers/contexts/notifications'
import Bus from '@helpers/bus'
import Constants from '@constants'
import { useHasBeenSeen } from '@helpers/hooks/useOnScreen'
import { usePlayerContext } from '@helpers/contexts/player'
import PriceComponent from '@components/Micro/Price/Price'

export interface ProductProps {
	index: number
	width?: any
	data?: any
	dispatch?: any
	showStatus?: boolean
	showIndex?: boolean
	showPrice?: boolean
	storeEvent?: any
	forceStatus?: string
	onClick?: any
	twoByColumns?: boolean
	storeDatas?: any
}

const mapStateToProps = (state: State, props: ProductProps) => {
	return { ...state, ...props }
}

const Product: React.FC<ProductProps> = ({
	index,
	data,
	onClick,
	storeDatas,
	showIndex,
	showStatus,
	showPrice,
	twoByColumns = false
}) => {
	data.index = index
	const productClickHandler = () => {
		if (onClick) {
			onClick(data)
		}
	}

	const { panelsQueue } = useNotificationsContext()
	const { fakeStatus } = usePlayerContext()

	const [goToReplay, setGoToReplay]: [ReactElement<any, any>, Dispatch<SetStateAction<ReactElement<any, any>>>] =
		useState(null)
	const productRef = useRef()
	const hasBeenSeen = useHasBeenSeen(productRef)

	let $index = null
	let $status = null
	let $price = 0
	let crossOutPrice = 0

	let statusColor = Theme.colors.black()
	let icon = 'clock'
	let text = storeDatas.ui.panel_catalog_status_waiting

	if (data.status === 'live') {
		statusColor = Theme.colors.green()
		icon = 'chevron_round'
		text = storeDatas.ui.panel_catalog_status_live
	}

	if (data.status === 'passed') {
		statusColor = Theme.colors.black(0.4)
		icon = 'check_round'
		text = storeDatas.ui.panel_catalog_status_passed
	}

	if (!data?.available) {
		text = storeDatas.ui.panel_catalog_status_unavailable
		icon = 'round_cross'
		statusColor = Theme.colors.red()
	}

	const isCurrentProductOpened = () => {
		let i = panelsQueue.length
		while (i--) {
			const panel = panelsQueue[i]
			if (panel.panelType === 'product') {
				if (panel.productId === data.uuid) {
					return true
				}
			}
		}
		return false
	}

	if (!data?.available || showStatus) {
		$status = (
			<Box
				mt={10}
				fontSize={'10em'}
				css={[Style.status, Theme.squaredEdges]}
				backgroundColor={statusColor}
				color={Theme.colors.white()}
				alignItems="center"
			>
				<Box width={10} height={10} css={Style.statusIcon}>
					<Icon name={icon} width="100%" height="100%" fill={Theme.colors.white()} />
				</Box>
				{text}
			</Box>
		)
	}

	if (showIndex) {
		$index = (
			<Flex width={1} fontSize={['18em', '20em']} css={Style.title} color={Theme.colors.text_main}>
				{index + 1 > 9 ? index + 1 : '0' + (index + 1)}
			</Flex>
		)
	}

	if (data.variants?.order?.length) {
		const item = data.variants.combinations.find((combination) => combination.price > 0)
		$price = item?.price || 0
		crossOutPrice = item?.crossOutPrice || 0
	} else {
		$price = data.price
		crossOutPrice = data.crossOutPrice
	}

	useEffect(() => {
		let subscribed = true
		if (storeDatas.event.event.status === 'replay' && subscribed && fakeStatus !== 'live') {
			setGoToReplay(
				<GoToReplay item={data} index={index} width={34} height={34} p={'11px'} style={Style.notif} />
			)
		}
		return () => {
			subscribed = false
		}
	}, [data])

	useEffect(() => {
		if (hasBeenSeen) {
			Bus.send(Constants.bus.player.product_viewed, data)
		}
	}, [hasBeenSeen])

	return (
		<Box css={[Style.el, twoByColumns ? Style.twoByColumns : null]} mb={30} ref={productRef}>
			<Flex width={1} flexWrap="wrap" textAlign="left">
				<Box className="image-product" css={[Style.image, Theme.squaredEdges]} width={1}>
					<Box
						onClick={productClickHandler}
						css={[
							Style.imageBtn,
							data.isOutOfStock ? Style.imageIsOutOfStock : null,
							isCurrentProductOpened() ? Style.isCurrentItem : null
						]}
					>
						<Placeholder
							sizes={[[105, 160]]}
							media={
								data.images.length ? data.images[0] : { url: 'medias/default/product-1050x1600.jpg' }
							}
							color={Theme.colors.background}
							fit="cover"
						/>
					</Box>
					{goToReplay}
				</Box>
				<Box width={1} onClick={productClickHandler} mt={20}>
					{$index}
					<Flex width={1}>{$status}</Flex>
					<Flex width={1} mt={10} css={Style.title} color={Theme.colors.text_main}>
						{data.title}
					</Flex>
					{showPrice && (
						<PriceComponent price={$price} crossOutPrice={crossOutPrice} channel={storeDatas?.channel} />
					)}
				</Box>
			</Flex>
		</Box>
	)
}

export default connect(mapStateToProps, null)(Product)
