import { useNotificationsContext } from '@helpers/contexts/notifications'
import { usePlayerContext } from '@helpers/contexts/player'
import { TranslateXAndFadeTransition } from '@styles/transitions/TranslateXAndFadeTransition'
import { IgnoreEl, ShutterWrapper } from './style'

interface ShutterProps {
	isOpen: boolean
	children: any
}

const Shutters: React.FC<ShutterProps> = ({ isOpen, children }) => {
	const { currentShutter, closeShutter } = useNotificationsContext()
	const { playerData, isMobile } = usePlayerContext()

	const closeHandler = () => {
		if (currentShutter?.config?.onClose) {
			currentShutter?.config?.onClose()
		}

		closeShutter()
	}

	return (
		<TranslateXAndFadeTransition timeout={400} classNames="shutter" in={isOpen} translate={'100%'} unmountOnExit>
			<ShutterWrapper>
				{currentShutter?.config?.hasIgnore && (
					<IgnoreEl onClick={closeHandler} isMobile={!!isMobile}>
						{playerData.ui.csat.ignore}
					</IgnoreEl>
				)}
				{children}
			</ShutterWrapper>
		</TranslateXAndFadeTransition>
	)
}

export default Shutters
