import { Flex } from 'rebass'
import Style from './style'

interface Props {
	width?: number | string
	height?: number | string
	padding?: number | string
}

const Spinner: React.FC<Props> = ({ width = '100%', height = '100%', padding = 'unset' }) => {
	return (
		<Flex
			css={[Style.spinning]}
			width={width}
			height={height}
			alignItems="center"
			justifyContent="center"
			padding={padding}
		/>
	)
}

export default Spinner
