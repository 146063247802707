export interface ModalProps extends ModalConfig {
	isOpen: boolean
	onClose: () => void
	children: any
}

export interface ModalConfig {
	iconProps?: ModalIconProps
	customIcon?: JSX.Element
	titleProps?: ModalTextProps
	descriptionProps?: ModalTextProps
	customDescription?: JSX.Element
	showFireworks?: boolean
	onClose?: () => void
}

interface ModalIconProps {
	bgcolor?: string
	name: string
	largeIcon?: boolean
}

interface ModalTextProps {
	text?: string
	color?: string
	width?: string
}

export interface ModalBuild {
	modalType: ModalsType
	config: ModalConfig
	Component?: JSX.Element
	shouldOpenOnce?: boolean
}

export enum ModalsType {
	SOCIAL_MEDIAS = 'social_medias_popin',
	COMMERCIAL_OPTIN = 'commercial_optin_popin',
	COMMERCIAL_OPTIN_CONFIRM = 'commercial_optin_confirm_popin',
	WINNING_INSTANT_SUBSCRIPTION = 'winning_instant_subscription_popin',
	WINNING_INSTANT_TIMESUP = 'winning_instant_timesup_popin',
	WINNING_INSTANT_WINNER = 'winning_instant_winner_popin',
	WINNING_INSTANT_LOSER = 'winning_instant_loser_popin',
	WINNING_INSTANT_WRONG_ANSWER = 'winning_instant_wrong_answer_popin',
	CONVERSATIONAL_QUIT_REPLAY = 'conversational_quit_replay_popin',
	FIRST_NAME = 'first_name',
	RGPD_OPT_OUT = 'RGPD_opt-out',
	RGPD_OPT_OUT_FORM = 'RGPD_opt_out_form',
	SUCCESS = 'success'
}

export const WinningInstantResultsModals = [
	ModalsType.WINNING_INSTANT_LOSER,
	ModalsType.WINNING_INSTANT_WINNER,
	ModalsType.WINNING_INSTANT_WRONG_ANSWER
]
