import { getDrawerBuild } from '@components/Notifiers/Drawers/library'
import { DrawerContextState, DrawersActionType } from './constants'

export const drawersReducer = (state: DrawerContextState, action: any): DrawerContextState => {
	switch (action.type) {
		case DrawersActionType.OPEN_DRAWER:
			return { drawerOpen: true, currentDrawer: getDrawerBuild(action.drawerType) }
		case DrawersActionType.CLOSE_DRAWER:
			return { drawerOpen: false }
		default:
			return state
	}
}
