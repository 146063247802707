import { css } from '@emotion/react'
import Theme from '@styles/theme'

export default {
	el: css`
		position: sticky;
		top: 0;
		z-index: 200;
	`,

	avatar: css`
		border-radius: 100%;
		overflow: hidden;
		* {
			border-radius: 100%;
			overflow: hidden;
		}
	`,

	title: css`
		font-family: ${Theme.fontFamilies.Main};
		font-weight: ${Theme.fontWeights.bold};
		color: ${Theme.colors.white()};
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		padding: 10px 0;
		br {
			white-space: nowrap;
		}
	`,

	close: css`
		height: 30px;
		width: 30px;
		border-radius: 8px;
		border: 1px solid #fff;
		cursor: pointer;
		position: relative;
		transition: opacity 600ms ${Theme.eases.outExpo} 0ms;
		&:before,
		&:after {
			content: '';
			width: 14px;
			height: 2px;
			background: #fff;
			position: absolute;
			left: 50%;
			top: 50%;
			margin-left: -7px;
		}
		&:before {
			transform: rotate(-45deg);
		}
		&:after {
			transform: rotate(45deg);
		}
		${Theme.fadeAndScaleOnClick()}
	`
}
