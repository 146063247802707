import { css } from '@emotion/react'
import React from 'react'
import { Box } from 'rebass'
import { classes, DivText, FormattedText, SpanText } from './style'

interface Props {
	text: string
	fieldKey: string
	showClickablePhone?: boolean
	isSelectable?: boolean
}

const TextWithUrlAndPhone: React.FC<Props> = ({ text, fieldKey, showClickablePhone = false, isSelectable = false }) => {
	const URL_REGEX =
		/\b((?:https?:\/\/|www\d{0,3}[.]|[a-z0-9.-]+[.][a-z]{2,4}\/)(?:[^\s()<>]+|\(([^\s()<>]+|(\([^\s()<>]+\)))*\))+(?:\(([^\s()<>]+|(\([^\s()<>]+\)))*\)|[^\s`!()[\]{};:'".,<>?«»“”‘’]))/i
	const PHONE_REGEX = /(\+?( |-|\.)?\d{1,2}( |-|\.)?)?(\(?\d{3}\)?|\d{3})( |-|\.)?(\d{3}( |-|\.)?\d{4})/g

	const setHttp = (link) => {
		return link.search(/^http[s]?\:\/\//) == -1 ? 'https://' + link : link
	}

	const setPhone = (number) => `tel:${number}`

	const textWithPhone = () => {
		const newText = text
		const matches = text.match(PHONE_REGEX)
		// Cleanup spaces in phone number
		if (matches) matches.forEach((number) => newText.replace(number, number.replace(' ', '')))

		return newText
	}

	const setLink = (part: string, key: string, isPhoneNumber: boolean = false) => {
		const classeName = isPhoneNumber ? classes.phone : classes.link
		return (
			<SpanText className={classeName} key={key} selectable={isSelectable}>
				<Box
					as="a"
					href={isPhoneNumber ? setPhone(part) : setHttp(part)}
					target="_blank"
					onClick={(e) => e.stopPropagation()}
					color={'inherit'}
					css={css`
						text-decoration: underline;
					`}
				>
					{part}
				</Box>
				{isPhoneNumber ? <Box as="span"> </Box> : null}
			</SpanText>
		)
	}

	const setSpan = (part: string, key: string) => {
		return (
			<SpanText key={key} selectable={isSelectable}>
				{part}{' '}
			</SpanText>
		)
	}

	const formattedText = textWithPhone()
		.split(/\r|\n/) // split text in rows looking for carriage return and new line
		.map((row, i) => {
			// split row in parts looking for spaces
			const formattedRow = row.split(' ').map((part, i) => {
				const key = `message-key-${i}-${fieldKey}`

				if (URL_REGEX.test(part)) {
					return setLink(part, key)
				}

				if (PHONE_REGEX.test(part)) {
					return setLink(part, key, showClickablePhone)
				}

				return setSpan(part, key)
			})
			return (
				<DivText key={`row-${i}-${fieldKey}`} selectable={isSelectable}>
					{formattedRow}
				</DivText>
			)
		})

	return <FormattedText>{formattedText}</FormattedText>
}

export default TextWithUrlAndPhone
