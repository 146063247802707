import Cta from '@components/Micro/Cta'
import { useNotificationsContext } from '@helpers/contexts/notifications'
import { usePlayerContext } from '@helpers/contexts/player'
import Theme from '@styles/theme'
import { LeaveXPText, SurveyH1, SurveyWrapper } from './style'
import Icon from '@components/Micro/Icon'

const SatisfactionSurveyExit: React.FC = () => {
	const { playerData, isReplay, isMobile } = usePlayerContext()
	const { closeShutter, currentShutter } = useNotificationsContext()

	const onExit = () => {
		currentShutter.config.onClose()
		setTimeout(closeShutter, 100)
	}

	const iconSize = () => (isMobile ? 60 : 50)

	return (
		<SurveyWrapper>
			<>
				<Icon name="success_color" width={iconSize()} height={iconSize()} fill={Theme.colors.green()} />
				<SurveyH1>{playerData.ui.csat.thankYou}</SurveyH1>
				{isReplay && (
					<Cta label={playerData.ui.showroom_cta_ended} onClick={closeShutter} justifyContent="center" />
				)}
				<LeaveXPText onClick={onExit}>{playerData.ui.showroom_cookies_tos.leave}</LeaveXPText>
			</>
		</SurveyWrapper>
	)
}

export default SatisfactionSurveyExit
