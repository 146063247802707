import { captureException, setUser } from '@sentry/react'
import { ApiResponseError } from 'types/Errors'

export const handleApiResponseErrors = (response, currentViewer?): void => {
	if (response?.errors?.length) {
		response.errors.forEach((errorData) => {
			// Adds user context to error response from api
			if (currentViewer) {
				setUser(currentViewer)
			}
			captureException(new ApiResponseError(errorData?.error || 'unhandled error'))
		})
	}
}
