import { convReplayButtonClasses } from './../../components/ConversationalNotification/index'
import styled from '@emotion/styled'
import Theme from '@styles/theme'
import { chatClasses } from '../../components/Chat'
import { modularChatBoxClasses } from '../../components/ChatBox'
import { muteButtonClasses } from '../../components/LiveActions/MuteButton'
import { LiveContentWrapper, LivePlayerContainer as BasePlayerContainer } from '../style'
import { drawerClasses } from '@components/Notifiers/Drawers/style'
import { pinnedMessageClasses } from '../../components/PinnedMessage'
import { liveProductsClasses } from '../../components/LiveProducts'
import { classes as productInfoClasses } from '@components/Notifiers/Panels/Product/Description/ProductInfos/style'
import { classes as chatFieldClases } from '../../components/ChatField/style'
import { panelsClasses } from '@components/Notifiers/Panels/style'
import { winningInstantButtonClasses } from '../../components/WinningInstantButton'
import { chatFieldClasses } from '../../components/ChatField/Modular/style'
import { XTranlationAndFadeOverride } from '@styles/transitions/TransitionOverrides'
import { desktopLandscapeClasses } from './classes'

export const ChatAndActionsContainer = styled.div<{ isReplay: boolean }>`
	box-sizing: border-box;
	position: absolute;
	pointer-events: all;
	height: 150px;
	display: flex;
	align-items: flex-end;
	gap: ${Theme.spacing(2)};
	grid-gap: ${Theme.spacing(2)};
	z-index: ${Theme.zIndices.header};
	right: ${Theme.spacing(3)};
	bottom: ${({ isReplay }) => Theme.spacing(isReplay ? 7 : 3)};
	scrollbar-color: transparent transparent;
	*::-webkit-scrollbar {
		display: none; // Safari and Chrome
	}

	&.${desktopLandscapeClasses.cinema} {
		& > div {
			& * {
				opacity: 0;
			}
			& .${muteButtonClasses.root} {
				opacity: 1 !important;
				& * {
					opacity: 1;
				}
			}
		}
	}

	.${pinnedMessageClasses.root} {
		width: 300px;
	}

	& .${modularChatBoxClasses.root} {
		height: 130px;
	}
	& .${chatClasses.root} {
		transition: all 400ms ease-in-out;
		opacity: 1;
		width: 300px;
	}
	&.${desktopLandscapeClasses.panelsOpened} {
		& .${chatClasses.root}, .${modularChatBoxClasses.root}, .${pinnedMessageClasses.root} {
			opacity: 0;
			pointer-events: none;
		}
	}

	& .${pinnedMessageClasses.root} {
		box-sizing: border-box;
	}
`

export const ContentWrapper = styled(LiveContentWrapper)`
	& .${drawerClasses.content} {
		width: 33%;
		position: absolute;
		right: 0;
		border-top-right-radius: 0;
	}

	&.${desktopLandscapeClasses.panelsOpened} {
		& .${productInfoClasses.cta} {
			width: fit-content;
		}

		& .${panelsClasses.root} {
			& .${chatFieldClases.root} {
				border-top: 1px solid ${Theme.colors.grey2};
			}
		}
	}

	& .${chatFieldClasses.xs} {
		min-height: 70px;
	}

	& .${panelsClasses.wrapper} {
		transition: width 400ms ${Theme.eases.inOutSmooth};
	}

	.${convReplayButtonClasses.root} {
		top: ${Theme.spacing(15)};
	}
`

export const LiveProductsContainer = styled.div`
	position: absolute;
	top: ${Theme.spacing(15)};
	left: ${Theme.spacing(3)};

	.${liveProductsClasses.description} {
		align-items: center;
	}

	${XTranlationAndFadeOverride(liveProductsClasses.transition, '-100%')}

	.${liveProductsClasses.description} {
		width: 350px;
	}

	.${liveProductsClasses.button} {
		white-space: unset;
		max-width: 120px;
	}
`

export const LivePlayerContainer = styled(BasePlayerContainer)`
	& .${winningInstantButtonClasses.root} {
		top: 81px;
	}
`

export const WinningInstantButtonWrapper = styled.div`
	position: absolute;
`
