import React from 'react'
import Theme from '@styles/theme'
import Bus from '@helpers/bus'
import Constants from '@constants'
import { usePlayerContext } from '@helpers/contexts/player'
import { useNotificationsContext } from '@helpers/contexts/notifications'
import { ModalsType } from '@components/Notifiers/Modals/constants'
import { useConversationalReplayContext } from '@helpers/contexts/conversationalReplay'
import { StoreConfig } from '@models/stores'
import { State } from '@stores/index'
import { connect } from 'react-redux'
import { IconCtaWrapper, closePlayerButtonClasses } from './style'

interface ClosePlayerButtonProps {
	storeConfig?: StoreConfig
	show?: boolean
}

const mapStateToProps = (state: State, props: ClosePlayerButtonProps) => {
	return { ...state, ...props }
}

const ClosePlayerButton: React.FC<ClosePlayerButtonProps> = ({ show = true, storeConfig }) => {
	const { hasConversationalReplay, isMobile, playerData, isReplay, isLandscape } = usePlayerContext()
	const { hasStartedConversation, iAdvizeIsLoaded } = useConversationalReplayContext()

	const { openModal, modalHistory } = useNotificationsContext()

	const closePlayer = () => {
		Bus.send(Constants.bus.player.event_closed)
	}
	const reducePlayer = () => {
		Bus.send(Constants.bus.player.reduced, { isLandscape })
	}

	const handleClick = (e) => {
		e.stopPropagation()
		if (storeConfig?.hasPip) {
			return reducePlayer()
		}
		if (
			(isMobile || isLandscape) &&
			hasConversationalReplay &&
			!hasStartedConversation &&
			isReplay &&
			iAdvizeIsLoaded
		) {
			const modalType = ModalsType.CONVERSATIONAL_QUIT_REPLAY
			if (modalHistory.includes(modalType)) {
				closePlayer()
			} else {
				openModal(modalType, {
					playerData,
					onStartConversation: () => {
						Bus.send(Constants.bus.player.conversational_replay_clicked)
					},
					onClosePlayer: closePlayer
				})
			}
		} else {
			closePlayer()
		}
	}

	return (
		<>
			{show && (
				<IconCtaWrapper
					iconProps={{
						name: storeConfig.hasPip ? 'chevron' : 'close',
						fill: Theme.colors.white(),
						r: storeConfig.hasPip ? 90 : 0
					}}
					onClick={handleClick}
					className={closePlayerButtonClasses.root}
					hasPip={storeConfig.hasPip}
				/>
			)}
		</>
	)
}

export default connect(mapStateToProps, null)(ClosePlayerButton)
