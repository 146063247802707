import React from 'react'
import {
	AnsweredMessageWrapper,
	AnswerImage,
	AnswerMessageWrapper,
	AnswerTextWrapper,
	AuthorName,
	MessageAvatar,
	MessageText,
	ParentMessageWrapper
} from './style'
import Theme from '@styles/theme'
import Utils from '@utils/index'
import TextWithUrlAndPhone from '@components/Micro/TextWithUrlAndPhone'
import { Message } from 'types/Message'
import { Brand } from 'types/Branding'
import { isEmptyOrSpaces } from '@helpers/EmptyStringChecker'
import getConfig from 'next/config'
const { publicRuntimeConfig } = getConfig()
const NEXT_PUBLIC_IMGIX_DOMAIN = publicRuntimeConfig.NEXT_PUBLIC_IMGIX_DOMAIN

interface Props {
	name: string
	message: Message
	brand: Brand
	isBlock?: boolean
	isLandscapeMobile?: boolean
	className?: string
}

const AnsweredMessage: React.FC<Props> = ({ name, message, brand, isBlock, isLandscapeMobile, className }) => {
	const brandImage = NEXT_PUBLIC_IMGIX_DOMAIN + '/' + brand.avatar.url + '?auto=format'

	return (
		<AnsweredMessageWrapper
			className={className}
			isBlock={isBlock}
			backgroundColor={brand.color}
			isAnswer={!!message.parent_message}
		>
			{/* Parent message */}
			{message.parent_message && (
				<ParentMessageWrapper isBlock={isBlock} backgroundColor={Theme.colors.white()}>
					<AuthorName color={Theme.colors.text_main} isBlock={isBlock} isLandscapeMobile={isLandscapeMobile}>
						{Utils.capitalize(message.parent_user_displayName)}
						<span>&nbsp;:&nbsp;</span>
					</AuthorName>
					<MessageText color={Theme.colors.text_main} isBlock={isBlock} isLandscapeMobile={isLandscapeMobile}>
						{message.parent_value}
					</MessageText>
				</ParentMessageWrapper>
			)}

			{/* Answer */}
			<AnswerMessageWrapper hasParentMessage={!!message.parent_message}>
				<MessageAvatar>
					<AnswerImage src={brandImage} alt={`${brand.name} avatar`} />
				</MessageAvatar>
				<AnswerTextWrapper>
					{!isEmptyOrSpaces(name) && (
						<AuthorName
							color={Theme.colors.white()}
							isBlock={isBlock}
							isLandscapeMobile={isLandscapeMobile}
						>
							{name}
							<span>&nbsp;:&nbsp;</span>
						</AuthorName>
					)}
					<MessageText color={Theme.colors.white()} isBlock={isBlock} isLandscapeMobile={isLandscapeMobile}>
						{
							<TextWithUrlAndPhone
								text={message.value}
								fieldKey="base-message"
								showClickablePhone={message.is_brand}
								isSelectable={true}
							/>
						}
					</MessageText>
				</AnswerTextWrapper>
			</AnswerMessageWrapper>
		</AnsweredMessageWrapper>
	)
}

export default AnsweredMessage
