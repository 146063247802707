import { css } from '@emotion/react'
import Theme from '@styles/theme'

export default {
	date: css`
		font-family: ${Theme.fontFamilies.Second};
		font-weight: ${Theme.fontWeights.bold};
		text-transform: uppercase;
		border-radius: 10px;
	`,
	title: css`
		font-family: ${Theme.fontFamilies.Main};
		font-weight: ${Theme.fontWeights.bold};
		line-height: 140%;
		white-space: pre-wrap;
		@media ${Theme.media(0)} {
			line-height: 130%;
		}
	`
}
