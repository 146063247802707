import { css } from '@emotion/react'
import styled from '@emotion/styled'
import Theme from '@styles/theme'
import { pinnedMessageClasses } from '../../components/PinnedMessage'

const ChatHeight = '18vh'

const translatedStyle = (translated) =>
	translated &&
	css`
		transform: translate3d(0, 20px, 0);
	`

interface ChatBoxWrapperProps {
	isLive: boolean
	translated: boolean
	hidden: boolean
}

export const ChatContainer = styled.div<ChatBoxWrapperProps>`
	box-sizing: border-box;
	position: absolute;
	left: 15px;
	right: 70px;
	height: ${ChatHeight};
	transition:
		transform 400ms ${Theme.eases.inOutCubic},
		opacity 600ms ${Theme.eases.outExpo};
	will-change: transform, opacity;
	opacity: ${({ hidden }) => (hidden ? 0 : 1)};
	pointer-events: all;
	scrollbar-color: transparent transparent;
	*::-webkit-scrollbar {
		display: none; // Safari and Chrome
	}
	width: calc(100% - 165px);
	bottom: ${({ isLive }) => (isLive ? '125px' : '130px')};
	padding-bottom: 10px;
	${({ translated }) => translatedStyle(translated)};

	& .${pinnedMessageClasses.root} {
		position: absolute;
		bottom: 125px;
		width: 260px;
	}
`
