import { ShutterBuild, ShuttersType } from './constants'
import SatisfactionSurveyExit from './SatisfactionSurveyExit'
import SatisfactionSurveyForm from './SatisfactionSurveyForm'

export const getShutterBuild = (shutterType: ShuttersType, metadata: Record<string, any>): ShutterBuild => {
	switch (shutterType) {
		case ShuttersType.CSAT_SHUTTER:
			return {
				shutterType,
				Component: <SatisfactionSurveyForm />,
				config: {
					onClose: metadata.onClose,
					hasIgnore: true
				}
			}
		case ShuttersType.CSAT_SHUTTER_EXIT:
			return {
				shutterType,
				Component: <SatisfactionSurveyExit />,
				config: {
					onClose: metadata.onClose
				}
			}
	}
}
