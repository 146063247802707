import React from 'react'
import { Flex } from 'rebass'
import Style from './style'
import Theme from '@styles/theme'
import Items from '@components/StatusManager/LiveAndReplay/components/Items'
import Grid from '@components/Utils/Grid'
import { mapDispatchToProps } from '@helpers/wrappers/withReduxStore'
import { State } from '@stores/index'
import { connect } from 'react-redux'
import Bus from '@helpers/bus'
import Constants from '@constants'
import { useNotificationsContext } from '@helpers/contexts/notifications'

interface Props {
	storeDatas?: any
	storeEvent?: any
	storeProducts?: any
}

const mapStateToProps = (state: State, props: Props) => {
	return { ...state, ...props }
}

const Program: React.FC<Props> = ({ storeDatas, storeProducts }) => {
	const { closeAllPanels } = useNotificationsContext()

	const productClickHandler = (e) => {
		Bus.send(Constants.bus.player.product_clicked, e)
		Bus.send(Constants.bus.player.link_browsed, { url: e.link.url, target: '_blank' })
		closeAllPanels()
	}

	if (storeDatas && storeProducts.length && storeDatas.event.event.settings.options.hasPreliveProducts) {
		return (
			<Flex
				width={1}
				mt={Theme.preLive.separationValues}
				mb={[50, 50]}
				textAlign={'center'}
				justifyContent="center"
				alignItems="center"
				flexWrap="wrap"
				className="program-wrapper"
			>
				{storeDatas.event.event.content.program.title && (
					<Flex
						width={0.8}
						justifyContent="center"
						fontSize={['24em', '28em']}
						css={Style.title}
						color={Theme.colors.text_main}
						className="program-title"
					>
						{storeDatas.event.event.content.program.title.replace(/(\\r\\n|\\n|\\r)/g, '\n')}
					</Flex>
				)}
				{storeDatas.event.event.content.program.text && (
					<Flex
						width={0.8}
						mt={20}
						justifyContent="center"
						fontSize={['13em', '14em']}
						css={Style.text}
						color={Theme.colors.text_second}
						className="wysiwyg program-text"
						mb={20}
					>
						{storeDatas.event.event.content.program.text.replace(/(\\r\\n|\\n|\\r)/g, '\n')}
					</Flex>
				)}
				{storeProducts.length && (
					<Grid className="program-items">
						<Items
							showIndexes={true}
							showStatuses={false}
							showPrices={storeDatas.event.event.settings.options.hasPrelivePrices}
							products={storeProducts}
							onClick={productClickHandler}
						/>
					</Grid>
				)}
			</Flex>
		)
	} else {
		return <></>
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Program)
