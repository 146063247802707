import styled from '@emotion/styled'
import Theme from '@styles/theme'

export const Text = styled.span<{ color }>`
	color: ${({ color }) => color};
`

export const RegularText = styled(Text)`
	font-weight: ${Theme.fontWeights.regular};
`

export const BoldText = styled(Text)`
	font-weight: ${Theme.fontWeights.bold};
`

export const MainText = styled(Text)`
	font-family: ${Theme.fontFamilies.Main};
`

export const SecondText = styled(Text)`
	font-family: ${Theme.fontFamilies.Second};
`

export const SansSerifText = styled(Text)`
	font-family: ${Theme.fontFamilies.sansSerif};
`

export const BoldSansSerifText = styled(BoldText)`
	font-family: ${Theme.fontFamilies.sansSerif};
`

export const BoldMainText = styled(BoldText)`
	font-family: ${Theme.fontFamilies.Main};
`

export const RegularSecondText = styled(RegularText)`
	font-family: ${Theme.fontFamilies.Second};
`
