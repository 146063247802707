import React, { useEffect, useState } from 'react'
import { mapDispatchToProps } from '@helpers/wrappers/withReduxStore'
import { State } from '@stores/index'
import { connect } from 'react-redux'
import PreLive from '@components/StatusManager/PreLive'
import Live from '@components/StatusManager/LiveAndReplay'
import Bus from '@helpers/bus'
import Constants from '@constants'
import { Box } from 'rebass'
import PlusJakartaSansBold from '@assets/fonts/PlusJakartaSans-Bold.woff2'
import PlusJakartaSansRegular from '@assets/fonts/PlusJakartaSans-Regular.woff2'
import Theme from '@styles/theme'
import getConfig from 'next/config'
import AplozeConsent from '@components/Popins/AplozeConsent'
import { BrandProvider } from '@helpers/contexts/brands'
import { MessagesProvider } from '@helpers/contexts/messages'
import Icon from '@components/Micro/Icon'
import Style from './style'
import { usePlayerContext } from '@helpers/contexts/player'
const { publicRuntimeConfig } = getConfig()
const NEXT_PUBLIC_CDN_DOMAIN = publicRuntimeConfig.NEXT_PUBLIC_CDN_DOMAIN

interface Props {
	dispatch?: (action) => void
	storeDatas?: any
}

const mapStateToProps = (state: State, props: Props) => {
	return { ...state, ...props }
}

const StatusManager: React.FC<Props> = ({ storeDatas, dispatch }) => {
	//
	const { isMobile, isDesktop } = usePlayerContext()
	const [fontsAreLoaded, setFontsAreLoaded] = useState(false)
	const [$tpl, setTpl] = useState(null)

	useEffect(() => {
		if (storeDatas) {
			const toLoad = []
			toLoad.push(
				new FontFace(
					'Main',
					storeDatas.event.event.settings.branding.fonts.fontTitle
						? `url(${NEXT_PUBLIC_CDN_DOMAIN}/${storeDatas.event.event.settings.branding.fonts.fontTitle.url})`
						: `url(${PlusJakartaSansBold})`,
					{
						//@ts-ignore
						weight: Theme.fontWeights.bold
					}
				).load()
			)

			toLoad.push(
				new FontFace(
					'Second',
					storeDatas.event.event.settings.branding.fonts.fontTextBold
						? `url(${NEXT_PUBLIC_CDN_DOMAIN}/${storeDatas.event.event.settings.branding.fonts.fontTextBold.url})`
						: `url(${PlusJakartaSansBold})`,
					{
						//@ts-ignore
						weight: Theme.fontWeights.bold
					}
				).load()
			)

			toLoad.push(
				new FontFace(
					'Second',
					storeDatas.event.event.settings.branding.fonts.fontTextRegular
						? `url(${NEXT_PUBLIC_CDN_DOMAIN}/${storeDatas.event.event.settings.branding.fonts.fontTextRegular.url})`
						: `url(${PlusJakartaSansRegular})`,
					{
						//@ts-ignore
						weight: Theme.fontWeights.regular
					}
				).load()
			)

			Promise.all(toLoad).then((loadedFonts) => {
				loadedFonts.forEach(function (font) {
					document.fonts.add(font)
				})
				setFontsAreLoaded(true)
			})
		}
	}, [storeDatas])

	useEffect(() => {
		if (fontsAreLoaded && storeDatas?.event?.event?.status) {
			Bus.send(Constants.bus.player.loaded, storeDatas?.event?.event).once(
				Constants.bus.library.config_sent,
				(config) => {
					dispatch({ type: 'SET_CONFIG', config })
				}
			)
		}
	}, [fontsAreLoaded])

	useEffect(() => {
		if (fontsAreLoaded && storeDatas?.event?.event?.status) {
			if (storeDatas.event.event.status === 'prelive') {
				setTpl(
					<>
						<PreLive />
						<AplozeConsent />
					</>
				)
			} else {
				setTpl(
					<>
						<Live />
						<AplozeConsent />
					</>
				)
			}
		}
	}, [fontsAreLoaded, storeDatas?.event?.event?.status, isMobile, isDesktop])

	return (
		<BrandProvider>
			<MessagesProvider>
				{$tpl}
				{!$tpl && (
					<Box width={40} height={40} css={Style.template}>
						<Icon name={'spinner'} width="100%" height="100%" fill={Theme.colors.white()} />
					</Box>
				)}
			</MessagesProvider>
		</BrandProvider>
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(StatusManager)
