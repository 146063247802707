import React, { useEffect, useRef, useState } from 'react'
import { mapDispatchToProps } from '@helpers/wrappers/withReduxStore'
import { State } from '@stores/index'
import { connect } from 'react-redux'
import getConfig from 'next/config'
const { publicRuntimeConfig } = getConfig()
const NEXT_PUBLIC_API_ENDPOINT = publicRuntimeConfig.NEXT_PUBLIC_API_ENDPOINT
import Theme from '@styles/theme'
import Icon from '@components/Micro/Icon'
import { Flex, Box } from 'rebass'
import Field from '@components/Micro/Field'
import Style from './style'
import Utils from '@utils/index'
import Cta from '@components/Micro/Cta'
import { css } from '@emotion/react'
import TextWithUrlAndPhone from '@components/Micro/TextWithUrlAndPhone'
import { useViewer } from '@helpers/contexts/viewerContext'
import { useNotificationsContext } from '@helpers/contexts/notifications'
import { ModalsType } from '../constants'
import { usePollsContext } from '@helpers/contexts/polls'
import { StoreConfig } from '@models/stores'
import Bus from '@helpers/bus'
import Constants from '@constants'
import TermsOfServicesComponent from '@components/Templates/TermsOfServices'
import { handleApiResponseErrors } from '@helpers/contexts/fetchHelpers'

interface CommercialOptinSettings {
	title: string
	description: string
	text: string
}

interface Props {
	playerData?: any
	storeConfig?: StoreConfig
	commercialOptinSettings: CommercialOptinSettings
	tracker?: any
	onClose: () => void
	dispatch?: (action) => void
}

const mapStateToProps = (state: State, props: Props) => {
	return { ...state, ...props }
}

const CommercialOptinPopin: React.FC<Props> = ({
	playerData,
	storeConfig,
	commercialOptinSettings,
	tracker,
	dispatch
}) => {
	/**
	 * @States
	 */
	const { openModal } = useNotificationsContext()
	const { currentViewer, updateCurrentViewer } = useViewer()
	const { poll } = usePollsContext()
	const [email, setEmail] = useState<string>('')
	const [name, setName] = useState<string>('')
	const [hasOptin, setHasOptin] = useState<boolean>(false)
	const [emailIsNotValid, setEmailIsNotValid] = useState<boolean>(false)
	const [nameIsNotValid, setNameIsNotValid] = useState<boolean>(false)
	const [optinExperienceIsNotValid, setOptinExperienceIsNotValid] = useState<boolean>(false)
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

	const tosRef = useRef(null)

	/**
	 * @Effects
	 */
	useEffect(() => {
		setName(currentViewer?.firstName || '')
		setEmail(currentViewer?.email || '')
		setHasOptin(!!currentViewer?.emailCommercialOptIn)
	}, [currentViewer])

	/**
	 * @Methods
	 */
	const submit = () => {
		if (playerData.event.event.status === 'live') {
			let hasError = false
			if (!Utils.is.isEmail(email)) {
				setEmailIsNotValid(true)
				hasError = true
			} else {
				setEmailIsNotValid(false)
			}

			if (name.length < 1) {
				setNameIsNotValid(true)
				hasError = true
			} else {
				setNameIsNotValid(false)
			}

			if (!hasOptin) {
				setOptinExperienceIsNotValid(true)
				hasError = true
			} else {
				setOptinExperienceIsNotValid(false)
			}

			const acceptedTos = tosRef?.current?.validate()
			if (!storeConfig?.consent?.tos && !acceptedTos) {
				tosRef?.current?.setError()
				hasError = true
			}

			if (!hasError) {
				updateCurrentViewer({ firstName: name, email, emailCommercialOptIn: hasOptin }).then(() => {
					if (acceptedTos) {
						const consent = {
							...storeConfig.consent,
							tos: true
						}
						Bus.send(Constants.bus.player.legal_consented, consent)
						dispatch({ type: 'SET_CONSENT', ...consent })
					}
				})
				subscribe()
			}
		}
	}

	const subscribe = async () => {
		if (isSubmitting) return

		setIsSubmitting(true)
		setCommercialOptinMethod()
			.then((res) => {
				if (!res?.errors) {
					setIsSubmitting(false)
				}
			})
			.catch((error) => {
				console.log(error)
				setIsSubmitting(false)
			})
	}

	const optinClickHandler = () => {
		setHasOptin((current) => {
			return !current
		})
	}

	const setCommercialOptinMethod = () => {
		let anonId = null
		if (tracker) anonId = tracker.getAnonId()

		return fetch(`${NEXT_PUBLIC_API_ENDPOINT}/api/public/events/${playerData.event.event.uuid}/consents`, {
			body: JSON.stringify({
				viewerId: currentViewer?.uid,
				email: email,
				name: name,
				hasCommercialOptin: hasOptin,
				domainId: storeConfig.domain,
				interactionId: poll?.uuid,
				eventId: playerData.event.event.uuid,
				anonId: anonId
			}),
			headers: {
				'Content-Type': 'application/json'
			},
			method: 'POST'
		}).then((res) => {
			handleApiResponseErrors(res, currentViewer)
			openModal(ModalsType.COMMERCIAL_OPTIN_CONFIRM, { playerData }, true)
			return res.json()
		})
	}

	return (
		<Flex width={1} flexWrap="wrap" as="form" action="" textAlign="left" mt={30}>
			<Field
				label={`${playerData.ui.alert_sms_form_firstname_label || 'FIRST NAME'}*`}
				name="name"
				type="text"
				value={name}
				isRequired={true}
				hasError={nameIsNotValid}
				onChange={(e) => {
					setName(e.currentTarget.value)
				}}
			/>
			<Field
				label={`${playerData.ui.commercial_optin_email || 'E-MAIL'}*`}
				name="email"
				type="text"
				value={email}
				isRequired={true}
				requiredLabel={playerData.ui.alert_sms_form_required}
				hasError={emailIsNotValid}
				onChange={(e) => {
					setEmail(e.currentTarget.value)
				}}
			/>
			<Flex width={1} flexWrap={'wrap'} justifyContent="space-between" onClick={optinClickHandler}>
				<Box
					width={16}
					height={16}
					p={'3px'}
					css={[
						Style.optinCheck,
						hasOptin ? Style.optinCheckIsChecked : null,
						css`
							border: 1px solid ${optinExperienceIsNotValid ? Theme.colors.red() : Theme.colors.text_main};
						`
					]}
				>
					<Icon name="check" width="100%" height="100%" fill={Theme.colors.text_main} />
				</Box>

				<Box
					width={'calc(100% - 25px)'}
					css={[Style.optinText]}
					fontSize={'11em'}
					color={optinExperienceIsNotValid ? Theme.colors.red() : Theme.colors.text_main}
					mb={[storeConfig?.consent?.tos ? 50 : 10]}
				>
					<TextWithUrlAndPhone text={commercialOptinSettings.text} fieldKey="optin-text" />
				</Box>
			</Flex>
			{!storeConfig?.consent?.tos && <TermsOfServicesComponent innerRef={tosRef} spacing={10} />}
			<Box mx="auto">
				<Cta
					label={playerData.ui.message_modal_submit}
					onClick={submit}
					customPadding={'20px 30px !important'}
				/>
			</Box>
		</Flex>
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(CommercialOptinPopin)
