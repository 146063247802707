import { iconCtaClasses } from '@components/Micro/IconCta'
import styled from '@emotion/styled'
import Theme from '@styles/theme'
import { chatFieldClasses } from '../ChatField'
import { chatMessageClasses } from '../ChatMessage'
import { liveActionsClasses } from '../LiveActions'

const PREFIX = 'chat'

const widthTransition = `width 400ms ${Theme.eases.inOutCubic}`
const transformTransition = `transform 400ms ${Theme.eases.inOutCubic}`
const opacityTransition = `opacity 400ms ${Theme.eases.inOutCubic}`

export const classes = {
	root: PREFIX,
	floating: `floating`,
	fieldFocused: `${PREFIX}-field-focused`
}

export const ChatWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	height: 100%;
	width: 100%;
	&:not(.${classes.floating}) .${chatMessageClasses.root} {
		margin: 0 ${Theme.spacing(4)};
	}
`

export const ChatContainer = styled.div`
	width: 100%;
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: flex-end;
	& .${chatFieldClasses.root} {
		position: relative;
		flex-grow: 1;
	}
	& .${liveActionsClasses.root} {
		min-height: 60px;
		margin-right: ${Theme.spacing(3)};
		flex-direction: row-reverse;
		transition:
			${opacityTransition} 100ms,
			${transformTransition} 100ms,
			${widthTransition} 100ms;
		will-change: opacity, transform, width;

		& .${iconCtaClasses.root} {
			background-color: ${Theme.colors.black()};
		}
	}
	&.${classes.fieldFocused} {
		& .${liveActionsClasses.root} {
			opacity: 0;
			transform: translateX(20px) scale(0);
			width: 0;
			transition: ${opacityTransition}, ${transformTransition}, ${widthTransition};
			pointer-events: none;
		}
	}
`
