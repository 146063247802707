const PREFIX = 'mobile-landscape-template'

export const mobileLandscapeClasses = {
	root: PREFIX,
	chatPanel: `${PREFIX}-chat-panel`,
	chatPanelButton: `${PREFIX}-chat-panel-button`,
	shadow: `${PREFIX}-shadows`,
	headerHide: `${PREFIX}-header-hide`,
	horizontal: `${PREFIX}-horizontal`,
	vertical: `${PREFIX}-vertical`,
	pip: `${PREFIX}-pip`,
	headerFullWidth: 'header-full-width'
}
