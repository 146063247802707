const Constants = {
	cookies: {
		technical: '_aplz_tc.',
		analytics: '_aplz_ac.'
	},
	bus: {
		player: {
			play: 'APLOZE_BUS_PLAYER_PLAY',
			pause: 'APLOZE_BUS_PLAYER_PAUSE',
			loaded: 'APLOZE_BUS_PLAYER_LOADED',
			closed: 'APLOZE_BUS_PLAYER_CLOSED',
			reduced: 'APLOZE_BUS_PLAYER_REDUCED',
			legal_consented: 'APLOZE_BUS_PLAYER_LEGAL_CONSENTED',
			event_participated: 'APLOZE_BUS_PLAYER_EVENT_PARTICIPATED',
			event_closed: 'APLOZE_BUS_PLAYER_EVENT_CLOSED',
			subscribe_clicked: 'APLOZE_BUS_PLAYER_SUBSCRIBE_CLICKED',
			subscribe_calendar_clicked: 'APLOZE_BUS_PLAYER_SUBSCRIBE_CALENDAR_CLICKED',
			event_added_to_calendar: 'APLOZE_BUS_PLAYER_EVENT_ADDED_TO_CALENDAR',
			subscribe_sms_clicked: 'APLOZE_BUS_PLAYER_SUBSCRIBE_SMS_CLICKED',
			event_subscribed_by_sms: 'APLOZE_BUS_PLAYER_EVENT_SUBSCRIBED_BY_SMS',
			unsubscribe_clicked: 'APLOZE_BUS_PLAYER_UNSUBSCRIBE_CLICKED',
			event_unsubscribed_by_sms: 'APLOZE_BUS_PLAYER_EVENT_UNSUBSCRIBED_BY_SMS',
			share_clicked: 'APLOZE_BUS_PLAYER_SHARE_CLICKED',
			event_shared: 'APLOZE_BUS_PLAYER_EVENT_SHARED',
			shopper_network_clicked: 'APLOZE_BUS_PLAYER_SHOPPER_NETWORK_CLICKED',
			brand_network_clicked: 'APLOZE_BUS_PLAYER_BRAND_NETWORK_CLICKED',
			link_browsed: 'APLOZE_BUS_PLAYER_LINK_BROWSED',
			like_clicked: 'APLOZE_BUS_PLAYER_LIKE_CLICKED',
			catalog_clicked: 'APLOZE_BUS_PLAYER_CATALOG_CLICKED',
			cart_clicked: 'APLOZE_BUS_PLAYER_CART_CLICKED',
			product_clicked: 'APLOZE_BUS_PLAYER_PRODUCT_CLICKED',
			product_viewed: 'APLOZE_BUS_PLAYER_PRODUCT_VIEWED',
			product_page_clicked: 'APLOZE_BUS_PLAYER_PRODUCT_PAGE_CLICKED',
			product_added_to_cart: 'APLOZE_BUS_PLAYER_PRODUCT_ADDED_TO_CART',
			product_added_to_cart_success: 'APLOZE_BUS_PLAYER_PRODUCT_ADDED_TO_CART_SUCCESS',
			event_status_changed: 'APLOZE_BUS_PLAYER_EVENT_STATUS_CHANGED',
			conversational_replay_clicked: 'APLOZE_BUS_PLAYER_CONVERSATIONAL_REPLAY_CLICKED',
			interaction_distributed: 'APLOZE_BUS_PLAYER_INTERACTION_DISTRIBUTED',
			interaction_clicked: 'APLOZE_BUS_PLAYER_INTERACTION_CLICKED',
			interaction_viewed: 'APLOZE_BUS_PLAYER_INTERACTION_VIEWED',
			history_changed: 'APLOZE_BUS_PLAYER_HISTORY_CHANGED',
			video_teasing_watched: 'APLOZE_BUS_PLAYER_VIDEO_TEASING_WATCHED',
			video_teasing_viewed: 'APLOZE_BUS_PLAYER_VIDEO_TEASING_VIEWED',
			manage_cookie: 'APLOZE_BUS_PLAYER_MANAGE_COOKIE',
			csat_viewed: 'APLOZE_BUS_PLAYER_CSAT_VIEWED',
			viewer_loaded: 'APLOZE_BUS_PLAYER_VIEWER_LOADED'
		},
		library: {
			config_sent: 'APLOZE_BUS_LIBRARY_CONFIG_SENT',
			opened: 'APLOZE_BUS_LIBRARY_OPENED',
			closed: 'APLOZE_BUS_LIBRARY_CLOSED',
			reduced: 'APLOZE_BUS_LIBRARY_REDUCED',
			expanded: 'APLOZE_BUS_LIBRARY_EXPANDED',
			cart_callback: 'APLOZE_BUS_LIBRARY_CART_CALLBACK',
			iadvize_chatbox_notified: 'APLOZE_BUS_LIBRARY_IADVIZE_CHATBOX_NOTIFIED',
			remove_last_panel: 'APLOZE_BUS_LIBRARY_REMOVE_LAST_PANEL',
			iadvize_loaded: 'APLOZE_BUS_LIBRARY_IADVIZE_LOADED',
			conversational_replay_closed: 'APLOZE_BUS_LIBRARY_CONVERSATIONAL_REPLAY_CLOSED',
			consent_updated: 'APLOZE_BUS_LIBRARY_CONSENT_UPDATED'
		}
	}
}

export default Constants
