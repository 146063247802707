import React, { useEffect, useMemo, useRef, useState } from 'react'
import { LiveProductsCard, liveProductsClasses } from '@components/StatusManager/LiveAndReplay/components/LiveProducts'
import Timeline from '@components/StatusManager/LiveAndReplay/components/Timeline'
import { mapDispatchToProps } from '@helpers/wrappers/withReduxStore'
import { State } from '@stores/index'
import dynamic from 'next/dynamic'
import { connect } from 'react-redux'
import { GenericPlayerTemplateProps } from '../constant'
import { classes as globalClasses, livePlayerclasses } from '../style'
import PausePlayButton from '../../components/PausePlayButton'
import Panels from '@components/Notifiers/Panels'
import { useNotificationsContext } from '@helpers/contexts/notifications'
import WinningInstantButton from '../../components/WinningInstantButton'
import { PanelsType } from '@components/Notifiers/Panels/constants'
import {
	LiveProductsContainer,
	ShadowContainer,
	ContentWrapper,
	Header,
	LivePlayerContainer,
	ChatContainer
} from './style'
import LiveActions, { AvailableAction } from '../../components/LiveActions'
import { classGenerator as cx } from '@styles/sharedLogics'
import Drawer from '@components/Notifiers/Drawers'
import { ModalsType } from '@components/Notifiers/Modals/constants'
import { usePlayerContext } from '@helpers/contexts/player'
import { usePollsContext } from '@helpers/contexts/polls'
import { DrawersType } from '@components/Notifiers/Drawers/constants'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import Chat from '../../components/Chat'
import Unmute from '../../components/Unmute'
import ConversationalNotification from '../../components/ConversationalNotification'
import { useConversationalReplayContext } from '@helpers/contexts/conversationalReplay'
import Shutters from '@components/Notifiers/Shutters'
import { mobileLandscapeClasses } from './classes'

const Livestream = dynamic(() => import('@components/StatusManager/LiveAndReplay/components/Livestream'), {
	ssr: false
})

const mapStateToProps = (state: State, props: GenericPlayerTemplateProps) => {
	return { ...state, ...props }
}

const MobileLandscapeTemplate: React.FC<GenericPlayerTemplateProps> = ({
	/** @Refs */
	trackerRef,
	livestreamRef,
	timelineRef,
	chatboxRef,
	/** @Booleans */
	isStarted,
	isMuted,
	isReduced,
	isReplay,
	isReplayOrFinishing,
	isDragged,
	hasConversationalReplay,
	/** @Values */
	playerData,
	products,
	currentStep,
	/** @Setters */
	onDrag,
	onWrite,
	onPlay,
	onPlayOnReady,
	onPause,
	onLiveStreamPlay,
	onSeekTo
}) => {
	const {
		panelsQueue,
		landscapeHorizontalChatOpened,
		openPanel,
		closeDrawer,
		drawerOpen,
		firstNameDrawerOpened,
		openModal,
		pollDrawerOpened,
		currentDrawer,
		pollPanelOpened,
		closeChat,
		closeModal,
		openDrawer,
		firstNameModalOpened,
		closePollPanel,
		catalogOpened
	} = useNotificationsContext()
	const { onPollClose } = usePollsContext()
	const { isMobile, isVertical, isPlayed, isReady, isWriting, playOnReady, hasPlayedOnReady } = usePlayerContext()
	const { isPip, shutterOpen, currentShutter } = useNotificationsContext()
	const { isConversing } = useConversationalReplayContext()
	const [showActions, setShowActions] = useState<boolean>(false)
	const shadowRef = useRef(null)
	const pausePlayRef = useRef(null)

	/**
	 * @Methods
	 */
	const handleShadowClick = () => {
		if (shadowRef.current.classList.contains(globalClasses.shadow)) {
			setShowActions(!showActions)
			if (pausePlayRef?.current) {
				pausePlayRef.current.showPauseBtn(!showActions)
			}
		}
	}

	const onVerticalModeSwitch = () => {
		if (landscapeHorizontalChatOpened) {
			closeChat()
		}

		if (firstNameModalOpened) {
			closeModal()
			openDrawer(DrawersType.FIRST_NAME_DRAWER)
		}

		if (pollPanelOpened) {
			closePollPanel()
			openDrawer(DrawersType.POLL_DRAWER)
		}
	}

	const onHorizontalModeSwicth = () => {
		// Initialize chat opened by default on horizontal mode
		if (firstNameDrawerOpened) {
			closeDrawer()
			openModal(ModalsType.FIRST_NAME, { onClose: closeModal, isModal: true })
		}

		if (pollDrawerOpened) {
			closeDrawer()
			if (!pollPanelOpened) {
				openPanel(PanelsType.POLL, {
					back: playerData?.ui.panel_back,
					onClose: onPollClose
				})
			}
		}

		if (!landscapeHorizontalChatOpened && !catalogOpened) {
			openPanel(PanelsType.LANDSCAPE_HORIZONTAL_CHAT, {
				back: playerData?.ui.panel_chat_title,
				onWrite: onWrite,
				isMobile
			})
		}
	}

	const mobileLandscapeData = useMemo(
		() => ({
			winningInstantButtonProps: isVertical
				? {
						top: 'auto',
						bottom: '20px',
						right: '15px',
						left: 'auto'
					}
				: {
						left: '15px',
						right: 'auto'
					},
			liveActions: (isVertical ? ['ROTATE'] : ['CART', 'CATALOG', 'LIKE', 'ROTATE']) as AvailableAction[],
			headerActions: (isVertical
				? ['CLOSE_PLAYER', 'OTHERS']
				: ['CLOSE_PLAYER', 'OTHERS', 'OPEN_CHAT']) as AvailableAction[]
		}),
		[isVertical, drawerOpen, currentDrawer, currentStep, products, chatboxRef, trackerRef]
	)

	const playHandler = () => {
		if (showActions) setShowActions(false)
		onPlay()
	}

	const pauseHandler = () => {
		if (showActions) setShowActions(false)
		onPause()
	}

	/**
	 * @Effects
	 */

	useEffect(() => {
		if (isReady && playOnReady && !hasPlayedOnReady && !isPlayed) {
			onPlayOnReady()
		}
	}, [isPlayed, isReady, playOnReady])

	useEffect(() => {
		if (!isConversing)
			if (!isMuted) {
				if (!isVertical) {
					onHorizontalModeSwicth()
				} else {
					onVerticalModeSwitch()
				}
			}
	}, [isMuted, isVertical])

	useEffect(() => {
		if (!isPlayed && !isMuted) return setShowActions(true)
	}, [isPlayed])

	return (
		<ContentWrapper
			isReduced={isReduced}
			isLandscape={true}
			isMobile={true}
			chatOpened={landscapeHorizontalChatOpened && !isVertical}
			className={cx(livePlayerclasses.root, mobileLandscapeClasses.root, {
				[mobileLandscapeClasses.horizontal]: !isVertical,
				[mobileLandscapeClasses.vertical]: isVertical
			})}
		>
			<Shutters isOpen={shutterOpen}>
				{currentShutter?.Component && React.cloneElement(currentShutter.Component)}
			</Shutters>
			<LivePlayerContainer
				isReduced={isReduced}
				panelsOpened={!isVertical && panelsQueue.length > 0}
				className={cx({
					[mobileLandscapeClasses.horizontal]: !isVertical,
					[mobileLandscapeClasses.vertical]: isVertical,
					[mobileLandscapeClasses.pip]: isPip
				})}
			>
				{!isVertical && !isReduced && (
					<Drawer isOpen={drawerOpen}>
						{currentDrawer?.Component && React.cloneElement(currentDrawer.Component)}
					</Drawer>
				)}
				{!isReduced && (
					<>
						<WinningInstantButton
							trackerRef={trackerRef}
							buttonProps={mobileLandscapeData.winningInstantButtonProps}
						/>
						{isMuted && <Unmute onPlayClick={onPlay} playerIsUnmuted={!isMuted} playerIsReady={isReady} />}

						{/* Hide if panels opened in vertical mode */}
						{showActions && ((!isPip && isVertical) || !isVertical) && (
							<Header
								noAvatar={!isVertical}
								noTitle={!isVertical}
								className={cx({
									[mobileLandscapeClasses.headerHide]: !showActions,
									[mobileLandscapeClasses.headerFullWidth]: isVertical || !panelsQueue.length
								})}
								actions={mobileLandscapeData.headerActions}
							/>
						)}
					</>
				)}
				<Livestream
					innerRef={livestreamRef}
					onPlaying={onLiveStreamPlay}
					isLandscape={true}
					pipEnabled={isVertical}
				/>
				{!isReduced && (
					<ShadowContainer
						isReplay={isReplay}
						height={['-webkit-fill-available', '100vh', '100%']}
						ref={shadowRef}
						className={cx(mobileLandscapeClasses.shadow, {
							[globalClasses.replay]: isReplayOrFinishing,
							[globalClasses.visible]: !isMuted || isStarted,
							[globalClasses.shadowHideActions]: !showActions,
							[mobileLandscapeClasses.horizontal]: !isVertical,
							[mobileLandscapeClasses.vertical]: isVertical,
							[globalClasses.shadow]: isPlayed
						})}
						onClick={handleShadowClick}
					>
						<LiveActions column products={products} actions={mobileLandscapeData.liveActions} />
						<PausePlayButton
							isDragged={isDragged}
							onPause={pauseHandler}
							onPlay={playHandler}
							innerRef={pausePlayRef}
						/>
						{isReplay && <Timeline innerRef={timelineRef} seekTo={onSeekTo} setIsDragged={onDrag} />}
						{!isVertical && (
							<LiveProductsContainer isReplay={isReplay}>
								<LiveProductsCard togglable />
							</LiveProductsContainer>
						)}
					</ShadowContainer>
				)}
				{isReplay && hasConversationalReplay && <ConversationalNotification isHidden={isReduced} />}
			</LivePlayerContainer>
			{!isReduced && (
				<>
					<Panels isLandscape={!isVertical} />
					{isVertical && (
						<ChatContainer isWriting={isWriting}>
							<TransitionGroup className="product-transition">
								<CSSTransition
									timeout={800}
									classNames={liveProductsClasses.card}
									in={!!currentStep}
									unmountOnExit
								>
									<LiveProductsCard block />
								</CSSTransition>
							</TransitionGroup>

							<Chat
								onWrite={onWrite}
								chatRef={chatboxRef}
								products={products}
								actions={['CART', 'CATALOG', 'LIKE']}
							/>
						</ChatContainer>
					)}

					{!isReduced && (
						<Drawer isOpen={drawerOpen}>
							{currentDrawer?.Component && React.cloneElement(currentDrawer.Component)}
						</Drawer>
					)}
				</>
			)}
		</ContentWrapper>
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(MobileLandscapeTemplate)
