import React, { useEffect, useRef, useState } from 'react'
import { Flex, Box } from 'rebass'
import Style, { classes, FireworksWrapper } from './style'
import Theme from '@styles/theme'
import Icon from '@components/Micro/Icon'
import { css } from '@emotion/react'
import { mapDispatchToProps } from '@helpers/wrappers/withReduxStore'
import { State } from '@stores/index'
import { connect } from 'react-redux'
import { Fireworks } from 'fireworks-js'
import { usePlayerContext } from '@helpers/contexts/player'
interface Props {
	closeClickHandler?: any
	isOpened?: boolean
	isSubmitting?: boolean
	children?: any
	storeModal?: Record<string, any>
	maxWidth?: string | number
	showFireworks?: boolean
	closable?: boolean
}

const mapStateToProps = (state: State, props: Props) => {
	return { ...state, ...props }
}

const Popin: React.FC<Props> = ({
	closeClickHandler,
	isOpened,
	isSubmitting,
	children,
	maxWidth,
	showFireworks,
	closable = true
}) => {
	const [isSmall, setIsSmall] = useState(false)
	const [fireworks, setFireworks] = useState<any>(null)
	const { isReduced, displayMode, isVertical } = usePlayerContext()

	const contentRef = useRef()
	const fireworksRef = useRef(null)

	const checkSizes = () => {
		//@ts-ignore
		const s = contentRef.current.getBoundingClientRect()
		if (s.height > window.innerHeight - 80) {
			setIsSmall(false)
		} else {
			if (s.height !== 0) {
				setIsSmall(true)
			}
		}
	}

	useEffect(() => {
		checkSizes()
	})

	useEffect(() => {
		let theFireworks = null
		if (showFireworks && fireworksRef.current) {
			const container = fireworksRef.current
			theFireworks = new Fireworks(container, {
				rocketsPoint: { min: 50, max: 70 },
				hue: { min: 0, max: 360 },
				delay: { min: 5, max: 15 },
				acceleration: 1.02,
				friction: 0.97,
				gravity: 1.3,
				particles: 60,
				traceSpeed: 3,
				explosion: 6,
				autoresize: true,
				brightness: {
					min: 50,
					max: 90
				},
				decay: { min: 0.015, max: 0.04 },
				mouse: {
					click: false,
					move: false,
					max: 3
				},
				boundaries: {
					x: 50,
					y: 50,
					width: container.clientWidth,
					height: container.clientHeight
				},
				sound: {
					enabled: false
				}
			})
			setFireworks(theFireworks)
			theFireworks?.start()
		}

		return () => {
			if (showFireworks && fireworksRef.current) {
				setFireworks(null)
				theFireworks?.stop() || fireworks?.stop()
				theFireworks?.clear() || fireworks?.clear()
			}
		}
	}, [showFireworks, isReduced])

	const onClick = () => {
		if (fireworks) {
			fireworks.stop()
			fireworks.clear()
			setFireworks(null)
		}

		if (closable) {
			closeClickHandler()
		}
	}

	return (
		<Flex
			width={1}
			css={[
				Style.el,
				isOpened ? Style.elIsOpened : null,
				isReduced
					? css`
							display: none !important;
							overflow: hidden;
						`
					: null
			]}
			justifyContent="center"
			alignItems="center"
			flexWrap="wrap"
			display={[
				!isSmall ? 'flex' : 'block !important',
				displayMode === 'MOBILE_LANDSCAPE' && !isVertical ? 'flex' : 'block'
			]}
		>
			{showFireworks && !isReduced && <FireworksWrapper ref={fireworksRef} onClick={onClick}></FireworksWrapper>}
			<Box
				css={[
					Style.overlay,
					isOpened ? Style.overlayIsOpened : null,
					!closable ? Style.notClosableOverlay : null
				]}
				width={1}
				height={'100%'}
				onClick={isSubmitting ? null : onClick}
			/>
			<Box
				className={classes.content}
				css={[
					Style.content,
					isOpened ? Style.contentIsOpened : null,
					isSmall ? Style.contentIsSmall : null,
					displayMode === 'MOBILE_LANDSCAPE' && !isVertical ? Style.smallPadding : null,
					Theme.squaredEdges
				]}
				maxWidth={[maxWidth || Theme.grid.maxWidth, '600px']}
				width={0.9}
				backgroundColor={Theme.colors.white()}
				p={['50px 20px 30px 20px', '50px', '50px 80px']}
				m={'0px auto'}
				ref={contentRef}
			>
				<Box css={[Style.spinner, isSubmitting ? Style.spinnerIsShown : null]} />
				{closable && (
					<Box
						width={25}
						height={24}
						p={'6px'}
						css={Style.close}
						onClick={isSubmitting ? null : onClick}
						className="popin-close-button"
					>
						<Icon name="close" width="100%" height="100%" fill={Theme.colors.text_main} />
					</Box>
				)}
				<Flex width={1} flexWrap={'wrap'} css={isSubmitting ? Style.isSubmitting : null}>
					<Box width={1}>{children}</Box>
				</Flex>
			</Box>
		</Flex>
	)
}

export const popinClasses = classes

export default connect(mapStateToProps, mapDispatchToProps)(Popin)
