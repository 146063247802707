const { publicRuntimeConfig } = getConfig()
import getConfig from 'next/config'

const NEXT_PUBLIC_CDN_DOMAIN = publicRuntimeConfig.NEXT_PUBLIC_CDN_DOMAIN
const aplozeTosLink = `${NEXT_PUBLIC_CDN_DOMAIN}/cgu`

/**
 * Check if the term of services are the one from aploze, append the eventId to the url if it's the case,
 * in order to load the event specific settings
 */
export const appendEventIdToTosLink = (eventId: string, tosLink: string): string => {
	if (tosLink.startsWith(aplozeTosLink)) {
		const url = new URL(tosLink)
		url.searchParams.append('id', eventId)

		return url.toString()
	} else {
		return tosLink
	}
}
