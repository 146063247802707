import Constants from '@constants'
import Bus from '@helpers/bus'
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { ProviderBaseProps } from 'types/Common'

interface ConversationalReplayContext {
	hasStartedConversation?: boolean
	setHasStartedConversation?: (value: boolean) => void
	setIAdvizeIsLoaded?: (value: boolean) => void
	iAdvizeIsLoaded?: boolean
	isConversing?: boolean
	setIsConversing?: (isConversing: boolean) => void
}

const Context = createContext<ConversationalReplayContext | null>({})

export const useConversationalReplayContext = () => useContext(Context)

export const ConversationalReplayProvider: React.FC<ProviderBaseProps> = ({ children }) => {
	/**
	 * @States
	 */
	const [hasStartedConversation, setHasStartedConversation] = useState<boolean>(false)
	const [isConversing, setIsConversing] = useState<boolean>(false)
	const [iAdvizeIsLoaded, setIAdvizeIsLoaded] = useState<boolean>(false)

	/**
	 * @Methods
	 */
	const iAdvizeLoadingHandler = () => {
		if (!iAdvizeIsLoaded) {
			setIAdvizeIsLoaded(true)
		}
	}

	const isConversingHandler = () => {
		setTimeout(() => setIsConversing(false), 300)
	}

	/**
	 * @Effects
	 */
	useEffect(() => {
		Bus.on(Constants.bus.library.iadvize_loaded, iAdvizeLoadingHandler)
		Bus.on(Constants.bus.library.conversational_replay_closed, isConversingHandler)
		return () => {
			Bus.off(Constants.bus.library.iadvize_loaded, iAdvizeLoadingHandler)
			Bus.off(Constants.bus.library.conversational_replay_closed, isConversingHandler)
		}
	}, [])

	useEffect(() => {
		if (hasStartedConversation) {
			setIsConversing(true)
		}
	}, [hasStartedConversation])

	const values = useMemo(
		(): ConversationalReplayContext => ({
			hasStartedConversation,
			setHasStartedConversation,
			setIAdvizeIsLoaded,
			iAdvizeIsLoaded,
			isConversing,
			setIsConversing
		}),
		[hasStartedConversation, iAdvizeIsLoaded, isConversing]
	)

	return <Context.Provider value={values}>{children}</Context.Provider>
}
