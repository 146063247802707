import React from 'react'
import { Box } from 'rebass'
import Icon from '@components/Micro/Icon'
import Theme from '@styles/theme'
import Style from './style'

const Fullscreen: React.FC<{ style?: any; onClick?: any; status?: boolean }> = ({ style, onClick, status }) => {
	return (
		<Box
			css={[Style.el, style]}
			width={40}
			height={40}
			p={'12px'}
			backgroundColor={Theme.colors.white()}
			onClick={onClick}
		>
			<Icon
				name={status ? 'unfullscreen' : 'fullscreen'}
				width="100%"
				height="100%"
				fill={Theme.colors.black()}
			/>
		</Box>
	)
}

export default Fullscreen
