import { css, SerializedStyles } from '@emotion/react'
import Theme from '@styles/theme'
import styled from '@emotion/styled'
import { Flex } from 'rebass'
import { customScrollbar } from '@styles/shared/customScrollbar'
import { DisplayMode } from '@helpers/contexts/player'

const PREFIX = 'panel'

const getLandscapeWidth = () => {
	const thirdWidth = window.innerWidth / 3
	return `${thirdWidth > 400 ? '400px' : '33vw'}`
}

export const paddingByDisplayMode = (mode: DisplayMode): SerializedStyles => {
	switch (mode) {
		case 'DESKTOP_LANDSCAPE':
		case 'DESKTOP_PORTRAIT':
			return css`
				padding: 0 ${Theme.spacing(8)};
			`
		case 'MOBILE_PORTRAIT':
			return css`
				padding: 0 ${Theme.spacing(6)};
			`
		case 'MOBILE_LANDSCAPE':
			return css`
				padding: 0 ${Theme.spacing(6)};
				@media ${Theme.media(0)} {
					padding-left: ${Theme.spacing(4)};
					padding-right: ${Theme.spacing(4)};
				}
			`
	}
}

export const widthByDisplayMode = (mode: DisplayMode): SerializedStyles => {
	switch (mode) {
		case 'DESKTOP_LANDSCAPE':
		case 'DESKTOP_PORTRAIT':
			return css`
				width: calc(100% - 80px);
			`
		case 'MOBILE_PORTRAIT':
			return css`
				width: calc(100% - 60px);
			`
		case 'MOBILE_LANDSCAPE':
			return css`
				width: calc(100% - 60px);
				@media ${Theme.media(0)} {
					width: calc(100% - 40px);
				}
			`
	}
}

export const panelsClasses = {
	root: `${PREFIX}`,
	wrapper: `${PREFIX}-wrapper`
}

export const PanelWrapper = styled.div<{ isOpen?: boolean; isLandscape?: boolean }>`
	display: flex;
	width: ${({ isLandscape, isOpen }) => (isLandscape ? (isOpen ? getLandscapeWidth() : '0%') : '100%')};
	height: 100%;
	flex-wrap: wrap;
	position: ${({ isLandscape }) => (isLandscape ? 'initial' : 'fixed')};
	pointer-events: none;
	overflow: hidden;
	top: 0;
	left: 0;
	background-color: ${Theme.colors.black(0)};
	z-index: ${Theme.zIndices.panels};
	${customScrollbar}

	@media ${Theme.media(0)} {
		position: ${({ isLandscape }) => (isLandscape ? 'initial' : 'absolute')};
	}
	${({ isOpen }) =>
		isOpen &&
		css`
			pointer-events: all;
		`}
`

export const PanelContent = styled.div<{ hasPanels?: boolean; isLandscape?: boolean }>`
	display: flex;
	justify-content: center;
	width: 100%;
	min-width: ${({ isLandscape }) => (isLandscape ? getLandscapeWidth() : '100%')};
	height: 100%;
	flex-wrap: wrap;
	align-content: flex-start;
	background-color: ${Theme.colors.white()};
	transform: ${({ hasPanels }) => (hasPanels ? 'translateX(0)' : 'translateX(100%)')};
	/* Scroll bar */
	overflow: auto;
	scrollbar-color: ${Theme.colors.grey1} transparent;
	scrollbar-width: thin;

	*::-webkit-scrollbar {
		background-color: transparent;
		width: 8px;
	}
	*::-webkit-scrollbar-thumb {
		background-color: ${Theme.colors.grey1};
		border-radius: 10px;
	}
	*::-webkit-scrollbar-corner {
		background: transparent;
	}
`

export const PanelHeaderContainer = styled(Flex)<{ isFullScreen?: boolean }>`
	display: ${({ isFullScreen }) => (isFullScreen ? 'none' : 'flex')};
	width: 100%;
	justify-content: flex-start;
	align-items: center;
	border-bottom: 1px solid #e5e5e5;
	position: sticky;
	top: 0;
	background-color: ${Theme.colors.white()};
	z-index: 2;
`

export const PanelHeader = styled(Flex)`
	display: flex;
	width: 100%;
	flex-wrap: wrap;
	justify-content: center;
	will-change: transform;
	position: absolute;
	width: 100%;
	left: 0;
	top: 0;
`

export const PanelBody = styled(Flex)<{ isFullScreen?: boolean }>`
	width: 100%;
	flex-wrap: wrap;
	justify-content: center;
	align-content: flex-start;
	will-change: transform;
	position: absolute;
	width: 100%;
	height: ${({ isFullScreen }) => (isFullScreen ? '100%' : 'calc(100% - 60px)')};
	left: 0;
	top: ${({ isFullScreen }) => (isFullScreen ? '0px !important' : '60px')};
	@media ${Theme.media(0)} {
		top: 66px;
		height: ${({ isFullScreen }) => (isFullScreen ? '100%' : 'calc(100% - 66px)')};
	}
`

export const HeaderContentWrapper = styled.div<{ displayMode: DisplayMode }>`
	display: flex;
	justify-content: flex-start;
	width: 100%;
	${({ displayMode }) => paddingByDisplayMode(displayMode)}
`

export const CloseButton = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;
	height: 60px;
	@media ${Theme.media(0)} {
		height: 66px;
	}
	${Theme.fadeAndScaleOnClick()}
`

export const HeaderTitle = styled.div`
	font-size: 14em;
	padding: 23px 7.5% 21px 12px;
	color: ${() => Theme.colors.text_main};
	font-family: ${Theme.fontFamilies.Main};
	font-weight: ${Theme.fontWeights.bold};
	cursor: pointer;
	@media ${Theme.media(0)} {
		padding: 26px 7.5% 21px 12px;
	}
`

export const PanelContentContainer = styled.div<{ displayMode: DisplayMode }>`
	${({ displayMode }) => widthByDisplayMode(displayMode)}
`

/**
 * @Out animation
 */
export default {
	movableItemIsCurrent: css`
		pointer-events: all;
		opacity: 1;
		transform: translateX(0);
		transition: all 400ms ${Theme.eases.inOutSmooth};
	`,

	movableItemIsPassed: css`
		transform: translateX(-25%);
		pointer-events: none;
		opacity: 0;
		transition: all 400ms ${Theme.eases.inOutSmooth};
		*::-webkit-scrollbar-thumb {
			// hide scroll bar during animation without pop effect generated by scroll bar appearance
			background-color: transparent;
		}
	`,

	close: css`
		border: 1px solid ${Theme.colors.black(0.2)};
		border-radius: 8px;
	`
}
