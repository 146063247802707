import React, { useState, useEffect, useRef } from 'react'
import { Flex, Box } from 'rebass'
import Cta from '@components/Micro/Cta'
import Style from './style'
import { useRouter } from 'next/router'
import { mapDispatchToProps } from '@helpers/wrappers/withReduxStore'
import { State } from '@stores/index'
import { connect } from 'react-redux'
import Theme from '@styles/theme'
import Icon from '@components/Micro/Icon'
import Grid from '@components/Utils/Grid'
import 'intersection-observer'
import UaParser from 'ua-parser-js'
import { useViewer } from '@helpers/contexts/viewerContext'

import { zonedTimeToUtc } from 'date-fns-tz'

import {
	differenceInDays,
	addDays,
	differenceInHours,
	addHours,
	differenceInMinutes,
	addMinutes,
	differenceInSeconds
} from 'date-fns'
import Bus from '@helpers/bus'
import Constants from '@constants'

interface Props {
	dispatch?: (action) => void
	storeDatas?: any
}

const mapStateToProps = (state: State, props: Props) => {
	return { ...state, ...props }
}

const Timer: React.FC<Props> = ({ storeDatas, dispatch }) => {
	const { viewerRegistration } = useViewer()

	let $ctaAlert = null
	let $ctaShare = null
	let $ctaAlertTiny = null
	let $ctaShareTiny = null

	let $days = null
	let $hours = null
	let $minutes = null
	let $seconds = null
	let $day = null
	let $hour = null
	let $minute = null
	let $second = null

	const [isOpenedNotify, setIsOpenedNotify] = useState(false)
	const [isOpenedCalendar, setIsOpenedCalendar] = useState(false)
	const [isOpenedShare, setIsOpenedShare] = useState(false)
	// const [isSubmiting, setIsSubmiting] = useState(false)
	const [isBlinking, setIsBlinking] = useState(false)
	const [stickyIsShown, setStickyIsShown] = useState(false)
	const [calendarInfos, setClendarInfos] = useState({})
	const [isMobile, setIsMobile] = useState(false)

	const [timer, setTimer] = useState({
		days: '00',
		hours: '00',
		minutes: '00',
		seconds: '00',
		daysInt: 0,
		hoursInt: 0,
		minutesInt: 0,
		secondsInt: 0
	})

	const eventId = useRouter().query.id

	const el = useRef(null)

	const setTime = () => {
		const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
		let now = zonedTimeToUtc(new Date(), currentTimezone)
		const to = zonedTimeToUtc(storeDatas.event.event.time, currentTimezone)
		const days = differenceInDays(to, now)
		now = addDays(now, days)
		const hours = differenceInHours(to, now)
		now = addHours(now, hours)
		const minutes = differenceInMinutes(to, now)
		now = addMinutes(now, minutes)
		const seconds = differenceInSeconds(to, now)

		if (to > now) {
			setTimer({
				days: days < 10 ? '0' + days : '' + days,
				hours: hours < 10 ? '0' + hours : '' + hours,
				minutes: minutes < 10 ? '0' + minutes : '' + minutes,
				seconds: seconds < 10 ? '0' + seconds : '' + seconds,
				daysInt: days,
				hoursInt: hours,
				minutesInt: minutes,
				secondsInt: seconds
			})
		} else {
			setTimer({
				days: '00',
				hours: '00',
				minutes: '00',
				seconds: '00',
				daysInt: 0,
				hoursInt: 0,
				minutesInt: 0,
				secondsInt: 0
			})
			setIsBlinking((current) => {
				return !current
			})
			window.setTimeout(() => {
				setIsBlinking((current) => {
					return !current
				})
			}, 500)
		}
	}

	const hasAtLeastOneRegistrationOption = () => storeDatas.event.event.settings.registrationTypes.length > 0

	if (storeDatas) {
		if (storeDatas.event.event.settings.registrationTypes) {
			$ctaAlert = hasAtLeastOneRegistrationOption() ? (
				<Box mt={[25, 0]} width={[0.85, 'auto']} mr={[0, 20]}>
					<Cta
						onClick={() => {
							setIsOpenedNotify(!isOpenedNotify)
							Bus.send(Constants.bus.player.subscribe_clicked)
						}}
						label={storeDatas.ui.alert_cta}
						justifyContent={'flex-start'}
						icon={'notification'}
					/>
				</Box>
			) : (
				<></>
			)
		}
		$ctaShare = (
			<Box mt={[10, 0]} width={[0.85, 'auto']}>
				<Cta
					onClick={() => {
						Bus.send(Constants.bus.player.share_clicked)
						if (navigator.share && isMobile) {
							Bus.send(Constants.bus.player.event_shared, { type: 'native' })
						} else {
							setIsOpenedShare(!isOpenedShare)
						}
					}}
					label={storeDatas.ui.share_cta}
					type={'border'}
					justifyContent={'flex-start'}
					icon={'share'}
				/>
			</Box>
		)

		$ctaAlertTiny = hasAtLeastOneRegistrationOption() ? (
			<Box>
				<Cta
					onClick={() => {
						Bus.send(Constants.bus.player.subscribe_clicked)
						setIsOpenedNotify(!isOpenedNotify)
					}}
					justifyContent={'flex-start'}
					icon={'notification'}
					iconOnly={true}
				/>
			</Box>
		) : (
			<></>
		)

		$ctaShareTiny = (
			<Box ml={['7px', 15]}>
				<Cta
					onClick={() => {
						Bus.send(Constants.bus.player.share_clicked)
						if (navigator.share && isMobile) {
							Bus.send(Constants.bus.player.event_shared, { type: 'native' })
						} else {
							setIsOpenedShare(!isOpenedShare)
						}
					}}
					type={'border'}
					justifyContent={'flex-start'}
					icon={'share'}
					iconOnly={true}
				/>
			</Box>
		)

		$days = storeDatas.ui.timer_days
		$day = storeDatas.ui.timer_day
		$hours = storeDatas.ui.timer_hours
		$hour = storeDatas.ui.timer_hour
		$minutes = storeDatas.ui.timer_minutes
		$minute = storeDatas.ui.timer_minute
		$seconds = storeDatas.ui.timer_seconds
		$second = storeDatas.ui.timer_second
	}

	useEffect(() => {
		let interval = null
		const parser = new UaParser()
		const device = parser.getDevice()
		setIsMobile(device.type === 'mobile' ? true : false)

		if (storeDatas) {
			interval = setInterval(setTime, 1000)
			setTime()
			const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
			const date = zonedTimeToUtc(storeDatas.event.event.time, currentTimezone)
			const startsAt = date.toISOString()
			const time = date.getTime()
			const endTime = time + 1000 * 60 * 60 * 1
			const endsAt = new Date(endTime).toISOString()
			const title = storeDatas.event.event.title.replace(/(\\r\\n|\\n|\\r)/g, ' ')
			setClendarInfos({
				//@ts-ignore
				name: title,
				details: title,
				startsAt,
				endsAt
			})
		}
		return () => {
			if (interval) {
				clearInterval(interval)
			}
		}
	}, [storeDatas])

	useEffect(() => {
		let observer = null
		if (process.browser) {
			observer = new IntersectionObserver(
				([e]) => {
					setStickyIsShown(e.intersectionRatio < 1)
				},
				{
					rootMargin: '-1px 0px 0px 0px',
					threshold: [1]
				}
			)
			observer.observe(el.current)
		}

		return () => {
			if (observer && el.current) {
				observer.unobserve(el.current)
			}
		}
	}, [])

	const calendarPopinOverlayClickHandler = () => {
		setIsOpenedCalendar(!isOpenedCalendar)
	}

	const notifyPopinOverlayClickHandler = () => {
		setIsOpenedNotify(!isOpenedNotify)
	}
	const sharePopinOverlayClickHandler = () => {
		setIsOpenedShare(!isOpenedShare)
	}

	const registration = () => viewerRegistration(storeDatas.event.event.uuid)

	return (
		<>
			{storeDatas && isOpenedNotify && (
				<Flex css={Style.calendarPopin} justifyContent="center" alignContent="center" flexWrap="wrap">
					<Box css={Style.popOverlay} onClick={notifyPopinOverlayClickHandler}></Box>
					<Box css={[Style.calendarPopinContent, Theme.squaredEdges]} p={['50px 20px 20px', '50px 40px']}>
						<Box
							width={25}
							height={24}
							p={'6px'}
							css={Style.close}
							onClick={notifyPopinOverlayClickHandler}
						>
							<Icon name="close" width="100%" height="100%" fill={Theme.colors.text_main} />
						</Box>
						<Flex flexWrap="wrap" justifyContent="center">
							<Box
								width={50}
								height={50}
								mb={30}
								p={'15px'}
								backgroundColor={Theme.colors.cta_main}
								css={Style.icon}
							>
								<Icon name="notification" width="100%" height="100%" fill={Theme.colors.white()} />
							</Box>
							<Box width={1} css={Style.popTitle} color={Theme.colors.text_main}>
								{storeDatas.ui.alert_title}
							</Box>
							<Box
								width={[1, 0.6]}
								css={Style.popText}
								mt={10}
								color={Theme.colors.text_second}
								className="wysiwyg"
							>
								{storeDatas.ui.alert_text}
							</Box>
							<Flex width={1} flexWrap="wrap" mt={40} justifyContent="center">
								{storeDatas.event.event.settings.registrationTypes.indexOf('sms') !== -1 && (
									<Box
										mr={[
											0,
											storeDatas.event.event.settings.registrationTypes.indexOf('calendar') !== -1
												? 10
												: 0
										]}
										mb={[10, 0]}
										width={[1, 'auto']}
									>
										<Cta
											type={'border'}
											justifyContent={'flex-start'}
											icon={registration() ? 'check' : 'chat_plain'}
											style={registration() ? Style.hasSubscribed : null}
											label={
												registration()
													? storeDatas.ui.alert_sms_cta_already
													: storeDatas.ui.alert_sms_cta
											}
											onClick={() => {
												if (!registration()) {
													Bus.send(Constants.bus.player.subscribe_sms_clicked)
													setIsOpenedNotify(!isOpenedNotify)
													dispatch({
														type: 'ADD_POPIN',
														popin: {
															mode: 'live',
															value: eventId,
															tracker: 'prelive_notify_cta'
														}
													})
												}
											}}
										/>
									</Box>
								)}
								{storeDatas.event.event.settings.registrationTypes.indexOf('calendar') !== -1 && (
									<Box mb={[10, 0]} width={[1, 'auto']}>
										<Cta
											label={storeDatas.ui.alert_calendar_cta}
											type={'border'}
											justifyContent={'flex-start'}
											icon={'calendar'}
											onClick={() => {
												Bus.send(Constants.bus.player.subscribe_calendar_clicked)
												setIsOpenedNotify(!isOpenedNotify)
												setIsOpenedCalendar(!isOpenedCalendar)
											}}
										/>
									</Box>
								)}
							</Flex>
						</Flex>
					</Box>
				</Flex>
			)}
			{isOpenedCalendar && (
				<Flex css={Style.calendarPopin} justifyContent="center" alignContent="center" flexWrap="wrap">
					<Box css={Style.popOverlay} onClick={calendarPopinOverlayClickHandler}></Box>
					<Box css={[Style.calendarPopinContent, Theme.squaredEdges]} p={['50px 20px 20px', '50px 40px']}>
						<Box
							width={25}
							height={24}
							p={'6px'}
							css={Style.close}
							onClick={calendarPopinOverlayClickHandler}
							className="registration-close-icon"
						>
							<Icon name="close" width="100%" height="100%" fill={Theme.colors.text_main} />
						</Box>
						<Flex flexWrap="wrap" justifyContent="center">
							<Box
								width={50}
								height={50}
								mb={30}
								p={'15px'}
								backgroundColor={Theme.colors.cta_main}
								css={Style.icon}
							>
								<Icon name="calendar" width="100%" height="100%" fill={Theme.colors.white()} />
							</Box>
							<Box width={1} css={Style.popTitle} color={Theme.colors.text_main}>
								{storeDatas.ui.alert_calendar_title}
							</Box>
							<Box
								width={[1, 0.6]}
								css={Style.popText}
								mt={10}
								color={Theme.colors.text_second}
								className="wysiwyg"
							>
								{storeDatas.ui.alert_calendar_text}
							</Box>
							<Box width={1} css={Style.poplabel} mt={30} mb={20}>
								{storeDatas.ui.alert_calendar_select}:
							</Box>
							<Flex width={1} flexWrap="wrap">
								<Box mr={[0, 10]} mb={[10, 0]} width={[1, 'auto']}>
									<Cta
										label={'Google Calendar'}
										type={'border'}
										justifyContent={'flex-start'}
										icon={'google_calendar'}
										noIconFill={true}
										onClick={() => {
											Bus.send(Constants.bus.player.event_added_to_calendar, {
												type: 'google',
												infos: calendarInfos
											})
										}}
										target={'_blank'}
									/>
								</Box>
								<Box mr={[0, 10]} mb={[10, 0]} width={[1, 'auto']}>
									<Cta
										label={'Outlook'}
										type={'border'}
										justifyContent={'flex-start'}
										icon={'outlook'}
										noIconFill={true}
										onClick={() => {
											Bus.send(Constants.bus.player.event_added_to_calendar, {
												type: 'outlook',
												infos: calendarInfos
											})
										}}
										target={'_blank'}
									/>
								</Box>
								<Box mr={[0, 0]} mb={[10, 0]} width={[1, 'auto']}>
									<Cta
										label={'Yahoo'}
										type={'border'}
										justifyContent={'flex-start'}
										icon={'yahoo'}
										noIconFill={true}
										onClick={() => {
											Bus.send(Constants.bus.player.event_added_to_calendar, {
												type: 'yahoo',
												infos: calendarInfos
											})
										}}
										target={'_blank'}
									/>
								</Box>
							</Flex>
						</Flex>
					</Box>
				</Flex>
			)}
			{isOpenedShare && (
				<Flex css={Style.calendarPopin} justifyContent="center" alignContent="center" flexWrap="wrap">
					<Box css={Style.popOverlay} onClick={sharePopinOverlayClickHandler}></Box>
					<Box css={[Style.calendarPopinContent, Theme.squaredEdges]} p={['50px 20px 20px', '50px 40px']}>
						<Box width={25} height={24} p={'6px'} css={Style.close} onClick={sharePopinOverlayClickHandler}>
							<Icon name="close" width="100%" height="100%" fill={Theme.colors.text_main} />
						</Box>
						<Flex flexWrap="wrap" justifyContent="center">
							<Box
								width={50}
								height={50}
								mb={30}
								p={'15px'}
								backgroundColor={Theme.colors.cta_main}
								css={Style.icon}
							>
								<Icon name="share" width="100%" height="100%" fill={Theme.colors.white()} />
							</Box>
							<Box width={1} css={Style.popTitle} color={Theme.colors.text_main}>
								{storeDatas.ui.share_title}
							</Box>
							<Box
								width={[1, 0.6]}
								css={Style.popText}
								mt={10}
								color={Theme.colors.text_second}
								className="wysiwyg"
							>
								{storeDatas.ui.share_text}
							</Box>
							<Flex width={1} flexWrap="wrap" mt={40} justifyContent="center">
								<Box
									mr={['4px', 10]}
									mb={[10, 0]}
									onClick={() => {
										Bus.send(Constants.bus.player.event_shared, { type: 'clipboard' })
									}}
								>
									<Cta type={'border'} justifyContent={'flex-start'} icon={'link'} iconOnly={true} />
								</Box>
								<Box
									mr={['4px', 10]}
									mb={[10, 0]}
									onClick={() => {
										Bus.send(Constants.bus.player.event_shared, { type: 'email' })
									}}
								>
									<Cta type={'border'} justifyContent={'flex-start'} icon={'email'} iconOnly={true} />
								</Box>
								<Box
									mr={['4px', 10]}
									mb={[10, 0]}
									onClick={() => {
										Bus.send(Constants.bus.player.event_shared, { type: 'twitter' })
									}}
								>
									<Cta
										type={'border'}
										justifyContent={'flex-start'}
										icon={'twitter'}
										iconOnly={true}
									/>
								</Box>
								<Box
									mr={['4px', 10]}
									mb={[10, 0]}
									onClick={() => {
										Bus.send(Constants.bus.player.event_shared, { type: 'facebook' })
									}}
								>
									<Cta
										type={'border'}
										justifyContent={'flex-start'}
										icon={'facebook'}
										iconOnly={true}
									/>
								</Box>
								<Box
									mr={['4px', 10]}
									mb={[10, 0]}
									onClick={() => {
										Bus.send(Constants.bus.player.event_shared, { type: 'pinterest' })
									}}
								>
									<Cta
										type={'border'}
										justifyContent={'flex-start'}
										icon={'pinterest'}
										iconOnly={true}
									/>
								</Box>
								<Box
									mr={[0, 10]}
									mb={[10, 0]}
									onClick={() => {
										Bus.send(Constants.bus.player.event_shared, { type: 'linkedin' })
									}}
								>
									<Cta
										type={'border'}
										justifyContent={'flex-start'}
										icon={'linkedin'}
										iconOnly={true}
									/>
								</Box>
							</Flex>
						</Flex>
					</Box>
				</Flex>
			)}
			<Flex
				width={1}
				justifyContent="center"
				textAlign={'center'}
				mb={[20, 50]}
				mt={25}
				className="timer-component"
			>
				<Box
					css={[Style.numberWrap, Theme.squaredEdges]}
					backgroundColor={Theme.colors.background}
					className="first-number-wrapper"
				>
					<Box
						fontSize={['20em', '20em']}
						css={[Style.number, isBlinking ? Style.isBlinking : null]}
						color={Theme.colors.text_main}
						mt={[11, '13px']}
					>
						{timer.days}
					</Box>
					<Box fontSize={'9em'} css={Style.unit} mt={10} color={Theme.colors.text_second}>
						{timer.daysInt <= 1 ? $day : $days}
					</Box>
				</Box>
				<Box css={[Style.numberWrap, Theme.squaredEdges]} backgroundColor={Theme.colors.background}>
					<Box
						fontSize={['20em', '20em']}
						css={[Style.number, isBlinking ? Style.isBlinking : null]}
						color={Theme.colors.text_main}
						mt={[11, '13px']}
					>
						{timer.hours}
					</Box>
					<Box fontSize={'9em'} css={Style.unit} mt={10} color={Theme.colors.text_second}>
						{timer.hoursInt <= 1 ? $hour : $hours}
					</Box>
				</Box>
				<Box css={[Style.numberWrap, Theme.squaredEdges]} backgroundColor={Theme.colors.background}>
					<Box
						fontSize={['20em', '20em']}
						css={[Style.number, isBlinking ? Style.isBlinking : null]}
						color={Theme.colors.text_main}
						mt={[11, '13px']}
					>
						{timer.minutes}
					</Box>
					<Box fontSize={'9em'} css={Style.unit} mt={10} color={Theme.colors.text_second}>
						{timer.minutesInt <= 1 ? $minute : $minutes}
					</Box>
				</Box>
				<Box css={[Style.numberWrap, Theme.squaredEdges]} backgroundColor={Theme.colors.background}>
					<Box
						fontSize={['20em', '20em']}
						css={[Style.number, isBlinking ? Style.isBlinking : null]}
						color={Theme.colors.text_main}
						mt={[11, '13px']}
					>
						{timer.seconds}
					</Box>
					<Box fontSize={'9em'} css={Style.unit} mt={10} color={Theme.colors.text_second}>
						{timer.secondsInt <= 1 ? $second : $seconds}
					</Box>
				</Box>
			</Flex>
			<Flex width={1} flexWrap={['wrap', 'nowrap']} justifyContent="center" ref={el} className="cta-component">
				{hasAtLeastOneRegistrationOption() && $ctaAlert}
				{$ctaShare}
			</Flex>
			<Flex
				css={[Style.stickyBar, stickyIsShown ? Style.stickyIsShown : null]}
				width={1}
				height={70}
				justifyContent="center"
				className="footer-bar"
			>
				<Grid justifyContent="space-between" flexWrap={'wrap'} alignContent={'center'} alignItems={'center'}>
					<Box
						fontSize={['14em', '16em']}
						css={Style.title}
						width={['calc(100% - 107px)', 'calc(100% - 105px)']}
						color={Theme.colors.text_main}
					>
						{storeDatas.event.event.title.replace(/(\\r\\n|\\n|\\r)/g, ' ')}
					</Box>
					<Box width={[97, 105]}>
						<Flex flexWrap="wrap">
							{hasAtLeastOneRegistrationOption() && $ctaAlertTiny}
							{$ctaShareTiny}
						</Flex>
					</Box>
				</Grid>
			</Flex>
		</>
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(Timer)
