import { classGenerator as cx } from '@styles/sharedLogics'
import React from 'react'
import { classes, SwitchBar, SwitchContainer, SwitchButton, SwitchLabel } from './style'
interface SwitchProps {
	onClick?: (action) => void
	isActive: boolean
	label?: string
	position?: 'before' | 'after'
	disabled?: boolean
}

const Switch: React.FC<SwitchProps> = ({ onClick, isActive, label, disabled, position = 'before' }) => {
	return (
		<SwitchContainer
			onClick={onClick}
			className={cx(classes.root, {
				[classes.reverse]: !!label && position === 'after',
				[classes.fullWidth]: !!label,
				[classes.disabled]: disabled
			})}
		>
			{label && <SwitchLabel>{label}</SwitchLabel>}
			<SwitchBar className={cx({ [classes.active]: isActive })}>
				<SwitchButton className={cx({ [classes.active]: isActive })} />
			</SwitchBar>
		</SwitchContainer>
	)
}

export const switchClasses = classes

export default Switch
