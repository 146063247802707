import Utils from '@utils/index'

type Handler = (datas: Record<string, any>) => void

class Bus {
	handlers: {
		event: string
		handler: Handler
		isOnce?: boolean
	}[]

	constructor() {
		this.handlers = []
		// Observe Player events
		if (process.browser) {
			window.addEventListener('message', this.iframeMessageHandler, false)
		}
	}

	iframeMessageHandler = (e) => {
		if (e.data && Utils.is.isJsonString(e.data)) {
			if (e.data.indexOf('APLOZE_BUS_LIBRARY') !== -1) {
				let details = JSON.parse(e.data)
				let event = details.event
				let datas = details.datas || null
				this.receive(event, datas)
			}
		}
	}

	send(event: string, datas?: any) {
		window.parent.postMessage(
			JSON.stringify({
				event,
				datas
			}),
			'*'
		)
		return this
	}

	receive(event: string, datas?: any) {
		var i = this.handlers.length
		while (i--) {
			var handler = this.handlers[i]
			if (handler.event === event) {
				handler.handler(datas)
				if (handler.isOnce) {
					this.off(event, handler.handler)
				}
			} else {
				if (handler.event === '*') {
					var e = { name: event, datas: {} }
					if (datas) {
						e.datas = datas
					}
					handler.handler(e)
				}
			}
		}
		return this
	}

	on(event: string, handler: Handler) {
		this.handlers.push({ event, handler })
		return this
	}

	off(event: string, handler: Handler) {
		var final = []
		var i = this.handlers.length
		while (i--) {
			if (this.handlers[i].event !== event && this.handlers[i].handler !== handler) {
				final.push(this.handlers[i])
			}
		}
		this.handlers = final
		return this
	}

	once(event: string, handler: Handler) {
		this.handlers.push({ event, handler, isOnce: true })
		return this
	}
}

export default new Bus()
