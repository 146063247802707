import Placeholder from '@components/Micro/Placeholder'
import { classGenerator } from '@styles/sharedLogics'
import React, { useEffect } from 'react'
import { classes, ProductsImageWrapper, ProductImage } from './style'
import Theme from '@styles/theme'
import { handleClassesRotation } from './logic'
import cloneDeep from 'lodash/cloneDeep'
import { clearTimeoutCallback } from '@helpers/SubscriptionCanceler'

interface ProductsImageProps {
	className?: string
	products: Array<any>
}

const ProductsImage: React.FC<ProductsImageProps> = ({ products, className }) => {
	/**
	 * @States
	 */
	const ANIMATION_DELAY = 4000
	const [counter, setCounter] = React.useState(0)
	const [displayProducts, setDisplayProducts] = React.useState([])

	/**
	 * @Methods
	 */
	const getImage = (product) => {
		if (product?.images) {
			const image = product.images.length ? product.images[0] : { url: 'medias/default/product-1050x1600.jpg' }
			return image
		}
	}

	const buildDisplayProducts = () => {
		if (products.length > 1 && products.length <= 3) {
			return [...products, ...cloneDeep(products)]
		}
		return products
	}

	/**
	 * @Effects
	 */
	useEffect(() => {
		let timeout = null
		if (counter > 0) {
			handleClassesRotation(displayProducts, classes)
		}
		timeout = setTimeout(() => {
			if (displayProducts.length) {
				setCounter(counter + 1)
			}
		}, ANIMATION_DELAY)

		return () => clearTimeoutCallback(timeout)
	}, [counter, displayProducts])

	useEffect(() => {
		setDisplayProducts(buildDisplayProducts())
	}, [products])

	return (
		<ProductsImageWrapper className={classGenerator(classes.wrapper, className)}>
			{displayProducts?.length &&
				displayProducts.map((product, index) => (
					<ProductImage
						key={`product-${index + 1}`}
						id={`product-${index + 1}`}
						length={displayProducts.length}
						className={classGenerator(classes.root, `${classes.root}-${index + 1}`, {
							[classes.shadow]: displayProducts.length > 1
						})}
					>
						<Placeholder
							sizes={[[34, 50]]}
							media={getImage(product)}
							color={Theme.colors.background}
							fit="cover"
						/>
					</ProductImage>
				))}
		</ProductsImageWrapper>
	)
}

export default ProductsImage
