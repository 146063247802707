import { css } from '@emotion/react'
import Theme from '@styles/theme'

export default {
	title: css`
		font-family: ${Theme.fontFamilies.Main};
		font-weight: ${Theme.fontWeights.bold};
		/* color: ${Theme.colors.black()}; */
		line-height: 140%;
		white-space: pre-wrap;
	`,
	text: css`
		font-family: ${Theme.fontFamilies.Second};
		font-weight: ${Theme.fontWeights.regular};
		/* color: ${Theme.colors.black(0.4)}; */
		line-height: 140%;
		line-height: 140%;
		white-space: pre-wrap;
	`,
	hide: css`
		display: none;
	`
}
