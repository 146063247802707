import { handleSquaredEdges } from '@styles/sharedLogics'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import Theme from '@styles/theme'
import { BaseAuthorName, BaseMessageText } from './../style'

interface AnsweredMessageWrapperProps {
	backgroundColor: string
	isBlock?: boolean
	isAnswer?: boolean
}

const parentMessageDesktopStyle = css`
	border-top-left-radius: 9px;
	border-top-right-radius: 9px;
`

const answerStyle = css`
	padding: 0 0 8px 0;
	overflow: hidden;
`

const adminBlockStyle = css`
	border-radius: 10px;
	border: 1px solid ${Theme.colors.white(0.9)};
	overflow: hidden;
`

const selectable = css`
	user-select: text;
`

export const AnsweredMessageWrapper = styled.div<AnsweredMessageWrapperProps>`
	display: flex;
	flex-wrap: wrap;
	padding: 8px 8px;
	border-radius: 10px;
	background-color: ${({ backgroundColor }) => backgroundColor || null};
	${selectable};
	${({ isAnswer }) => (isAnswer ? answerStyle : null)}
	${({ isBlock }) => (isBlock ? adminBlockStyle : null)}	
	${handleSquaredEdges}
`

export const ParentMessageWrapper = styled.div<{ backgroundColor; isBlock? }>`
	background-color: ${({ backgroundColor }) => backgroundColor || null};
	width: 100%;
	padding: 8px;
	${({ isBlock }) => (isBlock ? parentMessageDesktopStyle : null)}
	${handleSquaredEdges}
`

export const AuthorName = styled(BaseAuthorName)`
	font-family: ${Theme.fontFamilies.Second};
	line-height: 120%;
`

export const MessageText = styled(BaseMessageText)`
	line-height: 130%;
	width: 100%;
	a {
		word-wrap: break-word;
		text-decoration: underline;
		color: ${Theme.colors.white()};
	}
`

export const AnswerMessageWrapper = styled.div<{ hasParentMessage }>`
	display: flex;
	width: 100%;
	padding: ${({ hasParentMessage }) => (hasParentMessage ? `8px 8px 0 8px` : 0)};
	${selectable};
`

export const AnswerImage = styled.img`
	object-fit: cover;
	${handleSquaredEdges};
`

export const AnswerTextWrapper = styled.div`
	margin-left: 5px;
	margin-top: 0px;
	width: calc(100% - 25px);
	${selectable};
`

export const MessageAvatar = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 18px;
	height: 18px;
	border-radius: 100%;
	overflow: hidden;
	* {
		border-radius: 100%;
		overflow: hidden;
	}
	${handleSquaredEdges}
`
