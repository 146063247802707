import React from 'react'
import { FooterLink, LogoAndNetworks, CopyrightWrapper, LogoWrapper, NetworksWrapper, FooterLinks } from './style'
import Grid from '@components/Utils/Grid'
import { useRouter } from 'next/router'
import { mapDispatchToProps } from '@helpers/wrappers/withReduxStore'
import { State } from '@stores/index'
import { connect } from 'react-redux'
import Logo from '@components/Micro/Logo'
import Networks from './Networks'
import Bus from '@helpers/bus'
import Constants from '@constants'
import { useNotificationsContext } from '@helpers/contexts/notifications'
import { ModalsType } from '@components/Notifiers/Modals/constants'
import { usePlayerContext } from '@helpers/contexts/player'

interface Props {
	dispatch?: (action) => void
	storeDatas?: any
}

const mapStateToProps = (state: State, props: Props) => {
	return { ...state, ...props }
}

const Footer: React.FC<Props> = ({ storeDatas, dispatch }) => {
	const { playerData } = usePlayerContext()
	const { openModal } = useNotificationsContext()

	const eventId = useRouter().query.id

	if (storeDatas) {
		const links = storeDatas.event.event.content.footer.links.map((link, i) => {
			return (
				<FooterLink
					key={`footer-link-${i}`}
					onClick={() => {
						Bus.send(Constants.bus.player.link_browsed, { url: link.url, target: '_blank' })
					}}
				>
					{link.label}
				</FooterLink>
			)
		})

		const unsubscribeClickHandler = () => {
			Bus.send(Constants.bus.player.unsubscribe_clicked)

			dispatch({
				type: 'ADD_POPIN',
				popin: {
					mode: 'unlive',
					value: eventId,
					tracker: 'footer_unnotify_cta'
				}
			})
		}

		const personalDataClickHandler = () => openModal(ModalsType.RGPD_OPT_OUT, { playerData })

		return (
			<>
				<LogoAndNetworks className="footer-container">
					<CopyrightWrapper>{/* <Copyright align={['center', 'left']} /> */}</CopyrightWrapper>
					<LogoWrapper>
						<Logo isClickable={true} justify={'center'} height={'60px'} />
					</LogoWrapper>
					<NetworksWrapper>
						<Networks />
					</NetworksWrapper>
				</LogoAndNetworks>

				<FooterLinks>
					<Grid justifyContent="center" flexWrap={'wrap'} className="links-wrapper">
						{links}

						{!storeDatas.event.event.legalSettings.optout.cookiesOptoutDisabled && (
							<FooterLink onClick={personalDataClickHandler}>
								{playerData.ui.tos_opt_out.title}
							</FooterLink>
						)}

						{!storeDatas.event.event.legalSettings.optout.phoneOptoutDisabled && (
							<FooterLink onClick={unsubscribeClickHandler}>
								{storeDatas.ui.alert_sms_unsubscribe_footer_label}
							</FooterLink>
						)}
					</Grid>
				</FooterLinks>
			</>
		)
	} else {
		return <></>
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer)
