import styled from '@emotion/styled'
import { Flex, Box } from 'rebass'
import Theme from '@styles/theme'
import { paddingByDisplayMode } from '../style'
import { DisplayMode } from '@helpers/contexts/player'

const PREFIX = 'winning-instant-form'

export const classes = {
	root: PREFIX,
	rewardTitleMobileLandspace: `${PREFIX}-reward-title-mobile-landspace`
}

export const RewardsWrapper = styled.div<{ displayMode: DisplayMode }>`
	${({ displayMode }) => paddingByDisplayMode(displayMode)}
	padding-top: ${Theme.spacing(4)};
	padding-bottom: ${Theme.spacing(4)};
	background-color: ${() => Theme.colors.background};
`

export const Rewards = styled(Flex)`
	font-size: 24px;
	align-items: center;
`

export const RewardsTitle = styled.div`
	font-size: 14px;
	line-height: 18px;
	font-weight: ${Theme.fontWeights.bold};
	font-family: ${Theme.fontFamilies.Main};
	color: ${() => Theme.colors.text_main};
`

export const RewardsToggle = styled(Box)`
	text-decoration: underline;
	font-size: 12px;
	line-height: 16px;
	cursor: pointer;
	font-family: ${Theme.fontFamilies.Second};
`

export const RewardsList = styled(Box)`
	margin-top: 20px;
	padding: 15px 20px;
	border-radius: 15px;
	border: 1px solid ${() => Theme.colors.text_main};
`

export const RewardsListItem = styled(Box)`
	font-size: 13px;
	line-height: 22px;
	font-weight: ${Theme.fontWeights.regular};
	font-family: ${Theme.fontFamilies.Second};
	color: ${() => Theme.colors.text_main};
`

export const Container = styled.div<{ displayMode: DisplayMode }>`
	${({ displayMode }) => paddingByDisplayMode(displayMode)}
	padding-top: ${Theme.spacing(6)};
	padding-bottom: ${Theme.spacing(6)};
`

export const Question = styled(Box)`
	font-size: 20px;
	font-weight: ${Theme.fontWeights.bold};
	font-family: ${Theme.fontFamilies.Main};
	overflow-wrap: break-word;
	word-wrap: break-word;
	hyphens: auto;
`

export const SplitBar = styled(Box)`
	width: 100%;
	margin: 30px 0;
	height: 1px;
`

export const FillDescription = styled(Box)`
	font-size: 14px;
	line-height: 22px;
	font-weight: ${Theme.fontWeights.regular};
	font-family: ${Theme.fontFamilies.Second};
	margin-bottom: 25px;
`

export const Answer = styled(Box)`
	font-size: 14px;
	line-height: 18px;
	padding: 10px 15px;
	border-radius: 8px;
	border: 1px solid;
	border-color: ${() => Theme.colors.text_main};
	cursor: pointer;
	white-space: nowrap;
	transition: width ease 0.8s;
	opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
	background-color: ${({ currentChoice }) => (currentChoice ? `${Theme.colors.text_main}10` : 'transparent')};
	font-family: ${Theme.fontFamilies.Second};
	font-weight: ${({ currentChoice }) => (currentChoice ? Theme.fontWeights.bold : Theme.fontWeights.regular)};
	${({ noSelection }) => noSelection && Theme.fadeAndScaleOnClick(false)}
`

export const Optin = styled(Flex)`
	width: 100%;
	flex-wrap: wrap;
	justify-content: space-between;
	cursor: pointer;
`

export const OptinBox = styled(Box)`
	width: 16px;
	height: 16px;
	padding: 3px;
	border-radius: 4px;
	border: 1px solid;
	border: 1px solid;
	${() => Theme.squaredEdges};
`

export const OptinText = styled(Box)`
	font-family: ${Theme.fontFamilies.Second};
	font-weight: ${Theme.fontWeights.regular};
	line-height: 16px;
	width: calc(100% - 25px);
	font-size: 12px;
`

export const Button = styled(Flex)`
	font-size: 14px;
	color: ${Theme.colors.white()};
	font-weight: ${Theme.fontWeights.bold};
	font-family: ${Theme.fontFamilies.Main};
	padding: 19px 45px;
	justify-content: center;
	border-radius: 15px;
	cursor: pointer;
	margin-top: ${Theme.spacing(6)};
`

export const RulesOptinLink = styled('a')`
	text-decoration: underline;
	color: ${() => Theme.colors.text_main};
`

export const IconWrapper = styled.div`
	display: flex;
	margin-right: ${Theme.spacing(2)};
`

export const TextWrapper = styled.div`
	display: flex;
	flex-grow: 1;
	justify-content: space-between;
`

export const RewardsTop = styled(Flex)`
	align-items: center;
	justify-content: space-between;
`

export const FormWrapper = styled(Box)`
	overflow-y: scroll;
	width: 100%;
	height: 100%;
	&.${classes.rewardTitleMobileLandspace} {
		${TextWrapper} {
			flex-wrap: wrap;
		}
		${RewardsTitle} {
			font-size: 13px;
		}
		${Container} {
			padding-top: ${Theme.spacing(4)};
			padding-bottom: ${Theme.spacing(4)};
		}
	}
`

export const TimerWrapper = styled.div`
	@media ${Theme.media(0)} {
		display: none;
	}
`
