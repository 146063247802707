import React from 'react'
import { Box } from 'rebass'
import { Title, ProductInfo, Description, Cta, classes } from './style'
import { mapDispatchToProps } from '@helpers/wrappers/withReduxStore'
import { State } from '@stores/index'
import { connect } from 'react-redux'

import Bus from '@helpers/bus'
import Constants from '@constants'
import { useNotificationsContext } from '@helpers/contexts/notifications'
import { usePlayerContext } from '@helpers/contexts/player'
import { Product } from 'types/Product'
import { useTimeLineContext } from '@helpers/contexts/timeline'

interface Props {
	data?: Product
	storeDatas?: any
}

const mapStateToProps = (state: State, props: Props) => {
	return { ...state, ...props }
}

const ProductInfos: React.FC<Props> = ({ data, storeDatas }) => {
	//
	const { closeAllPanels } = useNotificationsContext()
	const { videoMode, isMobile, isLandscape } = usePlayerContext()
	const { currentTime } = useTimeLineContext()

	const ctaClickHandler = () => {
		Bus.send(Constants.bus.player.link_browsed, {
			url: data.link.url,
			target: '_self',
			isLandscape: videoMode === 'landscape',
			timecode: currentTime
		})

		Bus.send(Constants.bus.player.product_page_clicked, data)

		if (isMobile && !isLandscape) {
			closeAllPanels()
		}
	}

	const checkUrl = (message) => {
		if (message) {
			const URL_REGEX =
				/\b((?:https?:\/\/|www\d{0,3}[.]|[a-z0-9.-]+[.][a-z]{2,4}\/)(?:[^\s()<>]+|\(([^\s()<>]+|(\([^\s()<>]+\)))*\))+(?:\(([^\s()<>]+|(\([^\s()<>]+\)))*\)|[^\s`!()[\]{};:'".,<>?«»“”‘’]))/i
			return message
				.replace('\n', ' \n')
				.split(' ')
				.map((part, i) => {
					const key = `message-key-${i}`
					return URL_REGEX.test(part) ? (
						<>
							<Box as="a" href={setHttp(part)} target="_blank" key={key}>
								{part}
							</Box>
							<Box as="span"> </Box>
						</>
					) : (
						<Box as="span" key={key}>
							{part}{' '}
						</Box>
					)
				})
		} else {
			return ''
		}
	}

	const setHttp = (link: string) => {
		const foundIncorrectUrlPosition = link.search(/http[s]?\:\/\//)
		if (foundIncorrectUrlPosition == -1) {
			link = 'https://' + link
		}
		return link
	}

	return (
		<ProductInfo>
			<Title className={classes.title}>{storeDatas.ui.panel_product_description_label}</Title>
			<Description className={classes.description}>
				{checkUrl(data.description?.replace(/(\\r\\n|\\n|\\r)/g, '\n'))}
			</Description>
			<Cta className={classes.cta} onClick={ctaClickHandler}>
				{storeDatas.ui.panel_product_page_cta}
			</Cta>
		</ProductInfo>
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductInfos)
