import React from 'react'
import Icon from '@components/Micro/Icon'
import Popin from '@components/Utils/Popin'
import Theme from '@styles/theme'
import { IconCenter, IconWrapper, PopinContainer, PopinDescription, PopinTitle } from './style'
import { ModalProps } from './constants'
import TextWithUrlAndPhone from '@components/Micro/TextWithUrlAndPhone'
import { usePlayerContext } from '@helpers/contexts/player'

const Modal: React.FC<ModalProps> = ({
	children,
	isOpen,
	iconProps,
	customIcon,
	titleProps,
	descriptionProps,
	customDescription,
	showFireworks,
	onClose
}) => {
	const { displayMode, isLandscape, isVertical } = usePlayerContext()

	const isHorizontalView = () => displayMode === 'DESKTOP_PORTRAIT' || (isLandscape && !isVertical)

	return (
		<Popin
			isOpened={isOpen}
			closeClickHandler={onClose}
			maxWidth={isHorizontalView() ? '315px' : null}
			showFireworks={showFireworks}
		>
			<PopinContainer>
				<IconCenter>
					{!!customIcon && customIcon}
					{!customIcon && (
						<IconWrapper backgroundColor={iconProps?.bgcolor || Theme.colors.green()}>
							<Icon
								name={iconProps?.name || 'thumb'}
								width={iconProps?.largeIcon ? '32px' : '22px'}
								height={iconProps?.largeIcon ? '32px' : '22px'}
								fill={Theme.colors.white()}
							/>
						</IconWrapper>
					)}
				</IconCenter>
				<PopinTitle color={titleProps?.color}>{titleProps?.text}</PopinTitle>
				<PopinDescription color={Theme.colors.text_second} width={descriptionProps?.width}>
					{!!customDescription && customDescription}
					{descriptionProps?.text && (
						<TextWithUrlAndPhone text={descriptionProps?.text} fieldKey="modal-description" />
					)}
				</PopinDescription>
				{children}
			</PopinContainer>
		</Popin>
	)
}

export default Modal
