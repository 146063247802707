import { TimeCode } from 'types/Product'

type StepDuration = {
	index: number
	start: number
	duration: number
}

export const getShowStartAndDuration = (
	storeDatas: any
): {
	showStart: number
	duration: number
} => {
	const startRecTimecode = Date.parse(storeDatas.record.stream_start_time) / 1000
	const startShowTimecode = Date.parse(storeDatas.record.live_start_time) / 1000

	const showStart = startShowTimecode - startRecTimecode
	const end =
		storeDatas.record.live_end_time === storeDatas.record.stream_end_time
			? storeDatas.record.replay_duration / 1000
			: Date.parse(storeDatas.record.live_end_time) / 1000 - startRecTimecode
	const duration = end - showStart
	return { showStart, duration }
}

export const getPercent = (time: number, storeDatas: any) => {
	const { showStart, duration } = getShowStartAndDuration(storeDatas)
	const percentage = ((time - showStart) / duration) * 100
	return percentage >= 100 ? 100 : percentage
}

const sortTcsByTsThenStep = (a: TimeCode, b: TimeCode) => {
	if (a.ts < b.ts) {
		return -1
	} else if (a.ts > b.ts) {
		return 1
	} else {
		if (a.step === null) return -1
		if (a.step && b.step) return a.step > b.step ? 1 : -1
		return 0
	}
}

export const generateStepsDuration = (storeTimecodes: TimeCode[], storeDatas: any): StepDuration[] => {
	let tmcds = storeTimecodes

	if (storeTimecodes.length !== 0) {
		if (storeTimecodes[storeTimecodes.length - 1].step !== null) {
			const { showStart, duration } = getShowStartAndDuration(storeDatas)
			tmcds.push({ uuid: '', step: null, ts: (duration + showStart) * 1000 })
		}
	}

	const sortedTmcds = tmcds.sort(sortTcsByTsThenStep)
	const stepsDurations: StepDuration[] = []

	sortedTmcds.forEach((tmcd, i) => {
		const endTmcd = tmcds[i + 1]
		if (tmcd.step !== null && endTmcd && endTmcd.ts > tmcd.ts) {
			const start = getPercent(tmcd.ts / 1000, storeDatas)
			const end = getPercent(endTmcd.ts / 1000, storeDatas)

			stepsDurations.push({
				index: tmcd.step,
				start,
				duration: end - start - 1
			})
		}
	})

	return stepsDurations
}
