import Popins from '@components/Popins'
import Status from '@components/StatusManager'
import Themer from '@components/Utils/Themer'
import DefaultLayout from '@layouts/index'
import { NextPage } from 'next'

import { ConversationalReplayProvider } from '@helpers/contexts/conversationalReplay'
import { PollsProvider } from '@helpers/contexts/polls'
import { NotificationsProvider } from '@helpers/contexts/notifications'
import { PlayerProvider } from '@helpers/contexts/player'
import ViewerProvider from '@helpers/contexts/viewerContext'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import getConfig from 'next/config'
import { NotificationContainer } from 'react-notifications'
import { TimeLineProvider } from '@helpers/contexts/timeline'
import { WinningInstantProvider } from '@helpers/contexts/winningInstant'
const { publicRuntimeConfig } = getConfig()

const NEXT_PUBLIC_DB_ENV = publicRuntimeConfig.NEXT_PUBLIC_DB_ENV

const rate = NEXT_PUBLIC_DB_ENV === 'development' ? 0.0 : 0.5

Sentry.init({
	dsn: 'https://7b5bc9622b604666a1c32caa35ce8efb@o62907.ingest.sentry.io/6687053',
	integrations: [new BrowserTracing()],
	tracesSampleRate: rate,
	sampleRate: rate,
	environment: NEXT_PUBLIC_DB_ENV
})

const Home: NextPage = () => {
	return (
		<>
			<PlayerProvider>
				<TimeLineProvider>
					<ConversationalReplayProvider>
						<ViewerProvider>
							<NotificationsProvider>
								<WinningInstantProvider>
									<PollsProvider>
										<DefaultLayout>
											<NotificationContainer />
											<Themer />
											<Popins />
											<Status />
										</DefaultLayout>
									</PollsProvider>
								</WinningInstantProvider>
							</NotificationsProvider>
						</ViewerProvider>
					</ConversationalReplayProvider>
				</TimeLineProvider>
			</PlayerProvider>
		</>
	)
}

export default Home
