import Icon from '../Icon'
import { DeactivatedChatWrapper, IconWrapper } from './style'
import Theme from '@styles/theme'
import Text from '../Text'
import { usePlayerContext } from '@helpers/contexts/player'

const DeactivatedChat: React.FC = () => {
	const { translations } = usePlayerContext()
	return (
		<DeactivatedChatWrapper>
			<IconWrapper backgroundColor={Theme.colors.cta_main}>
				<Icon name="no_chat" width={25} height={25} fill={Theme.colors.white()} />
			</IconWrapper>
			<Text variant="boldTitle" family="Main" color={Theme.colors.text_main}>
				{translations.chat_deactivated.title}
			</Text>
			<Text variant="regular" family="Second" color={Theme.colors.text_second}>
				{translations.chat_deactivated.description}
			</Text>
		</DeactivatedChatWrapper>
	)
}

export default DeactivatedChat
