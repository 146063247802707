import React, { useCallback, useEffect, useRef, useState } from 'react'
import Style, {
	AnimationWrapper,
	ArrowWrapper,
	ButtonWrapper,
	classes,
	GiftIcon,
	NotifContent,
	NotifDescription,
	NotifTitle,
	ToastWrapper,
	TopBar
} from './style'
import Icon from '@components/Micro/Icon'
import Timer from '@components/Micro/Timer'
import { TimerService } from '@helpers/TimerService'
import { useViewer } from '@helpers/contexts/viewerContext'
import Theme from '@styles/theme'
import { usePlayerContext } from '@helpers/contexts/player'
import { useNotificationsContext } from '@helpers/contexts/notifications'
import Toast from '@components/Micro/Toast'
import { BaseFlex } from '@styles/common'
import { FadeAndScaleTransition } from '@styles/transitions/FadeAndScaleTransition'
import { useHasBeenSeen } from '@helpers/hooks/useOnScreen'
import { useWinningInstantContext } from '@helpers/contexts/winningInstant'
import { TranslateYAndFadeTransition } from '@styles/transitions/TranslateYAndFadeTransition'
import Bus from '@helpers/bus'
import Constants from '@constants'

interface Props {
	trackerRef?: any
	buttonProps?: any
	buttonTop?: number
}

const WinningInstantButton: React.FC<Props> = ({ buttonProps, buttonTop = 15 }) => {
	/**
	 * @States
	 */
	const topBarRef = useRef(null)
	const buttonRef = useRef()
	const hasBeenSeen = useHasBeenSeen(buttonRef)

	const { getWIParticipation } = useViewer()
	const { playerData, isReduced, videoMode, isMobile } = usePlayerContext()
	const {
		winningInstant,
		wiCounter,
		canShowWinningInstant,
		isActivated,
		showUserNotification,
		clickWinningInstant,
		hasBeenDisplayed
	} = useWinningInstantContext()
	const { winningInstantFormOpened } = useNotificationsContext()
	const [animationInProgress, setAnimationInProgress] = useState<boolean>(false)
	const topToastActive = !isMobile && videoMode === 'landscape'

	/**
	 * @Effects
	 */

	/**
	 * @Button animation OPEN
	 */
	useEffect(() => {
		if (showEveryMinute() && !winningInstantFormOpened) {
			setAnimationInProgress(true)
			setTimeout(() => setAnimationInProgress(false), 10_000)
		}
	}, [wiCounter])

	/**
	 * @Button animation CLOSE
	 */
	useEffect(() => {
		if (winningInstantFormOpened) {
			setAnimationInProgress(false)
		}
	}, [winningInstantFormOpened])

	/**
	 * @Send data that button has been seen when it's shown
	 */
	useEffect(() => {
		if (hasBeenSeen && !hasBeenDisplayed) {
			Bus.send(Constants.bus.player.interaction_viewed, {
				interactionId: winningInstant?.uuid,
				interactionType: 'winning_instant'
			})
		}
	}, [hasBeenSeen])

	/**
	 * @Methods
	 */

	const timerCss = {
		position: 'absolute',
		bottom: '-7px',
		left: '50%',
		transform: 'translate(-50%, 0)'
	}

	const hasUserParticipated = (): boolean => {
		return winningInstant ? !!getWIParticipation(winningInstant?.uuid) : false
	}

	const showEveryMinute = () => {
		return (
			wiCounter &&
			wiCounter % 60 === 0 &&
			winningInstant.duration !== 60_000 &&
			winningInstant.duration !== wiCounter * 1000 &&
			!hasUserParticipated()
		)
	}

	const formaterTimer = () => TimerService.formatedTimer(wiCounter)

	const hideAnimationWithDelay = () => (animationInProgress ? 'show delayed_transition' : 'hide')
	const showAnimationWithDelay = () => (animationInProgress ? 'hide' : 'show end_transition')
	const hideAnimation = () => (animationInProgress ? 'show' : 'hide')
	const hidePointerWhenParticipated = () => (hasUserParticipated() ? 'default' : 'pointer')

	const getButtonTopPosition = () => {
		if (!topToastActive && isActivated && topBarRef.current?.clientHeight) {
			return topBarRef.current?.clientHeight + 15
		} else return buttonTop
	}

	const canShowWinningInstantNotif = useCallback(
		(type: 'topbar' | 'toast') => {
			const toastCondition: boolean = type === 'toast' ? topToastActive : !topToastActive
			const result =
				isActivated && showUserNotification && toastCondition && canShowWinningInstant && !hasUserParticipated()
			return result
		},
		[isActivated, topToastActive, canShowWinningInstant, showUserNotification]
	)

	return (
		winningInstant && (
			<>
				{/* TOPBAR */}
				<TranslateYAndFadeTransition
					timeout={800}
					classNames="bar"
					css={[isReduced ? Style.isReduced : null]}
					in={canShowWinningInstantNotif('topbar')}
					translate="-25%"
					unmountOnExit
				>
					<TopBar onClick={clickWinningInstant} ref={topBarRef}>
						<GiftIcon>
							<Icon name="gift" height={23} width={23} />
						</GiftIcon>
						<NotifContent>
							<NotifTitle color={Theme.colors.text_main}>
								{playerData?.ui?.winning_instant?.title}
							</NotifTitle>
							<NotifDescription color={Theme.colors.text_second}>
								{playerData?.ui?.winning_instant?.description}
							</NotifDescription>
						</NotifContent>
					</TopBar>
				</TranslateYAndFadeTransition>

				{/* TOAST */}
				<TranslateYAndFadeTransition
					timeout={800}
					classNames="toast"
					css={[isReduced ? Style.isReduced : null]}
					in={canShowWinningInstantNotif('toast')}
					translate="-100px"
					unmountOnExit
				>
					<ToastWrapper ref={topBarRef}>
						<Toast onClick={clickWinningInstant}>
							<BaseFlex alignItems="center">
								<GiftIcon padding={11.5}>
									<Icon name="gift" height={23} width={23} />
								</GiftIcon>
								<NotifContent>
									<NotifTitle color={Theme.colors.text_main}>
										{playerData?.ui?.winning_instant?.title}
									</NotifTitle>
									<NotifDescription color={Theme.colors.text_second}>
										{playerData?.ui?.winning_instant?.description}
									</NotifDescription>
								</NotifContent>
							</BaseFlex>
						</Toast>
					</ToastWrapper>
				</TranslateYAndFadeTransition>

				{/* BUTTON */}
				<FadeAndScaleTransition
					timeout={200}
					classNames="button"
					css={[Style.buttonTransition, isReduced ? Style.isReduced : null]}
					in={!!isActivated && canShowWinningInstant}
					scale={0.9}
					unmountOnExit
				>
					<ButtonWrapper
						className={classes.root}
						onClick={clickWinningInstant}
						top={getButtonTopPosition()}
						cursor={hidePointerWhenParticipated()}
						buttonProps={buttonProps}
						ref={buttonRef}
					>
						<AnimationWrapper className={animationInProgress ? 'animated' : 'compact'}>
							<div className={`animated_content ${hideAnimation()}`}>
								<GiftIcon minWidth="34px" className={hideAnimation()}>
									<Icon name="gift" height={18} width={18} />
								</GiftIcon>
								<div className={hideAnimationWithDelay()}>
									<NotifTitle color={Theme.colors.text_main}>
										{playerData?.ui?.winning_instant?.title}
									</NotifTitle>
									<NotifDescription color={Theme.colors.text_second}>
										{playerData?.ui?.winning_instant?.play_now}
									</NotifDescription>
								</div>
								<ArrowWrapper
									className={hideAnimationWithDelay()}
									backgroundColor={Theme.colors.cta_main}
								>
									<Icon name="arrow_0_deg" width={10} height={10} fill={Theme.colors.white()} />
								</ArrowWrapper>
							</div>
							<div className={showAnimationWithDelay()}>
								<Icon name="gift" width={30} height={30} />
							</div>
						</AnimationWrapper>

						{isActivated && wiCounter && wiCounter > 0 && (
							<div className={showAnimationWithDelay()}>
								<Timer timer={formaterTimer()} customCss={timerCss} />
							</div>
						)}
						{isActivated && !wiCounter && <div>...</div>}
					</ButtonWrapper>
				</FadeAndScaleTransition>
			</>
		)
	)
}

export const winningInstantButtonClasses = classes

export default WinningInstantButton
