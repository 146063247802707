import Bus from '@helpers/bus'
import Constants from '@constants'
import { mapDispatchToProps } from '@helpers/wrappers/withReduxStore'
import { StoreConfig } from '@models/stores'
import { State } from '@stores/index'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import CookieConsent from '../CookieConsent'

interface Props {
	dispatch?: (action) => void
	storeDatas?: any
	storeConfig?: StoreConfig
	storeStepsProgram?: any
}

const mapStateToProps = (state: State, props: Props) => {
	return { ...state, ...props }
}

const AplozeConsent: React.FC<Props> = ({ storeDatas, storeConfig, dispatch, storeStepsProgram }) => {
	const setConsent = (technicalConsent: boolean, analyticsConsent: boolean) => {
		const consent = {
			technical: technicalConsent,
			analytics: analyticsConsent,
			tos: false, // The viewer should never have accepted terms of service when he loads the experience
			expiration: storeDatas.channel.legalSettings.cookies.cookiesShelfLife
		}
		Bus.send(Constants.bus.player.legal_consented, consent)
		dispatch({ type: 'SET_CONSENT', ...consent })
	}

	const onCookiesAccept = (func, analytics) => {
		setConsent(func, analytics)
	}

	const onCookiesRefuse = () => {
		setConsent(false, false)
		Bus.send(Constants.bus.player.closed)
	}

	const configIsLoaded = storeConfig.viewerId
	const showCookieConsent = !storeConfig?.consent?.technical

	useEffect(() => {
		if (storeDatas?.event?.event?.status === 'prelive' && storeConfig.consent.technical) {
			Bus.send(Constants.bus.player.event_participated, { steps: storeStepsProgram })
		}
	}, [storeConfig.consent])

	useEffect(() => {
		if (storeConfig) {
			Bus.on(Constants.bus.library.consent_updated, (datas) => {
				dispatch({ type: 'SET_CONSENT', ...datas.consent })
			})
		}
	}, [storeConfig])

	return configIsLoaded ? (
		<>
			{showCookieConsent && (
				<CookieConsent storeDatas={storeDatas} onAccept={onCookiesAccept} onRefuse={onCookiesRefuse} />
			)}
		</>
	) : (
		<></>
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(AplozeConsent)
