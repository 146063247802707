import React, { createContext, useContext, useEffect, useMemo, useReducer, useState } from 'react'
import { DeviceTypes, playerInitialState, PlayerActions, EventStatuses } from './constants'
import UaParser from 'ua-parser-js'
import Bus from '@helpers/bus'
import Constants from '@constants'
import { getPlayerDisplayModeValue, isFinishing, playerReducer } from './logic'
import { ProviderBaseProps } from 'types/Common'

export type DisplayMode = ReturnType<typeof getPlayerDisplayModeValue>

interface PlayerContext {
	device?: DeviceTypes | string
	eventStatus?: EventStatuses | string
	videoMode?: string
	isMobile?: boolean
	isDesktop?: boolean
	isTablet?: boolean
	isReady?: boolean
	isStreaming?: boolean
	isPlayed?: boolean
	isMuted?: boolean
	playerData?: any
	isReplayOrFinishing?: boolean
	isReplay?: boolean
	isLive?: boolean
	isFinishing?: boolean
	isStarted?: boolean
	isVertical?: boolean
	isLandscape?: boolean
	isReduced?: boolean
	playOnReady?: boolean
	hasPlayedOnReady?: boolean
	hasConversationalReplay?: boolean
	isCinema?: boolean
	isWriting?: boolean
	displayMode?: DisplayMode
	tracker?: React.MutableRefObject<any>
	setTracker?: (tracker: React.MutableRefObject<any>) => void
	setIsReady?: (isReady: boolean) => void
	setIsStreaming?: (isStreaming: boolean) => void
	setIsPlayed?: (isPlayed: boolean) => void
	setIsMuted?: (isMuted: boolean) => void
	setIsStarted?: (isStarted: boolean) => void
	setIsReduced?: (isReduced: boolean) => void
	setIsCinema?: (isCinema: boolean) => void
	setIsWriting?: (isWriting: boolean) => void
	setPlayerData?: (playerData: any) => void
	setPlayOnReady?: (boolean) => void
	handlePlay?: (livestreamRef) => void
	handlePlayOnReady?: (livestreamRef) => void
	handlePause?: (livestreamRef: any) => void
	manageIVSAction?: (actionType: string) => void //should be rename manageAndLog once tracker is usable without his ref.
	chatRef?: any
	setChatRef?: (chatRef: any) => void
	setFakeStatus?: (fakeStatus: string) => void
	fakeStatus?: string
	replayChatDeactivated?: boolean
	translations?: Record<string, any>
}

const Context = createContext<PlayerContext>({})

export const usePlayerContext = () => useContext(Context)

export const PlayerProvider: React.FC<ProviderBaseProps> = ({ children }) => {
	/**
	 * @States
	 */
	const [
		{
			playerData,
			isReady,
			isStreaming,
			isPlayed,
			playOnReady,
			hasPlayedOnReady,
			isMuted,
			isStarted,
			toFinish,
			eventStatus,
			videoMode,
			lastEventStatus,
			hasConversationalReplay
		},
		managePlayer
	] = useReducer(playerReducer, playerInitialState)
	const [device, setDevice] = useState<DeviceTypes>()
	const [isVertical, setIsVertical] = useState<boolean>()
	const [isWriting, setIsWriting] = useState<boolean>()
	const [displayMode, setDisplayMode] = useState<DisplayMode>()
	const [isReduced, setIsReduced] = useState<boolean>(false)
	const [isCinema, setIsCinema] = useState<boolean>(false)
	const [tracker, setTracker] = useState<React.MutableRefObject<any>>(null)
	const [chatRef, setChatRef] = useState<React.MutableRefObject<JSX.Element>>()
	const [fakeStatus, setFakeStatus] = useState<string>(null)

	const handleOrientationChange = () => {
		const isPortrait = window.matchMedia('(orientation: portrait)').matches
		setIsVertical(isPortrait)
	}

	/**
	 * @Effects
	 */
	useEffect(() => {
		const handleWindowResize = () => {
			const playerDevice = new UaParser().getDevice()
			handleOrientationChange()
			setDevice(DeviceTypes[playerDevice.type || 'desktop'])
		}
		handleWindowResize()

		window.addEventListener('resize', handleWindowResize)
		return () => {
			window.removeEventListener('resize', handleWindowResize)
		}
	}, [])

	/**
	 * @handle finishing behavior here
	 */
	useEffect(() => {
		if (eventStatus !== lastEventStatus) {
			Bus.send(Constants.bus.player.event_status_changed, eventStatus)
		}
		if (isFinishing(eventStatus, toFinish)) {
			window.location.reload()
		}
	}, [eventStatus, lastEventStatus])

	useEffect(() => {
		if (videoMode && device) {
			const newDisplayMode = getPlayerDisplayModeValue(device, videoMode)

			if (newDisplayMode !== displayMode) {
				setDisplayMode(newDisplayMode)
			}
		}
	}, [device, isVertical, videoMode, displayMode])

	const values = useMemo(
		(): PlayerContext => ({
			eventStatus,
			videoMode,
			device,
			playerData,
			isMobile: device === DeviceTypes.mobile,
			isDesktop: device === DeviceTypes.desktop,
			isTablet: device === DeviceTypes.tablet,
			isReady,
			isStreaming,
			isPlayed,
			isMuted,
			isReplayOrFinishing: eventStatus === EventStatuses.REPLAY || eventStatus === EventStatuses.FINISHING,
			isReplay: eventStatus === EventStatuses.REPLAY,
			isLive: eventStatus === EventStatuses.LIVE,
			isFinishing: eventStatus === EventStatuses.FINISHING,
			hasPlayedOnReady,
			isStarted,
			isVertical,
			isCinema,
			isReduced,
			isLandscape: videoMode === 'landscape',
			playOnReady,
			hasConversationalReplay,
			displayMode,
			tracker,
			/** @Setters */
			setTracker,
			setIsReady: (isReady) => managePlayer({ type: PlayerActions.SET_IS_READY, data: { isReady } }),
			setIsStreaming: (isStreaming) =>
				managePlayer({ type: PlayerActions.SET_IS_STREAMING, data: { isStreaming } }),
			setIsPlayed: (isPlayed) => managePlayer({ type: PlayerActions.SET_IS_PLAYED, data: { isPlayed } }),
			setIsMuted: (isMuted) => managePlayer({ type: PlayerActions.SET_IS_MUTED, data: { isMuted } }),
			setIsStarted: (isStarted) => managePlayer({ type: PlayerActions.SET_IS_STARTED, data: { isStarted } }),
			setIsReduced,
			setIsCinema,
			setIsWriting,
			setPlayOnReady: (playOnReady: boolean) =>
				managePlayer({ type: PlayerActions.SET_PLAY_ON_READY, data: { playOnReady } }),
			setPlayerData: (playerData) => managePlayer({ type: PlayerActions.SET_PLAYER_DATA, data: { playerData } }),
			handlePlay: (livestreamRef) => {
				managePlayer({ type: PlayerActions.HANDLE_PLAY, data: { livestreamRef } })
				Bus.send(Constants.bus.player.play, {
					event: playerData?.event.event,
					program: playerData?.event.program
				})
			},
			handlePlayOnReady: (livestreamRef) => {
				Bus.send(Constants.bus.player.play, {
					event: playerData?.event.event,
					program: playerData?.event.program
				})
				Bus.send(Constants.bus.player.event_participated, { steps: playerData.event.steps })
				managePlayer({ type: PlayerActions.HANDLE_PLAY_ON_READY, data: { livestreamRef } })
			},
			handlePause: (livestreamRef) => {
				Bus.send(Constants.bus.player.pause, { event: playerData?.event })
				managePlayer({ type: PlayerActions.HANDLE_PAUSE, data: { livestreamRef } })
			},
			manageIVSAction: (actionType) => {
				managePlayer({ type: actionType })
			},
			chatRef,
			setChatRef,
			isWriting,
			setFakeStatus,
			fakeStatus,
			replayChatDeactivated:
				eventStatus === EventStatuses.REPLAY && !playerData?.event?.event?.settings?.options?.hasReplayChat,
			translations: playerData.ui
		}),
		[
			device,
			isStreaming,
			isPlayed,
			playerData,
			eventStatus,
			isVertical,
			isReduced,
			playOnReady,
			videoMode,
			displayMode,
			isCinema,
			isWriting,
			chatRef,
			tracker,
			fakeStatus
		]
	)

	return <Context.Provider value={values}>{children}</Context.Provider>
}
