import React, { useEffect, useState, useImperativeHandle } from 'react'
import { useRouter } from 'next/router'
import { mapDispatchToProps } from '@helpers/wrappers/withReduxStore'
import { State } from '@stores/index'
import { connect } from 'react-redux'
import Utils from '@utils/index'

interface Props {
	innerRef?: any
	storeConfig?: any
	storeIsOffline?: boolean
	storeDatas?: any
	dispatch?: (action) => void
}

const mapStateToProps = (state: State, props: Props) => {
	return { ...state, ...props }
}

const Tracker: React.FC<Props> = ({ innerRef, storeDatas }) => {
	const eventId = useRouter().query.id
	const [anonId, setAnonId] = useState(null)

	useEffect(() => {
		setUserAnonId()
	}, [])

	useImperativeHandle(
		innerRef,
		() => ({
			getAnonId() {
				return anonId
			}
		}),
		[eventId, storeDatas]
	)

	const setUserAnonId = () => {
		const auid = Utils.uniqueId()
		setAnonId(auid)
	}

	return <></>
}

export default connect(mapStateToProps, mapDispatchToProps)(Tracker)
