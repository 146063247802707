import { useEffect, useState } from 'react'

const useIntersectingObserver = (ref, callback: (isIntersecting: boolean) => void) => {
	const observer = new IntersectionObserver(([entry]) => {
		callback && callback(entry.isIntersecting)
	})

	useEffect(() => {
		if (observer && ref?.current) {
			observer.observe(ref.current)
			// Remove the observer as soon as the component is unmounted
			return () => {
				observer.disconnect()
			}
		}
	}, [ref?.current])
}

export const useOnScreen = (ref) => {
	const [isIntersecting, setIntersecting] = useState(false)

	useIntersectingObserver(ref, setIntersecting)

	return isIntersecting
}

export const useHasBeenSeen = (ref) => {
	const [hasBeenSeen, setHasBeenSeen] = useState(false)

	const handleObserver = (isIntersecting: boolean) => {
		if (isIntersecting && !hasBeenSeen) {
			setHasBeenSeen(true)
		}
	}

	useIntersectingObserver(ref, handleObserver)

	return hasBeenSeen
}
