import { ModalsType } from '@components/Notifiers/Modals/constants'
import React, { createContext, useContext, useMemo, useState, useEffect, useReducer } from 'react'
import { WinningInstant } from 'types/WinningInstant'
import { useNotificationsContext } from '../notifications'
import { usePlayerContext } from '../player'
import { useViewer } from '../viewerContext'
import { TimerService } from '@helpers/TimerService'
import getConfig from 'next/config'
import { winningInstantContextInitialState, winningInstantReducer } from './logic'
import Bus from '@helpers/bus'
import Constants from '@constants'
import { PanelsType } from '@components/Notifiers/Panels/constants'
import { DrawersType } from '@components/Notifiers/Drawers/constants'
import { handleApiResponseErrors } from '../fetchHelpers'
import { ProviderBaseProps } from 'types/Common'
const { publicRuntimeConfig } = getConfig()
const NEXT_PUBLIC_API_ENDPOINT = publicRuntimeConfig.NEXT_PUBLIC_API_ENDPOINT

interface WinningInstantContext {
	setWinningInstant?: (winningInstant: WinningInstant) => void
	winningInstant?: WinningInstant
	wiCounter?: number
	canShowWinningInstant?: boolean
	getAndSetWinningInstant?: (
		metadata: { uuid: string; action: 'start' | 'end'; type: string },
		playerData: any
	) => void
	isActivated?: boolean
	hasBeenDisplayed?: boolean
	hasBeenClicked?: boolean
	showUserNotification?: boolean
	setShowUserNotification?: (show?: boolean) => void
	clickWinningInstant?: () => void
}

const Context = createContext<WinningInstantContext | null>({})

export const useWinningInstantContext = () => useContext(Context)

export const WinningInstantProvider: React.FC<ProviderBaseProps> = ({ children }) => {
	/**
	 * @States
	 */
	const { getWIParticipation, currentViewer } = useViewer()
	const { playerData, isLive, isPlayed, isVertical, videoMode, displayMode } = usePlayerContext()
	const {
		openPanel,
		openDrawer,
		closeLastPanel,
		openModal,
		winningInstantFormOpened,
		currentDrawer,
		closeDrawer,
		winningInstantResultsModalOpened
	} = useNotificationsContext()
	const [{ winningInstant, hasBeenClicked, hasBeenDisplayed }, manageWinningInstant] = useReducer(
		winningInstantReducer,
		winningInstantContextInitialState
	)
	const [isActivated, setIsActivated] = useState<boolean>(false)
	const [showUserNotification, setShowUserNotification] = useState<boolean>(false)
	const [wiCounter, setWiCounter] = useState<number>(0)

	/**
	 * @Methods
	 */
	const hasUserParticipated = (): boolean => {
		return winningInstant ? !!getWIParticipation(winningInstant?.uuid) : false
	}

	const resetWiCounter = (wiId) => {
		if (wiId === winningInstant?.uuid) {
			setWiCounter(null)
		}
	}

	const getAndSetWinningInstant = (
		metadata: { uuid: string; action: 'start' | 'end'; type: string },
		playerDataParam: any
	) => {
		const route =
			metadata.action === 'end'
				? `${NEXT_PUBLIC_API_ENDPOINT}/api/public/events/${playerDataParam.event.event.uuid}/winning_instants/${metadata.uuid}/results`
				: `${NEXT_PUBLIC_API_ENDPOINT}/api/public/events/${playerDataParam.event.event.uuid}/winning_instants/${metadata.uuid}`
		fetch(route, { headers: { 'Content-Type': 'application/json' } })
			.then((res) => res.json())
			.then((data) => {
				handleApiResponseErrors(data, currentViewer)
				manageWinningInstant({ type: 'SET_WINNING_INSTANT', data: { ...data, ...metadata } })
			})
	}

	const handleClick = () => {
		setShowUserNotification(false)
		const wiParticipation = getWIParticipation(winningInstant?.uuid)
		if (!!wiParticipation || !wiCounter) return

		if (winningInstant && !wiParticipation) {
			Bus.send(Constants.bus.player.interaction_clicked, {
				interactionId: winningInstant.uuid,
				interactionType: 'winning_instant'
			})
			manageWinningInstant({ type: 'SET_HAS_BEEN_CLICKED', data: true })
			if (!wiParticipation)
				openPanel(PanelsType.WINNING_INSTANT_FORM, {
					back: playerData?.ui.panel_back
				})
		}
	}

	const finish = () => {
		resetWiCounter(winningInstant)
		manageWinningInstant({ type: 'CLEAR_WINNING_INSTANT' })
	}

	const notify = () => {
		manageWinningInstant({ type: 'SET_HAS_BEEN_DISPLAYED', data: true })

		const isUserPresentOnWiStart = () => !wiCounter || wiCounter >= winningInstant.duration / 1000 - 2
		if (isUserPresentOnWiStart()) {
			if (isVertical || videoMode === 'portrait') {
				openDrawer(DrawersType.WINNING_INSTANT)
			} else {
				openPanel(PanelsType.WINNING_INSTANT_FORM, {
					back: playerData?.ui.panel_back
				})
			}
		} else {
			setShowUserNotification(true)
			setTimeout(() => {
				setShowUserNotification(false)
			}, 10_000)
		}
	}

	const closeWinningInstantDrawer = () => {
		if (currentDrawer?.drawerType === 'winning_instant') {
			closeDrawer()
		}
	}

	/**
	 * @LIFE_CYCLE START
	 * set activated status
	 * reset topBar count
	 */
	const handleStart = () => {
		if (winningInstant?.action) {
			setIsActivated(!!winningInstant && wiCounter > 0)
		}
	}

	/**
	 * @LIFE_CYCLE NOTIFY
	 * notify user that winning instant has started
	 * log appearance
	 */
	const handleDisplay = () => {
		if (
			winningInstant &&
			!hasBeenDisplayed &&
			!hasUserParticipated() &&
			wiCounter &&
			!winningInstantResultsModalOpened()
		) {
			Bus.send(Constants.bus.player.interaction_distributed, {
				interactionId: winningInstant.uuid,
				interactionType: 'winning_instant'
			})
			notify()
		}
	}

	/**
	 * @LIFE_CYCLE RESULTS
	 * display modal depending of user's answer's validity.
	 */
	const handleUserResult = () => {
		if (winningInstant?.action === 'end' && isLive) {
			const winners = winningInstant.winnerIds
			const wiParticipation = getWIParticipation(winningInstant.uuid)
			if (wiParticipation) {
				const hasRightAnswer = wiParticipation.answerId === winningInstant?.rightAnswer?.uuid
				if (!hasRightAnswer) {
					return openModal(ModalsType.WINNING_INSTANT_WRONG_ANSWER, {
						playerData,
						rightAnswerContent: winningInstant?.rightAnswer?.content,
						onClose: () => finish()
					})
				}

				const isWinner = winners.find((w) => w === wiParticipation?.uuid)?.length
				if (!isWinner) {
					return openModal(ModalsType.WINNING_INSTANT_LOSER, {
						playerData,
						onClose: () => finish()
					})
				}

				if (isWinner) {
					return openModal(ModalsType.WINNING_INSTANT_WINNER, {
						playerData,
						position: winners.findIndex((winner) => winner === wiParticipation.uuid) + 1,
						rewardsList: winningInstant.rewardsList,
						onClose: () => finish()
					})
				}
			}
		}
	}

	const handleTimesUp = () => {
		if (!wiCounter) {
			closeWinningInstantDrawer()
			if (winningInstantFormOpened) {
				closeLastPanel()
				setTimeout(() => {
					openModal(ModalsType.WINNING_INSTANT_TIMESUP, { playerData })
				}, 500)
			}
		}
	}

	/**
	 * @LIFE_CYCLE CLEAR
	 * clear winning instant when over
	 * reset to initial state
	 */
	const handleClear = () => {
		if (winningInstant && wiCounter === null && hasBeenDisplayed) {
			manageWinningInstant({ type: 'CLEAR_WINNING_INSTANT' })
			setShowUserNotification(false)
		}
	}

	/**
	 * @Effects
	 */

	/**
	 * @LIFE_CYCLE BEGIN
	 * start timer once winning instant is defined
	 */
	useEffect(() => {
		const interval = setInterval(() => {
			const timer = TimerService.getWinningInstantCounter(winningInstant)
			setWiCounter(timer)
		}, 1000)

		return () => clearInterval(interval)
	}, [winningInstant])

	useEffect(() => {
		if (isPlayed) {
			handleStart()
			handleDisplay()
			handleUserResult()
			handleTimesUp()
			handleClear()
		}
	}, [wiCounter, winningInstant])

	useEffect(() => {
		if (!isVertical && videoMode !== 'portrait') {
			closeWinningInstantDrawer()
		}
	}, [videoMode, currentDrawer, isVertical, displayMode])

	const values = useMemo(
		(): WinningInstantContext => ({
			clickWinningInstant: handleClick,
			setWinningInstant: (data: any) => manageWinningInstant({ type: 'SET_WINNING_INSTANT', data }),
			winningInstant,
			wiCounter,
			canShowWinningInstant: isLive && isPlayed && !!winningInstant && winningInstant?.action !== 'end',
			getAndSetWinningInstant,
			isActivated,
			hasBeenClicked,
			hasBeenDisplayed,
			showUserNotification,
			setShowUserNotification
		}),
		[winningInstant, wiCounter, hasBeenClicked, hasBeenDisplayed, isActivated, showUserNotification]
	)

	return <Context.Provider value={values}>{children}</Context.Provider>
}
