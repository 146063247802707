import * as libphonenumber from 'google-libphonenumber'
const PNF = libphonenumber.PhoneNumberFormat
const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance()

export default class PhoneNumberService {
	static check(number?: string, countryCode?: string) {
		try {
			const parsedNumber = phoneUtil.parseAndKeepRawInput(number, countryCode)
			return phoneUtil.isValidNumberForRegion(parsedNumber, countryCode)
		} catch (e) {
			return false
		}
	}

	static format(number: string, countryCode: string) {
		const parsedNumber = phoneUtil.parseAndKeepRawInput(number, countryCode)
		return phoneUtil.format(parsedNumber, PNF.E164)
	}
}
