import React, { useEffect } from 'react'
import { useRouter } from 'next/router'
import { mapDispatchToProps } from '@helpers/wrappers/withReduxStore'
import { State } from '@stores/index'
import { connect } from 'react-redux'
import Firebase from '@helpers/firebase'
import getConfig from 'next/config'
import { FirestoreDataConverter, QueryDocumentSnapshot } from '@google-cloud/firestore'
import { usePlayerContext } from '@helpers/contexts/player'
import { useWinningInstantContext } from '@helpers/contexts/winningInstant'
const { publicRuntimeConfig } = getConfig()
const NEXT_PUBLIC_API_ENDPOINT = publicRuntimeConfig.NEXT_PUBLIC_API_ENDPOINT

interface Props {
	dispatch?: (action) => void
	storeProducts?: any
	storeDatas?: any
	storeTimecodes?: any
	storeStepsProgram?: any
}

const mapStateToProps = (state: State, props: Props) => {
	return { ...state, ...props }
}

const Datas: React.FC<Props> = ({ dispatch, storeDatas, storeStepsProgram, storeTimecodes }) => {
	/**
	 * @States
	 */
	const { setPlayerData, fakeStatus } = usePlayerContext()
	const { setWinningInstant } = useWinningInstantContext()
	const eventId = useRouter().query.id

	const routes = {
		publicEvent: `${NEXT_PUBLIC_API_ENDPOINT}/api/public/events/${eventId}`,
		publicTimecodes: `${NEXT_PUBLIC_API_ENDPOINT}/api/events/${eventId}/timecodes`
	}

	const extractProductsFromSteps = (steps) => {
		const output = []
		const memo = []
		for (const step of steps) {
			for (const product of step.products) {
				if (memo.indexOf(product.uuid) === -1) {
					output.push(product)
					memo.push(product.uuid)
				}
			}
		}
		return output
	}

	useEffect(() => {
		//
		if (eventId) {
			fetch(routes.publicEvent)
				.then((r) => r.json())
				.then(
					(res) => {
						// observe event
						const db = Firebase.firestore()
						// Observe events
						fetchEventsOnSnapshot(db, res)
						// Get timecodes
						fetchTimeCodes(res)

						fetchWinningInstantTimecodes(db)
					},
					(error) => {
						console.log(error)
					}
				)
		}
	}, [eventId])

	const fetchTimeCodes = (data) => {
		fetch(routes.publicTimecodes)
			.then((r) => r.json())
			.then(
				(timecodes) => {
					const filteredTimecodes = timecodes
						.filter((timecode) => typeof timecode.ts === 'number' && timecode.ts >= 0)
						.sort((tc1, tc2) => tc1.ts - tc2.ts)
					if (data.event.event.status !== 'prelive') {
						dispatch({ type: 'SET_TIMECODES', timecodes: filteredTimecodes })
					}
				},
				(error) => {
					console.log(error)
				}
			)
	}

	const fetchEventsOnSnapshot = (db: Firebase.firestore.Firestore, data) => {
		//@ts-ignore
		const eventDoc = db.collection('events').doc(eventId)
		eventDoc.onSnapshot(
			(snapshot) => {
				const event = snapshot.data()
				if (event) {
					const clone = JSON.parse(JSON.stringify(data))
					clone.event.event.status = fakeStatus || event.status
					setPlayerData(clone)
					//remove the dispatch when every components using storeDatas are migrated.
					dispatch({ type: 'SET_DATAS', datas: clone })
					dispatch({ type: 'SET_STEPS_PROGRAM', stepsProgram: clone.event.program.steps || [] })
					dispatch({
						type: 'SET_PRODUCTS',
						products: extractProductsFromSteps(clone.event.program.steps)
					})
				}
			},
			(err) => {
				console.log(`Encountered error: ${err}`)
			}
		)
	}

	// Since we want to listen to the snapshot of the (directly on firebase)
	// we don't go threw api to fetch the winning instant from here.
	// The converter helps us protect the data retrieved from firebase
	const fetchWinningInstantTimecodes = (db: Firebase.firestore.Firestore) => {
		const winningInstantTCsDoc = db
			.collection('timecodes')
			.where('type', '==', 'winning_instant')
			.where('event', '==', db.doc('/events/' + eventId))
			.orderBy('created_at', 'desc')

		winningInstantTCsDoc.onSnapshot((snapshot) => {
			fetchWinningInstantTimecodesFromFirebase(snapshot)
		})

		winningInstantTCsDoc.get().then((snapshot) => {
			fetchWinningInstantTimecodesFromFirebase(snapshot)
		})
	}

	const fetchWinningInstantTimecodesFromFirebase = (snapshot) => {
		if (snapshot.docs.length) {
			const timecode = snapshot.docs[0].data()
			const winningInstant = timecode.interaction
			winningInstant
				.withConverter(firebaseConverter)
				.get()
				.then((res) => {
					const data = res.data()
					if (data?.started && !data?.ended) {
						setWinningInstant({ ...data, action: 'start' })
					}
				})
		}
	}

	const firebaseConverter: FirestoreDataConverter<any> = {
		toFirestore: (data) => data,
		fromFirestore: (snapshot: QueryDocumentSnapshot) => {
			const data = snapshot.data()

			return {
				uuid: data.uuid,
				question: data.question,
				answers: data.answers,
				duration: data.duration,
				hasCommercialOptin: data.hasCommercialOptin,
				commercialOptinText: data.commercialOptinText || null,
				hasRulesOptin: data.hasRulesOptin,
				rulesOptin: data.rulesOptin,
				rulesOptinLink: data.rulesOptinLink,
				rewardsList: data.rewardsList,
				startTime: data.startTime,
				ended: data.ended,
				started: data.started
			}
		}
	}

	useEffect(() => {
		if (storeDatas && storeStepsProgram && storeTimecodes) {
			if (fakeStatus && !storeTimecodes.length) {
				if (storeStepsProgram.length) {
					dispatch({ type: 'SET_CURRENT_STEP', step: storeStepsProgram[0] })
				}
			} else {
				if (storeDatas.event.event.status === 'live' && !fakeStatus) {
					let step = null
					const i = storeTimecodes.length
					if (i) {
						step = storeTimecodes[i - 1].step
					}
					dispatch({ type: 'SET_CURRENT_STEP', step: step === null ? null : storeStepsProgram[step] })
				}
				if (fakeStatus && fakeStatus === 'live' && storeStepsProgram.length) {
					dispatch({ type: 'SET_CURRENT_STEP', step: storeStepsProgram[0] })
				}
			}
		}

		//
	}, [storeDatas, storeStepsProgram, storeTimecodes])

	return <></>
}

export default connect(mapStateToProps, mapDispatchToProps)(Datas)
