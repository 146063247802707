import Icon from '@components/Micro/Icon'
import Popin from '@components/Utils/Popin'
import { mapDispatchToProps } from '@helpers/wrappers/withReduxStore'
import { State } from '@stores/index'
import React from 'react'
import { connect } from 'react-redux'
import { IconCenter, IconWrapper, PopinContainer, PopinDescription, PopinTitle } from './style'
import Theme from '@styles/theme'

interface Props {
	storeModal?: Record<string, any>
	dispatch?: Function
}

const mapStateToProps = (state: State, props: Props) => {
	return { ...state, ...props }
}

const BaseModal: React.FC<Props> = ({ storeModal, dispatch }) => {
	const closeClickHandler = () => {
		if (storeModal?.onClose) {
			return storeModal.onClose()
		}
		dispatch({ type: 'REMOVE_MODAL' })
	}

	return (
		<Popin
			isOpened={storeModal && storeModal.type === 'base_modal'}
			closeClickHandler={closeClickHandler}
			maxWidth="315px"
		>
			{storeModal && (
				<PopinContainer>
					<IconCenter>
						{!!storeModal?.customIcon && storeModal.customIcon}
						{!storeModal?.customIcon && (
							<IconWrapper backgroundColor={storeModal?.iconBGColor || Theme.colors.green()}>
								<Icon
									name={storeModal?.iconName || 'thumb'}
									width="22px"
									height="22px"
									fill={Theme.colors.white()}
								/>
							</IconWrapper>
						)}
					</IconCenter>
					<PopinTitle>{storeModal?.title}</PopinTitle>
					<PopinDescription
						marginBottom={!!storeModal?.customChildren ? 25 : 0}
						color={Theme.colors.text_second}
					>
						{storeModal?.description}
					</PopinDescription>
					{!!storeModal?.customChildren && storeModal.customChildren}
				</PopinContainer>
			)}
		</Popin>
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(BaseModal)
