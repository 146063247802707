import { BaseFlex } from '@styles/common'
import styled from '@emotion/styled'
import Theme from '@styles/theme'

export const TitleWrapper = styled.div`
	font-family: ${Theme.fontFamilies.Main};
	font-weight: ${Theme.fontWeights.bold};
	font-size: 20em;
	margin-top: ${Theme.spacing(6)};
	color: ${() => Theme.colors.text_main};
	width: 100%;
`

export const ContentWrapper = styled(BaseFlex)`
	height: 100%;
	width: 100%;
	flex-wrap: wrap;
	overflow-y: scroll;
`
