import React, { useEffect, useRef, useState } from 'react'
import { PollAnswer, PollAnswersWrapper, PollQuestion, pollClasses } from './style'
import PollVote from './PollVote'
import { usePollsContext } from '@helpers/contexts/polls'
import Spinner from '@components/Micro/Spinner'
import Bus from '@helpers/bus'
import Constants from '@constants'
import { useHasBeenSeen } from '@helpers/hooks/useOnScreen'

const PollContent: React.FC = () => {
	const { poll, answerId, submitVote, orderedVotes, answersLoaded, maxPercentage, isSubmitting } = usePollsContext()

	const [pollAlreadyShown, setPollAlreadyShown] = useState<boolean>(false)

	const pollRef = useRef()
	const hasBeenSeen = useHasBeenSeen(pollRef)

	useEffect(() => {
		Bus.send(Constants.bus.player.interaction_viewed, {
			interactionId: poll?.uuid,
			interactionType: 'poll'
		})
	}, [hasBeenSeen])

	useEffect(() => {
		if (poll && !pollAlreadyShown && !(answerId || orderedVotes.length)) {
			Bus.send(Constants.bus.player.interaction_distributed, {
				interactionId: poll.uuid,
				interactionType: 'poll'
			})
			setPollAlreadyShown(true)
		}
	}, [])

	return (
		<>
			<PollQuestion className={pollClasses.question} ref={pollRef}>
				{poll?.question}
			</PollQuestion>
			{poll && (
				<PollAnswersWrapper>
					{isSubmitting ? (
						<Spinner padding="30px" />
					) : (
						!answerId &&
						poll?.answers &&
						poll?.answers?.map((answer, i) => (
							<PollAnswer
								onClick={() => submitVote(answer.uuid)}
								key={`poll-answer-${i}`}
								className={pollClasses.answer}
							>
								{answer.content}
							</PollAnswer>
						))
					)}
					{orderedVotes.length &&
						orderedVotes.map((votes, i) => (
							<PollVote
								maxPourcentage={maxPercentage}
								votes={votes}
								key={`poll-votes-${i}`}
								answerId={answerId}
								answersLoaded={answersLoaded}
							/>
						))}
				</PollAnswersWrapper>
			)}
		</>
	)
}

export default PollContent
