import React, { useState, useRef } from 'react'
import { ChatFieldWrapper, classes, FieldWrapper, InputWrapper, SendIcon, SendWrapper } from './style'
import Icon from '@components/Micro/Icon'
import { State } from '@stores/index'
import { connect } from 'react-redux'
import Theme from '@styles/theme'
import { useViewer } from '@helpers/contexts/viewerContext'
import { useMessagesContext } from '@helpers/contexts/messages'
import { classGenerator as cx } from '@styles/sharedLogics'
import { useNotificationsContext } from '@helpers/contexts/notifications'
import { DrawersType } from '@components/Notifiers/Drawers/constants'
import { ModalsType } from '@components/Notifiers/Modals/constants'

interface Props {
	innerRef?: any
	storeDatas?: any
	onLikeClick?: any
	onWriting?: any
	onUnWriting?: any
	isMobile?: any
	floating?: boolean
	isLandscape?: boolean
	isVertical?: boolean
	inputType?: 'text' | 'textarea'
}

const mapStateToProps = (state: State, props: Props) => {
	return { ...state, ...props }
}

const ChatField: React.FC<Props> = ({
	onWriting,
	onUnWriting,
	storeDatas,
	isLandscape,
	isVertical,
	isMobile,
	floating,
	inputType = 'text'
}) => {
	const { currentViewer, viewerIsBanned } = useViewer()
	const { sendMessage } = useMessagesContext()
	const { openDrawer, openModal, closeModal } = useNotificationsContext()

	const input = useRef(null)
	const [message, setMessage] = useState('')
	const [isWriting, setIsWriting] = useState(false)

	const inputFocusHandler = () => {
		if (currentViewer?.firstName) {
			setIsWriting(true)
			onWriting()
		} else {
			if (!isVertical && isLandscape) {
				openModal(ModalsType.FIRST_NAME, { onClose: closeModal, isModal: true })
			} else {
				openDrawer(DrawersType.FIRST_NAME_DRAWER)
			}
			//@ts-ignore
			input.current.blur()
		}
	}

	const inputBlurHandler = () => {
		setIsWriting(false)
		onUnWriting()
	}

	const submit = async () => {
		const value = message.trim()
		if (value.length > 1 && value.length < 136) {
			if (!viewerIsBanned()) {
				setMessage('')
				sendMessage(value, currentViewer, storeDatas)
			} else {
				setMessage('')
			}
		}
	}

	const onKeyUp = (e) => {
		if (e.keyCode === 13) {
			submit()
		}
	}

	return (
		<ChatFieldWrapper
			className={cx(classes.root, { [classes.floating]: floating })}
			isLandscape={isLandscape}
			isHorizontal={!isVertical}
			isMobile={isMobile}
			isWriting={isWriting}
			onClick={() => input.current.focus()}
		>
			<FieldWrapper className={cx(classes.fieldWrapper, { [classes.mobileLandscape]: isLandscape && isMobile })}>
				<InputWrapper
					ref={input}
					type={inputType}
					value={message}
					onChange={(e) => {
						setMessage(e.currentTarget.value)
					}}
					onKeyUp={onKeyUp}
					onFocus={inputFocusHandler}
					onBlur={inputBlurHandler}
					placeholder={isWriting ? null : storeDatas.ui.message_field_placeholder}
					isMobile={isMobile}
					isLandscape={isLandscape}
					isHorizontal={!isVertical}
					className={cx({
						[classes.floating]: floating,
						[classes.textarea]: inputType === 'textarea'
					})}
				/>
				<SendWrapper onClick={submit}>
					<SendIcon isWriting={isWriting}>
						<Icon name="plane" width={14} height={14} fill={Theme.colors.black()} />
					</SendIcon>
				</SendWrapper>
			</FieldWrapper>
		</ChatFieldWrapper>
	)
}

export const chatFieldClasses = classes

export default connect(mapStateToProps, null)(ChatField)
