import React, { useRef, useImperativeHandle } from 'react'
import GoToReplay from '@components/Micro/GoToReplay'
import Theme from '@styles/theme'
import ProductInfos from './ProductInfos'
import ProductVariants from '@components/Micro/ProductVariants'
import { mapDispatchToProps } from '@helpers/wrappers/withReduxStore'
import { State } from '@stores/index'
import { connect } from 'react-redux'
import Gallery from './Gallery'
import { css } from '@emotion/react'
import { Product } from 'types/Product'
import PriceComponent from '@components/Micro/Price/Price'
import {
	ProductDescriptionWrapper,
	ProductImageWrapper,
	ProductInfosWrapper,
	ProductTitle,
	ProductTitleWrapper
} from './style'
import { usePlayerContext } from '@helpers/contexts/player'

interface Props {
	dispatch?: (action) => void
	innerRef?: any
	data?: Product
	onVariantChoice?: any
}

const mapStateToProps = (state: State, props: Props) => {
	return { ...state, ...props }
}

const ProductDescription: React.FC<Props> = ({ innerRef, data, onVariantChoice }) => {
	const { displayMode, playerData, translations } = usePlayerContext()
	const variantsRef = useRef(null)
	const galleryRef = useRef(null)

	let $title = data.title

	useImperativeHandle(
		innerRef,
		() => ({
			resetVariants() {
				variantsRef.current.reset()
			},
			buy() {
				return variantsRef.current.buy()
			}
		}),
		[variantsRef]
	)

	const variantsChoiceHandler = (infos) => {
		if (infos.price) {
			if (onVariantChoice) {
				onVariantChoice(infos)
			}
		}
	}

	const getPriceAndCrossOutPrice = (): { price: number; crossOutPrice: number } => {
		if (data.variants?.order?.length) {
			const item = data.variants.combinations.find((combination) => combination.price > 0)
			return { price: item?.price || 0, crossOutPrice: item?.crossOutPrice || 0 }
		} else {
			return { price: data.price, crossOutPrice: data.crossOutPrice }
		}
	}

	return (
		<ProductDescriptionWrapper>
			<ProductTitleWrapper displayMode={displayMode}>
				<ProductTitle>{$title}</ProductTitle>
				<PriceComponent
					price={getPriceAndCrossOutPrice().price}
					crossOutPrice={getPriceAndCrossOutPrice().crossOutPrice}
					channel={playerData?.channel}
				/>
			</ProductTitleWrapper>
			{data.images && (
				<ProductImageWrapper displayMode={displayMode}>
					<Gallery images={data.images} innerRef={galleryRef} parallax={0} />
				</ProductImageWrapper>
			)}
			<ProductInfosWrapper displayMode={displayMode}>
				<ProductVariants
					innerRef={variantsRef}
					data={data}
					onChoice={variantsChoiceHandler}
					translations={translations}
				/>
				{playerData.event.event.status === 'replay' && (
					<GoToReplay
						item={data}
						style={css`
							border: 1px solid ${Theme.colors.cta_second};
						`}
						isCta={true}
					/>
				)}
				<ProductInfos data={data} />
			</ProductInfosWrapper>
		</ProductDescriptionWrapper>
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductDescription)
