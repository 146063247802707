import Sizes from '@tools/sizes'
import Raf from '@tools/raf'
import Ease from '@tools/ease'

export default class Slider {
	constructor(options) {
		this.sizes = {}
		this.isDowned = false
		this.easeX = new Ease({ duration: 600, easing: 'expoOut' })
		this.easeY = new Ease()
		this.slideLimit = 30
		this.swipeSpeed = 2
		this.current = null
		this.slidePositions = []
		this.pos = {
			start: {
				x: 0,
				y: 0
			},
			save: {
				x: 0,
				y: 0
			},
			dist: {
				x: 0,
				y: 0
			},
			final: {
				x: 0,
				y: 0
			}
		}
		this.isSlided = false
		this.elements = options.elements
		this.handlers = options.handlers

		if (this.elements.cards.current.length > 1) {
			this._bind()
			this._observe()
		}
	}

	_bind() {
		this._sizesHandler = this._sizesHandler.bind(this)
		this._rafHandler = this._rafHandler.bind(this)
		this._mouseDownHandler = this._mouseDownHandler.bind(this)
		this._mouseMoveHandler = this._mouseMoveHandler.bind(this)
		this._mouseUpHandler = this._mouseUpHandler.bind(this)
	}

	_observe() {
		Sizes.subscribe(this._sizesHandler)
		Raf.subscribe(this._rafHandler)
		this.elements.viewport.current.addEventListener('mousedown', this._mouseDownHandler)
		this.elements.viewport.current.addEventListener('mousemove', this._mouseMoveHandler)
		this.elements.viewport.current.addEventListener('mouseup', this._mouseUpHandler)
		this.elements.viewport.current.addEventListener('mouseleave', this._mouseUpHandler)
		this.elements.viewport.current.addEventListener('touchstart', this._mouseDownHandler)
		this.elements.viewport.current.addEventListener('touchmove', this._mouseMoveHandler)
		this.elements.viewport.current.addEventListener('touchend', this._mouseUpHandler)
	}

	_unobserve() {
		Sizes.unsubscribe(this._sizesHandler)
		Raf.unsubscribe(this._rafHandler)
		if (this.elements.viewport.current) {
			this.elements.viewport.current.removeEventListener('mousedown', this._mouseDownHandler)
			this.elements.viewport.current.removeEventListener('mousemove', this._mouseMoveHandler)
			this.elements.viewport.current.removeEventListener('mouseup', this._mouseUpHandler)
			this.elements.viewport.current.removeEventListener('mouseleave', this._mouseUpHandler)
			this.elements.viewport.current.removeEventListener('touchstart', this._mouseDownHandler)
			this.elements.viewport.current.removeEventListener('touchmove', this._mouseMoveHandler)
			this.elements.viewport.current.removeEventListener('touchend', this._mouseUpHandler)
		}
	}

	_mouseDownHandler(e) {
		this.isDowned = true
		const event = e.targetTouches ? e.targetTouches[0] : e
		this.pos.start.x = event.clientX
		this.pos.start.y = event.clientY
		this.pos.save.x = this.pos.final.x
		this.pos.save.y = this.pos.final.y
	}

	_mouseMoveHandler(e) {
		if (this.isDowned) {
			const event = e.targetTouches ? e.targetTouches[0] : e
			this.pos.dist.x = (this.pos.start.x - event.clientX) * this.swipeSpeed
			this.pos.dist.y = (this.pos.start.y - event.clientY) * this.swipeSpeed
			this.pos.final.x = this.pos.save.x + this.pos.dist.x
			this.pos.final.y = this.pos.save.y + this.pos.dist.y
			if (Math.abs(this.pos.dist.x) > this.slideLimit) {
				if (!this.isSlided && this.handlers.slided) {
					this.handlers.slided()
					this.isSlided = true
				}
			}
			if (this.isSlided && e.targetTouches) {
				e.preventDefault()
			}
			this._setPos(true)
		}
	}

	_mouseUpHandler(e) {
		if (this.isDowned) {
			this.isDowned = false
			this._align()
			if (this.isSlided && this.handlers.unslided) {
				this.handlers.unslided()
				this.isSlided = false
			}
		}
	}

	getNearestPos(pos) {
		let dist = 99999
		let target = -1

		this.slidePositions.map((value, i) => {
			if (Math.abs(value - pos) < dist) {
				dist = Math.abs(value - pos)
				target = i
			}
		})

		return target
	}

	_align() {
		let target = this.getNearestPos(this.pos.final.x)
		let index = 0

		if (target < 0) {
			index = 0
		} else if (target > this.elements.cards.current.length - 1) {
			index = this.elements.cards.current.length - 1
		} else {
			index = target
		}

		this.current = index
		this.goTo()
	}

	goTo() {
		this.pos.final.x = this.slidePositions[this.current]
		if (this.handlers.goTo) {
			this.handlers.goTo(this.current)
		}
		this._setPos()
	}

	_setPos(force) {
		// if (force) {
		// 	this.easeX.force(this.pos.final.x);
		// 	this.easeY.force(this.pos.final.y);
		// } else {
		// 	this.easeX.set(this.pos.final.x);
		// 	this.easeY.set(this.pos.final.y);
		// }

		let posX = this.pos.final.x

		if (this.pos.final.x < 0) {
			posX = this.pos.final.x * 0.1
		} else if (this.pos.final.x > this.sizes.totalWidth) {
			posX = this.sizes.totalWidth + (this.pos.final.x - this.sizes.totalWidth) * 0.1
		}

		this.easeX.set(posX)
		this.easeY.set(this.pos.final.y)
	}

	_sizesHandler(infos) {
		this.sizes = {
			viewport: this.elements.viewport.current.getBoundingClientRect(),
			overview: this.elements.overview.current.getBoundingClientRect(),
			totalWidth: 0
		}

		let getWidthBeforeIndex = (index) => {
			let w = 0
			this.elements.cards.current.map((value, i) => {
				let s = value.getBoundingClientRect()
				if (i < index) {
					w += s.width
				}
			})
			return w
		}

		this.elements.cards.current.map((value, i) => {
			this.slidePositions[i] = getWidthBeforeIndex(i)
			if (i !== this.elements.cards.current.length - 1) {
				this.sizes.totalWidth += value.getBoundingClientRect().width
			}
		})
	}

	_rafHandler(infos) {
		this.easeX.update(infos)
		this.easeY.update(infos)
		this.handlers.translated({
			x: this.easeX.pos,
			y: this.easeY.pos,
			px: this.easeX.pos / this.sizes.totalWidth
		})
	}

	goNext() {
		if (this.current < this.elements.cards.current.length - 1) {
			this.current++
			this.goTo()
		}
	}

	goPrev() {
		if (this.current > 0) {
			this.current--
			this.goTo()
		}
	}

	goToIndex(index) {
		this.current = index
		this.goTo()
	}

	resize() {
		this._sizesHandler(Sizes.sizes)
		this.goTo()
	}

	destroy() {
		if (this.elements.cards.current.length > 1) {
			this._unobserve()
		}
		this.easeX.destroy()
		this.easeY.destroy()
	}
}
