import React from 'react'
import Link from '@components/Micro/Link'
import Style, { classes } from './style'
import { Flex, Box } from 'rebass'
import { mapDispatchToProps } from '@helpers/wrappers/withReduxStore'
import { State } from '@stores/index'
import { connect } from 'react-redux'
import Theme from '@styles/theme'
import { css } from '@emotion/react'
import Icon from '@components/Micro/Icon'
import { classGenerator as cx } from '@styles/sharedLogics'
import { usePlayerContext } from '@helpers/contexts/player'

interface Props {
	dispatch?: (action) => void
	to?: any
	target?: any
	query?: any
	params?: any
	label?: string
	className?: any
	mt?: any
	mb?: string
	icon?: string
	style?: any
	onClick?: () => void
	onDirectClick?: any
	size?: string // small / medium / large
	type?: 'plain' | 'border' | 'border-selected' // plain / border
	fullWidth?: boolean
	justifyContent?: any
	iconOnly?: boolean
	noIconFill?: boolean
	customPadding?: string
	disabled?: boolean
}

const mapStateToProps = (state: State, props: Props) => {
	return { ...state, ...props }
}

const Cta: React.FC<Props> = ({
	to,
	target,
	query,
	params,
	label = 'Btn Label',
	className,
	mt,
	mb,
	icon,
	style,
	onClick,
	onDirectClick,
	size = 'medium',
	type = 'plain',
	justifyContent,
	iconOnly = false,
	noIconFill = false,
	customPadding,
	fullWidth = false,
	disabled = false
}) => {
	const { isMobile } = usePlayerContext()
	let $icon = null

	if (icon) {
		$icon = (
			<Box width={15} height={15} mr={10} css={[iconOnly ? Style.iconOnly : Style.icon]}>
				<Icon
					name={icon}
					width="100%"
					height="100%"
					fill={
						noIconFill
							? null
							: type === 'plain' || type === 'border-selected'
								? Theme.colors.white()
								: Theme.colors.text_main
					}
				/>
			</Box>
		)

		if (iconOnly) {
			label = null
		}
	}
	let $cta = (
		<Flex
			as="a"
			onClick={() => {
				if (onClick && !disabled) {
					onClick()
				}
			}}
			css={[
				style,
				Style.cta,
				icon ? Style.hasIcon : null,
				type === 'border'
					? css`
							border: 1px solid ${Theme.colors.cta_second};
							color: ${Theme.colors.text_main};
						`
					: null,
				type === 'border-selected'
					? css`
							border: 1px solid ${Theme.colors.cta_second};
							color: ${Theme.colors.white()};
							background-color: ${Theme.colors.cta_second};
						`
					: null,
				disabled
					? css`
							opacity: 0.5;
							pointer-events: none;
						`
					: null,
				iconOnly
					? css`
							padding: 0 !important;
							width: 100% !important;
						`
					: null,
				Theme.squaredEdges
			]}
			className={size + ' ' + type}
			backgroundColor={type === 'plain' ? Theme.colors.cta_main : 'none'}
			flexWrap="wrap"
			alignContent="center"
			justifyContent="center"
			p={customPadding}
			width={isMobile || fullWidth ? '100%' : 'auto'}
		>
			{$icon}
			<Box>{label}</Box>
		</Flex>
	)

	if (to) {
		$cta = (
			<Link to={to} target={target} query={query} params={params}>
				{$cta}
			</Link>
		)
	}

	return (
		<Flex
			className={cx(className, classes.root)}
			mt={mt}
			mb={mb}
			onClick={onDirectClick}
			width={iconOnly ? 45 : 1}
			height={iconOnly ? 45 : null}
			flexWrap={'wrap'}
			justifyContent={justifyContent}
		>
			{$cta}
		</Flex>
	)
}

export const ctaClasses = classes

export default connect(mapStateToProps, mapDispatchToProps)(Cta)
