import React from 'react'
import {
	RewardsListContainer,
	RewardsListDescription,
	RewardsListTitle,
	PositionIcon as WinningInstantPositionIcon,
	RightAnswer,
	WinnerDescriptionContainer
} from './style'
import Theme from '@styles/theme'

interface RewardsListProps {
	playerData: any
	rewardsList: string
}

interface WinnersDescriptionProps {
	playerData: any
	position: number
}

export const PositionIcon = WinningInstantPositionIcon

export const RewardsList: React.FC<RewardsListProps> = ({ playerData, rewardsList }) => {
	return (
		<>
			<RewardsListTitle>{playerData?.ui?.winning_instant?.winner_prices}</RewardsListTitle>
			<RewardsListContainer backgroundColor={Theme.colors.background}>
				{rewardsList.split(/\n/).map((reward, i) => (
					<RewardsListDescription key={`rewards-list-modal-item-${i}`}>{reward}</RewardsListDescription>
				))}
			</RewardsListContainer>
		</>
	)
}

export const WinnersDescription: React.FC<WinnersDescriptionProps> = ({ position, playerData }) => {
	const descriptionTextArray = playerData?.ui?.winning_instant?.winner_description.split('%{position}')
	return (
		<WinnerDescriptionContainer>
			<div>
				{descriptionTextArray[0]}
				<b>{position}</b>
			</div>
			<div>{descriptionTextArray[1]}</div>
		</WinnerDescriptionContainer>
	)
}

export const WinningInstantRightAnswer: React.FC<{ rightAnswerContent: string }> = ({ rightAnswerContent }) => (
	<RightAnswer>{rightAnswerContent}</RightAnswer>
)
