import { css } from '@emotion/react'
import Theme from '@styles/theme'

export default {
	el: css`
		/* opacity: 0.6; */
		transform: translate3d(0, 0, 0);
		position: relative;
	`,

	image: css`
		overflow: hidden;
		transform: translate3d(0, 0, 0);
		border-radius: 20px;
		> div {
			border-radius: 20px;
			overflow: hidden;
		}
	`,

	title: css`
		font-family: ${Theme.fontFamilies.Main};
		font-weight: ${Theme.fontWeights.bold};
		line-height: 140%;
		white-space: pre-wrap;
		max-width: 800px;
		h2 {
			text-align: center;
			width: 100%;
		}
	`,

	name: css`
		font-family: ${Theme.fontFamilies.Main};
		font-weight: ${Theme.fontWeights.bold};
	`,

	bio: css`
		font-family: ${Theme.fontFamilies.Second};
		font-weight: ${Theme.fontWeights.regular};
		line-height: 140%;
		white-space: pre-wrap;
		max-width: 800px;
		> p {
			margin-bottom: 30px;
			&:last-child {
				margin-bottom: 0;
			}
		}
	`,

	network: css`
		&:active {
			opacity: 0.5;
		}
		transition: opacity 600ms ${Theme.eases.outExpo} 0ms;
		cursor: pointer;
		@media ${Theme.media(0)} {
			&:hover {
				opacity: 0.5;
			}
		}
	`
}
