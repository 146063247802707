import styled from '@emotion/styled'
import { css } from '@emotion/react'
import Theme from '@styles/theme'
import { Flex, Box } from 'rebass'

interface DisplayModeNotifierProps {
	isMobile?: boolean
	isTablet?: boolean
	isHorizontal?: boolean
	storeIsOffline?: boolean
}

const handleWarningDisplay = ({ isMobile, isTablet, isHorizontal, storeIsOffline }: DisplayModeNotifierProps) => {
	if ((isMobile && !isHorizontal) || (isTablet && isHorizontal) || storeIsOffline) {
		return css`
			display: flex;
			justify-content: center;
			align-items: center;
			text-align: center;
		`
	}
}

export const RightAnswer = styled(Flex)`
	border-radius: 8px;
	width: 100%;
	padding: 10px;
	font-size: 14px;
	line-height: 18px;
	justify-content: center;
	align-items: center;
	color: ${Theme.colors.white()};
	background-color: ${Theme.colors.green()};
	font-family: ${Theme.fontFamilies.Main};
	font-weight: ${Theme.fontWeights.bold};
	text-align: center;
	margin-top: 25px;
`

export const RewardsListTitle = styled(Box)`
	font-size: 14px;
	line-height: 18px;
	text-align: center;
	font-family: ${Theme.fontFamilies.Main};
	font-weight: ${Theme.fontWeights.bold};
	margin-bottom: 15px;
	@media ${Theme.media(0)} {
		font-size: 16px;
	}
`

export const RewardsListDescription = styled(Box)`
	font-size: 13px;
	line-height: 22px;
	font-family: ${Theme.fontFamilies.Second};
	font-weight: ${Theme.fontWeights.regular};
	color: ${() => Theme.colors.text_main};
`
export const RewardsListContainer = styled(Box)`
	padding: 15px 20px;
	border-radius: 15px;
`

export const PositionIcon = styled(Flex)`
	width: 100px;
	height: 100px;
	justify-content: center;
	align-items: center;
	background-color: ${Theme.colors.yellow};
	color: ${Theme.colors.white()};
	border-radius: 70px;
	border: dashed 2px ${Theme.colors.brown};
	box-shadow: 0px 0px 0px 10px ${Theme.colors.yellow};
	font-weight: ${Theme.fontWeights.bold};
	font-size: 20px;
	margin-bottom: 25px;
	font-family: ${Theme.fontFamilies.Main};
	font-size: 60px;
`
export const DisplayModeNotifier = styled.div<DisplayModeNotifierProps>`
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: ${Theme.zIndices.max};
	background-color: ${Theme.colors.black(0.9)};
	backdrop-filter: blur(10px);
	color: ${Theme.colors.white()};
	font-family: ${Theme.fontFamilies.Main};
	font-weight: ${Theme.fontWeights.bold};
	line-height: 140%;
	font-size: 18em;
	display: none;
	justify-content: center;
	align-items: center;
	${(props) => handleWarningDisplay(props)}
`

export const WinnerDescriptionContainer = styled.div`
	margin-bottom: 20px;
`
