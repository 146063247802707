import { useNotificationsContext } from '@helpers/contexts/notifications'
import { usePlayerContext } from '@helpers/contexts/player'
import { useRef } from 'react'
import { drawerClasses, DrawerContent, DrawerWrapper, Overlay } from './style'

interface Props {
	isOpen?: boolean
	children?: JSX.Element
}

const Drawer: React.FC<Props> = ({ isOpen, children }) => {
	//
	const { isPlayed } = usePlayerContext()
	const { closeDrawer } = useNotificationsContext()
	const drawerRef = useRef(null)
	const isOpened = isOpen && isPlayed

	return (
		<DrawerWrapper ref={drawerRef} isOpened={isOpened} className={drawerClasses.root}>
			<Overlay isOpened={isOpened} onClick={closeDrawer} />
			<DrawerContent isOpened={isOpened} className={drawerClasses.content}>
				{children}
			</DrawerContent>
		</DrawerWrapper>
	)
}

export default Drawer
