import mitt from 'mitt'

class Raf {
	constructor() {
		this.handlers = []
		this.isEnabled = false
		this.raf = null
		this.time = {
			time: 0,
			delta: 0,
			last: 0,
			fps: 0,
			now: Date.now()
		}

		this._bind()
		this.emitter = mitt()
	}

	_bind() {
		this._tickHandler = this._tickHandler.bind(this)
	}

	_smartDisabler() {
		if (!this.handlers.length) {
			this._disable()
		}
	}

	_enable() {
		if (!this.isEnabled) {
			this._observe()
			this.isEnabled = true
		}
	}

	_disable() {
		if (this.isEnabled) {
			this._unobserve()
			this.isEnabled = false
		}
	}

	_observe() {
		this._tickHandler()
	}

	_unobserve() {
		window.cancelAnimationFrame(this.raf)
	}

	_tickHandler(time) {
		this.raf = window.requestAnimationFrame(this._tickHandler)
		this.time.time = time
		this.time.delta = time - this.time.last
		this.time.last = time
		this.time.fps = 1 / (this.time.delta / 1000)
		this.time.now = Date.now()
		this.emitter.emit('tick', this.time)
	}

	subscribe(handler) {
		this._enable()
		this.handlers.push(handler)
		this.emitter.on('tick', handler)
	}

	unsubscribe(handler) {
		this.emitter.off('tick', handler)
		this.handlers.splice(this.handlers.indexOf(handler), 1)
		this._smartDisabler()
	}
}

export default new Raf()
