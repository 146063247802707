import React, { useEffect, useState } from 'react'
import { RGPDOptOutType } from '..'
import PhoneField from '@components/Micro/PhoneField'
import Cta from '@components/Micro/Cta'
import CheckBox from '@components/Micro/CheckBox'
import { classes, RGPDOptOutFormContainer } from './style'
import Field from '@components/Micro/Field'
import Switch from '@components/Micro/Switch'
import Text from '@components/Micro/Text'
import Theme from '@styles/theme'
import { useViewer } from '@helpers/contexts/viewerContext'
import { useNotificationsContext } from '@helpers/contexts/notifications'
import { StoreConfig, ViewerOptoutType } from '@models/stores'
import { UiConfirm } from '../../library'
import { ModalsType } from '../../constants'
import Utils from '@utils/index'
import PhoneNumberService from '@helpers/PhoneNumberService'
import { usePlayerContext } from '@helpers/contexts/player'
import { State } from '@stores/index'
import { connect } from 'react-redux'
import { mapDispatchToProps } from '@helpers/wrappers/withReduxStore'
import { OptoutSettings } from 'types/LegalSettings'

interface Phone {
	number: string
	countryCode: string
	error?: boolean
}

interface Email {
	value: string
	error?: boolean
}

interface CookiesConsents {
	functionnal: boolean
	analytic: boolean
}

interface RGPDOptOutFormProps {
	type: RGPDOptOutType
	ui: any
	storeConfig: StoreConfig
	dispatch: (action) => void
}

interface Consent {
	value: boolean
	error: boolean
}

const mapStateToProps = (state: State, props: RGPDOptOutFormProps) => {
	return { ...state, ...props }
}

const RGPDOptOutForm: React.FC<RGPDOptOutFormProps> = ({ type, ui, storeConfig, dispatch }) => {
	const { optoutViewer, currentViewer, manageCookie } = useViewer()
	const { isVertical, playerData } = usePlayerContext()
	const { openModal } = useNotificationsContext()
	const [consent, setConsent] = useState<Consent>({ value: false, error: false })
	const [phone, setPhone] = useState<Phone>({
		countryCode: currentViewer?.phoneCountryCode || '',
		number: currentViewer?.phoneNumber || ''
	})
	const [email, setEmail] = useState<Email>({ value: currentViewer?.email || '' })
	const [cookiesConsent, setCookiesConsent] = useState<CookiesConsents>({
		functionnal: true,
		analytic: false
	})

	const openSuccessModal = () => openModal(ModalsType.SUCCESS, { ui: getUiConfirm() }, true)

	const submitCookies = () => {
		Object.entries(cookiesConsent).forEach(([cookieType, active]) => {
			manageCookie(cookieType, active)
			// Set analytic cookie in store too to prevent from fetching cookie directly
			if (cookieType === 'analytic') {
				dispatch({
					type: 'SET_CONFIG',
					config: { ...storeConfig, consent: { ...storeConfig.consent, analytics: active } }
				})
			}
		})
		openSuccessModal()
	}

	const validateAndSubmitEmailOrPhone = (viewerOptoutType: ViewerOptoutType) => {
		// Validation errors
		let hasError = false
		if (viewerOptoutType === 'phone' && !PhoneNumberService.check(phone.number, phone.countryCode)) {
			hasError = true
			setPhone({ ...phone, error: true })
		}

		if (viewerOptoutType === 'email' && !Utils.is.isEmail(email.value)) {
			hasError = true
			setEmail({ ...email, error: true })
		}

		if (!consent.value) {
			hasError = true
			setConsent({ ...consent, error: true })
		}

		if (!hasError) {
			if (viewerOptoutType) {
				const countryCodeOptout = viewerOptoutType === 'phone' ? phone.countryCode : null
				optoutViewer(
					{ value: email.value || phone.number, countryCode: countryCodeOptout },
					viewerOptoutType
				).then((_) => openSuccessModal())
			}
		}
	}

	const handleSubmit = () => {
		if (type === 'cookies') {
			submitCookies()
		} else {
			validateAndSubmitEmailOrPhone(type)
		}
	}

	const getUiConfirm = (): UiConfirm => ({
		icon: 'check_round',
		title: ui.confirm_label,
		description: ui.confirm_description
	})

	useEffect(() => {
		const analyticCookie = storeConfig.consent.analytics
		setCookiesConsent({ ...cookiesConsent, analytic: analyticCookie })
	}, [])

	const getOptoutSettings = (): string => {
		const optout: OptoutSettings = playerData?.event?.event?.legalSettings?.optout
		switch (type) {
			case 'phone':
				return optout?.smsOptout
			case 'email':
				return optout?.emailOptout
		}
	}

	return (
		<RGPDOptOutFormContainer isVertical={isVertical}>
			{['email', 'phone'].includes(type) && (
				<>
					{type === 'phone' && (
						<PhoneField
							className={classes.input}
							label={ui.input_label}
							hasError={phone.error}
							onChange={(val, cc) => {
								setPhone({ number: val, countryCode: cc, error: false })
							}}
							defaultNumber={currentViewer?.phoneNumber}
							defaultCountryCode={currentViewer?.phoneCountryCode}
						/>
					)}
					{type === 'email' && (
						<Field
							className={classes.input}
							label={ui.input_label}
							name="email"
							type="text"
							value={email.value}
							hasError={email.error}
							onChange={(e) => {
								setEmail({ value: e.currentTarget.value, error: false })
							}}
						/>
					)}
					<CheckBox
						hasError={consent.error}
						value={consent.value}
						onClick={() => setConsent({ value: !consent.value, error: false })}
					>
						{getOptoutSettings() || ui.checkbox_label}
					</CheckBox>
				</>
			)}
			{type === 'cookies' && (
				<>
					<Switch
						disabled
						label={ui.functionnal.label}
						isActive={cookiesConsent.functionnal}
						onClick={() => {
							setCookiesConsent({ ...cookiesConsent, functionnal: !cookiesConsent.functionnal })
						}}
					/>
					<Text size="12px" weight="400" height="16px" color={Theme.colors.grey1} family="Second">
						{ui.functionnal.description}
					</Text>
					<Switch
						label={ui.analytic.label}
						isActive={cookiesConsent.analytic}
						onClick={() => {
							setCookiesConsent({ ...cookiesConsent, analytic: !cookiesConsent.analytic })
						}}
					/>
					<Text size="12px" weight="400" height="16px" color={Theme.colors.grey1} family="Second">
						{ui.analytic.description}
					</Text>
				</>
			)}
			<Cta label={ui.validation_button} onClick={handleSubmit} fullWidth={isVertical} />
		</RGPDOptOutFormContainer>
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(RGPDOptOutForm)
