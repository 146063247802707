import { css } from '@emotion/react'
import Theme from '@styles/theme'
import styled from '@emotion/styled'

export const Prices = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: ${Theme.spacing(1)} ${Theme.spacing(1)};
	margin-top: ${Theme.spacing(1)};
`

const PriceStyle = styled.div`
	font-size: 12px;
	line-height: 14px;
	font-family: ${() => Theme.fontFamilies.Second};
	font-weight: ${() => Theme.fontWeights.bold};
	color: ${() => Theme.colors.text_second};
	border: 1px solid transparent;
	border-radius: 20px;
`
export const Price = styled(PriceStyle)<{ isCrossedOut: boolean }>`
	color: ${() => Theme.colors.text_second};
	${({ isCrossedOut }) =>
		isCrossedOut &&
		css`
			padding: 2px 5px;
			color: ${Theme.colors.text_main};
			border: 1px solid ${Theme.colors.text_main};
		`};
`
export const CrossOutPrice = styled(PriceStyle)`
	text-decoration: line-through;
`
