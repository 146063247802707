import React from 'react'
import { AuthorName, BaseMessageWrapper, MessageLine, MessageText } from './style'
import Theme from '@styles/theme'
import Utils from '@utils/index'
import { Message } from 'types/Message'
import { Brand } from 'types/Branding'
import { isEmptyOrSpaces } from '@helpers/EmptyStringChecker'

interface Props {
	message?: Message
	brand: Brand
	name: string
	isBlock?: boolean
	isLandscapeMobile?: boolean
	className?: string
}

const BaseMessage: React.FC<Props> = ({ message, brand, name, isBlock, isLandscapeMobile, className }) => {
	const isBrandMessage = message.is_brand

	return (
		<BaseMessageWrapper className={className}>
			<MessageLine isBlock={isBlock}>
				{!isEmptyOrSpaces(name) && (
					<AuthorName
						isAdmin={message.is_brand}
						isBlock={isBlock}
						backgroundColor={isBrandMessage ? brand.color : null}
						color={
							isBlock
								? isBrandMessage
									? Theme.colors.white()
									: Theme.colors.text_main
								: Theme.colors.white()
						}
						isLandscapeMobile={isLandscapeMobile}
					>
						{Utils.capitalize(name)}
						<span>&nbsp;:&nbsp;</span>
					</AuthorName>
				)}

				<MessageText
					color={isBlock ? Theme.colors.text_main : Theme.colors.white()}
					isBlock={isBlock}
					isLandscapeMobile={isLandscapeMobile}
				>
					{message.value}
				</MessageText>
			</MessageLine>
		</BaseMessageWrapper>
	)
}

export default BaseMessage
