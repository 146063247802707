import styled from '@emotion/styled'
import Theme from '@styles/theme'

const PREFIX = 'live-actions'

export const classes = {
	root: PREFIX
}

export const LiveActionsContainer = styled.div<{ column: boolean }>`
	display: flex;
	height: fit-content;
	grid-gap: ${Theme.spacing(2)};
	gap: ${Theme.spacing(2)};
	align-items: center;
	flex-direction: ${({ column }) => (column ? 'column' : 'row')};
`
