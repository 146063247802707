import Theme from '@styles/theme'
import styled from '@emotion/styled'

const PREFIX = 'product-infos'

export const classes = {
	root: PREFIX,
	title: `${PREFIX}-title`,
	description: `${PREFIX}-description`,
	cta: `${PREFIX}-cta`
}

export const ProductInfo = styled.div`
	width: 100%;
`

export const Title = styled.div`
	font-size: 14em;
	font-family: ${Theme.fontFamilies.Main};
	font-weight: ${Theme.fontWeights.bold};
	line-height: 140%;
	color: ${() => Theme.colors.text_main};
`

export const Description = styled.div`
	font-size: 13em;
	font-family: ${Theme.fontFamilies.Second};
	font-weight: ${Theme.fontWeights.regular};
	line-height: 140%;
	color: ${() => Theme.colors.text_second};
	white-space: pre-wrap;
`

export const Cta = styled.div`
	font-size: 13em;
	font-family: ${Theme.fontFamilies.Second};
	font-weight: ${Theme.fontWeights.bold};
	text-align: center;
	white-space: nowrap;
	cursor: pointer;
	padding: ${Theme.spacing(2)};
	border-radius: 8px;
	margin-top: ${Theme.spacing(4)};
	color: ${() => Theme.colors.text_main};
	border: 1px solid ${() => Theme.colors.cta_second};
	width: fit-content;
	${() => Theme.squaredEdges}
	${Theme.fadeAndScaleOnClick()}
`
