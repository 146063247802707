import styled from '@emotion/styled'
import { BaseFlex } from '@styles/common'
import Theme from '@styles/theme'

export const SurveyWrapper = styled(BaseFlex)`
	justify-content: center;
	flex-direction: column;
	align-items: center;
	gap: ${Theme.spacing(6)};
	padding: ${Theme.spacing(6)};
`
export const SurveyH1 = styled.div`
	font-size: 20px;
	line-height: 24px;
	text-align: center;
	font-weight: bold;
	color: ${() => Theme.colors.text_main};
`

export const LeaveXPText = styled.div`
	font-size: 12px;
	line-height: 14px;
	color: ${Theme.colors.grey1};
	text-decoration: underline;
	cursor: pointer;
`
