import Icon from '@components/Micro/Icon'
import Theme from '@styles/theme'
import React from 'react'
import { Box } from 'rebass'
import Style, { TimerComponent } from './style'

interface Props {
	timer: JSX.Element | string
	customCss?: Record<string, any>
	className?: string
}

const Timer: React.FC<Props> = ({ timer, customCss, className }) => {
	return (
		<TimerComponent customCss={customCss} className={className}>
			<Icon name="clock" height={[9, 10.5]} width={[9, 10.5]} fill={Theme.colors.white()} />
			<Box css={Style.timerText} ml={2.5}>
				{timer}
			</Box>
		</TimerComponent>
	)
}

export default Timer
