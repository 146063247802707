import { Product } from 'types/Product'

export interface PanelBuild {
	panelType: PanelsType
	Component: JSX.Element
	backText?: string
	productId?: string
	hideCloseButton?: boolean
	products?: Product[]
}

export enum PanelsType {
	WINNING_INSTANT_FORM = 'winning_instant_form',
	PRODUCT = 'product',
	CATALOG = 'catalog',
	LANDSCAPE_HORIZONTAL_CHAT = 'landscape_horizontal_chat',
	POLL = 'poll'
}
