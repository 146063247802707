import React, { useState, useRef, useEffect } from 'react'
import { mapDispatchToProps } from '@helpers/wrappers/withReduxStore'
import { State } from '@stores/index'
import { connect } from 'react-redux'
import { Flex, Box } from 'rebass'
import Style from './style'
import Theme from '@styles/theme'
import Cta from '@components/Micro/Cta'
import Icon from '@components/Micro/Icon'
import Field from '@components/Micro/Field'
import PhoneField from '@components/Micro/PhoneField'
import { css } from '@emotion/react'
import Popin from '@components/Utils/Popin'
import TextWithUrlAndPhone from '@components/Micro/TextWithUrlAndPhone'
import PhoneNumberService from '@helpers/PhoneNumberService'
import { useViewer } from '@helpers/contexts/viewerContext'
import Bus from '@helpers/bus'
import Constants from '@constants'
import TermsOfServicesComponent from '@components/Templates/TermsOfServices'
import { usePlayerContext } from '@helpers/contexts/player'
import {
	isValidFirstname,
	VIEWER_FIRSTNAME_MAX_LENGTH,
	VIEWER_FIRSTNAME_MIN_LENGTH
} from 'src/validation/ViewerValidation'

interface Props {
	onNotify?: any
	dispatch?: (action) => void
	storePopin?: any
	storeDatas?: any
	storeConfig?: any
}

const mapStateToProps = (state: State, props: Props) => {
	return { ...state, ...props }
}

const Popins: React.FC<Props> = ({ dispatch, storePopin, storeDatas, storeConfig }) => {
	const { currentViewer, registerViewer, unregisterViewer } = useViewer()
	const { isMobile, playerData } = usePlayerContext()

	const [firstname, setFirstname] = useState('')
	const [countryCode, setCountryCode] = useState('')
	const [phone, setPhone] = useState('')
	const [hasOptionalOptin, setHasOptionalOptin] = useState(false)

	const [isSubmitting, setIsSubmitting] = useState(false)
	const [isSuccess, setIsSuccess] = useState(false)
	const [isAlready, setIsAlready] = useState(false)
	const [notExists, setNotExists] = useState(false)
	const [firstnameIsNotValid, setFirstnameIsNotValid] = useState(false)
	const [nameErrorMessage, setNameErrorMessage] = useState<string>(null)
	const [phoneIsNotValid, setPhoneIsNotValid] = useState(false)
	const [optinCommercialIsNotValid, setOptinCommercialIsNotValid] = useState(false)

	const phoneFieldRef = useRef(null)
	const tosRef = useRef(null)

	useEffect(() => {
		setFirstname(currentViewer?.firstName || '')
		setPhone(currentViewer?.phoneNumber || '')
		setCountryCode(currentViewer?.phoneCountryCode || '')
		setHasOptionalOptin(!!currentViewer?.phoneCommercialOptIn)
		const alreadyRegistered = currentViewer?.registrations.find((r) => r.eventId === storeDatas.event.event.uuid)
		setIsAlready(alreadyRegistered !== undefined)
	}, [currentViewer])

	let $title = null
	let $text = null
	let $firstname = null
	let $phone = null
	let $submit = null
	let $icon = null
	let $dontWorryTitle = null
	let $commercialOptout = null

	if (storeDatas && storePopin) {
		if (storePopin.mode === 'live') {
			if (isSuccess) {
				$title = storeDatas.ui.alert_sms_success_title
				$text = storeDatas.ui.alert_sms_success_text
			} else if (isAlready) {
				$title = storeDatas.ui.alert_sms_already_title
				$text = storeDatas.ui.alert_sms_already_text
			} else {
				$icon = (
					<Box
						width={50}
						height={50}
						mb={30}
						p={'15px'}
						backgroundColor={isSuccess || isAlready ? Theme.colors.success_green : Theme.colors.cta_main}
						css={Style.icon}
					>
						<Icon name="chat_plain" width="100%" height="100%" fill={Theme.colors.white()} />
					</Box>
				)
				$title = storeDatas.ui.alert_sms_form_title
				$text = storeDatas.ui.alert_sms_form_text
				$dontWorryTitle =
					storeDatas.event.event.legalSettings?.smsNotifications?.description ||
					storeDatas.ui.alert_sms_form_optin_intro_text
			}
		}

		if (storePopin.mode === 'unlive') {
			if (isSuccess) {
				$title = storeDatas.ui.alert_sms_unsubscribe_success_title
				$text = storeDatas.ui.alert_sms_unsubscribe_success_text
			} else {
				$icon = (
					<Box
						width={50}
						height={50}
						mb={30}
						p={'15px'}
						backgroundColor={isSuccess || isAlready ? Theme.colors.success_green : Theme.colors.cta_main}
						css={Style.icon}
					>
						<Icon name="chat_plain" width="100%" height="100%" fill={Theme.colors.white()} />
					</Box>
				)
				$title = storeDatas.ui.alert_sms_unsubscribe_form_title
				$text = storeDatas.ui.alert_sms_unsubscribe_form_text

				$commercialOptout = storeDatas.ui.alert_sms_unsubscribe_commercial_usage_opt_out
			}
		}

		if (isSuccess || isAlready) {
			$icon = (
				<Box
					width={50}
					height={50}
					mb={30}
					p={'16px'}
					backgroundColor={
						notExists
							? Theme.colors.red()
							: isSuccess || isAlready
								? Theme.colors.success_green
								: Theme.colors.cta_main
					}
					css={Style.icon}
				>
					<Icon name={notExists ? 'close' : 'check'} width="100%" height="100%" fill={Theme.colors.white()} />
				</Box>
			)
		} else {
			$submit = storeDatas.ui.alert_sms_form_cta
		}

		$firstname = storeDatas.ui.alert_sms_form_firstname_label
		$phone = storeDatas.ui.alert_sms_form_phone_label
	}

	const optinOptionalClickHandler = () => {
		setHasOptionalOptin((current) => {
			return !current
		})
	}

	const closeClickHandler = () => {
		setFirstnameIsNotValid(false)
		setPhoneIsNotValid(false)
		setOptinCommercialIsNotValid(false)
		phoneFieldRef.current.reset()
		dispatch({ type: 'REMOVE_POPIN' })
		setIsAlready(false)
		setNotExists(false)
		setIsSuccess(false)
		setPhone('')
		setFirstname('')
		setHasOptionalOptin(false)
	}

	const notify = async () => {
		setIsSubmitting(true)

		const detail = {
			country_code: countryCode,
			readable: phone,
			usable: PhoneNumberService.format(phone, countryCode)
		}

		registerViewer(
			firstname,
			detail.usable,
			detail.country_code,
			hasOptionalOptin,
			storeConfig.path,
			storeDatas.event.event.uuid
		)
			.then((result) => {
				switch (result._tag) {
					case 'ViewerAlreadyRegistered':
						setIsAlready(true)
					case 'InvalidPhoneNumberError':
						setPhoneIsNotValid(true)
					case 'NoNotificationConfiguration':
					case 'ViewerSuccesfullyRegistered':
						const consent = {
							...storeConfig.consent,
							tos: true
						}
						Bus.send(Constants.bus.player.legal_consented, consent)
						dispatch({ type: 'SET_CONSENT', ...consent })
						setIsSuccess(true)
						Bus.send(Constants.bus.player.event_subscribed_by_sms, {
							firstname,
							phoneNumber: detail.usable,
							phoneCountryCode: detail.country_code,
							hasOptionalOptin
						})
				}
				setIsSubmitting(false)
			})
			.catch((error) => {
				setIsSuccess(false)
				setIsSubmitting(false)
				console.log(error)
			})
	}

	const unotify = async () => {
		setIsSubmitting(true)

		unregisterViewer(storeDatas.event.event.uuid, phone, countryCode, hasOptionalOptin)
			.then((result) => {
				switch (result._tag) {
					case 'ViewerUnregisteredError':
						setPhoneIsNotValid(true)
					case 'ViewerSuccesfullyUnregistered':
						setIsSuccess(true)
						Bus.send(Constants.bus.player.event_unsubscribed_by_sms, {
							firstname,
							phoneNumber: phone,
							phoneCountryCode: countryCode,
							hasOptionalOptin
						})
				}
				setIsSubmitting(false)
			})
			.catch((error) => {
				setIsSubmitting(false)
				setIsSuccess(false)
				console.log(error)
			})
	}

	const submit = () => {
		let hasError = false

		if (storePopin.mode !== 'unlive') {
			if (!isValidFirstname(firstname)) {
				setFirstnameIsNotValid(true)
				setNameErrorMessage(playerData.ui.errors.invalid_firstname)
				hasError = true
			} else {
				setFirstnameIsNotValid(false)
				setNameErrorMessage(null)
			}
		}

		if (!PhoneNumberService.check(phone, countryCode)) {
			setPhoneIsNotValid(true)
			hasError = true
		} else {
			setPhoneIsNotValid(false)
		}

		const acceptedTos = tosRef?.current?.validate()
		if (!storeConfig?.consent?.tos && !acceptedTos) {
			tosRef?.current?.setError()
			hasError = true
		}

		if (!hasError) {
			if (storePopin.mode !== 'unlive') {
				notify()
			} else {
				unotify()
			}
		}
	}

	if (storeDatas) {
		if (storePopin && storePopin.mode === 'unlive') {
			return (
				<DeleteLiveAlertPopin
					storeDatas={storeDatas}
					storePopin={storePopin}
					storeConfig={storeConfig}
					dispatch={dispatch}
				/>
			)
		} else {
			return (
				<Popin closeClickHandler={closeClickHandler} isOpened={storePopin !== null} isSubmitting={isSubmitting}>
					<Flex width={1} justifyContent={'center'} textAlign="center" flexWrap="wrap">
						{$icon}
						<Box width={1} css={Style.title} fontSize={'20em'} color={Theme.colors.text_main}>
							{$title}
						</Box>
						<Box
							width={1}
							css={Style.text}
							fontSize={'13em'}
							mt={20}
							color={Theme.colors.text_second}
							className="wysiwyg"
						>
							{$text}
						</Box>
					</Flex>
					<Flex
						width={1}
						flexWrap="wrap"
						css={isSuccess || isAlready ? Style.hideForm : null}
						mt={20}
						as="form"
						action=""
					>
						{storePopin !== null && (
							<>
								{storePopin.mode !== 'unlive' && (
									<Field
										label={$firstname + '*'}
										value={firstname}
										name="firstname"
										type="text"
										hasError={firstnameIsNotValid}
										onChange={(e) => {
											setFirstname(e.currentTarget.value)
										}}
										errorMessage={nameErrorMessage}
										constraints={{
											minLength: VIEWER_FIRSTNAME_MIN_LENGTH,
											maxLength: VIEWER_FIRSTNAME_MAX_LENGTH
										}}
									/>
								)}
								<PhoneField
									label={$phone + '*'}
									hasError={notExists || phoneIsNotValid}
									onChange={(val, cc) => {
										setPhone(val)
										setCountryCode(cc)
									}}
									onEnter={() => {
										submit()
									}}
									requiredLabel={storeDatas.ui.alert_sms_form_required}
									innerRef={phoneFieldRef}
									defaultNumber={currentViewer?.phoneNumber}
									defaultCountryCode={currentViewer?.phoneCountryCode}
								/>
							</>
						)}
						<Flex
							width={1}
							css={Style.reassurance}
							fontSize={'13em'}
							color={Theme.colors.text_main}
							className="wysiwyg"
							flexWrap="wrap"
							mb={isMobile ? Theme.spacing(3) : Theme.spacing(6)}
						>
							{$dontWorryTitle && <TextWithUrlAndPhone text={$dontWorryTitle} fieldKey={'dont-worry'} />}
						</Flex>
						{!storeConfig?.consent?.tos && storePopin?.mode === 'live' && (
							<TermsOfServicesComponent innerRef={tosRef} spacing={4} />
						)}
						{storePopin &&
							storePopin.mode === 'live' &&
							storeDatas.event.event.legalSettings?.smsNotifications?.hasCommercialOptin &&
							!currentViewer?.phoneCommercialOptIn && (
								<Flex
									width={1}
									flexWrap={'wrap'}
									justifyContent="space-between"
									css={Style.optinSelectable}
									onClick={optinOptionalClickHandler}
								>
									<Box
										width={16}
										height={16}
										p={'3px'}
										css={[
											Style.optinCheck,
											hasOptionalOptin ? Style.optinCheckIsChecked : null,
											css`
												border: 1px solid
													${optinCommercialIsNotValid
														? Theme.colors.red()
														: Theme.colors.text_main};
											`
										]}
									>
										<Icon name="check" width="100%" height="100%" fill={Theme.colors.text_main} />
									</Box>
									<Box width={'calc(100% - 25px)'} mb={[50]}>
										<Box
											as="span"
											css={[Style.optinText]}
											fontSize={'12px'}
											color={Theme.colors.text_main}
										>
											{storeDatas.event.event.legalSettings.smsNotifications.commercialOptin ||
												storeDatas.ui.alert_sms_form_optin_label_com}{' '}
										</Box>
										{storeDatas.event.event.legalSettings?.smsNotifications
											?.commercialOptinLink && (
											<Box
												as="span"
												css={[Style.optinTextLink]}
												fontSize={'11em'}
												color={Theme.colors.text_main}
												onClick={(e) => {
													e.stopPropagation()
													window.open(
														storeDatas.event.event.legalSettings.smsNotifications
															?.commercialOptinLink.url,
														'_blank'
													)
												}}
											>
												{
													storeDatas.event.event.legalSettings.smsNotifications
														?.commercialOptinLink.label
												}
											</Box>
										)}
									</Box>
								</Flex>
							)}

						{storePopin &&
							storePopin.mode === 'unlive' &&
							storeDatas.event.event.legalSettings?.smsNotifications?.hasCommercialOptin && (
								<Flex
									width={1}
									flexWrap={'wrap'}
									justifyContent="space-between"
									onClick={optinOptionalClickHandler}
								>
									<Box
										width={16}
										height={16}
										p={'3px'}
										css={[
											Style.optinCheck,
											hasOptionalOptin ? Style.optinCheckIsChecked : null,
											css`
												border: 1px solid
													${optinCommercialIsNotValid
														? Theme.colors.red()
														: Theme.colors.text_main};
											`
										]}
									>
										<Icon name="check" width="100%" height="100%" fill={Theme.colors.text_main} />
									</Box>
									<Box width={'calc(100% - 25px)'} mb={[50]}>
										<Box
											as="span"
											css={[Style.optinText]}
											fontSize={'11em'}
											color={Theme.colors.text_main}
										>
											{$commercialOptout}
										</Box>
									</Box>
								</Flex>
							)}
						<Flex width={1} justifyContent={['inherit', 'center']} flexWrap="wrap">
							<Cta label={$submit} onClick={submit} justifyContent={'center'} />
						</Flex>
						{storePopin &&
							storePopin.mode === 'live' &&
							(storeDatas.popin_legal ||
								(storeDatas.event.event.legalSettings?.smsNotifications?.hasAdditionalStatement && (
									<Box width={1} mt={30} fontSize={'9em'} css={Style.legals} className={'wysiwyg'}>
										<TextWithUrlAndPhone
											text={
												storeDatas.event.event.legalSettings?.smsNotifications
													?.additionalStatement
											}
											fieldKey={'additional-statement'}
										/>{' '}
										{storeDatas.event.event.legalSettings?.smsNotifications
											?.additionalStatementLink && (
											<Box
												as="span"
												css={Style.optinTextLink}
												color={Theme.colors.text_main}
												onClick={(e) => {
													e.stopPropagation()
													window.open(
														storeDatas.event.event.legalSettings.smsNotifications
															?.additionalStatementLink.url,
														'_blank'
													)
												}}
											>
												{
													storeDatas.event.event.legalSettings.smsNotifications
														?.additionalStatementLink.label
												}
											</Box>
										)}
									</Box>
								)))}
					</Flex>
				</Popin>
			)
		}
	} else {
		return <></>
	}
}

const DeleteLiveAlertPopin: React.FC<Props> = ({ dispatch, storePopin, storeDatas }) => {
	const { currentViewer, unregisterViewer, setViewerIsUnsubscribed, viewerIsUnsubscribed } = useViewer()

	const [countryCode, setCountryCode] = useState('')
	const [phone, setPhone] = useState('')

	const [hasCommercialOptout, setHasCommercialOptout] = useState(false)

	const [isSubmitting, setIsSubmitting] = useState(false)
	const [isSuccess, setIsSuccess] = useState(false)

	const [phoneIsNotValid, setPhoneIsNotValid] = useState(false)

	const phoneFieldRef = useRef(null)

	useEffect(() => {
		setPhone(currentViewer?.phoneNumber || '')
		setCountryCode(currentViewer?.phoneCountryCode || '')
	}, [currentViewer])

	let $title =
		isSuccess || viewerIsUnsubscribed
			? storeDatas.ui.alert_sms_unsubscribe_success_title
			: storeDatas.ui.alert_sms_unsubscribe_form_title

	let $text =
		isSuccess || viewerIsUnsubscribed
			? storeDatas.ui.alert_sms_unsubscribe_success_text
			: storeDatas.ui.alert_sms_unsubscribe_form_text

	let $phone = storeDatas.ui.alert_sms_form_phone_label
	let $submit = isSuccess || viewerIsUnsubscribed ? null : storeDatas.ui.alert_sms_form_cta
	let $icon = null
	let $commercialOptout =
		isSuccess || viewerIsUnsubscribed ? null : storeDatas.ui.alert_sms_unsubscribe_commercial_usage_opt_out

	if (isSuccess || viewerIsUnsubscribed) {
		$icon = (
			<Box
				width={50}
				height={50}
				mb={30}
				p={'16px'}
				backgroundColor={
					false
						? Theme.colors.red()
						: isSuccess || viewerIsUnsubscribed
							? Theme.colors.success_green
							: Theme.colors.cta_main
				}
				css={Style.icon}
			>
				<Icon name={false ? 'close' : 'check'} width="100%" height="100%" fill={Theme.colors.white()} />
			</Box>
		)
	} else {
		$icon = (
			<Box
				width={50}
				height={50}
				mb={30}
				p={'15px'}
				backgroundColor={isSuccess || viewerIsUnsubscribed ? Theme.colors.success_green : Theme.colors.cta_main}
				css={Style.icon}
			>
				<Icon name="chat_plain" width="100%" height="100%" fill={Theme.colors.white()} />
			</Box>
		)
	}

	const optinOptionalClickHandler = () => {
		setHasCommercialOptout((current) => {
			return !current
		})
	}

	const closeClickHandler = () => {
		setPhoneIsNotValid(false)
		dispatch({ type: 'REMOVE_POPIN' })
	}

	const submit = () => {
		let hasError = false

		if (!PhoneNumberService.check(phone, countryCode)) {
			setPhoneIsNotValid(true)
			hasError = true
		} else {
			setPhoneIsNotValid(false)
		}

		if (!hasError) {
			setIsSubmitting(true)

			unregisterViewer(storeDatas.event.event.uuid, phone, countryCode, hasCommercialOptout)
				.then((result) => {
					switch (result._tag) {
						case 'ViewerUnregisteredError':
							setPhoneIsNotValid(true)
						case 'ViewerSuccesfullyUnregistered':
							setIsSuccess(true)
							setViewerIsUnsubscribed(true)
							Bus.send(Constants.bus.player.event_unsubscribed_by_sms, {
								firstname: currentViewer?.firstName,
								phoneNumber: phone,
								phoneCountryCode: countryCode,
								hasOptionalOptin: hasCommercialOptout
							})
					}
					setIsSubmitting(false)
				})
				.catch((error) => {
					setIsSubmitting(false)
					setIsSuccess(false)
					console.log(error)
				})
		}
	}

	return (
		<Popin closeClickHandler={closeClickHandler} isOpened={storePopin !== null} isSubmitting={isSubmitting}>
			<Flex width={1} justifyContent={'center'} textAlign="center" flexWrap="wrap">
				{$icon}
				<Box width={1} css={Style.title} fontSize={'20em'} color={Theme.colors.text_main}>
					{$title}
				</Box>
				<Box
					width={1}
					css={Style.text}
					fontSize={'13em'}
					mt={20}
					color={Theme.colors.text_second}
					className="wysiwyg"
				>
					{$text}
				</Box>
			</Flex>
			<Flex
				width={1}
				flexWrap="wrap"
				css={isSuccess || viewerIsUnsubscribed ? Style.hideForm : null}
				mt={20}
				as="form"
				action=""
			>
				<PhoneField
					label={$phone + '*'}
					hasError={phoneIsNotValid}
					onChange={(val, cc) => {
						setPhone(val)
						setCountryCode(cc)
					}}
					onEnter={() => {
						submit()
					}}
					requiredLabel={storeDatas.ui.alert_sms_form_required}
					innerRef={phoneFieldRef}
					defaultNumber={currentViewer?.phoneNumber}
					defaultCountryCode={currentViewer?.phoneCountryCode}
				/>
				{storeDatas.event.event.legalSettings?.smsNotifications?.hasCommercialOptin && (
					<Flex
						width={1}
						flexWrap={'wrap'}
						justifyContent="space-between"
						onClick={optinOptionalClickHandler}
					>
						<Box
							width={16}
							height={16}
							p={'3px'}
							css={[
								Style.optinCheck,
								hasCommercialOptout ? Style.optinCheckIsChecked : null,
								css`
									border: 1px solid ${Theme.colors.text_main};
								`
							]}
						>
							<Icon name="check" width="100%" height="100%" fill={Theme.colors.text_main} />
						</Box>
						<Box width={'calc(100% - 25px)'} mb={[50]}>
							<Box as="span" css={[Style.optinText]} fontSize={'11em'} color={Theme.colors.text_main}>
								{$commercialOptout}
							</Box>
						</Box>
					</Flex>
				)}
				<Flex width={1} justifyContent={['inherit', 'center']} flexWrap="wrap">
					<Cta label={$submit} onClick={submit} justifyContent={'center'} />
				</Flex>
			</Flex>
		</Popin>
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(Popins)
