import { usePlayerContext } from '@helpers/contexts/player'
import { useImperativeHandle, useState } from 'react'
import { LinkTos, TermsOfServices } from './style'
import { appendEventIdToTosLink as formatTosLink } from 'utils/tos'
import CheckBox from '@components/Micro/CheckBox'

interface Props {
	innerRef: React.MutableRefObject<any>
	spacing?: number
}

const TermsOfServicesComponent: React.FC<Props> = ({ innerRef, spacing }) => {
	// States
	const { playerData } = usePlayerContext()
	const [tosAccepted, setTosAccepted] = useState<boolean>(false)
	const [checkboxChecked, setCheckboxChecked] = useState<boolean>(true)

	const settings = playerData.event.event.legalSettings.cookies.tosConsent

	const onLinkOpenHandler = (e) => {
		e.stopPropagation()
		window.open(formatTosLink(playerData.event.event.uuid, settings.link.url), '_blank')
	}

	const onClickHandler = () => {
		setTosAccepted(!tosAccepted)
		if (!tosAccepted) {
			setCheckboxChecked(true)
		}
	}

	useImperativeHandle(
		innerRef,
		() => ({
			validate() {
				return tosAccepted
			},
			setError() {
				return setCheckboxChecked(false)
			}
		}),
		[tosAccepted, checkboxChecked]
	)

	return (
		<TermsOfServices onClick={onClickHandler} alignItems="center" spacing={spacing}>
			<CheckBox hasError={!checkboxChecked} value={tosAccepted} onClick={onClickHandler}>
				{settings.policy}
				{settings.link && (
					<>
						{' '}
						<LinkTos onClick={onLinkOpenHandler}>{settings.link.label}</LinkTos>
					</>
				)}
			</CheckBox>
		</TermsOfServices>
	)
}

export default TermsOfServicesComponent
