import { VariantCombination } from 'types/Product'

export const isOptionAvailable =
	(combinations: VariantCombination[]) => (selected: Record<string, string>) => (label: string, option: string) => {
		const merged = { ...selected, [label]: option }

		//find all the combinations related to the selection
		const combinationsWithOptions = combinations.filter((combination) => {
			if (!combination.id) return false // Exclude combination that doesn't have an id

			const allThere = Object.keys(merged).every((key) => {
				const selectedValue = merged[key]
				const combinationValue = combination.options[key]

				return combinationValue === selectedValue
			})

			return allThere
		})

		const allDisabled = combinationsWithOptions.every((c) => !c.isEnabled)

		return !allDisabled
	}
