import IconCta from '@components/Micro/IconCta'
import React from 'react'
import Theme from '@styles/theme'
import { usePlayerContext } from '@helpers/contexts/player'

interface MuteButtonProps {
	show?: boolean
}

export const muteButtonClasses = {
	root: 'mute-button'
}

const MuteButton: React.FC<MuteButtonProps> = ({ show }) => {
	const { isCinema, setIsCinema } = usePlayerContext()
	const handleClick = (e) => {
		e.stopPropagation()
		setIsCinema(!isCinema)
	}

	return (
		<>
			{show && (
				<IconCta
					className={muteButtonClasses.root}
					iconProps={{
						name: isCinema ? 'unmute_chat' : 'mute_chat',
						fill: Theme.colors.white()
					}}
					onClick={handleClick}
				/>
			)}
		</>
	)
}

export default MuteButton
