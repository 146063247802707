import { checkBoxClasses } from '@components/Micro/CheckBox'
import { ctaClasses } from '@components/Micro/Cta'
import { switchClasses } from '@components/Micro/Switch'
import Text, { textClasses } from '@components/Micro/Text'
import styled from '@emotion/styled'
import Theme from '@styles/theme'

const PREFIX = 'rgpd-out-form'

export const classes = {
	root: PREFIX,
	input: `${PREFIX}-input`
}

const responsiveSpacing = (isVertical: boolean, values: [number, number]) => {
	const i = isVertical ? 1 : 0
	return Theme.spacing(values[i])
}

export const RGPDOptOutFormContainer = styled.div<{ isVertical?: boolean }>`
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	width: 100%;
	margin-top: ${Theme.spacing(6)};

	& .${classes.input} {
		margin-bottom: ${({ isVertical }) => responsiveSpacing(isVertical, [6, 4])};
	}

	& .${checkBoxClasses.root} {
		margin-bottom: ${({ isVertical }) => responsiveSpacing(isVertical, [9, 5])};
	}

	& .${switchClasses.root} {
		margin-bottom: ${Theme.spacing(3)};
	}

	& .${textClasses.root} {
		margin-bottom: ${Theme.spacing(6)};
	}

	& .${ctaClasses.root} {
		width: ${({ isVertical }) => (isVertical ? '100%' : 'auto')};
		align-self: center;
		margin: 0 auto;
	}
`

export const FormError = styled(Text)`
	color: ${Theme.colors.red()};
`
