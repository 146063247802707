import { ChatBoxWrapper } from '../style'
import styled from '@emotion/styled'
import { chatMessageClasses } from '../../ChatMessage'

const PREFIX = 'replay-chat'

export const replayChatclasses = {
	root: PREFIX,
	messageVisible: `${PREFIX}-message-visible`
}

export const ReplayChatBoxWrapper = styled(ChatBoxWrapper)`
	& .${chatMessageClasses.root} {
		max-height: 0;
		overflow: hidden;
		&.${replayChatclasses.messageVisible} {
			max-height: auto;
		}
	}
`
