import styled from '@emotion/styled'
import { Flex } from 'rebass'
import Theme from '@styles/theme'
import { css } from '@emotion/react'

export const TimerComponent = styled(Flex)((props) => ({
	...props.customCss,
	backgroundColor: Theme.colors.black(),
	color: Theme.colors.white(),
	fontSize: '10px',
	lineHeight: '14px',
	borderRadius: '100px',
	padding: '3px 6px',
	alignItems: 'center',
	justifyContent: 'center',
	width: 'fit-content',
	'@media(min-width: 640px)': {
		fontSize: '12px'
	}
}))

export default {
	timerText: css`
		font-family: PlusJakartaSans;
		font-weight: bold;
	`
}
