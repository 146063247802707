import { css } from '@emotion/react'
import Theme from '@styles/theme'

const XTranlationAndFadeOverride = (className, translate, ease = 'inOutSmooth') => {
	return css`
		.${className}-exit-active {
			opacity: 0 !important;
			transform: translateX(${translate}) !important;
			transition: all 800ms ${Theme.eases[ease]} !important;
		}

		.${className}-enter {
			opacity: 0;
			transform: translateX(${translate});
			transition: all 800ms ${Theme.eases[ease]};
		}

		.${className}-enter-active, .${className}-exit {
			opacity: 1;
			transform: translateX(0%);
		}
	`
}

const YTranlationAndFadeOverride = (className, translate, ease = 'inOutSmooth') => {
	return css`
		.${className}-exit-active {
			opacity: 0 !important;
			transform: translateY(${translate}) !important;
			transition: all 800ms ${Theme.eases[ease]} !important;
		}

		.${className}-enter {
			opacity: 0;
			transform: translateY(${translate});
			transition: all 800ms ${Theme.eases[ease]};
		}

		.${className}-enter-active, .${className}-exit {
			opacity: 1;
			transform: translateY(0%);
		}
	`
}

export { XTranlationAndFadeOverride, YTranlationAndFadeOverride }
