import { Product } from 'types/Product'

export const sortCatalogProducts = (
	products: Product[],
	currentStepProducts: Product[],
	isReplay?: boolean
): Product[] => {
	if (isReplay) return products

	let orderedProducts: Product[] = []
	if (currentStepProducts?.length) {
		orderedProducts = [...currentStepProducts]
	}
	const filteredStoreProducts = products.filter(
		(product: Product) => !orderedProducts.find((p: Product) => p.uuid === product.uuid)
	)
	return [...orderedProducts, ...filteredStoreProducts]
}
