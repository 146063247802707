import styled from '@emotion/styled'
import { Flex, Box } from 'rebass'
import Theme from '@styles/theme'

export const PopinTitle = styled(Box)<{ color: string }>`
	font-size: 20px;
	line-height: 26px;
	text-align: center;
	font-family: ${Theme.fontFamilies.Main};
	font-weight: ${Theme.fontWeights.bold};
	margin-bottom: 8px;
	color: ${({ color }) => (color ? color : undefined)};
`

export const PopinDescription = styled(Box)`
	font-size: 13px;
	line-height: 20px;
	text-align: center;
	font-family: ${Theme.fontFamilies.Second};
	font-weight: ${Theme.fontWeights.regular};
	margin: auto;

	b {
		font-weight: ${Theme.fontWeights.bold};
	}
`

export const PopinContainer = styled(Box)`
	max-width: 400px;
	margin: 0 auto;
	width: 100%;
`

export const IconWrapper = styled(Flex)`
	align-items: center;
	justify-content: center;
	border-radius: 30px;
	width: 60px;
	height: 60px;
`

export const IconCenter = styled(Flex)`
	align-items: center;
	justify-content: center;
	margin-bottom: 20px;
`
