import PollContent from '@components/Notifiers/SharedNotifiersContent/PollContent'
import { usePlayerContext } from '@helpers/contexts/player'
import { PanelContentContainer } from '../style'

const PollPanel: React.FC = () => {
	const { displayMode } = usePlayerContext()
	return (
		<PanelContentContainer displayMode={displayMode}>
			<PollContent />
		</PanelContentContainer>
	)
}

export default PollPanel
