import Cta from '@components/Micro/Cta'
import styled from '@emotion/styled'
import Theme from '@styles/theme'

const PREFIX = 'winning-instant-drawer'

export const classes = {
	root: PREFIX,
	iconContainer: `${PREFIX}-icon-container`,
	timer: `${PREFIX}-timer`
}

export const WinningInstantDrawerContent = styled.div`
	display: flex;
	width: 100%;
	margin-top: ${Theme.spacing(8)};
	flex-direction: column;
	align-items: center;
	gap: ${Theme.spacing(3)};

	& .${classes.iconContainer} {
		position: relative;
		width: 60px;
		height: 60px;
	}
	margin-bottom: ${Theme.spacing(8)};

	& .${classes.timer} {
		position: absolute;
		bottom: -10px;
		left: 50%;
		transform: translate(-50%, 0);
	}
`

export const Button = styled(Cta)`
	margin-top: ${Theme.spacing(2)};
	max-height: 50px;
`

export const Description = styled.div`
	text-align: center;
`
