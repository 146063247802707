import { usePlayerContext } from '@helpers/contexts/player'
import Theme from '@styles/theme'
import React from 'react'
import { Box } from 'rebass'
import Text from '../Text'
import Style, { CharacterCount, ErrorMessage, InputField, Label, RequiredLabel } from './style'

const Field: React.FC<{
	label?: string
	name?: string
	type?: string
	value?: string
	onChange?: any
	onEnter?: any
	hasError?: boolean
	inputMode?: any
	isDisabled?: boolean
	isHidden?: boolean
	isRequired?: boolean
	as?: any
	rows?: any
	cols?: any
	mb?: any
	placeholder?: any
	style?: any
	requiredLabel?: string
	className?: string
	errorMessage?: string
	constraints?: {
		minLength?: number
		maxLength?: number
	}
}> = ({
	label,
	name,
	type,
	value = '',
	onChange,
	onEnter,
	hasError,
	inputMode,
	isDisabled = false,
	isHidden,
	isRequired = false,
	mb = 20,
	as = 'input',
	rows,
	cols,
	placeholder,
	style,
	requiredLabel,
	className,
	errorMessage,
	constraints
}) => {
	const { isMobile } = usePlayerContext()

	return (
		<Box className={className} width={1} mb={mb} css={[style, isHidden ? Style.isHidden : null]}>
			<Label hasError={hasError}>{label}</Label>
			<InputField>
				<Box
					as={as}
					rows={rows}
					cols={cols}
					type={type}
					name={name}
					height={isMobile ? '45px' : '50px'}
					inputMode={inputMode}
					value={value}
					onChange={onChange}
					placeholder={placeholder}
					color={hasError ? Theme.colors.red() : Theme.colors.text_main}
					onKeyUp={(e) => {
						if (as !== 'textarea') {
							if (e.keyCode === 13) {
								if (onEnter) {
									onEnter()
								}
							}
						}
					}}
					css={[
						as === 'textarea' ? Style.textArea : null,
						Style.input,
						isDisabled ? Style.isDisabled : null,
						hasError ? Style.error : null,
						Theme.squaredEdges
					]}
					className="input"
					minLength={constraints?.minLength}
					maxLength={constraints?.maxLength}
				/>

				{constraints?.maxLength && (
					<CharacterCount isMobile={isMobile}>
						<Text
							height={'14px'}
							size={'10px'}
							color={hasError ? Theme.colors.red() : Theme.colors.text_second}
						>
							{constraints?.maxLength - value.length}
						</Text>
					</CharacterCount>
				)}
			</InputField>

			{hasError && (
				<ErrorMessage>
					<Text color={Theme.colors.red()} size={'10em'} height={'14px'}>
						{errorMessage}
					</Text>
				</ErrorMessage>
			)}

			{isRequired && requiredLabel && (
				<RequiredLabel color={Theme.colors.text_second}>* {requiredLabel}</RequiredLabel>
			)}
		</Box>
	)
}

export default Field
