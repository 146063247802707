import CardItem from '@components/Micro/CardItem'
import MenuItem from '@components/Micro/FloatingMenu/MenuItem'
import { IconUnion } from '@components/Micro/Icon'
import { ModalsType } from '@components/Notifiers/Modals/constants'
import Constants from '@constants'
import Bus from '@helpers/bus'
import { useNotificationsContext } from '@helpers/contexts/notifications'
import { usePlayerContext } from '@helpers/contexts/player'
import { ActionsContainer } from './style'

interface OtherActionsContentProps {
	variant?: 'floating-menu' | 'drawer'
	onItemClick?: () => void
}

export interface UiActionProps {
	icon: IconUnion
	label: string
	description?: string
	onClick: (e?: any) => void
}

const OtherActionsContent: React.FC<OtherActionsContentProps> = ({ variant = 'drawer', onItemClick }) => {
	const { playerData, isMobile } = usePlayerContext()
	const { openModal, closeDrawer } = useNotificationsContext()

	const onShareClick = (e) => {
		e.stopPropagation()
		Bus.send(Constants.bus.player.share_clicked)
		if (navigator.share && isMobile) {
			Bus.send(Constants.bus.player.event_shared, { type: 'native' })
		} else {
			openModal(ModalsType.SOCIAL_MEDIAS, { playerData })
		}
		closeDrawer()
	}

	const onPersonalDataClick = () => {
		openModal(ModalsType.RGPD_OPT_OUT, { playerData })
		closeDrawer()
	}

	const itemProps = (type: 'share' | 'personal_data') => {
		const action = playerData.ui.actions[type]
		let props: UiActionProps = {
			icon: type === 'share' ? 'share' : 'users',
			label: action.label,
			onClick: (e?: any) => {
				type === 'share' ? onShareClick(e) : onPersonalDataClick()
				onItemClick && onItemClick()
			}
		}

		if (variant === 'drawer') {
			props.description = action.description
		}

		return props
	}

	const hasPersonalDataOptout = () => {
		return (
			!playerData?.event.event.legalSettings.optout.phoneOptoutDisabled ||
			!playerData?.event.event.legalSettings.optout.emailOptoutDisabled ||
			!playerData?.event.event.legalSettings.optout.cookiesOptoutDisabled
		)
	}

	return (
		<>
			{variant === 'drawer' && (
				<ActionsContainer>
					<>
						<CardItem {...itemProps('share')} />
						{hasPersonalDataOptout() && <CardItem {...itemProps('personal_data')} />}
					</>
				</ActionsContainer>
			)}
			{variant === 'floating-menu' && (
				<>
					<MenuItem {...itemProps('share')}> {itemProps('share').label}</MenuItem>
					{hasPersonalDataOptout() && (
						<MenuItem {...itemProps('personal_data')}>{itemProps('personal_data').label}</MenuItem>
					)}
				</>
			)}
		</>
	)
}

export default OtherActionsContent
