import Constants from '@constants'
import Bus from '@helpers/bus'
import { mapDispatchToProps } from '@helpers/wrappers/withReduxStore'
import { State } from '@stores/index'
import React, { useImperativeHandle, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { Product, ProductVariant } from 'types/Product'
import { isOptionAvailable } from './logic'
import { SelectBox, SelectOption, SelectWrap, VariantLabel, VariantWrapper } from './style'

interface Props {
	data?: Product
	onChoice?: any
	innerRef?: any
	translations: Record<string, string>
}

const mapStateToProps = (state: State, props: Props) => {
	return { ...state, ...props }
}

export interface ImperativeHandleVariantsSelect {
	setError: () => void
	reset: () => void
	scrollTo: () => void
}

const ProductVariants: React.FC<Props> = ({ data, translations, onChoice, innerRef }) => {
	const [selectedVariants, setSelectedVariants] = useState<Record<string, string>>({})

	const selectRefs = data?.variants?.order.map((_) => useRef<ImperativeHandleVariantsSelect>(null))

	const selectVariant = (label: string, option: string) => {
		if (data.variants) {
			setSelectedVariants((current) => {
				const selection = { ...current, [label]: option }
				if (variantSelectionIsComplete(selection)) {
					//select variant
					onChoice(getCurrentInfos(selection))
				}

				return selection
			})
		}
	}

	const variantSelectionIsComplete = (selection: Record<string, string>) => {
		const variants = data.variants?.order || []
		return Object.keys(selection).length === variants.length
	}

	const getCurrentInfos = (selectedVariants: Record<string, string>) => {
		let output = null
		if (data.variants) {
			for (const combination of data.variants.combinations) {
				let isCurrent = true
				for (const label in selectedVariants) {
					if (combination.options[label] !== selectedVariants[label]) {
						isCurrent = false
					}
				}
				if (isCurrent) {
					output = combination
				}
			}
		}
		return output
			? {
					id: output.id,
					uuid: data.uuid,
					price: output.price,
					crossOutPrice: output.crossOutPrice,
					options: output.options
				}
			: {
					id: data.id,
					uuid: data.uuid,
					price: data.price,
					crossOutPrice: data.crossOutPrice
				}
	}

	useImperativeHandle(
		innerRef,
		() => ({
			reset: (): void => {
				setSelectedVariants({})
			},
			buy: (): boolean => {
				const selectionComplete = variantSelectionIsComplete(selectedVariants)

				if (!selectionComplete) {
					const variants = data.variants?.order || []
					const selectedKeys = Object.keys(selectedVariants)

					const mapped = variants.map((v) => !selectedKeys.includes(v))

					// find the first missing select so we only scroll to this one
					const firstMissing = mapped.indexOf(true)

					mapped.forEach((missing, i) => {
						if (missing && selectRefs[i]?.current) {
							selectRefs[i].current.setError()
							if (i == firstMissing) selectRefs[i].current.scrollTo()
						}
					})

					return false
				} else {
					const product = selectionComplete && getCurrentInfos(selectedVariants)
					Bus.send(Constants.bus.player.product_added_to_cart, { ...product })
					return true
				}
			}
		}),
		[selectedVariants, data, selectRefs, selectVariant]
	)

	return (
		<>
			{data?.variants?.order.map((label, i) => (
				<VariantSelect
					label={label}
					selectVariantTranslation={translations.panel_product_select_variant}
					innerRef={selectRefs[i]}
					selectVariant={selectVariant}
					variants={data?.variants}
					selectedVariants={selectedVariants}
					key={`variant-label-${i}`}
				/>
			))}
		</>
	)
}

const VariantSelect: React.FC<{
	label: string
	selectVariantTranslation: string
	innerRef: React.MutableRefObject<ImperativeHandleVariantsSelect>
	selectVariant: (label: string, value: string) => void
	selectedVariants: Record<string, string>
	variants?: ProductVariant
}> = ({ label, selectVariantTranslation, innerRef, selectVariant, selectedVariants, variants }) => {
	const [error, setError] = useState(false)
	const elemRef = useRef(null)

	useImperativeHandle(
		innerRef,
		(): ImperativeHandleVariantsSelect => ({
			scrollTo() {
				elemRef.current.scrollIntoView({
					behavior: 'smooth',
					block: 'start'
				})
			},
			setError() {
				setError(true)
			},
			reset() {
				setError(false)
			}
		}),
		[selectedVariants, innerRef]
	)

	const onChange = (label: string, option: string) => {
		selectVariant(label, option)
		setError(false)
	}

	return (
		<VariantWrapper ref={elemRef}>
			<VariantLabel error={error}>{label}</VariantLabel>
			<SelectWrap error={error}>
				{selectedVariants[label] || selectVariantTranslation}
				<SelectBox onChange={(e) => onChange(label, e.target.value)}>
					{[
						<SelectOption selected hidden disabled>
							{selectVariantTranslation}
						</SelectOption>
					].concat(
						variants.options[label]?.map(
							(option, j) =>
								isOptionAvailable(variants.combinations)(selectedVariants)(label, option) && (
									<SelectOption key={`${label}-variant-option-${j}`} value={option}>
										{option}
									</SelectOption>
								)
						)
					)}
				</SelectBox>
			</SelectWrap>
		</VariantWrapper>
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductVariants)
