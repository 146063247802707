import React from 'react'
import { DrawerTopBar } from '../style'
import Grid from '@components/Utils/Grid'
import PollContent from '@components/Notifiers/SharedNotifiersContent/PollContent'
import { pollClasses } from '@components/Notifiers/SharedNotifiersContent/PollContent/style'
import { usePollsContext } from '@helpers/contexts/polls'

const PollDrawer: React.FC = () => {
	const { onPollClose } = usePollsContext()

	return (
		<>
			<DrawerTopBar onClick={() => onPollClose()} />
			<Grid className={pollClasses.root}>
				<PollContent />
			</Grid>
		</>
	)
}

export default PollDrawer
