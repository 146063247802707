import React from 'react'
import { Flex, Box } from 'rebass'
import Style from './style'
import Theme from '@styles/theme'
import { mapDispatchToProps } from '@helpers/wrappers/withReduxStore'
import { State } from '@stores/index'
import { connect } from 'react-redux'
import Utils from '@utils/index'

interface Props {
	storeDatas?: any
}

const mapStateToProps = (state: State, props: Props) => {
	return { ...state, ...props }
}

const Title: React.FC<Props> = ({ storeDatas }) => {
	//

	let $title = null
	let $date = null

	if (storeDatas) {
		const options = Intl.DateTimeFormat().resolvedOptions()
		// The date doesn't fit the timezone but the date specified from the back-office (without timezone)
		$date = Utils.setReadableDate(new Date(storeDatas.event.event.time), options.locale, options.timeZone)
		$title = storeDatas.event.event.title.replace(/(\\r\\n|\\n|\\r)/g, '\n')
	}

	return (
		<Flex width={1} mt={[40, 60]} justifyContent="center" flexWrap="wrap" className="event-component">
			<Box
				justifyContent={'center'}
				css={[Style.date, Theme.squaredEdges]}
				fontSize={'12em'}
				mb={[10, 20]}
				color={Theme.colors.white()}
				backgroundColor={Theme.colors.cta_main}
				p={'5px 10px'}
				className="date-component"
			>
				{$date}
			</Box>
			<Flex width={1} justifyContent="center" className="title-component">
				<Box
					width={[0.9, 1]}
					maxWidth={['auto', '800px']}
					justifyContent={'center'}
					fontSize={['30em', '50em']}
					css={Style.title}
					color={Theme.colors.text_main}
					textAlign={'center'}
				>
					{$title}
				</Box>
			</Flex>
		</Flex>
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(Title)
