import React, { useEffect } from 'react'
import Panels from '@components/Notifiers/Panels'
import BottomBar from '@components/StatusManager/LiveAndReplay/components/BottomBar'
import Header from '@components/StatusManager/LiveAndReplay/components/Header'
import LiveProducts from '@components/StatusManager/LiveAndReplay/components/LiveProducts'
import Timeline from '@components/StatusManager/LiveAndReplay/components/Timeline'
import { css } from '@emotion/react'
import { mapDispatchToProps } from '@helpers/wrappers/withReduxStore'
import { State } from '@stores/index'
import Theme from '@styles/theme'
import dynamic from 'next/dynamic'
import { connect } from 'react-redux'
import { Box, Flex } from 'rebass'
import Items from '../../components/Items'
import WinningInstantButton from '../../components/WinningInstantButton'
import Style, { DisplayModeNotifier } from '../../style'
import { GenericPlayerTemplateProps } from '../constant'
import { LiveContentWrapper, livePlayerclasses } from '../style'
import PausePlayButton from '../../components/PausePlayButton'
import Drawer from '@components/Notifiers/Drawers'
import { useNotificationsContext } from '@helpers/contexts/notifications'
import Chat from '../../components/Chat'
import { LeftPanel, LeftPanelTitle, LivePlayerContainer } from './styles'
import { classGenerator as cx } from '@styles/sharedLogics'
import { usePlayerContext } from '@helpers/contexts/player'
import Unmute from '../../components/Unmute'
import Shutters from '@components/Notifiers/Shutters'
import { desktopPortraitClasses } from './classes'

const Livestream = dynamic(() => import('@components/StatusManager/LiveAndReplay/components/Livestream'), {
	ssr: false
})

const mapStateToProps = (state: State, props: GenericPlayerTemplateProps) => {
	return { ...state, ...props }
}

const DesktopPortraitTemplate: React.FC<GenericPlayerTemplateProps> = ({
	/** @Refs */
	trackerRef,
	livestreamRef,
	timelineRef,
	chatboxRef,
	/** @Booleans */
	isCinema,
	isStarted,
	isMuted,
	isTranslated,
	isReduced,
	isLive,
	isReplay,
	isReplayOrFinishing,
	isDragged,
	hasConversationalReplay,
	/** @Values */
	sizes,
	products,
	playerData,
	/** @Setters */
	onCinema,
	onDrag,
	onWrite,
	onPlay,
	onPlayOnReady,
	onPause,
	onLiveStreamPlay,
	onSeekTo
}) => {
	const { drawerOpen, currentDrawer, shutterOpen, currentShutter } = useNotificationsContext()
	const { isPlayed, isReady, playOnReady, hasPlayedOnReady, isVertical, fakeStatus } = usePlayerContext()

	useEffect(() => {
		if (isReady && playOnReady && !hasPlayedOnReady && !isPlayed) {
			onPlayOnReady()
		}
	}, [isPlayed, isReady, playOnReady])

	return (
		<LiveContentWrapper
			className={(livePlayerclasses.root, desktopPortraitClasses.root)}
			height={sizes.height}
			isReduced={isReduced}
		>
			<Shutters isOpen={shutterOpen}>
				{currentShutter?.Component && React.cloneElement(currentShutter.Component)}
			</Shutters>
			{!isReduced && (
				<LeftPanel
					width={sizes.width / 3}
					className={cx({
						[desktopPortraitClasses.chatIsVisible]: !isMuted || isStarted,
						[desktopPortraitClasses.replaySpinner]: hasConversationalReplay && isReplay
					})}
				>
					{(isLive || fakeStatus === 'live' || (isReplay && !hasConversationalReplay)) && (
						<>
							<LeftPanelTitle>
								<Box mt="5px" color={Theme.colors.text_main}>
									{playerData?.ui.panel_chat_title}
								</Box>
							</LeftPanelTitle>
							<Chat fieldBorderActive onWrite={onWrite} chatRef={chatboxRef} pinningPosition="top" />
						</>
					)}
				</LeftPanel>
			)}
			<LivePlayerContainer isReduced={isReduced}>
				{!isReduced && (
					<Drawer isOpen={drawerOpen && !isReduced}>
						{currentDrawer?.Component && React.cloneElement(currentDrawer.Component)}
					</Drawer>
				)}
				{isMuted && !isReduced && (
					<Unmute onPlayClick={onPlay} playerIsUnmuted={!isMuted} playerIsReady={isReady} />
				)}
				{!isReduced && <WinningInstantButton trackerRef={trackerRef} buttonTop={81} />}
				<Livestream innerRef={livestreamRef} onPlaying={onLiveStreamPlay} />
				{!isReduced && (
					<Flex
						width={1}
						height={['-webkit-fill-available', '100%', '100%']}
						className={'ui'}
						css={[
							Style.ui,
							isReplayOrFinishing ? Style.isReplay : null,
							!isMuted || isStarted ? Style.isVisible : null,
							isCinema ? Style.isCinema : null
						]}
					>
						<Header separator actions={['CLOSE_PLAYER', 'OTHERS']} />
						<PausePlayButton isDragged={isDragged} onPause={onPause} onPlay={onPlay} />
						<Flex
							width={1}
							css={[
								isLive || fakeStatus === 'live' ? Style.translatorLive : null,
								isReplayOrFinishing ? Style.translatorReplay : null,
								isTranslated ? Style.isTranslated : null
							]}
						>
							<LiveProducts />
							<BottomBar
								onWriting={() => onWrite(true)}
								onUnWriting={() => onWrite(false)}
								isTranslated={isTranslated}
								onCinemaClick={onCinema}
								isCinema={isCinema}
								products={products}
							/>
						</Flex>
						{isReplay && <Timeline innerRef={timelineRef} seekTo={onSeekTo} setIsDragged={onDrag} />}
					</Flex>
				)}
			</LivePlayerContainer>

			{!isReduced && (
				<Flex
					css={[
						css`
							width: ${sizes.width / 3}px;
							height: ${sizes.height}px;
						`,
						Style.desktopPanels,
						Style.desktopBlock,
						!isMuted || isStarted ? Style.desktopPanelsIsShown : null
					]}
					flexWrap="wrap"
					justifyContent="center"
				>
					<Flex
						css={Style.columnTitle}
						fontSize={'20px'}
						width={1}
						height={66}
						flexWrap="wrap"
						alignItems="center"
					>
						<Box mt="5px" color={Theme.colors.text_main}>
							{playerData?.ui.panel_catalog_title}
						</Box>
					</Flex>
					<Flex
						p={'0 25px'}
						width={1}
						height={'calc(100% - 67px)'}
						mt={66}
						flexWrap="wrap"
						justifyItems="center"
						css={Style.desktopCatalog}
					>
						<Items
							panelActive
							showIndexes={true}
							showStatuses={!isReplayOrFinishing}
							showPrices={true}
							twoByColumns={true}
							products={products}
							playerData={playerData}
						/>
						{!isReduced && <Panels />}
					</Flex>
				</Flex>
			)}
			{isVertical && !isReduced && (
				<DisplayModeNotifier isMobile={false} isHorizontal={false} isLandscape={false}>
					<div>{playerData.ui.turn_tablet_landscape}</div>
				</DisplayModeNotifier>
			)}
		</LiveContentWrapper>
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(DesktopPortraitTemplate)
