import { differenceInSeconds } from 'date-fns'
import { WinningInstant } from 'types/WinningInstant'

export class TimerService {
	static getTime = (time: Record<string, string> | Date): string => {
		return (time as Record<string, string>)?.seconds || time.toString()
	}

	static getCounter = (time: string, duration: number): number => {
		const now = new Date()
		const parsed = typeof time === 'number' ? new Date(time * 1000) : Date.parse(time)
		const inSeconds = duration === 0 ? 0 : duration / 1000
		return inSeconds - differenceInSeconds(now, parsed)
	}

	static getWinningInstantCounter = (winningInstant: WinningInstant) => {
		const time = winningInstant?.startTime ? this.getTime(winningInstant.startTime) : null
		const counter = !!time ? this.getCounter(time, winningInstant.duration) : 0
		return counter && counter > 0 ? counter : null
	}

	static formatedTimer = (timer) => {
		const minutes = Math.floor(timer / 60)
		const seconds = timer % 60

		const formatedSeconds = seconds < 10 ? `0${seconds}` : seconds
		return `${minutes}:${formatedSeconds}`
	}

	/**
	 * Calcul le temps entre deux time string
	 */
	static calculateInterval(ts1: string, ts2: string): number {
		// Extraire les heures, minutes et secondes
		const [hour1, minute1, second1] = ts1.slice(11, 19).split(':').map(Number)
		const [hour2, minute2, second2] = ts2.slice(11, 19).split(':').map(Number)

		// Convertir tout en secondes
		const ts1Seconds = hour1 * 3600 + minute1 * 60 + second1
		const ts2Seconds = hour2 * 3600 + minute2 * 60 + second2

		// Renvoyer la différence
		return ts2Seconds - ts1Seconds
	}
}
