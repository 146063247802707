import React from 'react'
import { ToastContainer, Variant, classes } from './style'

interface ToastProps {
	variant?: Variant
	onClick?: (event?: any) => void
	ref?: React.MutableRefObject<any>
	children: any
}

const Toast: React.FC<ToastProps> = ({ children, variant, ...props }) => {
	return (
		<ToastContainer className={classes.root} variant={variant} {...props}>
			{children}
		</ToastContainer>
	)
}

export const toastClasses = classes

export default Toast
