import styled from '@emotion/styled'
import { BaseFlex } from '@styles/common'
import { customScrollbar } from '@styles/shared/customScrollbar'
import { handleSquaredEdges } from '@styles/sharedLogics'
import Theme from '@styles/theme'

export const SurveyWrapper = styled(BaseFlex)`
	justify-content: center;
	flex-direction: column;
	align-items: center;
	gap: ${Theme.spacing(6)};
	padding: ${Theme.spacing(6)};

	a.medium.plain {
		padding: 19px 30px; // Specific cta padding for mobile
	}
`

const getSize = (isMobile: boolean) => (isMobile ? '60px' : '50px')

export const BrandAvatar = styled.div<{ isMobile: boolean }>`
	display: flex;
	justify-content: center;
	align-items: center;
	width: ${({ isMobile }) => getSize(isMobile)};
	height: ${({ isMobile }) => getSize(isMobile)};
	border-radius: 100%;
	overflow: hidden;
	/* For weird reasons, the look of the border is bugged on mobile (no border radius) */
	/* Box shadow somewhat fixes the issue but the look is different from desktop to mobile */
	border: 1px solid ${Theme.colors.black(0.15)};
	* {
		border-radius: 100%;
		overflow: hidden;
	}
	.placeholder-wrapper {
		background-color: transparent !important;
	}
	${handleSquaredEdges};
`

export const SurveyH1 = styled.div`
	font-size: 20px;
	line-height: 24px;
	text-align: center;
	font-weight: bold;
	color: ${() => Theme.colors.text_main};
`

export const CommentaryLabel = styled.div`
	font-size: 16px;
	line-height: 20px;
	text-align: center;
	color: ${() => Theme.colors.text_main};
	font-weight: bold;
	margin-bottom: ${Theme.spacing(3)};
`

export const TextAreaWrapper = styled.div`
	margin-top: ${Theme.spacing(4)};
	margin-bottom: ${Theme.spacing(6)};
`

export const TextAreaComponent = styled.textarea<{ isMobile: boolean }>`
	padding: ${Theme.multiSpacing(2, 3)};
	border-radius: 10px;
	border-color: ${Theme.colors.black(0.1)};
	width: ${({ isMobile }) => (isMobile ? 'calc(100vw - 80px)' : '410px')};
	max-width: 410px;
	font-family: PlusJakartaSans;
	resize: none;
	font-size: ${({ isMobile }) => isMobile && '13px'};
	${customScrollbar};

	&:focus-visible {
		outline: none;
	}
`
