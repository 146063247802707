import Theme from '@styles/theme'
import styled from '@emotion/styled'

const PREFIX = 'switch'

export const classes = {
	root: PREFIX,
	active: `active`,
	reverse: 'reverse',
	fullWidth: 'full-width',
	disabled: 'disabled'
}

export const SwitchBar = styled.div`
	display: flex;
	align-items: center;
	width: 40px;
	height: 20px;
	border-radius: 8px;
	position: relative;
	background-color: ${Theme.colors.grey2};

	&.${classes.active} {
		background-color: ${Theme.colors.green()};
	}
`

export const SwitchButton = styled.div`
	margin: 0 4px;
	transition: all 0.2s ease;
	height: 12px;
	width: 12px;
	border-radius: 10px;
	background-color: ${Theme.colors.white()};
	&.${classes.active} {
		transform: translateX(20px);
	}
`

export const SwitchLabel = styled.div`
	font-family: ${Theme.fontFamilies.Main};
	font-size: 14px;
	line-height: 20px;
	font-weight: 700;
`

export const SwitchContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	cursor: pointer;
	&.${classes.reverse} {
		flex-direction: row-reverse;
	}
	&.${classes.fullWidth} {
		width: 100%;
		justify-content: space-between;
	}
	&.${classes.disabled} {
		pointer-events: none;
		cursor: auto;
		& ${SwitchBar} {
			background-color: ${Theme.colors.grey1};
		}
	}
`
