import { getModalBuild } from '@components/Notifiers/Modals/library'
import { ModalsActionType, ModalsContextAction, ModalsContextState } from './constants'

export const modalsReducer = (state: ModalsContextState, action: any): ModalsContextState => {
	switch (action.type) {
		case ModalsActionType.OPEN_MODAL:
			return handleOpenModal(state, action)
		case ModalsActionType.OPEN_NEXT_MODAL:
			return { ...state, modalOpen: true, currentModal: state.modalsQueue[0] }
		case ModalsActionType.CLOSE_MODAL:
			return handleCloseModal(state)
		default:
			break
	}
}

const handleOpenModal = (
	state: ModalsContextState,
	{ modalType, metadata, closeCurrent }: ModalsContextAction
): ModalsContextState => {
	const modalBuild = getModalBuild(modalType, metadata)
	let modalsQueue = [modalBuild, ...state.modalsQueue]

	if (closeCurrent) {
		modalsQueue = modalsQueue.filter((modal) => modal.modalType !== state.currentModal.modalType)
	}

	return {
		modalOpen: true,
		currentModal: modalBuild,
		modalsQueue,
		modalHistory: [...state.modalHistory, modalBuild.modalType]
	}
}

const handleCloseModal = (state: ModalsContextState): ModalsContextState => {
	return {
		modalOpen: false,
		currentModal: state.modalsQueue.length > 1 ? state.modalsQueue[1] : undefined,
		modalsQueue: state.modalsQueue.slice(1),
		modalHistory: state.modalHistory
	}
}
