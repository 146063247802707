import React from 'react'
import Theme from '@styles/theme'
import IconCta from '@components/Micro/IconCta'
import Constants from '@constants'
import Bus from '@helpers/bus'
import { usePlayerContext } from '@helpers/contexts/player'
import { useTimeLineContext } from '@helpers/contexts/timeline'

interface CartButtonProps {
	playerData?: any
	products?: any
	show?: boolean
}

const CartButton: React.FC<CartButtonProps> = ({ show = true }) => {
	const { videoMode } = usePlayerContext()
	const { currentTime } = useTimeLineContext()

	const handleClick = (e) => {
		e.stopPropagation()
		Bus.send(Constants.bus.player.link_browsed, {
			url: 'cart',
			target: '_self',
			isLandscape: videoMode === 'landscape',
			timecode: currentTime
		})
		Bus.send(Constants.bus.player.cart_clicked)
	}

	return (
		<>
			{show && (
				<IconCta
					iconProps={{
						name: 'cart',
						fill: Theme.colors.white()
					}}
					onClick={handleClick}
				/>
			)}
		</>
	)
}

export default CartButton
