import { css, keyframes } from '@emotion/react'
import Theme from '@styles/theme'

const fade = keyframes`
	0% {
		opacity: 0.25;
		transform: scale(0.97);
	}
	50% {
		opacity: 1;
		transform: scale(1);
	}
	100% {
		opacity: 0.25;
		transform: scale(0.97)
    }
`

export default {
	template: css`
		position: absolute;
		left: 50%;
		top: 50%;
		margin-left: -20px;
		margin-top: -20px;
		animation: ${fade} 1200ms ${Theme.eases.linear} infinite;
	`
}
