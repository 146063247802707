export interface ShutterConfig {
	onClose: () => void
	hasIgnore?: boolean
}
export interface ShutterBuild {
	shutterType: string
	Component?: JSX.Element
	config?: ShutterConfig
}

// TODO: Remove these when sure to move CSAT to the library
export enum ShuttersType {
	CSAT_SHUTTER = 'csat_shutter',
	CSAT_SHUTTER_EXIT = 'csat_shutter_exit'
}
