import React, { useImperativeHandle, useState } from 'react'
import { PausePlayButtonWrapper, PausePlayedButtonEl } from './style'
import { mapDispatchToProps } from '@helpers/wrappers/withReduxStore'
import { State } from '@stores/index'
import { connect } from 'react-redux'
import Icon from '@components/Micro/Icon'
import Theme from '@styles/theme'
import { usePlayerContext } from '@helpers/contexts/player'
interface Props {
	onPlay?: () => void
	onPause?: () => void
	isDragged?: boolean
	innerRef?: any
}

const mapStateToProps = (state: State, props: Props) => {
	return { ...state, ...props }
}

const PlayOrPauseButton = ({ onClick, mode }) => {
	return (
		<PausePlayedButtonEl className="pause-play" onClick={onClick}>
			<Icon name={mode} width="100%" height="100%" fill={Theme.colors.text_main} />
		</PausePlayedButtonEl>
	)
}

const PausePlayButton: React.FC<Props> = ({ onPlay, onPause, isDragged, innerRef }) => {
	const [showPauseBtn, setShowPauseBtn] = useState<boolean>(false)
	const { isStreaming, isStarted, isPlayed, isReplay, isMobile, isReady } = usePlayerContext()

	const playHandler = (e) => {
		e.stopPropagation()
		onPlay()
		if (isReady) {
			scrollTo({ top: document.documentElement.scrollTop, behavior: 'smooth' })
		}
		if (showPauseBtn) setShowPauseBtn(false)
	}

	const pauseHandler = (e) => {
		e.stopPropagation()
		if (showPauseBtn) setShowPauseBtn(false)
		onPause()
	}

	const handleHover = (show: boolean) => {
		if (!isPlayed) return
		if (!isMobile) {
			setShowPauseBtn(show)
		}
	}

	const onClick = (e) => {
		if (!isPlayed) return
		if (isMobile) {
			setShowPauseBtn(!showPauseBtn)
		} else {
			if (!showPauseBtn) {
				pauseHandler(e)
			}
		}
	}

	useImperativeHandle(
		innerRef,
		() => ({
			showPauseBtn(boolean) {
				setShowPauseBtn(boolean)
			}
		}),
		[]
	)

	return (
		<>
			{isStreaming && isReplay && !isDragged && isStarted && (
				<PausePlayButtonWrapper
					onMouseEnter={() => handleHover(true)}
					onMouseLeave={() => handleHover(false)}
					onClick={onClick}
					hasShadow={!isPlayed || showPauseBtn}
				>
					{showPauseBtn && isPlayed && !isDragged && (
						<PlayOrPauseButton mode="pause" onClick={pauseHandler} />
					)}
					{!isPlayed && <PlayOrPauseButton mode="play" onClick={playHandler} />}
				</PausePlayButtonWrapper>
			)}
		</>
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(PausePlayButton)
