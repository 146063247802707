import React, { ReactElement, useEffect, useState } from 'react'
import { Flex, Box } from 'rebass'
import Icon from '@components/Micro/Icon'
import Cta from '@components/Micro/Cta'
import Theme from '@styles/theme'
import Style from './style'
import { mapDispatchToProps } from '@helpers/wrappers/withReduxStore'
import { State } from '@stores/index'
import { connect } from 'react-redux'
import { css } from '@emotion/react'
import { useNotificationsContext } from '@helpers/contexts/notifications'
import { Product, TimeCode } from 'types/Product'
import { usePlayerContext } from '@helpers/contexts/player'

interface Props {
	dispatch?: (action) => void
	width?: any
	height?: any
	p?: any
	style?: any
	item?: Product
	index?: any
	isCta?: boolean
	storeDatas?: any
	storeTimecodes?: TimeCode[]
	storeStepsProgram?: any
	text?: any
}

const mapStateToProps = (state: State, props: Props) => {
	return { ...state, ...props }
}

const GoToReplay: React.FC<Props> = ({
	width = 40,
	height = 40,
	p = '11px',
	style,
	item,
	isCta = false,
	storeDatas,
	storeStepsProgram,
	storeTimecodes,
	text = null,
	dispatch
}) => {
	const [el, setEl] = useState<ReactElement<any, any>>(null)
	const { closeAllPanels } = useNotificationsContext()
	const { isMobile } = usePlayerContext()

	useEffect(() => {
		let output = {}
		storeStepsProgram.forEach((step) => {
			step?.products.forEach((product: Product) => {
				let targetIndex = step?.index
				storeTimecodes.forEach((_el, i) => {
					let tc = storeTimecodes[i]
					if (tc?.step === targetIndex && !output[product.uuid]) {
						output[product.uuid] = tc
					}
				})
			})
		})
		if (item) {
			const productTimecode = output[item.uuid]
			if (productTimecode) {
				isCta ? setEl(cta(productTimecode)) : setEl(notCta(productTimecode))
			}
		}
	}, [storeDatas, item, storeTimecodes, storeStepsProgram])

	const onCtaClick = (productTimecode) => {
		if (isMobile) {
			closeAllPanels()
		}
		dispatch({ type: 'LIVESTREAM_SEEK_TO', time: productTimecode.ts / 1000 })
	}

	const cta = (productTimecode) => (
		<Cta
			label={storeDatas.ui.panel_product_story_cta}
			onClick={() => onCtaClick(productTimecode)}
			icon={'play'}
			style={[
				Style.btn,
				css`
					font-size: 12em !important;
					background-color: ${Theme.colors.red()} !important;
					flex-grow: 1;
				`
			]}
		/>
	)

	const notCta = (productTimecode) => (
		<Flex
			css={[
				Style.el,
				style,
				css`
					border: 1px solid ${Theme.colors.red()};
				`,
				Theme.squaredEdges
			]}
			width={[width, !isCta ? width : 'auto']}
			pl={[0, isCta ? 10 : 0]}
			height={height}
			onClick={() => onCtaClick(productTimecode)}
			backgroundColor={Theme.colors.red()}
			alignItems="center"
		>
			<Box width={width} height={height} p={p}>
				<Icon name="play" width="100%" height="100%" fill={Theme.colors.white()} />
			</Box>
			{text}
		</Flex>
	)
	return <>{item && el}</>
}

export default connect(mapStateToProps, mapDispatchToProps)(GoToReplay)
