import { Brand } from 'types/Branding'
import Theme from '@styles/theme'

interface BrandContextState {
	brand: Brand
}

export enum BrandContextActions {
	SET_BRAND = 'set_brand'
}

export const brandContextInitialState: BrandContextState = {
	brand: { name: '', avatar: { url: null }, color: Theme.colors.cta_main }
}

export const brandReducer = (state: BrandContextState, action: any): BrandContextState => {
	switch (action.type) {
		case BrandContextActions.SET_BRAND:
			return checkAndSetBrand(state, action.data)
		default:
			break
	}
}

const checkAndSetBrand = (state: BrandContextState, data: any): BrandContextState => {
	let newBrand = brandContextInitialState.brand
	switch (data?.channel?.name) {
		case 'Unify':
			newBrand = {
				name: 'Magimix',
				avatar: { url: 'medias/UOQMxj3QrPvoiTxUxaM3/OeO8Q7UlBmdVbz9DiG8M.png' },
				color: '#677887'
			}
			break
		default:
			newBrand = {
				name: data.event.event.settings.branding.brandName,
				avatar: data.event.event.settings.branding.avatar,
				color: Theme.colors.cta_main
			}
			break
	}

	return { ...state, brand: newBrand }
}
