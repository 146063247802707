import { css } from '@emotion/react'
import Theme from '@styles/theme'

export const handleSquaredEdges = () => css`
	${Theme.squaredEdges}
`

export const classGenerator = (...args) => {
	let buffer = []

	args.filter(Boolean).forEach((arg) => {
		const argType = typeof arg
		switch (argType) {
			case 'string':
				buffer.push(arg)
				break
			case 'object':
				Object.entries(arg).map(([className, active]) => {
					if (active) {
						buffer.push(className)
					}
				})
				break
			default:
				break
		}
	})

	return buffer.join(' ')
}
