import _Math from './math'
import Is from './is'
import Easing from './easing'

class Utils {
	static getPrefix() {
		let pre = ''
		const styles = window.getComputedStyle(document.documentElement, '')
		pre = (Array.prototype.slice
			.call(styles)
			.join('')
			.match(/-(moz|webkit|ms)-/) ||
			(styles.OLink === '' && ['', 'o']))[1]
		const dom = 'WebKit|Moz|MS|O'.match(new RegExp('(' + pre + ')', 'i'))[1]

		return {
			dom,
			lowercase: pre,
			css: '-' + pre + '-',
			js: pre[0].toUpperCase() + pre.substr(1)
		}
	}

	static uniqueId() {
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
			const r = (Math.random() * 16) | 0
			const v = c === 'x' ? r : (r & 0x3) | 0x8
			return v.toString(16)
		})
	}

	static getPromisedImages(files) {
		const promises = []
		let i = files.length
		while (i--) {
			promises[i] = new Promise(function (resolve) {
				const img = new Image()
				img.onload = function () {
					resolve(img)
				}
				img.src = files[i]
			})
		}
		return Promise.all(promises)
	}

	static nullObject(object) {
		for (const key in object) {
			object[key] = null
			delete object[key]
		}
	}

	static slugify(value) {
		let final = value
		const rExps = [
			{ re: /[\xC0-\xC6]/g, ch: 'A' },
			{ re: /[\xE0-\xE6]/g, ch: 'a' },
			{ re: /[\xC8-\xCB]/g, ch: 'E' },
			{ re: /[\xE8-\xEB]/g, ch: 'e' },
			{ re: /[\xCC-\xCF]/g, ch: 'I' },
			{ re: /[\xEC-\xEF]/g, ch: 'i' },
			{ re: /[\xD2-\xD6]/g, ch: 'O' },
			{ re: /[\xF2-\xF6]/g, ch: 'o' },
			{ re: /[\xD9-\xDC]/g, ch: 'U' },
			{ re: /[\xF9-\xFC]/g, ch: 'u' },
			{ re: /[\xC7-\xE7]/g, ch: 'c' },
			{ re: /[\xD1]/g, ch: 'N' },
			{ re: /[\xF1]/g, ch: 'n' }
		]

		for (let i = 0, len = rExps.length; i < len; i++) {
			final = final.replace(rExps[i].re, rExps[i].ch)
		}

		final = final
			.toLowerCase()
			.replace(/[?=]/g, '')
			.replace(/[!=]/g, '')
			.replace(/'+/g, '-')
			.replace(/\s+/g, '-')
			.replace(/[^a-z0-9-]/g, '')
			.replace(/-{2,}/g, '-')

		if (final.charAt(final.length - 1) === '-') {
			final = final.substr(0, final.length - 1)
		}

		return final
	}

	static removeTrailingSlash(path) {
		if (path.length !== 1) {
			return path.replace(/\/$/, '')
		} else {
			return path
		}
	}

	static addFirstSlash(path) {
		if (path.substring(0, 1) !== '/') {
			return '/' + path
		} else {
			return path
		}
	}

	// static replaceBetweenBrackets(string, start = "[", end = "]") {
	// 	var matches = string.match(/(\[).+?(\])/g);

	// 	if (matches) {
	// 		let i = matches.length;
	// 		while (i--) {
	// 			let match = matches[i];
	// 			string = string.replace(match, "ok");
	// 		}
	// 		return string;
	// 	}

	// 	return null;
	// }

	static replaceVars(string, vars, splitter = ' ') {
		let result = string
		const frags = string.split(splitter)

		let i = frags.length

		while (i--) {
			const frag = frags[i]

			if (frag.indexOf('{') === 0 && frag.indexOf('}') === frag.length - 1) {
				let key = frag.substr(1)
				key = key.slice(0, -1)

				if (vars[key]) {
					result = result.replace(frag, vars[key])
				}
			}
		}

		return result
	}

	static shorten(str, len, sep) {
		const _sep = sep || '...'
		const half = len / 2
		const firsthalf = half - _sep.length
		const lasthalf = half * -1

		return str.length > len ? str.substr(0, firsthalf) + '...' + str.substr(lasthalf, str.length) : str
	}

	static deleteFromArray(value, array) {
		const index = array.indexOf(value)

		if (index !== -1) {
			array.splice(index, 1)
		}
	}

	static escapeHTML(html) {
		const entityMap = {
			'&': '&amp;',
			'<': '&lt;',
			'>': '&gt;',
			'"': '&quot;',
			"'": '&#39;',
			'/': '&#x2F;'
		}

		return html.replace(/[&<>"'/]/g, function (s) {
			return entityMap[s]
		})
	}

	static clone(source) {
		let clone
		if (Object.prototype.toString.call(source) === '[object Array]') {
			clone = []
			for (let i = 0; i < source.length; i++) {
				clone[i] = this.clone(source[i])
			}
			return clone
		} else if (typeof source === 'object') {
			clone = {}
			for (const prop in source) {
				if (source.hasOwnProperty(prop)) {
					clone[prop] = this.clone(source[prop])
				}
			}
			return clone
		} else {
			return source
		}
	}

	static mergeDeep(target = {}, src = {}) {
		const array = Array.isArray(src)
		let dst = (array && []) || {}
		const _this = this

		if (array) {
			const _target = target || []
			dst = dst.concat(_target)

			src.forEach(function (e, i) {
				if (typeof dst[i] === 'undefined') {
					dst[i] = e
				} else if (typeof e === 'object') {
					dst[i] = _this.mergeDeep(_target[i], e)
				} else {
					if (_target.indexOf(e) === -1) {
						dst.push(e)
					}
				}
			})
		} else {
			if (target && typeof target === 'object') {
				Object.keys(target).forEach(function (key) {
					dst[key] = target[key]
				})
			}

			Object.keys(src).forEach(function (key) {
				if (typeof src[key] !== 'object' || !src[key]) {
					dst[key] = src[key]
				} else {
					if (!target[key]) {
						dst[key] = src[key]
					} else {
						dst[key] = _this.mergeDeep(target[key], src[key])
					}
				}
			})
		}

		return dst
	}

	static getAbsoluteBoundingRect(el) {
		let doc = document
		let win = window
		let body = doc.body
		// pageXOffset and pageYOffset work everywhere except IE <9.
		let offsetX =
			win.pageXOffset !== undefined
				? win.pageXOffset
				: (doc.documentElement || body.parentNode || body).scrollLeft
		let offsetY =
			win.pageYOffset !== undefined ? win.pageYOffset : (doc.documentElement || body.parentNode || body).scrollTop
		let rect = el.getBoundingClientRect()

		if (el !== body) {
			let parent = el.parentNode

			// The element's rect will be affected by the scroll positions of
			// *all* of its scrollable parents, not just the window, so we have
			// to walk up the tree and collect every scroll offset. Good times.
			while (parent !== body) {
				offsetX += parent.scrollLeft
				offsetY += parent.scrollTop
				parent = parent.parentNode
			}
		}

		return {
			bottom: rect.bottom + offsetY,
			height: rect.height,
			left: rect.left + offsetX,
			right: rect.right + offsetX,
			top: rect.top + offsetY,
			width: rect.width
		}
	}

	static getposition(el) {
		let xPos = 0
		let yPos = 0
		let _el = el

		while (_el) {
			if (_el.tagName === 'BODY') {
				// deal with browser quirks with body/window/document and page scroll
				const xScroll = _el.scrollLeft || document.documentElement.scrollLeft
				const yScroll = _el.scrollTop || document.documentElement.scrollTop

				xPos += _el.offsetLeft - xScroll + _el.clientLeft
				yPos += _el.offsetTop - yScroll + _el.clientTop
			} else {
				// for all other non-BODY elements
				xPos += _el.offsetLeft - _el.scrollLeft + _el.clientLeft
				yPos += _el.offsetTop - _el.scrollTop + _el.clientTop
			}

			_el = _el.offsetParent
		}

		return {
			x: xPos,
			y: yPos
		}
	}

	static capitalize(string) {
		return string.charAt(0).toUpperCase() + string.slice(1)
	}

	static uppercase(string) {
		return string.toUpperCase()
	}

	static lowercase(string) {
		return string.toLowerCase()
	}

	static sortByKey(array, key) {
		return array.sort(function (a, b) {
			const x = a[key]
			const y = b[key]

			return x < y ? -1 : x > y ? 1 : 0
		})
	}

	static parseHtml(markup) {
		let el
		if (markup.toLowerCase().trim().indexOf('<!doctype') === 0) {
			const doc = document.implementation.createHTMLDocument('')
			doc.documentElement.innerHTML = markup
			return doc
		} else if ('content' in document.createElement('template')) {
			// Template tag exists!
			el = document.createElement('template')
			el.innerHTML = markup
			return el.content
		} else {
			// Template tag doesn't exist!
			const docfrag = document.createDocumentFragment()
			el = document.createElement('body')
			el.innerHTML = markup
			for (let i = 0; i < el.childNodes.length; i++) {
				docfrag.appendChild(el.childNodes[i])
			}
			return docfrag
		}
	}

	static createElement(settings) {
		if (settings.type) {
			const el = document.createElement(settings.type)

			if (settings.type) {
				el.classList.add(settings.class)
			}

			if (settings.style) {
				let style = ''

				for (const property in settings.style) {
					if (settings.style.hasOwnProperty(property)) {
						style += property + ':' + settings.style[property] + ';'
					}
				}

				el.setAttribute('style', style)
			}

			return el
		} else {
			return document.createElement('div')
		}
	}

	static serializeObject(obj) {
		return Object.keys(obj).reduce((prev, key, i) => `${prev}${i !== 0 ? '&' : ''}${key}=${obj[key]}`, '')
	}

	static getCoverSize(pw, ph, cw, ch) {
		const pr = pw / ph
		const cr = cw / ch

		if (cr < pr) {
			return {
				w: ch * pr,
				h: ch,
				y: 0,
				x: -(ch * pr - cw) * 0.5
			}
		} else {
			return {
				w: cw,
				h: cw / pr,
				y: -(cw / pr - ch) * 0.5,
				x: 0
			}
		}
	}

	static shuffle(array) {
		let currentIndex = array.length
		let temporaryValue
		let randomIndex

		// While there remain elements to shuffle...
		while (currentIndex !== 0) {
			// Pick a remaining element...
			randomIndex = Math.floor(Math.random() * currentIndex)
			currentIndex -= 1

			// And swap it with the current element.
			temporaryValue = array[currentIndex]
			array[currentIndex] = array[randomIndex]
			array[randomIndex] = temporaryValue
		}

		return array
	}

	static setCookie(name, value, days) {
		let expires = ''
		if (days) {
			const date = new Date()
			date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
			expires = '; expires=' + date.toUTCString()
		}
		document.cookie = name + '=' + (value || '') + expires + '; path=/'
	}

	static getCookie(name) {
		const nameEQ = name + '='
		const ca = document.cookie.split(';')
		for (let i = 0; i < ca.length; i++) {
			let c = ca[i]
			while (c.charAt(0) === ' ') c = c.substring(1, c.length)
			if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
		}
		return null
	}

	static eraseCookie(name) {
		document.cookie = name + '=; Max-Age=-99999999;'
	}

	static scrollTo(to, duration, element = document.scrollingElement || document.documentElement) {
		const start = element.scrollTop
		const change = to - start
		const startDate = +new Date()
		const easeInOutQuad = function (tt, b, c, d) {
			let t = tt
			t /= d / 2
			if (t < 1) return (c / 2) * t * t + b
			t--
			return (-c / 2) * (t * (t - 2) - 1) + b
		}
		const animateScroll = function () {
			const currentDate = +new Date()
			const currentTime = currentDate - startDate
			element.scrollTop = parseInt(easeInOutQuad(currentTime, start, change, duration))
			if (currentTime < duration) {
				requestAnimationFrame(animateScroll)
			} else {
				element.scrollTop = to
			}
		}
		animateScroll()
	}

	static hexToRgb(hex) {
		const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
		const hexx = hex.replace(shorthandRegex, function (m, r, g, b) {
			return r + r + g + g + b + b
		})

		const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexx)
		return result
			? {
					r: parseInt(result[1], 16),
					g: parseInt(result[2], 16),
					b: parseInt(result[3], 16)
				}
			: null
	}

	static getTags(string) {
		var tag = document.createElement('div')
		tag.innerHTML = string

		var t = tag.getElementsByTagName('*')
		var ele = []
		for (var i = 0; i < t.length; i++) {
			ele.push(t[i].tagName.toLowerCase())
		}
		return ele
	}

	static replaceTag(string, oldTag, newTag, href, target = '_self') {
		string = string.replace(
			'<' + oldTag + '>',
			href
				? '<' + newTag + ' class="replaced-' + newTag + '" href="' + href + '" target="' + target + '">'
				: '<' + newTag + '>'
		)
		string = string.replace('</' + oldTag + '>', '</' + newTag + '>')

		return string
	}

	static trunc = (text, limit, words) => {
		if (text.length > words) {
			for (let i = limit; i > 0; i--) {
				if (
					text.charAt(i) === ' ' &&
					(text.charAt(i - 1) != ',' || text.charAt(i - 1) != '.' || text.charAt(i - 1) != ';')
				) {
					return text.substring(0, i) + '...'
				}
			}
			return text.substring(0, words) + '...'
		} else return text
	}

	static setReadablePrice = (value, locale, code) => {
		return new Intl.NumberFormat(locale, {
			style: 'currency',
			currency: code,
			currencyDisplay: 'narrowSymbol'
		}).format(value)
	}

	static getAbbr = (timezone, locale) => {
		const abbr = new Date().toLocaleTimeString(locale, { timeZone: timezone, timeZoneName: 'short' }).split(' ')[2]

		return abbr && !abbr.includes('GMT') ? abbr : null
	}

	static setReadableDate = (time, locale, timezone) => {
		const formatted = new Intl.DateTimeFormat(locale, {
			dateStyle: 'full',
			timeStyle: 'short',
			timeZone: timezone
		}).format(time)

		const abbr = Utils.getAbbr(timezone, locale)

		return abbr ? formatted + ` (${abbr})` : formatted
	}

	static getColorByLetter = (value) => {
		let colors = {
			a: '#2f4f4f',
			b: '#8b4513',
			c: '#808000',
			d: '#483d8b',
			e: '#008000',
			f: '#bc8f8f',
			g: '#000080',
			h: '#9acd32',
			i: '#8fbc8f',
			j: '#8b008b',
			k: '#b03060',
			l: '#ff0000',
			m: '#ffa500',
			n: '#ffff00',
			o: '#7fff00',
			p: '#00fa9a',
			q: '#8a2be2',
			r: '#dc143c',
			s: '#00ffff',
			t: '#0000ff',
			u: '#ff7f50',
			v: '#ff00ff',
			w: '#1e90ff',
			x: '#f0e68c',
			y: '#ee82ee',
			z: '#87cefa'
		}
		return colors[value]
	}
}

Utils.easing = Easing
Utils.is = Is
Utils.math = _Math

export default Utils
