import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { mapDispatchToProps } from '@helpers/wrappers/withReduxStore'
import { State } from '@stores/index'
import { connect } from 'react-redux'
import { Box } from 'rebass'
import Bus from '@helpers/bus'
import Constants from '@constants'
import Sizes from '@tools/sizes'
import BaseModal from '@components/Modals/BaseModal'
import { usePlayerContext } from '@helpers/contexts/player'
import MobilePortraitTemplate from './templates/MobilePortrait'
import { GenericPlayerTemplateProps } from './templates/constant'
import DesktopPortraitTemplate from './templates/DesktopPortrait'
import { LiveWrapper } from './templates/style'
import { useNotificationsContext } from '@helpers/contexts/notifications'
import DesktopLandscapeTemplate from './templates/DesktopLandscape'
import MobileLandscapeTemplate from './templates/MobileLandscape'
import { getPlayerDisplayModeValue } from '@helpers/contexts/player/logic'
import { DisplayModeNotifier } from './style'
import { sortCatalogProducts } from '@helpers/ProductsSorter'

const PlayerTemplate = (
	props: GenericPlayerTemplateProps & { displayMode: ReturnType<typeof getPlayerDisplayModeValue> }
): JSX.Element => {
	const { displayMode, ...templateProps } = props
	switch (displayMode) {
		case 'DESKTOP_LANDSCAPE':
			return <DesktopLandscapeTemplate {...templateProps} />
		case 'DESKTOP_PORTRAIT':
			return <DesktopPortraitTemplate {...templateProps} />
		case 'MOBILE_LANDSCAPE':
			return <MobileLandscapeTemplate {...templateProps} />
		case 'MOBILE_PORTRAIT':
			return <MobilePortraitTemplate {...templateProps} />
	}
}

interface Props {
	dispatch?: (action) => void
	playerData?: any
	storeStepsProgram?: any
	storeProducts?: any
	storeCurrentStep?: any
	storeTimecodes?: any
	storeIsOffline?: boolean
	storeLastConsent?: Record<string, any>
}

const mapStateToProps = (state: State, props: Props) => {
	return { ...state, ...props }
}

const Live: React.FC<Props> = ({ dispatch, storeProducts, storeCurrentStep, storeIsOffline }) => {
	const {
		isMobile,
		isVertical,
		isReady,
		isStreaming,
		isPlayed,
		isStarted,
		isMuted,
		isReplayOrFinishing,
		playerData,
		hasConversationalReplay,
		videoMode,
		displayMode,
		handlePlay,
		handlePlayOnReady,
		handlePause,
		isLive,
		isReplay,
		isReduced,
		setIsReduced,
		isCinema,
		setIsCinema,
		isWriting,
		setIsWriting,
		setChatRef
	} = usePlayerContext()
	const { isPip, closeLastPanel } = useNotificationsContext()

	const [isTranslated, setIsTranslated]: [boolean, Dispatch<SetStateAction<boolean>>] = useState(false)
	const [isDragged, setIsDragged]: [boolean, Dispatch<SetStateAction<boolean>>] = useState(false)
	const [sizes, setSizes]: [Record<string, number>, Dispatch<SetStateAction<Record<string, number>>>] = useState({
		width: 0,
		height: 0
	})

	const livestreamRef: React.MutableRefObject<JSX.Element> = useRef()
	const timelineRef: React.MutableRefObject<JSX.Element> = useRef()
	const chatboxRef: React.MutableRefObject<JSX.Element> = useRef()

	const seekToHandler = (t) => {
		if (livestreamRef.current && isReady) {
			//@ts-ignore
			livestreamRef.current.seekTo(t)
		}
	}

	const getObjectFitSize = (contains, containerWidth, containerHeight, width, height) => {
		const doRatio = width / height
		const cRatio = containerWidth / containerHeight
		let targetWidth = 0
		let targetHeight = 0
		const test = contains ? doRatio > cRatio : doRatio < cRatio

		if (test) {
			targetWidth = containerWidth
			targetHeight = targetWidth / doRatio
		} else {
			targetHeight = containerHeight
			targetWidth = targetHeight * doRatio
		}

		return {
			width: targetWidth,
			height: targetHeight,
			x: (containerWidth - targetWidth) / 2,
			y: (containerHeight - targetHeight) / 2
		}
	}

	const sizesHandler = (s) => {
		const format = { x: 9, y: 16 }
		const padding = { x: s.width * 0.025, y: s.height * 0.05 }
		const of = getObjectFitSize(true, s.width - padding.x * 2, s.height - padding.y * 2, format.x * 3, format.y)

		setSizes({ width: of.width, height: of.height })
	}

	const playingHandler = (ct) => {
		if (timelineRef.current) {
			//@ts-ignore
			timelineRef.current.update(ct)
		}
	}

	const cinemaClick = () => {
		dispatch({ type: 'REMOVE_MODAL' })

		setIsCinema(!isCinema)
	}

	useEffect(() => {
		let timer = null
		setIsTranslated(false)
		if (storeCurrentStep) {
			timer = setTimeout(() => {
				setIsTranslated(true)
			}, 800)
		}
		return () => {
			if (timer) {
				clearTimeout(timer)
			}
		}
	}, [storeCurrentStep])

	useEffect(() => {
		const networkHandler = () => {
			if (navigator.onLine) {
				dispatch({ type: 'UNSET_IS_OFFLINE' })
			} else {
				dispatch({ type: 'SET_IS_OFFLINE' })
			}
		}

		Bus.send(Constants.bus.player.history_changed, 0)
		window.addEventListener('online', networkHandler)
		window.addEventListener('offline', networkHandler)

		return () => {
			window.removeEventListener('online', networkHandler)
			window.removeEventListener('offline', networkHandler)
		}
	}, [])

	useEffect(() => {
		const libraryOpenedHandler = () => {
			if (livestreamRef.current) {
				//@ts-ignore
				if (livestreamRef.current.getPlayer()) {
					//@ts-ignore
					livestreamRef.current.unmute()
				}
			}
		}

		const libraryClosedHandler = () => {
			if (livestreamRef.current) {
				//@ts-ignore
				if (livestreamRef.current.getPlayer()) {
					if (isReplay) {
						//@ts-ignore
						livestreamRef.current.pause()
					} else {
						//@ts-ignore
						livestreamRef.current.mute()
					}
				}
			}
		}

		const libraryReducedHandler = () => {
			setIsReduced(true)
		}

		const libraryExpandedHandler = () => {
			setIsReduced(false)
		}

		Bus.on(Constants.bus.library.opened, libraryOpenedHandler)
		Bus.on(Constants.bus.library.closed, libraryClosedHandler)
		Bus.on(Constants.bus.library.reduced, libraryReducedHandler)
		Bus.on(Constants.bus.library.expanded, libraryExpandedHandler)
		Bus.on(Constants.bus.library.remove_last_panel, closeLastPanel)
		Sizes.subscribe(sizesHandler)
		return () => {
			Bus.off(Constants.bus.library.opened, libraryOpenedHandler)
			Bus.off(Constants.bus.library.closed, libraryClosedHandler)
			Bus.off(Constants.bus.library.reduced, libraryReducedHandler)
			Bus.off(Constants.bus.library.expanded, libraryExpandedHandler)
			Sizes.unsubscribe(sizesHandler)
		}
	}, [])

	useEffect(() => {
		if (chatboxRef) {
			setChatRef(chatboxRef)
		}
	}, [chatboxRef])

	const buildPlayerProps = (): GenericPlayerTemplateProps => {
		return {
			playerData,
			livestreamRef,
			chatboxRef,
			timelineRef,
			isCinema,
			isHorizontal: !isVertical,
			isStreaming,
			isPlayed,
			isStarted,
			isMuted,
			isDragged,
			isWriting,
			isTranslated,
			isReduced,
			isPip,
			isLive,
			isReplay,
			isReplayOrFinishing,
			hasConversationalReplay,
			sizes,
			products: sortCatalogProducts(storeProducts, storeCurrentStep?.products, isReplay),
			currentStep: storeCurrentStep,
			onCinema: cinemaClick,
			onPlay: () => handlePlay(livestreamRef),
			onPlayOnReady: () => handlePlayOnReady(livestreamRef),
			onPause: () => handlePause(livestreamRef),
			onLiveStreamPlay: playingHandler,
			onSeekTo: seekToHandler,
			onDrag: setIsDragged,
			onWrite: (bool) => setIsWriting(bool)
		}
	}

	return (
		<LiveWrapper isLandscape={videoMode === 'landscape'} isHorizontal={!isVertical} isMobile={isMobile}>
			{storeIsOffline && !isReduced && (
				<DisplayModeNotifier isMobile={isMobile} isHorizontal={!isVertical} storeIsOffline={true}>
					<Box>{playerData?.ui?.check_network}</Box>
				</DisplayModeNotifier>
			)}
			<BaseModal />
			{displayMode && <PlayerTemplate displayMode={displayMode} {...buildPlayerProps()} />}
		</LiveWrapper>
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(Live)
