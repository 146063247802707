import cloneDeep from 'lodash.clonedeep'
import { PlayerState, EventStatuses, PlayerActions, IVSPlayerActions, DeviceTypes } from './constants'

export const playerReducer = (state: PlayerState, action: any): PlayerState => {
	switch (action.type) {
		case PlayerActions.SET_IS_STREAMING:
			return { ...state, isStreaming: action.data.isStreaming }
		case PlayerActions.SET_IS_PLAYED:
			return { ...state, isPlayed: action.data.isPlayed }
		case PlayerActions.SET_IS_MUTED:
			return { ...state, isMuted: action.data.isMuted }
		case PlayerActions.SET_PLAYER_DATA:
			return { ...state, ...handlePlayerData(state.playerData, action.data.playerData) }
		case PlayerActions.SET_IS_STARTED:
			return { ...state, isStarted: action.data.isStarted }
		case PlayerActions.SET_IS_READY:
			return { ...state, isReady: action.data.isReady }
		case PlayerActions.SET_PLAY_ON_READY:
			return { ...state, playOnReady: action.data.playOnReady }
		case PlayerActions.HANDLE_PLAY:
			return handlePlayClick(state, action?.data?.livestreamRef)
		case PlayerActions.HANDLE_PLAY_ON_READY:
			return {
				...handlePlayClick(state, action?.data?.livestreamRef),
				hasPlayedOnReady: true
			}
		case PlayerActions.HANDLE_PAUSE:
			return handlePauseClick(state, action?.data?.livestreamRef)
		/**
		 * @IVSActions
		 * */
		case IVSPlayerActions.BUFFERING:
			return { ...state, isStreaming: false }
		case IVSPlayerActions.READY:
			return { ...state, isReady: true }
		case IVSPlayerActions.REBUFFERING:
			break
		case IVSPlayerActions.IDLE:
			return { ...state, isMuted: state.isStarted ? state.isStarted : true, isPlayed: false }
		case IVSPlayerActions.ERROR:
			break
		case IVSPlayerActions.PLAYING:
			return { ...state, isStreaming: true }
		case IVSPlayerActions.TEXT_METADATA_CUE:
			break
		default:
			break
	}
}

export const isFinishing = (eventStatus: string, toFinish: boolean): boolean => {
	if (eventStatus) {
		return eventStatus === EventStatuses.REPLAY && toFinish
	}
}

const handlePlayerData = (currentPlayerData, newPlayerData) => {
	let queryStatus = window !== undefined ? new URL(window.location.href).searchParams?.get('status') : null
	queryStatus = queryStatus === 'live' ? 'fakelive' : queryStatus

	const newState: any = {
		playerData: newPlayerData,
		lastEventStatus: currentPlayerData?.event?.event?.status,
		eventStatus: queryStatus || newPlayerData?.event.event.status,
		hasConversationalReplay: newPlayerData?.event.event.settings.options.hasConversationalReplay,
		videoMode: newPlayerData?.event?.event?.settings?.videoMode || 'portrait'
	}
	if (newPlayerData?.event.event.status === EventStatuses.FINISHING) {
		newState.toFinish = true
	}
	return newState
}

const handlePlayClick = (state, livestreamRef) => {
	let newState = cloneDeep(state)
	if (livestreamRef.current && state.isReady) {
		//@ts-ignore
		if (livestreamRef.current.getPlayer()) {
			if (!state.isPlayed) {
				newState = { ...newState, isPlayed: true }
				//@ts-ignore
				livestreamRef.current.unmute()

				if (!state.isStarted) {
					//@ts-ignore
					livestreamRef.current.goToStart()
					newState = { ...newState, isStarted: true }
				}

				//@ts-ignore
				livestreamRef.current.play()
			}
			newState = { ...newState, isMuted: false }
		}
	}
	return newState
}

const handlePauseClick = (state, livestreamRef) => {
	let newState = cloneDeep(state)

	if (livestreamRef.current && state.isReady) {
		//@ts-ignore
		if (livestreamRef.current.getPlayer()) {
			if (state.isPlayed) {
				newState = { ...newState, isPlayed: false }
				//@ts-ignore
				livestreamRef.current.pause()
				//@ts-ignore
				livestreamRef.current.mute()
			}
		}
	}
	return newState
}

export const getPlayerDisplayModeValue = (device: DeviceTypes, videoMode: 'portrait' | 'landscape') => {
	if (device === 'mobile' && videoMode === 'landscape') {
		return 'MOBILE_LANDSCAPE' as const
	}
	if (device === 'mobile' && videoMode === 'portrait') {
		return 'MOBILE_PORTRAIT' as const
	}
	if (device !== 'mobile' && videoMode === 'landscape') {
		return 'DESKTOP_LANDSCAPE' as const
	}

	return 'DESKTOP_PORTRAIT' as const
}
