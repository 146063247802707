import { cardItemClasses } from '@components/Micro/CardItem'
import styled from '@emotion/styled'
import Theme from '@styles/theme'

export const ActionsContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	padding: ${Theme.spacing(7)} 0;
	gap: 20px;

	& .${cardItemClasses.root} {
		${Theme.fadeAndScaleOnClick(false)}
	}
`
