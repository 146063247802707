import { css } from '@emotion/react'

const PREFIX = 'likes-box'

export const classes = {
	root: PREFIX
}

export default {
	el: css`
		position: absolute;
		right: 0;
		bottom: 0;
		width: 121px;
		mask-image: -webkit-gradient(linear, left 70%, left top, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)));
		overflow: hidden;
		pointer-events: none;
		z-index: 3;
		bottom: 0;
		/* border: 1px solid red; */
	`,

	canvas: css`
		/* width: 100%;
		height: 100%; */
	`,

	hidden: css`
		display: none;
	`
}
