import React from 'react'
import Theme from '@styles/theme'
import { usePlayerContext } from '@helpers/contexts/player'
import { useNotificationsContext } from '@helpers/contexts/notifications'
import { PanelsType } from '@components/Notifiers/Panels/constants'
import { classes, OpenChatCta } from './style'
import { classGenerator as cx } from '@styles/sharedLogics'

interface OpenChatButtonProps {
	chatRef?: any
	playerData?: any
	className?: string
	show?: boolean
}

const OpenChatButton: React.FC<OpenChatButtonProps> = ({ show = true, chatRef, className }) => {
	const { setIsWriting, playerData, isMobile } = usePlayerContext()
	const { landscapeHorizontalChatOpened, openPanel, closeAllPanels } = useNotificationsContext()

	const handleClick = (e) => {
		e.stopPropagation()
		if (!landscapeHorizontalChatOpened) {
			openPanel(PanelsType.LANDSCAPE_HORIZONTAL_CHAT, {
				back: playerData?.ui.panel_chat_title,
				chatRef,
				onWrite: (bool) => setIsWriting(bool),
				isMobile: isMobile
			})
		} else {
			closeAllPanels()
		}
	}

	return (
		<>
			{show && (
				<OpenChatCta
					className={cx(className, classes.root, { [classes.active]: landscapeHorizontalChatOpened })}
					iconProps={{
						name: landscapeHorizontalChatOpened ? 'reduce_arrow' : 'unreduce_arrow',
						fill: Theme.colors.white()
					}}
					onClick={handleClick}
				/>
			)}
		</>
	)
}

export default OpenChatButton
