export interface DrawerConfig {
	isOpen: boolean
	onClose: () => void
}
export interface DrawerBuild {
	drawerType: string
	Component?: JSX.Element
}

export enum DrawersType {
	FIRST_NAME_DRAWER = 'first_name_drawer',
	POLL_DRAWER = 'poll_drawer',
	SHARE_AND_OPTOUT_DRAWER = 'share_and_optout_drawer',
	WINNING_INSTANT = 'winning_instant'
}
